import React from "react";
import styled from "styled-components";

import WhatBox from "../sidecomponents/whatbox.js";
import CheckIcon from "../graphics/check-icon.png";
import EyeIcon from "../graphics/eye-icon.png";
import RefreshIcon from "../graphics/refresh-icon.png";
import ChartIcon from "../graphics/chart-icon.png";

export default () => {
  return (
    <WhatItIsContainer>
      <DescriptionContainer>
        <H1>What is Query-It?</H1>
        <H3>Cloud-based Business Process Automation Platform</H3>
        <P>
          Query-It is a fully customizable platform to digitize and automate
          your work processes. All your business data is generated & stored in a
          standardized format with real-time access to information and
          performance metrics. Take advantage of business process automation to
          make better data informed decisions. If you are using manual methods
          (spreadsheets, logs, emails, etc.) to handle your business process
          then contact us to see how Query-It can help
        </P>
      </DescriptionContainer>
      <BoxesContainer>
        <WhatBox text={"Simple, Intuitive & Secure"} image={CheckIcon} />
        <WhatBox text={"Process First, Tool Second"} image={RefreshIcon} />
        <WhatBox text={"Connected Worker Technology"} image={EyeIcon} />
        <WhatBox text={"Real-time Analytics"} image={ChartIcon} />
      </BoxesContainer>
    </WhatItIsContainer>
  );
};

const WhatItIsContainer = styled.div`
  min-height: 650px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BoxesContainer = styled.div`
  width: 90%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1350px) and (min-width: 651px) {
    justify-content: center;
    display: grid;
    grid-template-columns: 294px 294px;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 40px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }
`;
const DescriptionContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 650px) {
    margin-bottom: 0;
  }
`;
const H1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 400%;
  color: #37227a;
  text-align: center;

  @media (max-width: 1350px) and (min-width: 651px) {
    margin-top: 40px;
  }

  @media (max-width: 650px) {
    margin-top: 25px;
    font-size: 275%;
    width: 70%;
  }
`;
const H3 = styled.h3`
  margin: 0;
  font-size: 200%;
  color: #68589a;
  text-align: center;
  font-weight: 500;

  @media (max-width: 650px) {
    font-size: 120%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const P = styled.p`
  width: 60%;
  text-align: center;

  @media (max-width: 1350px) {
    width: 100%;
    margin-top: 10px;
<<<<<<< HEAD
    margin-bottom: 40px;
=======
>>>>>>> main
  }
`;
