import { BaseCollection, BaseDocument, BaseData } from "../base";

interface IncidentSchema extends BaseData {
  type: string, // Defect or Crash
  area: string, // Area of the app
  error: string, // Error message caught
  boundCode?: string
}

export class DevLogger extends BaseCollection<IncidentSchema> {

  incident(id: string) {
    return this.doc(id) as Incident;
  }

  add(data: IncidentSchema) {
    if ((process.env.DEPLOYPROD && process.env.DEPLOYPROD.trim() === 'production')) {
      return super.add(data);
    }
    return Promise.resolve(null);
  }
}

type Incident = BaseDocument<IncidentSchema>;