import { Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { ProjectQueriesContext } from "../..";
import { index_object_by_string } from "../../../../tools/forms";
import { StatusDot } from "../../../ui/decorations";
import { InteractiveTable } from "../../../ui/table";

import AdvancedTable from "../../../ui/tableadv";
import { QUERY_LINK_DEFAULT_COLS } from "../linking";
import { QuerySection, QuerySectionHeader } from "../query";

export default ({ queryData, formComposition, invitees, setFormData }) => {
  return (
    <QuerySection>
      <QueryFormSidebySide>
        <QueryNewFormInput
          title={formComposition.inviteeTitle}
          formInvitees={invitees}
          inviteeColumns={formComposition.inviteeData}
          setChange={setFormData}
          dataLinked={formComposition?.inviteeDataLinking !== undefined}
          dataLinkOnly={formComposition?.settings?.forceInviteeDataLinking}
        />
        {formComposition?.inviteeDataLinking && (
          <AttachFormInvitees
            queryData={queryData}
            formInvitees={invitees}
            setChange={setFormData}
            dataLinkSchema={formComposition.inviteeDataLinking}
          />
        )}
      </QueryFormSidebySide>
    </QuerySection>
  );
};

const QueryNewFormInput = ({
  title,
  dataLinked,
  dataLinkOnly,
  setChange,
  formInvitees,
  inviteeColumns,
}) => {
  return (
    <AdvancedTable
      schema={{
        body: {
          columns: [
            // Data link field support
            ...(dataLinked ? AttachFormInviteesInputTableColumns : []),
            // Basic field defaults
            ...NewFormInputTable,
            // Custom fields support
            ...(inviteeColumns ?? []),
          ],
        },
        options: { scroll: true },
      }}
      data={formInvitees ?? {}}
      label={"Form Invitees for " + title}
      setChange={setChange}
      disabled={dataLinkOnly}
    />
  );
};

const AttachFormInvitees = ({
  queryData,
  formInvitees,
  setChange,
  dataLinkSchema,
}) => {
  const queries = useContext(ProjectQueriesContext);

  // const setFormMembers = (callback) => {
  //   setChange(ex => ({...ex, body: callback(ex?.body) }));
  // }

  const linkMemberOnClick = (inviteeRow) => {
    // First let's get the data mapped into the invitee row
    let inviteeData = {};
    if (dataLinkSchema?.dataMap) {
      // Then apply the field map (with the rest of the data mixed in for fun)
      inviteeData = {
        ...(inviteeRow?.data ?? {}),
        ...Object.keys(dataLinkSchema.dataMap ?? {})
          .map((key) => ({
            [key]:
              index_object_by_string(inviteeRow, dataLinkSchema.dataMap[key]) ??
              "",
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      };
    } else {
      inviteeData = inviteeRow?.data ?? {};
    }
    // Now we'll do the set
    setChange((ex) => ({
      ...ex,
      body: [
        // First remove the row if it exists already (along with any empty rows)
        ...(ex?.body ?? []).filter(
          (invitee) =>
            invitee.queryId !== inviteeRow.id &&
            (invitee.queryId !== undefined || Object.keys(invitee).length > 1)
        ),
        // Then add the row if it didn't exist before
        ...((ex?.body ?? [])
          .map((invitee) => invitee.queryId)
          .includes(inviteeRow.id)
          ? []
          : [
              {
                queryId: inviteeRow.id,
                ...inviteeData,
              },
            ]),
        // Finally add an empty row if no rows exist
        ...((ex?.body ?? []).filter(
          (invitee) => invitee.queryId !== inviteeRow.id
        ).length === 0
          ? [{}]
          : []),
      ],
    }));
  };

  const formMembers = useMemo(() => {
    return formInvitees?.body ?? [];
  }, [formInvitees]);

  const linkedFormQueries = useMemo(() => {
    return formMembers?.map((fm) => fm.queryId);
  }, [formMembers]);

  const customFilter = useCallback(() => {
    return new Function(
      "thisQuery",
      "externalQuery",
      dataLinkSchema?.filter ?? "return true"
    );
  }, [dataLinkSchema]);

  return (
    <LinkingTableContainer>
      <Typography style={{ width: "100%", marginBottom: "4px" }}>
        Attach Form Invitees from {dataLinkSchema?.linkName}
      </Typography>
      <InteractiveTable
        columns={(
          dataLinkSchema?.tableSchema ?? QUERY_LINK_DEFAULT_COLS
        ).concat([
          {
            name: "Selected",
            component: (row) => (row._selected ? <StatusDot cmplt /> : null),
          },
        ])}
        data={[
          ...queries.filter(
            (dt) =>
              dt.schemaId == dataLinkSchema.schemaId &&
              customFilter(queryData, dt)
          ),
        ]
          .sort((a, b) => (a.dynamicId > b.dynamicId ? 1 : -1))
          .map((dt) => ({
            ...dt,
            _selected: linkedFormQueries.includes(dt.id),
          }))}
        onRowClick={(row) => {
          linkMemberOnClick(row._bin);
        }}
        allowOrdering
        hover
      />
    </LinkingTableContainer>
  );
};

const NewFormInputTable = [
  { type: "rowindex", title: "", id: "num" },
  { type: "text", title: "Name", id: "name" },
  { type: "text", title: "Email", id: "email" },
  { type: "text", title: "Phone Number", id: "phone" },
];

const AttachFormInviteesInputTableColumns = [
  { type: "hidden", title: "Query ID", id: "queryId" },
];

const LinkingTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;
  min-width: 48%;
  max-height: 500px;

  padding-right: 1%;
  padding-left: 1%;
  margin-top: 15px;
`;

const QueryFormSidebySide = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;

  justify-content: space-between;
`;
