import React, { useCallback, useEffect, useState, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Route, Routes, useSearchParams } from "react-router-dom";

// (Main) Components------------------------------------
import Navbar from "./maincomponents/navbar";
import { TOSFormatted } from "../account/tos";
import Footer from "./maincomponents/footer";
// -----------------------------------------------------

export default (props) => {
  const navHomeExternal = () => (window.location.href = "/?v=home");
  const navAboutExternal = () => (window.location.href = "/?v=about");
  const navWhyExternal = () => (window.location.href = "/?v=why");
  const navUseCasesExternal = () => (window.location.href = "/?v=use-cases");
  const navPricingExternal = () => (window.location.href = "./?v=pricing");

  return (
    <HomePageContainer>
      <Navbar
        navHome={navHomeExternal}
        navAbout={navAboutExternal}
        navWhy={navWhyExternal}
        navUseCases={navUseCasesExternal}
        navPricing={navPricingExternal}
      />
      <br /> <br /> <br /> <br /> <br />
      <div style={{ width: "90%", margin: "auto" }}>
        <TOSFormatted />
      </div>
      <br /> <br />
      <Navbar
        navHome={navHomeExternal}
        navAbout={navAboutExternal}
        navWhy={navWhyExternal}
        navUseCases={navUseCasesExternal}
        navPricing={navPricingExternal}
      />
    </HomePageContainer>
  );
};

export const HOMEVIEWS = {
  HOME: "home",
  HOW: "how",
  FEATURES: "features",
  PRICING: "pricing",
  CONTACT: "contact",
};

const HomePageContainer = styled.div`
  overflow-x: hidden;
  scroll-padding-top: 106px;
  min-height: 100%;
  width: 100%;
  display: inline-block;
  overflow: auto;
  font-family: Red Hat Display;
  background-color: white;

  @media (max-width: 1100px) {
    scroll-padding-top: 87px;
  }
`;
