import { v4 as uuid } from 'uuid';

export class StorageFile {

  public filename: string;
  public id: string;

  constructor(filename: string) {
    this.filename = filename;
    this.id = uuid().replaceAll('-', '');
  }

  toObject(): Object {
    return {name: this.filename, id: this.id}
  }
}