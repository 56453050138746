import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

//UI
import { Button2, Button } from "../../../ui/buttons";
import { GenericField, SelectField } from "../../../ui/inputs2";
import { Badge } from "../../../statics2/badges";
import {
  Box,
  Button as FileButton,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { EmojiEvents, Clear, Add, Close, Edit } from "@mui/icons-material";
import { InputContainer } from "../../../project/query/admin/fields/tools.js";
import CSSTransition from "react-transition-group/CSSTransition";
import { QueryBlur, QueryStatusBanner } from "../../../project/query/query";

// THEMES
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { panelTheme } from "../../../../themes/mui_theme_panels";
import {
  materialTheme,
  materialThemeContrast,
} from "../../../../themes/mui_theme";
import { ThemeContext } from "styled-components";

// FUNCTIONS
import { isMobile } from "react-device-detect";
import FileUploader from "react-firebase-file-uploader";
import { ProjectRouteContainer } from "../../../project";
import { ConfirmationModal } from "../../../ui/modals";
import q from "@queryit/api";

const storagePath = "/badges/";

export default ({ project, projectData }) => {
  const [badges, setBadges] = useState([]);

  const [importResultText, setImportResultText] = useState("");

  const [badgesBlueprint, setBadgesBlueprint] = useState(undefined);

  const [badgeDeleteTarget, setBadgeDeleteTarget] = useState(undefined);

  const [schemas, setSchemas] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentBadge, setCurrentBadge] = useState(null); // Null when adding, badge object when editing

  // Get schema data on mount
  useEffect(() => {
    const fetchSchemas = async () => {
      let processedSchemas = {};
      const currentSchemas = (await project?.schemas?.get()) || [];
      currentSchemas.forEach((schema) => {
        processedSchemas = {
          ...processedSchemas,
          [schema.id]: schema.name,
        };
      });
      setSchemas(processedSchemas);
    };
    fetchSchemas();
  }, [project]);

  useEffect(() => {
    // When project Data changes we need to regenerate the badges blueprint
    if (!projectData) {
      setBadgesBlueprint({});
      return;
    }
    setBadgesBlueprint(badges);
  }, [badges, projectData]);

  const getBadges = async (projectId) => {
    let projectBadgesDoc = await q.projects
      .project(projectId)
      ?.defaultBadges?.get();
    return projectBadgesDoc;
  };

  useEffect(() => {
    getBadges(projectData?.id).then((badges) => setBadges(badges));
  }, [projectData?.id]);

  // Uploads a file to firebase using a url - returns a new url of the uploaded file
  const uploadFile = async (fileUrl, name) => {
    let fileRef = q.storage?.ref(storagePath + name);

    // 1. Fetch the file
    let file = await fetch(fileUrl);
    // 2. Turn it into a blob
    const blob = await file.blob();
    // 3. Upload the blob to firebase
    let result = await q.storage?.upload(fileRef, blob);

    // 4. Using the snapshot returned from firebase, generate a url
    let url = await q.storage.generateDownloadURL(result.ref);

    return url;
  };

  const handleImport = async (e) => {
    setImportResultText("Loading...");
    let files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = e.target.files[0];

    //We only accept json
    if (file.type !== "application/json") {
      alert("Invalid file type. Make sure it ends in .json!");
      return;
    }

    let reader = new FileReader();
    reader.onload = (() => {
      return async (e) => {
        // Object of uploaded json file
        const badges = JSON.parse(e.target.result);

        // check for badges to be an array (not sure if needed?)
        if (!Array.isArray(badges)) {
          alert("Make sure badges is an array of badge objects");
          return;
        }

        const processedBadges = await Promise.all(
          badges.map(async (badge) => {
            // Take the current file url and create a copy of it in firebase storage
            // This allows us to import badge images that exist in one env (dev) to another env (prod)
            const newUrl = await uploadFile(badge.url, badge.flair);
            const cleanBadge = { ...badge };

            // Clean if theres an existing id in the badges json data (old export, etc)
            if (cleanBadge?.id) delete cleanBadge.id;

            // Update the badge url and project name
            return {
              ...cleanBadge,
              url: newUrl || "",
              project: projectData?.name,
              projectId: projectData?.id,
            };
          })
        );

        // wipe the existing badges in the project, since import overwrites
        const badgeDocs = await q.projects
          .project(projectData.id)
          .defaultBadges.get();
        await Promise.all(
          badgeDocs.map(async (doc) => {
            return await q.projects
              .project(projectData.id)
              .defaultBadges.doc(doc.id)
              .delete();
          })
        );

        // Add each newly imported badges to the project
        await Promise.all(
          processedBadges.map(async (badge) => {
            return await q.projects
              .project(projectData.id)
              .defaultBadges.add(badge);
          })
        );

        setImportResultText(
          `Successfully uploaded ${processedBadges.length} to ${projectData.name}`
        );
        setBadges(processedBadges);
      };
    })(file);
    reader.readAsText(file);
  };

  const handleBadgeDelete = async (badgeID) => {
    await q.projects
      .project(projectData.id)
      .defaultBadges.doc(badgeID)
      .delete();
    setBadges((prev) => {
      const newBadges = prev.filter((badge) => badge.id !== badgeID);
      return newBadges;
    });
  };

  const handleEditBadge = (badge) => {
    setCurrentBadge(badge); // Set the badge to be edited
    setModalOpen(true); // Open the modal
  };

  // Function to open modal for adding a new badge
  const handleAddBadge = () => {
    setCurrentBadge(null); // No badge to edit, so it's for adding
    setModalOpen(true); // Open the modal
  };

  const theme = useContext(ThemeContext);
  return (
    <ProjectRouteContainer>
      <ConfirmationModal
        open={badgeDeleteTarget !== undefined}
        onConfirm={() => handleBadgeDelete(badgeDeleteTarget)}
        onCancel={() => setBadgeDeleteTarget(undefined)}
        body="Are you sure you want to delete this badge?"
      />
      {modalOpen && (
        <MuiThemeProvider theme={panelTheme(materialTheme(theme))}>
          <BadgeModal
            open={modalOpen}
            setOpen={setModalOpen}
            projectData={projectData}
            setBadges={setBadges}
            schemas={schemas}
            currentBadge={currentBadge}
          />
        </MuiThemeProvider>
      )}
      <Typography variant="h4" color="white" mb={2}>
        <EmojiEvents fontSize="medium" /> Badge Editor
      </Typography>
      <>
        <h2 style={{ color: "grey" }}>Project Badges</h2>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {badges &&
            badges.map((badge, j) => (
              <div
                key={`${badge.id}-${j}`}
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Badge
                  name={badge.name}
                  amountNeeded={0}
                  amountObtained={1}
                  flair={badge.flair}
                  artUrl={badge.url}
                  info={badge?.info}
                />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Tooltip title="Delete Badge">
                    <IconButton onClick={() => setBadgeDeleteTarget(badge.id)}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Badge">
                    <IconButton onClick={() => handleEditBadge(badge)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
              </div>
            ))}
          <InputContainer
            style={{
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "100px",
              cursor: "pointer",
              alignSelf: "stretch",
            }}
            onClick={() => handleAddBadge()}
          >
            <div
              style={{
                fontSize: "16px",
                userSelect: "none",
                color: "#FFFFFF",
                padding: "5px",
              }}
            >
              Add Badge
            </div>
            <Add />
          </InputContainer>
        </div>
      </>
      <h2 style={{ color: "grey" }}>Export/Import Badges JSON</h2>
      <Message>
        Download {badges?.length} badge(s) from {projectData?.name} (id:{" "}
        {projectData?.id}):
      </Message>
      <DownloadButton
        type="button"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(badgesBlueprint)
        )}`}
        download={`${projectData.name.replaceAll(" ", "_")}_badges_export.json`}
      >
        Export Badges
      </DownloadButton>
      <br></br>
      <FileButtonStyled variant="contained" component="label">
        Import Badges
        <input type="file" onChange={handleImport} hidden accept="json/*" />
      </FileButtonStyled>
      <br></br>
      <div style={{ color: "white" }}>{importResultText}</div>
    </ProjectRouteContainer>
  );
};

const BadgeModal = ({
  open,
  setOpen,
  projectData,
  setBadges,
  schemas,
  currentBadge,
}) => {
  // Determine if it's a new badge or editing an existing one
  const isNewBadge = currentBadge === null;
  const [badge, setBadge] = useState(
    currentBadge || {
      amountNeeded: 1,
      amountObtained: 0,
      flair: "bronze",
      info: "Lorem Ipsum",
      name: "A Badge",
      project: projectData.name,
      projectId: projectData.id,
      tracking: "query",
      points: 1,
      schema: "",
      url: "https://firebasestorage.googleapis.com/v0/b/queryit-30aea.appspot.com/o/badges%2Fbronze?alt=media&token=0cff9703-3c95-4504-9c0e-86f975798c4f",
    }
  );
  const [imgError, setImgError] = useState(false);

  const handleChange = (key, event) => {
    const newValue = event.target.value;
    setBadge((prev) => {
      return {
        ...prev,
        [key]: newValue,
      };
    });
  };

  const handleSubmit = async () => {
    // If isNewBadge, then the submission is a new badge added to the badge collection
    if (isNewBadge) {
      /* const badgeRef = */ await q.projects
        .project(projectData.id)
        .defaultBadges.add(badge);
      // now fetch generated badgeID from db, or else the newest badge cant be deleted until refresh
      // current implementation is *pretty hacky*
      // - there doesnt seem to be a way to get a singular document from a ref using query-it methods
      // - ideal way is to just use badgeRef, and grab the id from there-this will even allow same image badges to work

      const currentBadges = await q.projects
        .project(projectData.id)
        .defaultBadges.get();
      const id = currentBadges.filter((badge) => badge.url === badge.url)[0]
        ?.id;

      setBadges((prev) => {
        return [
          ...prev,
          {
            ...badge,
            id: id,
          },
        ];
      });
    } else {
      // Update the existing badge document
      await q.projects
        .project(projectData.id)
        .defaultBadges.doc(badge.id)
        .update(badge);
      setBadges((prevBadges) => {
        return prevBadges.map((existingBadge) => {
          if (existingBadge.id === badge.id) {
            return { ...existingBadge, ...badge }; // Merging changes to the existing badge in state
          }
          return existingBadge;
        });
      });
    }
    setOpen(false);
  };

  return (
    <EditContainer>
      <QueryBlur onClick={() => setOpen(false)} />
      <CSSTransition in={open} timeout={500} classNames="edit" unmountOnExit>
        <Popup>
          <TopBar>
            <p style={{ marginLeft: "12px", fontSize: "20px" }}>
              <b>{isNewBadge ? "Build a Badge" : "Edit Badge"}</b>
            </p>
            <IconButton
              onClick={() => setOpen(false)}
              style={{ marginRight: "15px" }}
            >
              <Close />
            </IconButton>
          </TopBar>
          <ComponentContent>
            <EditUserPane>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {isNewBadge ? (
                  <>
                    <Badge
                      name={badge.name}
                      amountNeeded={badge.amountNeeded}
                      amountObtained={badge.amountNeeded}
                      flair={badge.flair}
                      artUrl={badge.url}
                      info={badge?.info}
                      textColour="black"
                    />
                    <Badge
                      name={badge.name}
                      amountNeeded={badge.amountNeeded}
                      amountObtained={badge.amountNeeded - 1}
                      flair={badge.flair}
                      artUrl={badge.url}
                      info={badge?.info}
                      textColour="black"
                    />
                  </>
                ) : (
                  <Badge
                    name={badge.name}
                    amountNeeded={badge.amountNeeded}
                    amountObtained={badge.amountNeeded}
                    flair={badge.flair}
                    artUrl={badge.url}
                    info={badge?.info}
                    textColour="black"
                  />
                )}
              </div>
              <div>
                <GenericField
                  label="Badge Name*"
                  data={badge.name}
                  onChange={(event) => handleChange("name", event)}
                />
                <GenericField
                  label="Hover Info Name*"
                  data={badge.info}
                  onChange={(event) => handleChange("info", event)}
                  multiLine={true}
                />
                <GenericField
                  label="Amount Needed*"
                  data={badge.amountNeeded}
                  onChange={(event) => handleChange("amountNeeded", event)}
                  number
                />
                <SelectField
                  label="Select Flair*"
                  data={badge.flair}
                  options={{
                    bronze: "Bronze",
                    silver: "Silver",
                    gold: "Gold",
                  }}
                  onChange={(event) => handleChange("flair", event)}
                  allowNone={false}
                />
                <SelectField
                  label="Earning Metric*"
                  data={badge.tracking}
                  options={{
                    query: "Query Creation",
                    comments: "Comment Creation",
                    approvals: "Approval Signature",
                    tasks: "Task Creation",
                  }}
                  onChange={(event) => handleChange("tracking", event)}
                  allowNone={false}
                />
                <GenericField
                  label="Leaderboard Point Value*"
                  data={badge.points}
                  onChange={(event) => handleChange("points", event)}
                  number
                />
                <SelectField
                  label="Tracking Schema"
                  data={badge.schema}
                  options={schemas}
                  onChange={(event) => handleChange("schema", event)}
                />
                <div>
                  <Typography style={{ margin: "2px", paddingBottom: "5px" }}>
                    Upload Badge Image
                  </Typography>
                  <FileUploader
                    onUploadSuccess={async (fileName) => {
                      const fileRef = q.storage?.ref(storagePath + fileName);
                      if (fileRef) {
                        const url = await q.storage.generateDownloadURL(
                          fileRef
                        );
                        setBadge((prev) => {
                          return {
                            ...prev,
                            url: url,
                          };
                        });
                      }
                    }}
                    onUploadError={() => setImgError(true)}
                    metadata={{}}
                    storageRef={q.storage?.compatibleRef(storagePath)}
                    disabled={false}
                    accept=".jpg, .jpeg, .png, .gif"
                  />
                  {imgError && (
                    <Typography>
                      Upload Failed. Try again or contact an administrator.
                    </Typography>
                  )}
                </div>
              </div>
            </EditUserPane>
          </ComponentContent>
          <BottomBar>
            <Button2
              label={isNewBadge ? "Add to Project" : "Set Badge"}
              style={{ marginRight: "12px" }}
              onClick={handleSubmit}
            />
          </BottomBar>
        </Popup>
      </CSSTransition>
    </EditContainer>
  );
};

const EditContainer = styled.div`
  position: absolute;
  font-family: ${(props) => props.theme.font};
  height: 60%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  height: ${isMobile ? "100%" : "80%"};
  min-height: 300px;
  width: ${isMobile ? "100%" : "50%"};
  background-color: blue;
  z-index: 20;
  position: relative;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ComponentContent = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 25;
`;

const EditUserPane = styled.div`
  background-color: white;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-bottom: 1px solid #c2c2c2;
  user-select: none;
  z-index: 30;
`;

const BottomBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: right;
  align-items: center;
  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
  z-index: 30;
`;

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;

// For download & upload buttons
const DownloadButton = styled.a`
  padding: 8px 16px !important;
  height: fit-content;
  width: fit-content;
  background: ${(props) =>
    props.toggleOn
      ? props.theme.button.active
      : props.theme.button.inactive} !important;
  color: ${(props) =>
    props.theme.button.inactiveText
      ? props.theme.button.inactiveText
      : props.theme.textAlt} !important;

  font-family: ${(props) => props.theme.font}!important;
  font-size: 14px !important;
  font-weight: 500;
  display: flex;
  align-items: center;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px !important;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;
  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;

const FileButtonStyled = styled(FileButton)`
  padding: 8px 16px !important;
  height: fit-content;
  width: fit-content;
  background: ${(props) =>
    props.toggleOn
      ? props.theme.button.active
      : props.theme.button.inactive} !important;
  color: ${(props) =>
    props.theme.button.inactiveText
      ? props.theme.button.inactiveText
      : props.theme.textAlt} !important;

  font-family: ${(props) => props.theme.font} !important;
  font-size: 14px !important;
  font-weight: 500;
  display: flex;
  align-items: center;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px !important;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;
  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;
