import { createTheme } from "@mui/material/styles";

/**
 *
 * Hey welcome, this is the main stylesheet for our mui components
 *
 * If you need to add opacity, you can use + 'XX', where you replace XX with:
 * https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4 based on % opacity
 *
 */

export const materialTheme = (userTheme) =>
  createTheme({
    palette: {
      primary: {
        main: userTheme.statusColors.active,
      },
      secondary: {
        main: userTheme.titleSub,
      },
      grey: {
        300: userTheme.step50,
        200: "#a1a4b7",
      },
      text: {
        primary: userTheme.text,
        secondary: userTheme.textHeading,
        disabled: userTheme.textDisabled,
      },
      background: {
        default: userTheme.step50,
        step50: userTheme.step50,
        step100: userTheme.step100,
        step150: userTheme.step150,
      },
      font: userTheme.font,
      button: {
        active: userTheme.button?.active,
        inactive: userTheme.button?.inactive,
        disabled: userTheme.button?.disabled,
      },
    },
    shape: {
      border: "none",
    },
    props: {
      MuiPaper: {
        elevation: 0,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "unset",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: "#424242",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          iconButton: {
            backgroundColor: "#424242",
          },
          dayLabel: {
            color: "#ffffff80",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            margin: "2px 6px",
          },
          label: {
            color: userTheme.textHeading,
          },
        },
      },
      MuiDataGridPanel: {
        styleOverrides: {
          paper: {
            backgroundColor: userTheme.step50,
          },
        },
      },
      MuiTableHead: {},
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            backgroundColor: userTheme.background,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          label: {
            color: userTheme.text,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userTheme.text.concat(" !important"),
          },
          root: {
            "&:hover": {
              color: userTheme.text,
            },
            "&:focus": {
              color: userTheme.text,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            cursor: "pointer",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnsPanel: {
            color: "#000",
          },
          // NOTE: This set of themes does not work right now, but should be supported in v5 of mui
          root: {
            border: "none",
          },
          cell: {
            border: userTheme.text + "1F",
          },
          filterForm: {
            "& .MuiInput-input": {
              color: "#000",
            },
            "& .MuiFormLabel-root": {
              color: "#000",
            },
            "& .MuiSvgIcon-root": {
              color: "#000",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userTheme.text,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: userTheme.step50,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "rgba(0, 0, 0, 0.24)",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: userTheme.text,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: userTheme.titleSub,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: userTheme.text.concat(" !important"),
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            position: "absolute",
          },
          primary: {
            backgroundColor: userTheme.titleSub,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: userTheme.statusColors.active,
          },
          colorSecondary: {
            backgroundColor: userTheme.statusColors.inactive,
          },
          label: {
            padding: 0,
            lineHeight: 2.4,
          },
          labelSmall: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:not([multiple]) option": {
              backgroundColor: userTheme.step50,
            },
            "&:hover option": {
              backgroundColor: userTheme.step100,
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            "&:not([multiple]) option": {
              backgroundColor: userTheme.step50,
              color: userTheme.text,
            },
            "&:hover option": {
              backgroundColor: userTheme.step100,
              color: userTheme.text,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            color: "#fff",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            backgroundColor: userTheme.step50,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: userTheme.step150,
          },
          positionStatic: {
            zIndex: 3,
          },
        },
      },
      MuiGridPanel: {
        styleOverrides: {
          paper: {
            backgroundColor: userTheme.step50,
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            color: "#000",
            backgroundColor: "#fff",
            "& .MuiSvgIcon-root": {
              color: "#000000A3",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            color: "#000",
          },
          switchViewIcon: {
            color: "#e0e0e0",
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          header: {
            color: "#000",
          },
          weekDayLabel: {
            color: "#e0e0e0",
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: "#000", // issue
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: userTheme.statusColors.active,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            "& .MuiSvgIcon-root": {
              color: userTheme.text,
            },
          },
        },
      },
    },
  });

export const materialThemeContrast = (userTheme) =>
  createTheme({
    palette: {
      primary: {
        main: userTheme.statusColors.active,
      },
      grey: {
        300: "#000000",
      },
      text: {
        primary: "#000000",
      },
      background: {
        default: "#fff",
      },
    },
  });
