import React, { useState } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Tabs, Tab, Box } from "@mui/material";
import { EscapeHeader, FullPageContainer } from "../ui/containers";

import TOSModal from "./tos";
import EULAModal from "./eula";
import PrivacyModal from "./privacy";
import PersonalizePanel from "./personalize";
import ManagePanel from "./manage";
import NotificationsPanel from "./notifications";
import DocumentsPanel from "./documents";

export default ({ user, userData, photoLink }) => {
  const [openModal, setOpenModal] = useState(undefined);
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!user || !userData) {
    return null;
  }

  const openEULA = () => setOpenModal("eula");
  const openTOS = () => setOpenModal("tos");
  const openPrivacy = () => setOpenModal("privacy");
  const closeLegal = () => setOpenModal(undefined);

  return (
    <FullPageContainer>
      <TOSModal open={openModal === "tos"} onClose={closeLegal} />
      <EULAModal open={openModal === "eula"} onClose={closeLegal} />
      <PrivacyModal open={openModal === "privacy"} onClose={closeLegal} />
      <UserSettingsContent>
        <SettingsTile>
          <HeaderDiv>
            <EscapeHeader />
            <AccountSettingsDiv>Account Settings</AccountSettingsDiv>
          </HeaderDiv>
          <AccountSettingTabsDiv>
            <TabsUnderlineDiv>
              <Tabs
                value={value}
                onChange={handleTabChange}
                centered
                style={{ borderBottom: "1px solid #c2c2c2" }}
              >
                <Tab label="Personalize" style={{ textTransform: "none" }} />
                <Tab label="Manage" style={{ textTransform: "none" }} />
                <Tab label="Notifications" style={{ textTransform: "none" }} />
                <Tab label="Documents" style={{ textTransform: "none" }} />
              </Tabs>
            </TabsUnderlineDiv>
          </AccountSettingTabsDiv>
          <TabPanel value={value} index={0}>
            <PersonalizePanel
              photoLink={photoLink}
              user={user}
              userData={userData}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ManagePanel user={user} userData={userData} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NotificationsPanel user={user} userData={userData} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DocumentsPanel
              openTOS={openTOS}
              openEULA={openEULA}
              openPrivacy={openPrivacy}
            />
          </TabPanel>
        </SettingsTile>
      </UserSettingsContent>
    </FullPageContainer>
  );
};

export const PhotoPreview = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const AccountDiv = styled.div`
  overflow: auto;
`;

export const SettingDiv = styled.div`
  width: ${isMobile ? "100%" : "60%"};
  margin: 10px;
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
`;

// const SettingFooter = styled.div`
//   width: 100%;
//   height: 16px;
//   margin: 10px;
//   font-size: 12px;
//   color: ${props => props.theme.textAlt};
//   font-family: ${props => props.theme.font};
// `;

const UserSettingsContent = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  padding: ${isMobile ? "0px" : "24px"};
  box-sizing: border-box;
`;

// const TOSLink = styled.div`
//   color: ${props => props.theme.textAlt};

//   cursor: pointer;
// `;

// const SettingLabel = styled.div`

// `;

const SettingsTile = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: ${isMobile ? "auto" : "50%"};

  height: ${isMobile ? "100%" : "fit-content"};

  background: ${(props) => props.theme.step100};
  border-radius: 4px;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const AccountSettingsDiv = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  display: flex;
`;

const AccountSettingTabsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  width: 100%;
`;

const TabsUnderlineDiv = styled.div`
  width: 100%;
  text-align: center;
  borderbottom: "1px solid #e8e8e8";
`;

export const SectionTitle = styled.div`
  color: ${(props) => props.theme.text};
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  display: flex;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
