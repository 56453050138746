import { BaseDocument, BaseCollection, BaseData } from "../../base";

interface ApprovalData extends BaseData {
  // User that must approve this.
  userId: string;
  status: "pending" | "accepted" | "rejected";

  // Custom data based on approvalSchema
  data: any;
}

export class Approvals extends BaseCollection<ApprovalData> {
  approval(id: string) {
    return this.doc(id) as Approval;
  }
}

type Approval = BaseDocument<ApprovalData>;