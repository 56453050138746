import { BaseCollection, BaseDocument, BaseData } from "../../base";

interface SchemaUserData extends BaseData {
  customFilter: any; // update these
  customFields: any; // update these
  customAnalyticsSchema: any;
}

export class SchemaUsers extends BaseCollection<SchemaUserData> {
  user(id: string) {
    return this.doc(id) as SchemaUser;
  }
}

type SchemaUser = BaseDocument<SchemaUserData>;
