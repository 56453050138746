import { createTheme } from "@mui/material/styles";

const NOPROP = "";

export const panelTheme = (userTheme) =>
  createTheme({
    palette: {
      ...userTheme.palette,
      text: {
        ...userTheme.palette.text,
        primary: "#000",
        disabled: "#56575a",
      },
      grey: {
        ...userTheme.palette.grey,
        300: "#1e1f21",
        200: "#a1a4b7",
      },
      primary: {
        ...userTheme.palette.primary,
        main: userTheme.palette.button?.active || "#000",
      },
      secondary: {
        ...userTheme.palette.secondary,
      },
      background: {
        ...userTheme.palette.background,
        step50: "#f2f2f2",
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userTheme.palette.primary.main,
          },
          colorDisabled: {
            color: userTheme.button?.disabled || "rgba(0, 0, 0, 0.26)",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:not([multiple]) option": {
              backgroundColor: userTheme.step50,
              color: "#000",
            },
            "&:hover option": {
              backgroundColor: userTheme.step100,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            backgroundColor: userTheme.step50,
          },
          "&:hover option": {
            backgroundColor: userTheme.step100,
          },
          noOptions: {
            color: "#000",
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            "&:not([multiple]) option": {
              backgroundColor: userTheme.step50,
            },
            "&:hover option": {
              backgroundColor: userTheme.step100,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: userTheme.step50,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            userSelect: "none",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#000",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            backgroundColor: "#E4E4E4",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            cursor: "pointer",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: userTheme.palette.primary.main,
          },
          label: {
            color: userTheme.palette.text.primary,
          },
          colorSecondary: {
            backgroundColor: userTheme.palette.grey[200],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          label: userTheme.palette.text.secondary,
          root: {
            backgroundColor: userTheme.palette.primary.main,
            "&:hover": {
              backgroundColor: userTheme.palette.primary.main,
            },
          },
          contained: {
            backgroundColor: NOPROP,
          },
          containedPrimary: {
            backgroundColor: NOPROP,
          },
          outlined: {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          outlinedPrimary: {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          textPrimary: {
            color: userTheme.palette.text.secondary,
          },
          iconSizeLarge: {
            "& > *:first-of-type": {
              color: userTheme.palette.text.secondary,
            },
          },
          iconSizeMedium: {
            "& > *:first-of-type": {
              color: userTheme.palette.text.secondary,
            },
          },
          iconSizeSmall: {
            "& > *:first-of-type": {
              color: userTheme.palette.text.secondary,
            },
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          toolbar: {
            backgroundColor: userTheme.palette.primary.main,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          daySelected: {
            backgroundColor: userTheme.palette.primary.main,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#eb0d95",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "#000",
            opacity: 0.7,
            "&.Mui-selected": {
              opacity: 1,
              color: "#000",
            },
          },
        },
      },
    },
  });
