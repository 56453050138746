import React from "react";
import styled from "styled-components";
import { Button as CustomButton } from "../ui/buttons";
import { Book, DirectionsWalk, HelpOutline, PrivacyTip } from "@mui/icons-material";
import { CURRENT_YEAR } from "../../common/dates";

// docs
import FAQ from "./docs/queryit_faq.pdf";

export default ({ openTOS, openEULA, openPrivacy }) => {
  return (
    <>
      <DocumentContainer>
        <DocumentSquares>
          <DocumentTitle>Terms of Service</DocumentTitle>
          <Book style={{ fontSize: 40 }} />
          <CustomButton text="Read Now" onClick={openTOS} />
        </DocumentSquares>
        <DocumentSquares>
          <DocumentTitle>End User License Agreement</DocumentTitle>
          <DirectionsWalk style={{ fontSize: 40 }} />
          <CustomButton text="Read Now" onClick={openEULA} />
        </DocumentSquares>
        <DocumentSquares>
          <DocumentTitle>Privacy Policy</DocumentTitle>
          <PrivacyTip style={{ fontSize: 40 }} />
          <CustomButton text="Read Now" onClick={openPrivacy} />
        </DocumentSquares>
        <DocumentSquares>
          <DocumentTitle>Frequently Asked Questions</DocumentTitle>
          <HelpOutline style={{ fontSize: 40 }} />
          <a
            rel="noreferrer"
            target="_blank"
            href={FAQ}
            style={{ textDecoration: "none" }}
          >
            <CustomButton text="Read Now" />
          </a>
        </DocumentSquares>
      </DocumentContainer>
      <CopyrightNotice>
        <CopyrightWord>&copy; Copyright {CURRENT_YEAR}, Query-It Inc.</CopyrightWord>
      </CopyrightNotice>
    </>
  );
};

const DocumentSquares = styled.div`
  width: 140px;
  height: 140px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 150px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 20px;
  justify-content: space-between;
  border-radius: 8px;
  padding: 14px 0;

  user-select: none;
  font-family: ${(props) => props.theme.font};
  background: ${(props) => props.theme.background};
`;

const DocumentTitle = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  display: flex;
  text-align: center;
`;

const DocumentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CopyrightNotice = styled.div`
  height: 14px;
  width: 100%;
  color: ${(props) => props.theme.text};
  font-family: ${(props) => props.theme.font};
  display: flex;

  margin-bottom: 2px;
  margin-top: 20px;

  justify-content: flex-start;
  align-items: bottom;
`;

const CopyrightWord = styled.small`
  margin-left: 10px;
`;
