import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ProjectRouteContainer } from "../../../project";
import { Button, Button2, ButtonSection } from "../../../ui/buttons";
import { BooleanField, GenericField } from "../../../ui/inputs2";
import {
  Tile,
  ModalPrompt,
  ModalContent,
  ModalFooter,
} from "../../../ui/containers";
import { InteractiveTable } from "../../../ui/table";
import { useCompanies } from "../../../../hooks/companies";

import {
  Modal,
  DialogContent,
  Button as FileButton,
  Button as ModalButton,
  Button as ExportButton,
  Typography,
  Box,
} from "@mui/material";
import FileUploader from "react-firebase-file-uploader";

import q from "@queryit/api";
import { APIStorage } from "@queryit/api";

import { generate_tempid, strip_undefined } from "../../../../tools";
import { Navigate } from "react-router-dom";
import { log_warning } from "../../../../tools/logger";

export default ({ project, projectData }) => {
  const [tempProject, setTempProject] = useState(undefined);
  const [photoLink, setPhotoLink] = useState(undefined);
  const [confirmModal, setConfirmModal] = useState(false);

  const [leaveProject, setLeaveProject] = useState(false);

  // Also fetch company data
  const [allCompanies, addCompany] = useCompanies();
  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    if (projectData) {
      setTempProject(projectData);
    }
  }, [projectData]);

  const onPropChange = (ind) => (e) => {
    const { value } = e.target;
    setTempProject((ex) => ({ ...ex, [ind]: value }));
  };

  const onBoolSettingChange = (ind) => (e) => {
    const { value } = e.target;
    setTempProject((ex) => ({
      ...ex,
      settings: { ...ex?.settings, [ind]: value },
    }));
  };

  const saveProject = () => {
    project.update(tempProject);
  };

  const launchProject = () => {
    project.update({ launched: true });
  };

  const deleteProject = async () => {
    // First delete all users from project
    await Promise.all(
      (
        await project.users.get()
      ).map(async (user) => {
        await project.users.projectUser(user.id).delete();
      })
    );
    // Then delete project
    await project.delete();
    // Then leave the project
    setLeaveProject(true);
  };

  /**
   * Functions for Project Migrate
   */

  const [projectBlueprint, setProjectBlueprint] = useState({});
  const [fileData, setFileData] = useState(undefined);
  const [pendingUpload, setPendingUpload] = useState(undefined);

  function handleImportUpload(e) {
    if (e.target.files.length < 1) {
      return;
    } // File must have been uploaded
    let f = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (() => {
      return (e) => {
        setFileData(e.target.result);
      };
    })(f);
    reader.readAsText(f);
  }

  useEffect(() => {
    // When project Data changes we need to regenerate the project blueprint
    if (!projectData) {
      setProjectBlueprint({});
      return;
    }
    setProjectBlueprint({ name: projectData.name, launched: false });
  }, [projectData]);

  useEffect(() => {
    if (fileData) {
      // Now we have a file that we've brought in, let's parse it and show a prompt to the user
      let blueprint = JSON.parse(fileData);
      setPendingUpload(blueprint);
    }
  }, [fileData]);

  function completeUpload() {
    // Commit the project blueprint and clean up
    project.update(pendingUpload).then(() => clearUpload());
  }

  function clearUpload() {
    setFileData(undefined);
    setPendingUpload(undefined);
  }

  /**
   * Project Logo Management
   */

  useEffect(() => {
    let mounted = true;
    if (tempProject) {
      if (tempProject.logo) {
        APIStorage.getDownloadURL(
          q
            .getProjectStorage(tempProject.id)
            .ref(`${tempProject.id}/${tempProject.logo}`)
        ).then((url) => {
          if (mounted) setPhotoLink(url);
        });
      } else {
        setPhotoLink(undefined);
      }
    } else {
      setPhotoLink(undefined);
    }
    return () => (mounted = false);
  }, [tempProject]);

  // Add and remove companies from project
  const newCompany = () => {
    if (selectedCompany === "") {
      return;
    }
    project.update({ companies: [...projectData.companies, selectedCompany] });
    setSelectedCompany("");
  };

  const removeCompany = (id) => {
    project.update({
      ...projectData,
      companies: [...projectData.companies.filter((el) => el !== id)],
    });
  };

  // When deleting we need to abandon before render!
  if (leaveProject) {
    return <Navigate to="/admin/manage/projects" />;
  }

  if (!projectData || !allCompanies || !tempProject) {
    return null;
  }

  return (
    <ProjectRouteContainer>
      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Are you sure?</h2>
              <p>
                This action is permanent, and the entire project will be lost.
                Are you sure {"you'd"} like to proceed? We usually recommend
                Archiving instead.
              </p>
            </ModalContent>
            <ModalFooter>
              <Button text={"Cancel"} onClick={() => setConfirmModal(false)} />
              <Button
                text={"OK"}
                onClick={() => {
                  setConfirmModal(false);
                  deleteProject();
                }}
              />
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      <Tile>
        <Message>
          Hey this is where you can edit the broader project settings
        </Message>
        <ButtonSection>
          <Button2 label={"Save Changes"} onClick={saveProject} />
          <Button2
            label={"Delete Project"}
            onClick={() => setConfirmModal(true)}
          />
          {!tempProject.launched && (
            <Button2 label="Launch Project" onClick={() => launchProject()} />
          )}
          {tempProject.launched && (
            <Button2
              label="Archive Project"
              onClick={() => log_warning("This option is not available")}
            />
          )}
        </ButtonSection>
        <GenericField
          label="Name"
          contrast={true}
          data={tempProject.name}
          onChange={onPropChange("name")}
        />
        <ProfilePhotoDiv>
          <PhotoPreviewContainer>
            {photoLink && <PhotoPreview src={photoLink} />}
            {!photoLink && (
              <PhotoDefault>
                {tempProject.name && tempProject.name[0]}
              </PhotoDefault>
            )}
          </PhotoPreviewContainer>
          <FileButton variant="contained" component="label">
            Click to upload company logo
            <FileUploader
              hidden
              onUploadSuccess={(fileName) => {
                setTempProject((ex) => ({ ...ex, logo: fileName }));
              }}
              filename={() => "logo_" + generate_tempid().substring(1, 7)}
              storageRef={q
                .getProjectStorage(tempProject.id)
                .compatibleRef(`${tempProject.id}/`)}
              metadata={{}}
              disabled={false}
            />
          </FileButton>
          <Button2
            label="Delete Photo"
            onClick={() =>
              project.update(
                strip_undefined({
                  ...tempProject,
                  logo: undefined,
                  id: undefined,
                })
              )
            }
          />
        </ProfilePhotoDiv>
        <Box sx={{ marginTop: 4, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">Project Settings</Typography>
          <BooleanField
            label="Anonymized Project (Queries are private to users)"
            contrast
            data={tempProject.settings?.isAnonymized}
            onChange={onBoolSettingChange("isAnonymized")}
          />
          <BooleanField
            label="Show Leaderboard (Users can view their ranking in the project)"
            contrast
            data={tempProject.settings?.isLeaderboarded}
            onChange={onBoolSettingChange("isLeaderboarded")}
          />
        </Box>
      </Tile>
      <Tile style={{ marginTop: 10 }}>
        <Modal open={pendingUpload !== undefined} onClose={clearUpload}>
          <DialogContent>
            <ModalPrompt>
              <ModalContent>
                <h2>Are you sure?</h2>
                <p>
                  This import wants to re-configure this Project to one with new
                  name {pendingUpload?.name}. This will <b>overwrite</b> the
                  existing project configuration (leaving schemas untouched).
                </p>
              </ModalContent>
              <ModalFooter>
                <ModalButton onClick={clearUpload}>No</ModalButton>
                <ModalButton onClick={completeUpload}>Yes</ModalButton>
              </ModalFooter>
            </ModalPrompt>
          </DialogContent>
        </Modal>
        <Message>Project Migration</Message>
        <ButtonSection>
          <ExportButton variant="contained" component="label">
            <ExportA
              type="button"
              // text={'Export Project Blueprint'}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(projectBlueprint)
              )}`}
              download={`${projectData.name.replaceAll(
                " ",
                "_"
              )}_project_blueprint.json`}
            >
              Export Project Blueprint
            </ExportA>
          </ExportButton>
          <FileButton variant="contained" component="label">
            Import Project Blueprint
            <input type="file" onChange={handleImportUpload} hidden />
          </FileButton>
        </ButtonSection>
        <Message>
          Hey this is where you can add and remove companies from a project
        </Message>
        <ButtonSection>
          <Button2
            label={"Add Selected Company"}
            onClick={newCompany}
            style={{ marginBottom: "10px" }}
          />
        </ButtonSection>
        {/* Not working... TODO: Fix */}
        {/* <SelectField
        label={'New Company'}
        data={selectedCompany}
        options={allCompanies}
        onChange={(val) => setSelectedCompany(val.id)}
        optionFormat={(option) => `${option.name}`}
        autocomplete={true}
        contrast
      /> */}
        <InteractiveTable
          data={projectData.companies ?? []}
          columns={analyticsColumns(removeCompany)}
        />
      </Tile>
    </ProjectRouteContainer>
  );
};

const analyticsColumns = (removeCompany) => [
  { name: "Id", index: "id", format: "return `${data}`" },
  {
    name: "...",
    component: (row) => (
      <Button text={"Remove Company"} onClick={() => removeCompany(row)} />
    ),
  },
];

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;

// FOR PHOTO UPLOAD

const PhotoDefault = styled.div``;

const PhotoUploader = styled.label`
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  background: ${(props) => props.theme.button.inactive};
  color: ${(props) => props.theme.textAlt};

  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 2px;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;
  margin-bottom: 10px;

  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;

const ProfilePhotoDiv = styled.div`
  background: ${(props) => props.theme.step150};
  padding: 20px;
  border-radius: 5px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const PhotoPreviewContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 100px;
  overflow: hidden;
  margin-bottom: 10px;

  background: #3e2c7d;
  user-select: none;

  font-size: 26px;

  &:hover {
    background-color: #302762;
    cursor: pointer;
  }
`;

const ExportA = styled.a`
  text-decoration: none;
  color: white;
`;

export const PhotoPreview = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
