import React, { useState, useMemo } from "react";
import styled from "styled-components";

//UI
import { Button2 } from "../../../ui/buttons";
import { GenericField, SelectField } from "../../../ui/inputs2";
import { Typography, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import CSSTransition from "react-transition-group/CSSTransition";
import { QueryBlur } from "../../../project/query/query";

import {
  AnnouncementEditor,
  BadgesEditor,
  CustomEditor,
  CommendationEditor,
  PatchEditor
} from "./editors";

//FUNCTIONS / HOOKS
import { isMobile } from "react-device-detect";
import {
  useCurrentBadges,
  useCurrentUsers,
  usePatchNotes,
} from "../../../../hooks/projects";

const DEFAULT_OPTIONS = {
  announcement: "Announcement",
  patchNotes: "Query-It Patch Notes",
  commend: "Commendation",
  custom: "Custom iFrame",
}

export default ({ openEdit, setOpenEdit, projectData, project }) => {
  const [buildPopup, setBuildPopup] = useState({
    type: undefined,
    received: 0,
    opened: 0,
    data: undefined,
    date: new Date(),
    name: undefined,
    projectId: projectData.id,
  });
  const [sendFailed, setSendFailed] = useState(false);
  const projectBadges = useCurrentBadges(project);
  const projectUsers = useCurrentUsers(project);
  const patchDoc = usePatchNotes();

  const options = useMemo(() => {
    let newOptions = DEFAULT_OPTIONS;
    if(projectBadges && projectBadges.length > 0){
      newOptions = {
        ...newOptions,
        badges: "Badge Alert"
      }
    }
    return newOptions;
  }, [projectBadges]);

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    let template;
    switch (newType) {
      case "announcement":
        template = [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ];
        break;
      case "badges":
        template = {
          header: "Edit Header...",
          text: "Enter some text here...",
          badges: projectBadges,
        };
        break;
      case "custom":
        template = {
          src: "https://www.youtube.com/embed/oHGunGFFQaw",
          width: 80,
          height: 100,
        };
        break;
      case "commend":
        template = {
          users: [],
          message: "Enter message...",
        };
        break;
      case "patchNotes":
        template = {
          pdf: patchDoc?.pdf ? patchDoc.pdf : "https://www.africau.edu/images/default/sample.pdf",
        };
        break;
    }
    setBuildPopup((prev) => {
      return {
        ...prev,
        type: newType,
        data: template,
      };
    });
    setSendFailed(false);
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setBuildPopup((prev) => {
      return {
        ...prev,
        name: newName,
      };
    });
    setSendFailed(false);
  };

  const handlePopupSend = async () => {
    if (Object.values(buildPopup).every((val) => val !== undefined)) {
      await project.popups.add(buildPopup);
      setOpenEdit(false);
    } else {
      setSendFailed(true);
    }
  };

  return (
    <EditContainer>
      <QueryBlur onClick={() => setOpenEdit(false)} />
      <CSSTransition
        in={openEdit}
        timeout={500}
        classNames="edit"
        unmountOnExit
      >
        <Popup>
          <TopBar>
            <p style={{ marginLeft: "12px", fontSize: "20px" }}>
              <b>New Popup</b>
            </p>
            <IconButton
              onClick={() => setOpenEdit(false)}
              style={{ marginRight: "15px" }}
            >
              <Close />
            </IconButton>
          </TopBar>
          <ComponentContent>
            <EditPopupPane>
              <Box>
                <GenericField
                  label="Name"
                  data={buildPopup.name}
                  onChange={handleNameChange}
                />
                <SelectField
                  label="Select Template"
                  data={buildPopup.type}
                  options={options}
                  onChange={handleTypeChange}
                  allowNone={false}
                />
              </Box>
              <TemplateResolver
                buildPopup={buildPopup}
                setBuildPopup={setBuildPopup}
                projectUsers={projectUsers}
              />
            </EditPopupPane>
          </ComponentContent>
          <BottomBar>
            {sendFailed && (
              <Typography style={{ marginRight: "10px" }}>
                Send failed. Make sure all fields are filled in.
              </Typography>
            )}
            <Button2
              label="Send Popup"
              style={{ marginRight: "12px" }}
              onClick={handlePopupSend}
            />
          </BottomBar>
        </Popup>
      </CSSTransition>
    </EditContainer>
  );
};

const TemplateResolver = ({ buildPopup, setBuildPopup, projectUsers }) => {
  if (!buildPopup?.type) return null;

  switch (buildPopup.type) {
    case "announcement":
      return (
        <AnnouncementEditor
          buildPopup={buildPopup}
          setBuildPopup={setBuildPopup}
        />
      );
    case "badges":
      return (
        <BadgesEditor buildPopup={buildPopup} setBuildPopup={setBuildPopup} />
      );
    case "custom":
      return (
        <CustomEditor buildPopup={buildPopup} setBuildPopup={setBuildPopup} />
      );
    case "commend":
      return (
        <CommendationEditor
          buildPopup={buildPopup}
          setBuildPopup={setBuildPopup}
          projectUsers={projectUsers}
        />
      );
    case "patchNotes":
      return <PatchEditor buildPopup={buildPopup} />;
  }
};

const EditContainer = styled.div`
  position: absolute;
  font-family: ${(props) => props.theme.font};
  height: 80%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  height: ${isMobile ? "100%" : "80%"};
  min-height: 300px;
  width: ${isMobile ? "100%" : "50%"};
  background-color: blue;
  z-index: 20;
  position: relative;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ComponentContent = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 25;
`;

const EditPopupPane = styled.div`
  background-color: white;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-bottom: 1px solid #c2c2c2;
  user-select: none;
  z-index: 30;
`;

const BottomBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;

  justify-content: right;
  align-items: center;
  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
  z-index: 30;
`;
