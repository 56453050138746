import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Check, QuestionMark } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { useHotkey } from "../../hooks/system";
import { FullPageContainer } from "../ui/containers";
import { Typography, LinearProgress } from "@mui/material";
import { useUserUpdate } from "../../hooks/users";

export default ({ userData }) => {
  const [ret, setRet] = useState(false);
  const [displayBadge, setDisplayBadge] = useState(
    userData?.displayBadge ? userData?.displayBadge : null
  );
  const updateUser = useUserUpdate();

  useHotkey("Escape", () => setRet(true));

  if (ret) {
    return <Navigate to="/app/projects" />;
  }

  const handleBadgeClick = (badge) => {
    if (
      userData.displayBadge &&
      userData.displayBadge.selectedBadge === badge.name &&
      userData.displayBadge.project === badge.project &&
      userData.displayBadge.selectedBadge !== "none"
    ) {
      //deselect this badge
      setDisplayBadge({
        selectedBadge: "none",
        flair: "none",
        project: "none",
        amountNeeded: 0,
        url: "none",
      });
      updateUser(userData.id, {
        ...userData,
        displayBadge: {
          selectedBadge: "none",
          flair: "none",
          project: "none",
          projectId: "none",
          amountNeeded: 0,
          url: "none",
          id: "none",
        },
      });
    } else {
      if (badge.amountObtained >= badge.amountNeeded) {
        //select this badge
        setDisplayBadge({
          selectedBadge: badge.name,
          flair: badge.flair,
          project: badge.project,
          amountNeeded: badge.amountNeeded,
          url: badge.url,
        });
        //set the selected badge in user data
        updateUser(userData.id, {
          ...userData,
          displayBadge: {
            selectedBadge: badge.name,
            flair: badge.flair,
            project: badge.project,
            projectId: badge.projectId,
            amountNeeded: badge.amountNeeded,
            url: badge.url,
            id: badge.id,
          },
        });
      }
    }
  };

  let hasCompleteBadges = false;
  let hasIncompleteBadges = false;
  const incBadgesByProject = {};

  userData.badges?.map((badge, i) => {
    if (badge.amountObtained >= badge.amountNeeded) {
      hasCompleteBadges = true;
    } else {
      hasIncompleteBadges = true;
      const { project, ...rest } = badge;
      if (!incBadgesByProject[project]) {
        incBadgesByProject[project] = [];
      }
      incBadgesByProject[project].push(rest);
    }
  });

  return (
    <FullPageContainer>
      <Container>
        {userData.badges && userData.badges.length > 0 ? (
          <Typography style={{ color: "white" }}>YOUR BADGES</Typography>
        ) : (
          <Typography style={{ color: "white" }}>
            NO BADGES TO DISPLAY
          </Typography>
        )}
        {displayBadge &&
        displayBadge?.selectedBadge &&
        displayBadge?.selectedBadge !== "none" ? (
          <div
            style={{
              marginTop: "20px",
              display: "inline-flex",
              flexDirection: "row",
              border: "3px solid #CF03B3",
              borderRadius: "10px",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Badge
              name={displayBadge.selectedBadge}
              amountNeeded={0}
              amountObtained={1} //always complete
              flair={displayBadge.flair}
              project={displayBadge.project}
              artUrl={displayBadge.url}
              isSelected
            />
          </div>
        ) : (
          <div
            style={{
              marginTop: "20px",
              display: "inline-flex",
              flexDirection: "row",
              border: "3px solid grey",
              borderRadius: "10px",
              padding: "5px",
              alignItems: "center",
            }}
          >
            <EmptyBadge
              style={{
                height: "50px",
                width: "50px",
              }}
            />
            <Typography
              style={{
                color: "white",
                fontSize: 16,
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              No badge selected
            </Typography>
          </div>
        )}
        {hasCompleteBadges && (
          <Typography
            style={{ color: "white", fontSize: 12, marginTop: "20px" }}
          >
            ACHIEVED BADGES
          </Typography>
        )}
        {userData.badges?.map((badge, i) => {
          return (
            <>
              {badge.amountObtained >= badge.amountNeeded ? (
                <div
                  key={i}
                  style={{ position: "relative", display: "inline-block" }}
                  onClick={() => handleBadgeClick(badge)}
                >
                  <Badge
                    name={badge.name}
                    amountNeeded={badge.amountNeeded}
                    amountObtained={badge.amountObtained}
                    flair={badge.flair}
                    project={badge.project}
                    artUrl={badge.url}
                    info={badge.info}
                  />
                  {badge.name === displayBadge?.selectedBadge && (
                    <div style={{ position: "absolute", top: "0", left: "0" }}>
                      <Check />
                    </div>
                  )}
                  {badge?.info && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                      }}
                    ></div>
                  )}
                </div>
              ) : null}
            </>
          );
        })}
        {hasIncompleteBadges ? (
          <>
            <Typography
              style={{ color: "white", fontSize: 12, marginTop: "20px" }}
            >
              UNACHIEVED BADGES
            </Typography>
            {Object.keys(incBadgesByProject).map((projectName, i) => (
              <div key={i}>
                <h2 style={{ color: "grey" }}>{projectName}</h2>
                <div>
                  {incBadgesByProject[projectName].map((badge, j) => (
                    <div
                      key={`${projectName}-${j}`}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Badge
                        name={badge.name}
                        amountNeeded={badge.amountNeeded}
                        amountObtained={badge.amountObtained}
                        flair={badge.flair}
                        project={badge.project}
                        artUrl={badge.url}
                        info={badge?.info}
                      />
                      {badge?.info && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : null}
      </Container>
    </FullPageContainer>
  );
};

export const Badge = ({
  name,
  amountNeeded,
  amountObtained,
  flair,
  project,
  isDisplay = false,
  isSelected = false,
  artUrl,
  info,
  textColour="white",
}) => {
  const canvasRef = useRef(null);
  const isComplete = parseInt(amountObtained) >= parseInt(amountNeeded);
  if (flair === "bronze") {
    flair = "#CD7F32";
  }

  //particles
  useEffect(() => {
    if (!isComplete && !isDisplay) return undefined;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const particles = [];

    const addParticle = (x, y) => {
      particles.push({
        x,
        y,
        size: Math.random() * 15 + 1,
        speedX: Math.random() * 3 - 1.5,
        speedY: Math.random() * 3 - 1.5,
        angle: Math.floor(Math.random() * 360),
        color: flair,
      });
    };

    const updateParticles = () => {
      particles.forEach((p, index) => {
        if (p.size <= 0) {
          particles.splice(index, 1);
        } else {
          p.x += p.speedX;
          p.y += p.speedY;
          p.angle += 5;
          p.size -= 0.1;
        }
      });
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((p) => {
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate((p.angle * Math.PI) / 180);
        ctx.fillStyle = p.color;
        ctx.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
        ctx.restore();
      });
    };

    const animateParticles = () => {
      requestAnimationFrame(animateParticles);
      addParticle(canvas.width / 2, canvas.height / 2);
      updateParticles();
      drawParticles();
    };

    canvas.width = 200;
    canvas.height = 200;
    animateParticles();

    return () => cancelAnimationFrame(animateParticles);
  }, [flair, isComplete, isDisplay]);

  //the selected badge has a different look to make it pop
  if (isSelected) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              position: "absolute",
              zIndex: 0,
              width: "100px",
              height: "100px",
            }}
          />
          <BadgeIcon
            style={{
              background: "white",
              border: "5px solid " + flair,
              height: "60px",
              width: "60px",
              left: "-5px",
            }}
            src={artUrl}
            alt="badge image"
          />
        </div>
        <div
          style={{
            dispaly: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: textColour,
              fontSize: 16,
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            Selected badge: {name}
          </Typography>
          <BadgeText style={{ color: "grey", whiteSpace: "nowrap" }}>
            {project}
          </BadgeText>
        </div>
      </>
    );
  }

  return (
    <Tooltip title={info}>
      <BadgeContainer
        style={{
          opacity: isComplete || isDisplay ? 1.0 : 0.5,
          width: isDisplay ? "70px" : "100px",
          maxHeight: isDisplay ? "140px" : "250px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              position: "absolute",
              zIndex: 0,
              width: isDisplay ? "75px" : "100px",
              height: isDisplay ? "75px" : "100px",
            }}
          />
          <BadgeIcon
            style={{
              background: "white",
              border: "5px solid " + flair,
              height: isDisplay ? "30px" : "60px",
              width: isDisplay ? "30px" : "60px",
              left: "-5px",
            }}
            src={artUrl}
            alt="badge image"
          />
        </div>
        {!isDisplay && (
          <LinearProgress
            variant="determinate"
            value={isComplete ? 100 : (amountObtained / amountNeeded) * 100}
          />
        )}
        {!isDisplay && !isComplete && (
          <BadgeText style={{ fontSize: 14, color: textColour }}>
            {amountObtained} / {amountNeeded}
          </BadgeText>
        )}
        {!isDisplay && isComplete && (
          <BadgeText style={{ fontSize: 14, color: textColour }}>Complete!</BadgeText>
        )}
        <BadgeText style={{ fontSize: isDisplay ? 10 : 16, color: textColour  }}>{name}</BadgeText>
        {!isDisplay ? (
          <BadgeText style={{ color: "grey", whiteSpace: "nowrap" }}>
            {project}
          </BadgeText>
        ) : null}
      </BadgeContainer>
    </Tooltip>
  );
};

export const DetermineBadgeDisplay = ({ displayBadge }) => {
  // there are no possible badges for the user to earn or display
  if(displayBadge === null) return;

  // the user can has at least the possibility of earning badges
  if (!displayBadge?.selectedBadge || displayBadge?.selectedBadge === "none") {
    return (
      <>
        <EmptyBadge />
        <BadgeText style={{ fontSize: 10 }}>No badge</BadgeText>
      </>
    );
  }

  return (
    <Badge
      name={displayBadge?.selectedBadge}
      flair={displayBadge.flair}
      artUrl={displayBadge.url}
      isDisplay
    ></Badge>
  );
};

const Container = styled.div`
  padding: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin-top: 18px;
  margin-left: 14px;
  width: ${isMobile ? "100%" : "50%"};

  background: ${(props) => props.theme.palette.background.step50};
  border-radius: 4px;
`;

const BadgeContainer = styled.div`
  border: 2px;
  max-width: 200px;
  max-height: 300px;
  padding: 5px;
  gap: 10px;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  display-items: center;
`;

const BadgeIcon = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
  border: 5px solid gold;
  position: relative;
  z-index: 2;
`;

const BadgeText = styled(Typography)`
  color: white;
  display: flex;
  justify-content: center;
`;

const EmptyBadge = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border: 5px dashed grey;
  margin-top: 15px;
  margin-bottom: 15px;

  z-index: 2;
  background-color: ${(props) => props.theme.palette.background.step150};
`;
