import { Button } from "@mui/material";
import React from "react";
import { Inputs } from "../../../ui/inputs2";

import { QueryContent } from "../query";

export default ({ setAdminEdit }) => {
  return (
    <QueryContent>
      <Inputs>
        <Button onClick={() => setAdminEdit(true)}>Force Query Editable</Button>
      </Inputs>
    </QueryContent>
  );
};
