import React, { useState, useContext } from "react";
import styled from "styled-components";

import { ProjectRouteContainer } from "../../../project";
import { InteractiveTable } from "../../../ui/table";
import { Button2, ButtonSection } from "../../../ui/buttons";
import { useCurrentUsers, usePopups } from "../../../../hooks/projects";
import AddPopup from "./new"
import ViewModal from "./view"

import { 
  Typography, 
} from "@mui/material";
import { Announcement } from "@mui/icons-material";

//THEMES
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { panelTheme } from "../../../../themes/mui_theme_panels";
import {
  materialTheme,
} from "../../../../themes/mui_theme";
import { ThemeContext } from "styled-components";

export default ({ project, projectData }) => {
  const popups = usePopups(project);
  const theme = useContext(ThemeContext);

  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewingPopup, setViewingPopup] = useState(undefined);

  const handlePopupView = (popup) => {
    setOpenView(true);
    setViewingPopup(popup);
  };

  return (
    <ProjectRouteContainer>
      {
        openEdit &&
        <MuiThemeProvider theme={panelTheme(materialTheme(theme))}>
          <AddPopup
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            projectData={projectData}
            project={project}
          />
        </MuiThemeProvider>
      }
      {
        !openEdit && // lets just make sure both modals wont open at the same time
        <ViewModal open={openView} closeView={() => {setOpenView(false)}} data={viewingPopup}/>
      }
      <Typography variant="h4" color="white" mb={2}>
        <Announcement fontSize="medium"/> Popup Manager
      </Typography>
      <ButtonSection>
        <Button2 label={"Add New Popup"} onClick={() => {setOpenEdit(true)}} />
      </ButtonSection>
      <Message>Popup Log</Message>
      <InteractiveTable
        data={popups ? popups : []}
        columns={analyticsColumns(handlePopupView)}
      />
    </ProjectRouteContainer>
  );
};

const analyticsColumns = (handlePopupView) => [
  { name: "Name", index: "name", },
  { name: "Date Sent", index: "date", },
  { name: "Users Received", index: "received", },
  { name: "Opened", index: "openRate", },
  {
    name: "View Popup",
    component: (row) => (
      <Button2 label={"View"} onClick={() => handlePopupView(row)} />
    ),
  },
];

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;