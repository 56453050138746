import React, { useState, useEffect, useRef } from "react";
import q from "@queryit/api";
import { log_error } from "../tools/logger";

let cachedUser = undefined;
let cachedUserData = undefined;

export const useUserAuth = () => {
  const [user, setUser] = useState(cachedUser);
  const [authAttempted, setAuthAttempted] = useState(false);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) setAuthAttempted(true);
    else didMountRef.current = true;
  }, [user]);

  useEffect(() => {
    let mounted = true;
    q.auth.onAuthStateChanged((e) => {
      const queryUser = q.users.currentUser();
      cachedUser = queryUser;
      if (mounted) setUser(queryUser === undefined ? null : queryUser);
    });
    return () => (mounted = false);
  }, []);

  return [user, authAttempted];
};

export const useUser = (user) => {
  const [userData, setUserData] = useState(undefined);

  useEffect(() => {
    if (user) {
      const unsub = user.listen(
        (userData) => {
          setUserData(userData);
          cachedUserData = userData;
        },
        (e) => log_error("User Data render failed" + e)
      );
      return () => unsub();
    } else if (user === null) {
      setUserData(undefined);
    }
  }, [user]);

  return userData;
};

export const useUserUpdate = () => {
  const updateUser = async (userID, updateData) => {
    try {
      const userRef = q.users.doc(userID);
      await userRef.update({ ...updateData });
    } catch (error) {
      log_error("User Update failed");
    }
  };

  return updateUser;
};

export const useUserPermissions = (user) => {
  const [permissions, setPermissions] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    if (user) {
      const unsub = user.permissions.listen(
        (data) => {
          if (mounted) setPermissions(data);
        },
        (e) => log_error("User Permissions render failed" + e)
      );
      return () => {
        mounted = false;
        unsub();
      };
    }
  }, [user]);

  return permissions;
};

/**
 * Philosophically, the difference here versus the userPermissions naive fetch is that
 * this set of permissions is a dict that we can check against as booleans.
 * All permissions are resolved here directly (though they are protected in the backend separately)
 *
 * Note: There is some level of trust because users can write some documents whether we like it or not
 * In these cases, we'd want to use a backend check to ensure the user is actually allowed to do what they'd like
 * TODO: Add backend checks for user permission rights
 */
export const useUserBuiltPermissions = (
  user,
  schemaData = undefined,
  projectUsers = []
) => {
  const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);

  useEffect(() => {
    let mounted = true;
    if (user) {
      const userId = user.ref.id;
      const unsub = user.permissions.listen(
        (permsData) => {
          const allowAll =
            permsData.admin ||
            projectUsers.find((u) => u.id === userId && u.projectAdmin);
          if (mounted)
            setPermissions({
              ...DEFAULT_PERMISSIONS,
              canEditSchema: allowAll,
              canEditSchemaDefaults: allowAll,
              canEditSchemaValidationControls: allowAll,
              canEditSchemaDynamicID: allowAll,
              canEditSchemaFilters: allowAll,
              canForceEditQuery: allowAll,
              canCancelQuery:
                allowAll || schemaData?.cancelWhitelist?.includes(userId),
              canDeleteQuery:
                allowAll || schemaData?.deleteWhitelist?.includes(userId),
              canDuplicateQuery:
                allowAll ||
                !schemaData?.settings?.duplicateRestricted ||
                schemaData?.duplicateWhitelist?.includes(userId),
            });
        },
        (e) => log_error("User Permissions render failed" + e)
      );
      return () => {
        mounted = false;
        unsub();
      };
    }
  }, [user, schemaData, projectUsers]);

  return permissions;
};

export const useUsers = () => {
  const [users, setUsers] = useState(undefined);

  const addUser = async (email, phone, name, userIsSSO) => {
    /**
     * AddUser is the method by which we introduce users to our application.
     * How this works is as follows:
     *  1. Attempt to create the user with email
     *    1.1 Now we'll check if the user requires SSO (this can only be the case on email registration)
     *  2. If email is not provided, try to create the user with phone number
     *  3. If neither is available, giveup
     */
    try {
      let user;
      let result;
      if (email !== undefined && email.length > 1) {
        result = await q.createUserWithEmail(email);
      } else if (phone !== undefined && phone.length > 1) {
        result = await q.createUserWithPhone(phone);
      } else {
        throw new Error("No email or phone number provided");
      }
      user = result.user;
      // Email can be optional
      let emailObj = {};
      if (email !== "") {
        emailObj["email"] = email;
      }
      // Phone can be optional
      let phoneObj = {};
      if (phone !== "") {
        phoneObj["phone"] = phone;
      }
      // First time is configurable
      // Note: The 'firstTime' variable defines that first-time processes should be run, and can be 'any' or 'sso' to force sso
      let firstTimeObj = {
        firstTime: "any",
      };
      if (userIsSSO) {
        firstTimeObj["firstTime"] = "sso";
        firstTimeObj["issuedPassword"] = false;
      }
      // Put it all together in an update
      const userRef = q.users.doc(user.uid);
      await userRef.update({
        id: user.uid,
        name: name,
        ...emailObj,
        ...firstTimeObj,
        ...phoneObj,
      });
      return userRef;
    } catch (error) {
      log_error("User Creation failed");
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;
    const unsub = q.users.listen((users) => {
      if (mounted) setUsers(users);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  return [users, addUser];
};

export const useUserLookup = (uid) => {
  const [user, setUser] = useState(undefined);
  const [userRef, setUserRef] = useState(undefined);

  useEffect(() => {
    let userMount = true;
    if (uid) {
      setUserRef(q.users.user(uid));
      q.users.user(uid).listen((data) => {
        if (userMount) setUser(data);
      });
    }
    return () => {
      userMount = false;
    };
  }, [uid]);

  return [user, userRef];
};

export const useNotifications = (user) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (user) {
      const unsub = user.notifications.listen((data) => {
        if (mounted) setNotifications(data);
      });
      return () => {
        unsub();
        mounted = false;
      };
    }
  }, [user]);

  return notifications;
};

// What distinguishes this hook from the one above is that this one takes in a userID
export const useUserNotifications = (userID) => {
  const [userNotifications, setUserNotifications] = useState([]);

  useEffect(() => {
    q.users
      .doc(userID)
      .collection("notifications")
      .listen((docs) => setUserNotifications(docs));
  }, []);

  return userNotifications;
};

export const useDeleteUser = () => {
  const deleteUser = async (userID) => {
    try {
      await q.users.doc(userID).delete();
    } catch (error) {
      log_error("User deletion failed");
    }
  };

  return deleteUser;
};

const DEFAULT_PERMISSIONS = {
  // Administrative Permissions
  canEditSchema: false,
  canEditSchemaDefaults: false,
  canEditSchemaValidationControls: false,
  canEditSchemaDynamicID: false,

  // User access write permissions
  canCancelQuery: false,
  canDeleteQuery: false,
};
