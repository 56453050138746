import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { withTheme } from "@mui/styles";
import { IconButton } from "@mui/material";
import { Edit, Check } from "@mui/icons-material";
import Draggable from "react-draggable";
import {
  TableBodyTCell,
  EditButtonContainer,
  ModifyPrompterButton,
  ModifyPrompter,
} from "./body";
import {
  ChangeContext,
  GlobalDisablerContext,
  TableCellInteractable,
  SchemaEditOnlyContext,
  TableEditorContext,
} from ".";
import { TextCell } from "./fields";

const TableHeader = ({
  columns,
  showSimpleHeader,
  headerScheme,
  headerData,
  noHover,
}) => {
  const { setSchemaChange } = useContext(ChangeContext);

  // remove header from advanced table scheme if it is empty
  useEffect(() => {
    if (Array.isArray(headerScheme) && headerScheme.length === 0) {
      setSchemaChange((ex) => {
        const { header, ...rest } = ex.schema;
        return rest;
      });
    }
  }, [headerScheme]);

  return (
    <TableHeadMain>
      {headerScheme &&
        headerScheme.map((headerRow, ind) => (
          <TableComplexHeader
            key={`Header-Row-${ind}`}
            headerRow={headerRow}
            rowIndex={ind}
            data={headerData}
            noHover={noHover}
          />
        ))}
      {showSimpleHeader && <TableSimpleHeader headers={columns} />}
    </TableHeadMain>
  );
};

const TableSimpleHeader = ({ headers }) => {
  // The simple head is special because it doesn't load any data
  // It just displays the headings for columns in the schema
  // Like a very normal table
  const MIN_COL_WIDTH = 15;
  const [headerHeight, setHeaderHeight] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const rowRef = useRef(null);
  const headerRefs = useRef([]);

  const schemaEditOnly = useContext(SchemaEditOnlyContext);
  const { setSchemaChange } = useContext(ChangeContext);

  useEffect(() => {
    setHeaderHeight(rowRef.current.offsetHeight);
  }, []);

  const mouseMove = useCallback(
    (e) => {
      if (activeIndex !== undefined) {
        // Calculate the column width
        const { left } =
          headerRefs.current[activeIndex].getBoundingClientRect();
        const width = e.clientX - left - MIN_COL_WIDTH;

        if (width >= MIN_COL_WIDTH) {
          setSchemaChange((ex) => ({
            ...(ex?.schema || {}),
            body: {
              ...(ex?.schema?.body || [{}]),
              columns: [
                // All columns BEFORE it
                ...(ex?.schema?.body?.columns ?? [{}]).slice(0, activeIndex),
                // The column itself
                {
                  ...ex?.schema?.body?.columns[activeIndex],
                  style: {
                    ...ex?.schema?.body?.columns[activeIndex]?.style,
                    width: width,
                  },
                },
                // All columns AFTER it
                ...(ex?.schema?.body?.columns ?? [{}]).slice(activeIndex + 1),
              ],
            },
          }));
        }
      }
    },
    [activeIndex]
  );

  const mouseUp = useCallback(() => {
    setActiveIndex(undefined);
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
  }, []);

  useEffect(() => {
    if (activeIndex !== undefined) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };
  }, [activeIndex]);

  return (
    <TableHeadTRow ref={rowRef}>
      {/* The first cell we render is a fixed-width cell for table modify prompter (which may or may not materialize) */}
      <TableCellInteractable />
      {headers.map((header, colIndex) =>
        header?.type === "hidden" ? null : (
          <TableHeadTCell
            key={colIndex}
            ref={(element) => headerRefs.current.push(element)}
            {...header.style}
          >
            <span>{header.title ? header.title : header.name}</span>
            {schemaEditOnly && (
              <ResizeHandle
                style={{
                  height: headerHeight,
                  ...(activeIndex === colIndex && {
                    borderColor: "rgba(0, 0, 0, 0.4)",
                  }),
                }}
                onMouseDown={() => setActiveIndex(colIndex)}
              />
            )}
          </TableHeadTCell>
        )
      )}
    </TableHeadTRow>
  );
};

const TableComplexHeader = ({ headerRow, rowIndex, data, noHover }) => {
  const [showModifyPrompter, setShowModifyPrompter] = useState(
    noHover ? true : false
  );
  const [isDragging, setIsDragging] = useState(false);

  const schemaEditOnly = useContext(SchemaEditOnlyContext);
  const { setSchemaChange } = useContext(ChangeContext);

  // Remove header from header array if it is empty
  useEffect(() => {
    if (Object.keys(headerRow).length === 0) {
      deleteHeader();
    }
  }, [headerRow]);

  const moveRow = (oldRowIndex, newRowIndex) => {
    if (newRowIndex > oldRowIndex) {
      setSchemaChange((ex) => ({
        ...(ex?.schema || {}),
        header: [
          // All indices BEFORE the OLD row position
          ...(ex?.schema?.header ?? [{}]).slice(0, oldRowIndex),
          // All indices BEFORE the NEW row position but AFTER the OLD row position
          ...(ex?.schema?.header ?? [{}]).slice(oldRowIndex + 1, newRowIndex),
          // The row itself
          (ex?.schema?.header ?? [{}])[oldRowIndex],
          // All indices AFTER the NEW row position
          ...(ex?.schema?.header ?? [{}]).slice(newRowIndex),
        ],
      }));
    } else if (newRowIndex < oldRowIndex) {
      newRowIndex += rowIndex;
      newRowIndex = newRowIndex < 0 ? 0 : newRowIndex;
      setSchemaChange((ex) => ({
        ...(ex?.schema || {}),
        header: [
          // All indices BEFORE the NEW row position
          ...(ex?.schema?.header ?? [{}]).slice(0, newRowIndex),
          // The row itself
          (ex?.schema?.header ?? [{}])[oldRowIndex],
          // All indices AFTER the NEW row position but BEFORE the old row position
          ...(ex?.schema?.header ?? [{}]).slice(newRowIndex, oldRowIndex),
          // All indices AFTER the OLD row position
          ...(ex?.schema?.header ?? [{}]).slice(oldRowIndex + 1),
        ],
      }));
    }
  };

  const duplicateHeader = () => {
    setSchemaChange((ex) => ({
      ...(ex?.schema || {}),
      header: [
        // All indices BEFORE row
        ...(ex?.schema?.header ?? [{}]).slice(0, rowIndex),
        // The row itself (twice)
        (ex?.schema?.header ?? [{}])[rowIndex],
        (ex?.schema?.header ?? [{}])[rowIndex],
        // All indices AFTER row
        ...(ex?.schema?.header ?? [{}]).slice(rowIndex + 1),
      ],
    }));
  };

  const deleteHeader = () => {
    setSchemaChange((ex) => ({
      ...(ex?.schema || {}),
      header: [
        // All indices BEFORE row
        ...(ex?.schema?.header ?? [{}]).slice(0, rowIndex),
        // All indices AFTER row
        ...(ex?.schema?.header ?? [{}]).slice(rowIndex + 1),
      ],
    }));
  };

  return (
    <Draggable
      handle=".handle"
      key={rowIndex}
      onStart={() => setIsDragging(true)}
      onStop={
        (e, { y }) =>
          !setIsDragging(false) && y >= 0
            ? moveRow(rowIndex, rowIndex + Math.round(y / 17)) //move row down
            : moveRow(rowIndex, Math.round(y / 30)) //move row up
      }
      position={{ x: 0, y: 0 }}
      axis="y"
    >
      <TableHeadTRow
        onMouseEnter={() => setShowModifyPrompter(true)}
        onMouseLeave={() => setShowModifyPrompter(false)}
        style={
          isDragging
            ? { position: "relative", zIndex: 100 }
            : { position: "relative" }
        }
      >
        <TableCellInteractable />
        {(Array.isArray(headerRow) ? headerRow : Object.values(headerRow)).map(
          (headerCell, cellIndex) => {
            return (
              <React.Fragment key={cellIndex}>
                {/*Generate padCells for first column here so prompter button can be beside it*/}
                {schemaEditOnly && cellIndex == 0 && headerCell.rowOffset && (
                  <>
                    {[...Array(headerCell.rowOffset - 1).keys()].map(
                      (v, ind) => (
                        <td key={ind}></td>
                      )
                    )}
                    <ModifyPrompter style={{ width: "unset" }}>
                      {showModifyPrompter && (
                        <ModifyPrompterButton
                          options={[
                            { label: "Duplicate", action: duplicateHeader },
                            { label: "Delete", action: deleteHeader },
                          ]}
                          noHoverIcon={noHover}
                          isDragging={isDragging}
                        />
                      )}
                    </ModifyPrompter>
                  </>
                )}
                <TableHeadCellResolver
                  key={`Header-${rowIndex}-${cellIndex}`}
                  cell={headerCell}
                  rowIndex={rowIndex}
                  colIndex={cellIndex}
                  data={
                    data?.[rowIndex]?.[cellIndex] ?? headerCell.default ?? ""
                  }
                />
              </React.Fragment>
            );
          }
        )}
      </TableHeadTRow>
    </Draggable>
  );
};

const TableHeadCellResolver = ({ cell, data, rowIndex, colIndex }) => {
  const [isFocus, setIsFocus] = useState(false);
  const { setChange } = useContext(ChangeContext);

  const disabled = useContext(GlobalDisablerContext);
  const schemaEditOnly = useContext(SchemaEditOnlyContext);
  const { tableHeaderEditor, setTableHeaderEditor, setTableColumnEditor } =
    useContext(TableEditorContext);

  const setRowChange = useCallback(
    (callback) => {
      setChange((ex) => ({
        ...ex,
        header: {
          ...ex.header,
          // The row itself (We've used dict all the way through the header because determinism is hard and pointless)
          [rowIndex]: {
            ...(ex.header ?? { [rowIndex]: {} })[rowIndex],
            [colIndex]: callback(
              ((ex.header ?? { [rowIndex]: {} })[rowIndex] ?? {})[colIndex] ??
                undefined
            ),
          },
        },
      }));
    },
    [setChange, rowIndex, colIndex]
  );

  let component = null;

  switch (cell.type) {
    case "static":
      component = data;
      break;
    case "text":
      component = <TextCell data={data} setChange={setRowChange} />;
      break;
    case "number":
      component = (
        <TextCell type="number" data={data} setChange={setRowChange} />
      );
      break;
    default:
      component = "";
  }

  if (disabled) {
    component = data; // We just revert everything to static if disabled :)
  }

  const cellStyle = {
    textAlign: cell.style?.align ?? "left",
  };

  const TableCellComponent = cell.style?.bold ? TableHeadTCell : TableBodyTCell;

  // Now if there's row offset, generate that too!
  const padCells = [...Array(cell.rowOffset ?? 0).keys()].map((v, ind) => (
    <td key={ind}></td>
  )); // Use dead cells (no style)

  return (
    <>
      {/*padCells show in the parent for the first column in edit view*/}
      {(!schemaEditOnly || colIndex != 0) && padCells}
      <TableCellComponent
        style={cellStyle}
        colSpan={cell.style?.colSpan ?? 1}
        isFocus={isFocus}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      >
        {schemaEditOnly ? (
          <EditButtonContainer>
            <IconButton
              onClick={() => {
                if (
                  tableHeaderEditor?.row == rowIndex &&
                  tableHeaderEditor?.col == colIndex
                ) {
                  setTableHeaderEditor(undefined);
                  setIsFocus(false);
                } else {
                  setTableColumnEditor(undefined);
                  setTableHeaderEditor({
                    details: cell,
                    row: parseInt(rowIndex, 10),
                    col: parseInt(colIndex, 10),
                  });
                }
              }}
              style={{ padding: 0 }}
            >
              {tableHeaderEditor?.row == rowIndex &&
              tableHeaderEditor?.col == colIndex ? (
                <Check />
              ) : (
                <Edit />
              )}
            </IconButton>
          </EditButtonContainer>
        ) : (
          component
        )}
      </TableCellComponent>
    </>
  );
};

export default TableHeader;

const TableHeadMain = styled.thead``;

const TableHeadTRow = styled.tr``;

const TableHeadTCell = withTheme(styled(TableBodyTCell)`
  font-weight: 500;
  background-color: ${(props) => props.theme.palette.grey[100]};

  // Conditional style application
  // Fixed width
  ${(props) =>
    props.width &&
    `max-width: ${isNaN(props.width) ? props.width : `${props.width}px`}; 
    min-width: ${isNaN(props.width) ? props.width : `${props.width}px`}`}
`);

const ResizeHandle = styled.div`
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 15px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;

  &:hover {
    border-color: #898989;
  }
`;
