import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import {
  FormCompositeContext,
  FormContent,
  FormDataContext,
  SUBMISSIONVIEWS,
} from ".";

import { QueryFields } from "../../project/query/fields";
import { MiniIconButtonShell, QuerySection } from "../../project/query/query";
import {
  Tooltip,
  Fab,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Save, Send } from "@mui/icons-material";
import { useFormData } from "../../../hooks/forms";
import { FloatingSideContainer } from "../../ui/containers";

export default ({ setStatusMessage, formSchemas }) => {
  const form = useContext(FormCompositeContext);
  const formData = useContext(FormDataContext);

  const [saveTrigger, setSaveTrigger] = useState(0);
  const [formSectionFocusTrigger, setFormSectionFocusTrigger] = useState({});

  const navigate = useNavigate();

  if (!formData || Object.values(formSchemas ?? {}).length < 1) {
    return null;
  }

  return (
    <>
      {/* This is a floating panel on the left side */}
      <FloatingSideContainer orientation="right">
        <Typography style={{ marginLeft: "10px", marginTop: "5px" }}>
          Form Sections
        </Typography>
        <List>
          {Object.values(formSchemas).map((formSchema) => (
            <ListItem
              button
              dense
              key={formSchema.id}
              onClick={() =>
                setFormSectionFocusTrigger((ex) => ({
                  ...ex,
                  [formSchema.id]: (ex?.[formSchema?.id] ?? 0) + 1,
                }))
              }
            >
              <ListItemText>{formSchema?.title}</ListItemText>
              {/* TODO: Add lock here to indicate locked section */}
              <ListItemIcon></ListItemIcon>
            </ListItem>
          ))}
        </List>
      </FloatingSideContainer>

      <FormContent>
        {/* TODO: Add company logo here */}
        {/* TODO: Add info like due date, next phase name, etc. to form here */}
        {Object.values(formSchemas).map((formSchema) => (
          <FormTab
            key={formSchema.id}
            formComposite={form}
            formSchema={formSchema}
            status={formData.status}
            saveTrigger={saveTrigger}
            focusTrigger={formSectionFocusTrigger?.[formSchema.id]}
          />
        ))}
      </FormContent>

      {/* this is a floating panel on the right side */}
      {isMobile ? (
        <>
          <Fab
            style={{ position: "fixed", bottom: 60, right: 8 }}
            color="primary"
            onClick={() => {
              setSaveTrigger((ex) => ex + 1);
              setStatusMessage("Form Saved");
            }}
          >
            <Save sx={{ color: "#901D81" }} />
          </Fab>
          <Fab
            size="small"
            style={{ position: "fixed", bottom: 130, right: 8 }}
            color="primary"
            onClick={() => {}}
          >
            <Send />
          </Fab>
        </>
      ) : (
        <FloatingSideContainer icons>
          <Tooltip title="Save">
            <MiniIconButtonShell
              onClick={() => {
                setSaveTrigger((ex) => ex + 1);
                setStatusMessage("Form Saved");
              }}
            >
              <Save />
            </MiniIconButtonShell>
          </Tooltip>
          <Tooltip title="Submit Form">
            <MiniIconButtonShell
              onClick={() => {
                form.update({ ...formData, status: "submitted" });
                setStatusMessage("Form Submitted");
                navigate("../" + SUBMISSIONVIEWS.COMPLETED);
              }}
            >
              <Send />
            </MiniIconButtonShell>
          </Tooltip>
        </FloatingSideContainer>
      )}
    </>
  );
};

// TODO: This needs render improvements
const FormTab = ({
  formComposite,
  formSchema,
  status,
  saveTrigger,
  focusTrigger,
}) => {
  const [form, formData, setFormData, dirty, clean, formDataStatic] =
    useFormData(formComposite, formSchema?.id);

  const lastSave = useRef(0);
  const myViewRef = useRef(undefined);

  const onFieldChange = useCallback(
    (fieldId) => (val) => {
      setFormData((ex) => ({ ...ex, data: { ...ex.data, [fieldId]: val } }));
    },
    [setFormData]
  );

  const setFieldChange = useCallback(
    (callback) => {
      setFormData((ex) => ({ ...ex, data: callback(ex?.data) }));
    },
    [setFormData]
  );

  useEffect(() => {
    if (saveTrigger !== lastSave.current) {
      lastSave.current = saveTrigger;
      form.update(formData);
      clean();
    }
  }, [saveTrigger, form, formData, clean]);

  useEffect(() => {
    if (myViewRef.current) {
      myViewRef.current.scrollIntoView();
    }
  }, [focusTrigger]);

  if (!formData) {
    return null;
  }

  // We only render fields if the formData is editable
  if (formData?.editable !== true) {
    return (
      <FormTabUnavailable
        ref={myViewRef}
        name={formSchema.name}
        title={formSchema.title}
      />
    );
  }

  return (
    <QueryFields
      ref={myViewRef}
      data={formData.data}
      onFieldChange={onFieldChange}
      setFieldChange={setFieldChange}
      fieldSet={formSchema.fields}
      status={status !== "submitted" ? "open" : "readonly"}
      editabilityConditions={{
        forceEdit: status !== "submitted",
      }}
    />
  );
};

const FormTabUnavailable = ({ name, title }) => {
  return (
    <QuerySection style={{ paddingTop: "20px" }}>
      <Typography style={{ width: "100%", textAlign: "center" }}>
        <b>{name}</b> is not available yet. You will be notified when the{" "}
        {title} section of the form is accessible.
      </Typography>
    </QuerySection>
  );
};

// Import wasn't working here
const FloatingContainer = styled.div`
  background: white;
  width: 32px;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  flex-direction: column;

  left: -50px;
  z-index: 1200;

  position: sticky;

  margin-left: 10px;
  height: fit-content;

  top: 0px; // To reset the top position
`;
