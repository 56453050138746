// import { warn_deprecated } from "./logger";

export const error_text_from_code = (code: number) => {
  switch (code) {
    case 10:
      return "Required Field";
    case 12:
      return "Validation Failed";
    default:
      // In case a custom error is defined by stringifying or something
      return code;
  }
};

// export const status_diff = (
//   newStatus: string,
//   oldStatus: string,
//   customStatusSet: any[] = []
// ) => {
//   warn_deprecated();
//   if (customStatusSet.length > 0) {
//     let oldAt = 0;
//     let newAt = 0;
//     let iter = 0;
//     customStatusSet.forEach((st) => {
//       if (st.status == oldStatus) {
//         oldAt = iter;
//       } else if (st.status == newStatus) {
//         newAt = iter;
//       }
//       iter++;
//     });
//     return oldAt - newAt;
//   } else {
//     // TODO: Add this evaluator once all statuses are defined
//     return 0;
//   }
// };

// export const is_status_open = (status: string, statusSet: any = undefined) => {
//   warn_deprecated();
//   if (!statusSet) {
//     return status === "open" || status === "pending";
//   }
//   let fullStatus = statusSet[status];
//   if (fullStatus) {
//     return (
//       !fullStatus.statusType ||
//       ["evaluate", "open"].includes(fullStatus.statusType)
//     );
//   } else {
//     return false;
//   }
// };

// export const is_status_closed = (
//   status: string,
//   customStatusSet: any = undefined
// ) => {
//   warn_deprecated();
//   if (!customStatusSet) {
//     return status === "closed" || status === "approved";
//   }
//   let fullStatus = customStatusSet[status];
//   if (fullStatus) {
//     return fullStatus.statusType === "closed" || fullStatus.status === "closed";
//   } else {
//     return false;
//   }
// };

// export const status_equal = (status: any, newStatusString: string) => {
//   warn_deprecated();
//   if (typeof status === "object" && status !== null) {
//     // Then we're dealing with a status object that could have a
//     // Status type or similar
//     return status.current.statusType
//       ? status.current.statusType == newStatusString
//       : status.current.status == newStatusString;
//   }
//   return status == newStatusString;
// };

// export const get_statusset = (schema: any) => {
//   warn_deprecated();
//   if (schema?.customStatusSet) {
//     return schema.customStatusSet;
//   } else if (schema?.type) {
//     return TEMPLATE_SCHEMA_STATUSSETS[schema.type];
//   }
//   return {};
// };

export const parse_on_fields = (
  fields: any[],
  callback: (fld: any) => void,
  sectionCallback = (sec: any) => {},
  iterator = () => {}
) => {
  /**
   * @param {Array} fields as defined, can be either a field, nested (group of fields) or a section (group of fields)
   * @param {Function} callback to be called on each field once extracted from nest or section (or just called if neither)
   * @param {Function} sectionCallback to be called on each section before iterating over its fields, calls with undefined on section exit
   * @param {Function} iterator to be called on each nest or field before calling callback (for counting rows in query)
   */
  fields?.forEach((fld) => {
    if (fld.section) {
      sectionCallback(fld);
      fld.section?.forEach((secFld: any) => {
        iterator();
        if (secFld.nested) {
          secFld.nested?.forEach((nstFld: any) => callback(nstFld));
        } else {
          callback(secFld);
        }
      });
      sectionCallback(undefined);
    } else {
      iterator();
      if (fld.nested) {
        fld.nested?.forEach((nstFld: any) => callback(nstFld));
      } else {
        callback(fld);
      }
    }
  });
};

export const index_object_by_string = (
  targetObject: { [i: string]: any },
  indexString: string
) => {
  let data = targetObject;
  try {
    indexString?.split(".").forEach((ind) => (data = data?.[ind] ?? undefined));
  } catch {
    return undefined;
  }
  return data;
};

export const get_field_by_id = (fields: any[], id: string) => {
  if (!fields || fields.length < 1) {
    return null;
  }
  // Now parse and find the field
  let candidate = fields.find((fld: any) => {
    if (fld.section) {
      return fld.section?.some((secFld: any) => {
        if (secFld.nested) {
          return secFld.nested?.some((nstFld: any) => nstFld.id == id);
        }
        return secFld.id == id;
      });
    } else {
      if (fld.nested) {
        return fld.nested?.some((nstFld: any) => nstFld.id == id);
      }
      return fld.id == id;
    }
  });
  // After candidate, check if it's a field at all, if not, return null
  if (!candidate) {
    return null;
  }
  // If it's a section, we'll deal with that now before returning
  if (candidate.section) {
    let secCandidate = candidate.section?.find((secFld: any) => {
      if (secFld.nested) {
        return secFld.nested?.some((nstFld: any) => nstFld.id == id);
      }
      return secFld.id == id;
    });
    if (!secCandidate) {
      return null;
    }
    candidate = secCandidate;
  }
  // Then handle if the candidate is nested
  if (candidate.nested) {
    return candidate.nested?.find((nstFld: any) => nstFld.id == id);
  } else {
    return candidate;
  }
};

// export const thisStatusAfterThat = (
//   thisStatus: any,
//   thatStatus: any,
//   customStatusSet = []
// ): boolean => {
//   warn_deprecated();
//   if (!thatStatus.advance) {
//     return false;
//   }
//   if (
//     thatStatus?.advance.requirements.some(
//       (rq: any) => rq.moveto === thisStatus.status
//     )
//   ) {
//     return true;
//   }

//   // If it's not directly following, we need to parse the set and check
//   // We'll go from thatStatus to thisStatus or the end, whichever comes first
//   // We assume there is a status with "statusType" closed at the end
//   let currStatus = thatStatus;
//   while (currStatus.statusType !== "closed") {
//     if (currStatus.status === thisStatus.status) {
//       return true;
//     }

//     // Now we can advance to the next status
//     if (
//       currStatus?.advance &&
//       currStatus?.advance.requirements.filter(
//         (rq: any) => rq.moveTo !== undefined
//       ).length > 1
//     ) {
//       // In this case we skip polls
//       customStatusSet[
//         currStatus?.advance.requirements.find(
//           (rq: any) => rq.moveTo !== undefined && rq.type !== "poll"
//         ).moveTo
//       ];
//     } else {
//       currStatus =
//         customStatusSet[
//           currStatus?.advance.requirements.find(
//             (rq: any) => rq.moveTo !== undefined
//           ).moveTo
//         ];
//     }
//   }

//   return false;
// };

/**
 * This function is designed to be used as the second parameter to a React.memo component
 * where we need to compare the props a bit more deeply but also a bit more shallowly than
 * the default
 *
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
export const ignorant_memo_props_compare = (prevProps: any, nextProps: any) => {
  // We'll go through each prop and handle them our own way

  // First some boring stuff
  if (Object.keys(prevProps).length !== Object.keys(nextProps).length) {
    return false;
  }

  // Now for the one that we care about
  for (const key of Object.keys(prevProps)) {
    // Ignore some props
    if (["onChangeGenerator", "project", "queries"].includes(key)) {
      continue;
    }
    // Handle some props in a special way
    else if (["field", "data"].includes(key)) {
      // Then we do a slightly (one level) deeper comparison
      if (prevProps.field && prevProps.data) {
        // Well let's see if there is a way we can use the field Id to check just this field data
        if (
          prevProps.data[prevProps.field.id] !==
          nextProps.data[nextProps.field.id]
        ) {
          return false;
        }
      } else {
        if (JSON.stringify(prevProps[key]) !== JSON.stringify(nextProps[key])) {
          return false;
        }
      }
    }
    // Now we can compare the remainder
    else if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }
  return true;
};

export const field_is_empty = (field: any, data: any) => {
  // This function acts as a field-type specific determiner for whether a field is empty or not
  // This is necessary because some field formats will default an object with some blank data components
  // Though most will just check if data is undefined or null!

  switch (field.type) {
    case "table":
    case "statictable":
      // In this case we'll iterate over each row and check that all user-available fields are not empty
      return (
        !data ||
        data.length < 1 ||
        data?.some((row: any) => {
          return Object.keys(row).some((key) => {
            if (key === "id") {
              return false;
            } // "id" is just a row id for bad state tracking (these tables are bad)
            return field_is_empty(
              field.columns.find((col: any) => col.id === key),
              row[key]
            );
          });
        })
      );
    case "tableadv":
      // TODO: Fill this condition (probably use a special util of the advanced table)
      return !data;
    case "signature":
      return !data || !data.confirmed;
    case "userlist":
      return data?.length === 0;
    case "files":
      return data?.data?.length === 0;
    default:
      return !data;
  }
};

/**
 * This function will return the due date of a query should it be defined, otherwise it will return undefined
 */
export function get_query_due_date(queryData: any, schemaData: any) {
  const getByDotNotation = (t: any, path: string) =>
    path.split(".").reduce((r, k) => r?.[k], t);

  // TODO: We currently assume dueDate is enabled if no setting for it is used (probably sus)
  let enableDueDate = schemaData?.settings?.enableDueDate ?? true;

  if (enableDueDate === true) {
    return queryData.dueDate;
  } else if (typeof enableDueDate === "string") {
    return getByDotNotation(queryData, enableDueDate);
  } else {
    return undefined;
  }
}
