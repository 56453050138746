import { BaseCollection, BaseDocument, BaseData } from "../../base"

interface BadgeData extends BaseData {
  name: string
  flair: string
  amountObtained: number
  amountNeeded: number
  projectId?: string
  project: string
  schema: string
  points: number
  url: string
  info: string
  tracking: string
}

export class Badges extends BaseCollection<BadgeData> {
  badge(id: string): Badge {
    return this.doc(id) as Badge
  }
}

type Badge = BaseDocument<BadgeData>
