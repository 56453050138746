import React from "react";
import {
  AnnouncementContent,
  BadgesContent,
  CustomContent,
  CommendContent,
  PatchNoteContent,
  CommentContent,
  FeedbackInput,
} from "./popups";

//this is the resolver, which will determine which popup content to render
export default ({ popup, closeView }) => {
    switch (popup.type) {
    case "announcement":
      return <AnnouncementContent key={popup.id} data={popup.data} />;
    case "badges":
      return <BadgesContent key={popup.id} data={popup.data} />;
    case "custom":
      return <CustomContent key={popup.id} data={popup.data} />;
    case "commend":
      return <CommendContent key={popup.id} data={popup.data} />;
    case "patchNotes":
      return <PatchNoteContent key={popup.id} data={popup.data} />;
    case "comment":
      return <CommentContent data={popup[0]} />;
    case "feedback":
      return <FeedbackInput data={popup} closeView={closeView}/>;
  }
};
