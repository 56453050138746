import { add } from "date-fns";

type DynamicDate = Date | null;

export const CURRENT_YEAR = new Date().getFullYear();

export const DATE_FILTERS: {[key: string]: {earliestDate: DynamicDate, latestDate: DynamicDate}} = {
  all: {
    earliestDate: null,
    latestDate: null,
  },
  today: {
    earliestDate: new Date(),
    latestDate: new Date(),
  },
  week: {
    earliestDate: (() => {
      let e = new Date();
      e = add(e, {
        days: -e.getDay(),
      });
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l = add(l, {
        days: 6 - l.getDay(),
      });
      return l;
    })(),
  },
  lastweek: {
    earliestDate: (() => {
      let e = new Date();
      e = add(e, {
        days: -e.getDay() - 7,
      });
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l = add(l, {
        days: -1 - l.getDay(),
      });
      return l;
    })(),
  },
  month: {
    earliestDate: (() => {
      let e = new Date();
      e.setDate(1);
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l = add(l, {
        months: 1,
      });
      l.setDate(0);
      return l;
    })(),
  },
  lastmonth: {
    earliestDate: (() => {
      let e = new Date();
      e.setDate(1);
      e = add(e, {
        months: -1,
      });
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l.setDate(0);
      return l;
    })(),
  },
  year: {
    earliestDate: (() => {
      let e = new Date();
      e.setDate(1);
      e.setMonth(0);
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l.setMonth(11);
      l.setDate(31);
      return l;
    })(),
  },
  lastyear: {
    earliestDate: (() => {
      let e = new Date();
      e.setDate(1);
      e.setMonth(0);
      e = add(e, {
        years: -1,
      });
      return e;
    })(),
    latestDate: (() => {
      let l = new Date();
      l.setMonth(11);
      l.setDate(31);
      l = add(l, {
        years: -1,
      });
      return l;
    })(),
  },
};

export const DATE_FILTER_OPTIONS = {
  all: "Show All",
  today: "Today",
  week: "This Week",
  lastweek: "Last Week",
  month: "This Month",
  lastmonth: "Last Month",
  year: "This Year",
  lastyear: "Last Year",
  custom: "Custom",
};
