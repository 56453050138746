import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { HOMEVIEWS } from "./moreabout";

// (Main) Components------------------------------------
import Navbar from "./maincomponents/navbar";
import Hero from "./maincomponents/hero";
import WhatItIs from "./maincomponents/what";
import HowItWorks from "./maincomponents/how";
import About from "./maincomponents/about";
import VisionStrategy from "./maincomponents/vision";
import TheCompany from "./maincomponents/company";
import Diffs from "./maincomponents/diffs";
import Features from "./maincomponents/features";
import Market from "./maincomponents/market";
import Clients from "./maincomponents/clients";
import Service from "./maincomponents/service";
import Footer from "./maincomponents/footer";
// -----------------------------------------------------

export default (props) => {
  const [search] = useSearchParams();

  useEffect(() => {
    const view = search.get("v");
    switch (view) {
      case HOMEVIEWS.HOME:
        setTimeout(navHome, 100);
        break;
      case HOMEVIEWS.ABOUT:
        setTimeout(navAbout, 100);
        break;
      case HOMEVIEWS.WHY:
        setTimeout(navWhy, 100);
        break;
      case HOMEVIEWS.USECASES:
        setTimeout(navUseCases, 100);
        break;
      case HOMEVIEWS.PRICING:
        setTimeout(navPricing, 100);
        break;
    }
  }, [search]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const navHome = () => ref1.current?.scrollIntoView({ behavior: "smooth" });
  const navAbout = () => ref2.current?.scrollIntoView({ behavior: "smooth" });
  const navWhy = () => ref3.current?.scrollIntoView({ behavior: "smooth" });
  const navUseCases = () =>
    ref4.current?.scrollIntoView({ behavior: "smooth" });
  const navPricing = () => ref5.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <HomePageContainer>
      <Navbar
        navHome={navHome}
        navAbout={navAbout}
        navWhy={navWhy}
        navUseCases={navUseCases}
        navPricing={navPricing}
      />
      <Hero ref={ref1} />
      <WhatItIs />
      <HowItWorks />
      <About showButton ref={ref2} />
      <VisionStrategy />
      <TheCompany />
      <Diffs ref={ref3} />
      <Features />
      <Market ref={ref4} />
      <Clients />
      <Service ref={ref5} />
      <Footer
        navHome={navHome}
        navAbout={navAbout}
        navWhy={navWhy}
        navUseCases={navUseCases}
        navPricing={navPricing}
      />
    </HomePageContainer>
  );
};

const HomePageContainer = styled.div`
  overflow-x: hidden;
  scroll-padding-top: 106px;
  min-height: 100%;
  width: 100%;
  display: inline-block;
  overflow: auto;
  font-family: Red Hat Display;
  background-color: white;

  @media (max-width: 1100px) {
    scroll-padding-top: 87px;
  }
`;
