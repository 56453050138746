import React from "react";
import styled from "styled-components";

import q from "@queryit/api";

export const MicrosoftSSO = ({ setError, rememberMe }) => {
  function hanndleMSLoginAttempt() {
    q.auth.setPersistence(
      rememberMe ? q.PERSISTENCE.local : q.PERSISTENCE.session
    );
    q.auth
      .signInWithMicrosoft()
      .then((data) => {})
      .catch((err) =>
        setError({ state: true, message: "Microsoft Sign-on Failed" })
      );
  }

  return (
    <MSButton
      onClick={() => {
        hanndleMSLoginAttempt();
      }}
    >
      <img src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png" />
      <div style={{ marginLeft: "12px" }}>Sign in with Microsoft</div>
    </MSButton>
  );
};

export const GoogleSSO = ({ setError, rememberMe }) => {
  function handleGLLoginAttempt() {
    q.auth.setPersistence(
      rememberMe ? q.PERSISTENCE.local : q.PERSISTENCE.session
    );
    q.auth
      .signInWithGoogle()
      .then((data) => {})
      .catch((err) =>
        setError({ state: true, message: "Google Sign-on Failed" })
      );
  }

  return (
    <GLButton
      onClick={() => {
        handleGLLoginAttempt();
      }}
    >
      <img
        style={{ width: 24 }}
        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
      />
      <div style={{ marginLeft: "11px" }}>Sign in with Google</div>
    </GLButton>
  );
};

// This button only supports ms dark style
const MSButton = styled.button`
  background: #2f2f2f;
  color: #fff;
  font-weight: 500;
  font-family: Segoe UI;
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 12px;
  margin-top: 15px;
`;

const GLButton = styled.button`
  background: #2f2f2f;
  color: #fff;
  font-weight: 500;
  font-family: Segoe UI;
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 12px;
  margin-top: 15px;
`;
