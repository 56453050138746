import { BaseCollection, BaseData, BaseDocument } from "../base";

interface PDFSchema extends BaseData {
  pdf: string;
}

export class PatchNotes extends BaseDocument<PDFSchema> {
  constructor(patch: BaseCollection<BaseData>) {
    super(patch.doc("0").ref);
  }
}
