import React from "react";

import { AppBar, Tabs, Tab } from "@mui/material";
import { SUBMISSIONVIEWS } from "../submission";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";

const OPTION_LABELS = {
  [SUBMISSIONVIEWS.SUBMISSION]: "My Submission",
  [SUBMISSIONVIEWS.REJECTION]: "Review Rejection",
  [SUBMISSIONVIEWS.ACKNOWLEDGEMENT]: "Intent Acknowledgement",
  [SUBMISSIONVIEWS.CLARIFICATION]: "Clarification",
  [SUBMISSIONVIEWS.NEGOTIATOR]: "Negotiation",
  [SUBMISSIONVIEWS.PREVIEW]: "Prequisite Information",
  [SUBMISSIONVIEWS.COMPLETED]: "Completed Submission",
  [SUBMISSIONVIEWS.SETTINGS]: "Settings",
};

export default ({ options, newClarifications }) => {
  const currentView = useLocation();
  const navigate = useNavigate();

  let selectedTab = currentView.pathname.split("/").pop();
  if (!options.includes(selectedTab)) {
    selectedTab = false; // This lets the tab be unselected and happy about it :)
  }

  return (
    <AppBar position="static">
      <Tabs
        value={selectedTab}
        centered
        onChange={(e, value) => navigate(value)}
      >
        {options.map((opt) => (
          <Tab
            fullWidth={true}
            label={<TabLabel>{OPTION_LABELS[opt]}</TabLabel>}
            value={opt}
            key={`formview-tab-${opt}`}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

const TabLabel = styled.div``;
