import React from "react";
import styled from "styled-components";

export const Heading = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  color: ${(props) => props.theme.text};

  margin-bottom: 24px;
`;

export const QueryBanner = ({ color, children }) => (
  <QueryBannerBack bg={color}>{children}</QueryBannerBack>
);

const QueryBannerBack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => props.bg ?? "#ffffc1"};
  padding: 10px 30px;
`;
