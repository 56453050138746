import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";

import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Mail, Drafts, Close } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

import { ButtonSection, Button2 } from "../ui/buttons";
import { BooleanField } from "../ui/inputs2";

import { MobileGrid, MobileGridTileDiv } from "../ui/table";
import { EscapeHeader } from "../ui/containers";
import { UserContext } from "../../App";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { ConfirmationModal } from "../ui/modals";

export default ({ notifications, projectBookmark }) => {
  const user = useContext(UserContext);
  const [selectedNotifications, setSelectedNotifications] = useState([]); // list of ids
  const [newlyRead, setNewlyRead] = useState([]);
  const [confirmClearAll, setConfirmClearAll] = useState(false);

  // Manage search params state
  const [searchParams, setSearchParams] = useSearchParams();

  const actionDict = {
    // 'open_query': (projectId, {queryId}) => { setMovingToQuery({query: queryId, project: projectId}) }
    open_query: (projectId, { queryId }) => {
      setSearchParams({ qq: `${projectId}-${queryId}` });
    },
    open_actions: (projectId, { queryId }) => {
      setSearchParams({ qq: `${projectId}-${queryId}`, fc: "actions" });
    },
  };

  // As soon as you come to this page, we mark all notifications as read
  useEffect(() => {
    if (notifications && user) {
      notifications.forEach((nt) => {
        if (!nt.read) {
          setNewlyRead((ex) => [...ex, nt.id]);
        }
        user.notifications.notification(nt.id).update({ read: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAll = () => {
    notifications.forEach((nt) => {
      user.notifications.notification(nt.id).delete();
    });
  };

  const clearSelected = () => {
    selectedNotifications.forEach((ntId) => {
      user.notifications.notification(ntId).delete();
    });
    setSelectedNotifications([]);
  };

  const markUnread = () => {
    selectedNotifications.forEach((ntId) => {
      user.notifications.notification(ntId).update({ read: false });
    });
    setSelectedNotifications([]);
  };

  return (
    <NotificationColumnContainer>
      <ConfirmationModal
        open={confirmClearAll}
        body="Are you sure you want to clear all notifications? This action cannot be reversed."
        onConfirm={() => {
          clearAll();
          setConfirmClearAll(false);
        }}
        onCancel={() => setConfirmClearAll(false)}
      />{" "}
      {!isMobile && (
        <NotificationLadder style={{ maxWidth: "1400px" }}>
          <EscapeHeader projectBookmark={projectBookmark} />
          <ButtonSection>
            <Button2
              label="Clear All"
              onClick={() => setConfirmClearAll(true)}
            />
            <Button2
              label="Clear Selected"
              onClick={() => clearSelected()}
              disabled={selectedNotifications.length < 1}
            />
            <Button2
              label="Mark Selected Unread"
              onClick={() => markUnread()}
              disabled={selectedNotifications.length < 1}
            />
          </ButtonSection>
          <NotificationTableContainer>
            {notifications && (
              <NotificationTable>
                <NotificationTableHeader>
                  <tr>
                    {notifications.length > 0 && (
                      <td style={{ paddingTop: "10px" }}>
                        <Tooltip title="Select All" placement="top">
                          <span>
                            <BooleanField
                              checkbox
                              data={
                                selectedNotifications.length ==
                                  notifications.length &&
                                notifications.length > 0
                              }
                              onChange={() =>
                                setSelectedNotifications((ex) =>
                                  ex.length > 0
                                    ? []
                                    : notifications.map((nt) => nt.id)
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      </td>
                    )}
                    <td
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        paddingTop: "10px",
                      }}
                    >
                      My Notifications
                    </td>
                  </tr>
                </NotificationTableHeader>
                <tbody>
                  {notifications
                    .sort((a, b) => {
                      return b.timestamp - a.timestamp;
                    })
                    .map((nt) => (
                      <NotificationRow
                        key={nt.id}
                        notification={nt}
                        boxCheck={(id) =>
                          setSelectedNotifications((ex) =>
                            ex.includes(id)
                              ? [...ex.filter((nt) => nt !== id)]
                              : [...ex, id]
                          )
                        }
                        unread={newlyRead.includes(nt.id) || !nt.read}
                        selected={selectedNotifications.includes(nt.id)}
                        execution={
                          nt.clickTrigger
                            ? actionDict[nt.clickTrigger.type]
                            : () => {}
                        }
                        toggleRead={(id) => {
                          user.notifications
                            .notification(id)
                            .update({ read: !nt.read });
                          setNewlyRead((ex) =>
                            ex.filter((ntId) => ntId !== id)
                          );
                        }}
                        clearNotification={(id) =>
                          user.notifications.notification(id).delete()
                        }
                      />
                    ))}
                  {notifications.length < 1 && (
                    <tr>
                      <td colSpan={3}>
                        <NotificationTitle style={{ textAlign: "center" }}>
                          All Caught Up!
                        </NotificationTitle>
                      </td>
                    </tr>
                  )}
                </tbody>
              </NotificationTable>
            )}
            {!notifications && (
              <LoadContainer>
                <CircularProgress />
              </LoadContainer>
            )}
          </NotificationTableContainer>
        </NotificationLadder>
      )}
      {isMobile && (
        <NotificationLadderMobile>
          <NotificationButtonSectionMobile>
            <Button2 label="Clear All" onClick={() => clearAll()} />
          </NotificationButtonSectionMobile>
          <MobileGrid style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {notifications.map((nt) => (
              <NotificationMobile
                key={nt.id}
                nt={nt}
                newlyRead={newlyRead.includes(nt.id)}
                unread={newlyRead.includes(nt.id) || !nt.read}
                onClick={(r) =>
                  nt.clickTrigger
                    ? actionDict[nt.clickTrigger.type](
                        nt.projectId,
                        nt.clickTrigger.arguments
                      )
                    : (() => {})(r)
                }
                onToggleOpen={(id) => {
                  user.notifications
                    .notification(id)
                    .update({ read: !nt.read });
                  setNewlyRead((ex) => ex.filter((ntId) => ntId !== id));
                }}
                onClear={(id) => user.notifications.notification(id).delete()}
              />
            ))}
            {notifications.length < 1 && (
              <NotificationMessageMobile style={{ textAlign: "center" }}>
                All Caught Up!
              </NotificationMessageMobile>
            )}
          </MobileGrid>
        </NotificationLadderMobile>
      )}
    </NotificationColumnContainer>
  );
};

const NotificationRow = ({
  notification,
  boxCheck,
  clearNotification,
  toggleRead,
  execution,
  unread,
  selected,
}) => {
  return (
    <tr>
      <td>
        <BooleanField
          checkbox
          data={selected}
          onChange={() => boxCheck(notification.id)}
        />
      </td>
      <td
        style={{ width: "99%" }}
        onClick={() =>
          execution(notification.projectId, notification.clickTrigger.arguments)
        }
      >
        <NotificationBody
          notification={notification}
          execution={execution}
          unread={unread}
        />
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <Tooltip title={`Mark ${!unread ? "un" : ""}read`}>
          <IconButton onClick={() => toggleRead(notification.id)}>
            {!unread && <Mail />}
            {unread && <Drafts />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear">
          <IconButton onClick={() => clearNotification(notification.id)}>
            <Close />
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
};

const NotificationBody = ({ notification, unread }) => {
  const notificationDate = notification.timestamp
    ? notification.timestamp.toDate()
    : undefined;
  const notificationDateDisplay = notificationDate
    ? format(notificationDate, "dd/MM/yyyy 'at' hh:mm")
    : "";

  return (
    <NotificationBox>
      <NotificationUnread>{unread && <UnreadDot />}</NotificationUnread>
      <NotificationText>
        <NotificationTitle>{notification.title}</NotificationTitle>
        <NotificationSubtext>{notificationDateDisplay}</NotificationSubtext>
        <NotificationSubtext>{notification.message}</NotificationSubtext>
      </NotificationText>
    </NotificationBox>
  );
};

const NotificationMobile = ({ nt, unread, onClick, onClear, onToggleOpen }) => {
  const notificationDate = nt.timestamp ? nt.timestamp.toDate() : undefined;
  const notificationDateDisplay = notificationDate
    ? format(notificationDate, "dd/MM/yyyy 'at' hh:mm")
    : "";

  return (
    <MobileGridTileDiv style={{ width: "fit-content" }} onClick={onClick}>
      <div
        style={{
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          margin: "0 10px 0 3px",
        }}
      >
        {unread && <UnreadDot />}
      </div>
      <div>
        <div>{nt.title}</div>
        <div>{notificationDateDisplay}</div>
        <div>{nt.message}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexShrink: 2,
          maxWidth: "60px",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onToggleOpen(nt.id);
          }}
        >
          {!unread && <Mail />}
          {unread && <Drafts />}
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClear(nt.id);
          }}
        >
          <Close />
        </IconButton>
      </div>
    </MobileGridTileDiv>
  );
};

const NotificationBox = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const NotificationText = styled.div`
  user-select: none;
`;

const NotificationTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const NotificationSubtext = styled.div`
  color: #d2d2d2;
  font-size: 14px;
`;

const NotificationUnread = styled.div`
  display: flex;
  min-width: 18px;
  flex: 0 0 18px;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;

const UnreadDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.palette.secondary.main};
`;

const NotificationColumnContainer = styled.div`
  height: 98%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.palette.font};
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

const NotificationLadder = styled.div`
  width: 50%;
  min-width: 600px;
  min-height: 60%;
  margin: 30px;
  padding: 10px 30px;
  background-color: ${(props) => props.theme.palette.background.step100};
  border-radius: 8px;
`;

const NotificationLadderMobile = styled.div`
  margin: 30px;
  min-width: 95%;
`;

const NotificationMessageMobile = styled.div`
  font-size: 22px;
  margin-top: 15px;
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;
  text-align: center;
`;

const NotificationButtonSectionMobile = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: flex-start;
`;

const NotificationTableContainer = styled.div`
  display: flex;
  overflow-y: auto;
  max-height: 90%;

  @media (max-height: 850px) {
    max-height: 85%;
  }

  @media (max-height: 640px) {
    max-height: 80%;
  }

  @media (max-height: 520px) {
    max-height: 75%;
  }

  @media (max-height: 430px) {
    max-height: 70%;
  }
`;

const NotificationTable = styled.table`
  border: none;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
`;

const NotificationTableHeader = styled.thead`
  border-bottom: 1px solid white;
`;

const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  min-width: 100%;
`;
