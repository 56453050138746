import React, { useContext } from "react";

import { UsersSelectInput } from "../fields/queryfields";
import { LocalityField, Inputs } from "../../../ui/inputs2";
import { CurrentProjectContext, ProjectUsersContext } from "../..";
import { UserDataContext } from "../../../../App";
import { TabPanelContainer } from ".";
import { QueryStatusContext } from "../query";

export default ({ schemaData, queryData, setQueryData, readonly }) => {
  const queryStatus = useContext(QueryStatusContext);
  const userData = useContext(UserDataContext);

  return (
    <TabPanelContainer style={{ flexDirection: "column" }}>
      {/* <QuerySectionHeader>Settings for the Query</QuerySectionHeader> */}
      <Inputs style={{ overflowX: "hidden" }}>
        {schemaData.type === "response" && (
          <UsersSelectInput
            data={queryData.assignedUser}
            onChange={(data) => {
              const { value } = data.target;
              setQueryData((ex) => ({ ...ex, assignedUser: value }));
            }}
            editable={
              (queryStatus?.is_created() || queryStatus?.is_open()) &&
              (userData.id === queryData.assignedUser ||
                userData.id === queryData.creator) &&
              !readonly
            }
            fill={true}
            label="Assigned User"
          />
        )}
        {!(schemaData.settings?.useDistributionList === false) && (
          <UsersSelectInput
            data={queryData.distribution}
            onChange={(data) => {
              const { value } = data.target;
              setQueryData({ ...queryData, distribution: value });
            }}
            editable={queryStatus?.is_created() && !readonly}
            fill={true}
            multi
            label="Distribution List"
          />
        )}
        {!(schemaData.settings?.enableDueDate === false) &&
          schemaData.type !== "custom" && (
            <LocalityField
              data={queryData.dueDate}
              label="Due Date"
              onChange={(value) => {
                setQueryData({ ...queryData, dueDate: value });
              }}
              disablePast
              useDate={true}
              disabled={
                !(
                  (queryStatus?.is_created() || queryStatus?.is_open()) &&
                  (userData.id === queryData.assignedUser ||
                    userData.id === queryData.creator)
                )
              }
            />
          )}
      </Inputs>
      {/* <SubHeader>Personal Settings</SubHeader> */}
    </TabPanelContainer>
  );
};
