import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import QRCode from "react-qr-code";

import { QueryPDFViewer, QueryHeader } from "../query";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button2 } from "../../../ui/buttons";
import { SelectField } from "../../../ui/inputs2";

import PrintWrapper from "./printwrap";

import SchemaDocument from "./pages/query";
import ApprovalDocument from "./pages/approvals";
import SettingsDocument from "./pages/settings";
import ResponsesDocument from "./pages/responses";
import TasksDocument from "./pages/tasks";
import CommentsDocument from "./pages/comments";

import { useApprovals } from "../../../../hooks/actions";
import { useResponse, useTasks, useComments } from "../../../../hooks/queries";
import { ProjectLogoContext, ProjectUsersContext } from "../..";

import { useSearchParams } from "react-router-dom";
import { QUERYVIEWS, ROUTEKEYS } from "../../../../common/query";

import { isMobile } from "react-device-detect";

const PRINT_VIEW_CHOICES = {
  QUERY: "Query",
  APPROVALS: "Approvals",
  TASKS: "Tasks",
  RESPONSES: "Responses",
  SETTINGS: "Settings",
  COMMENTS: "Comments",
};

export default ({ query, queryData, schemaData, projectId }) => {
  const [printSelection, setPrintSelection] = useState(["QUERY"]);
  const [printViewChoices, setPrintViewChoices] = useState(["QUERY"]);

  const users = useContext(ProjectUsersContext);

  // Now get the details for printing the other sections if needed
  const approvals = useApprovals(query);
  const tasks = useTasks(query);
  const response = useResponse(query);
  const comments = useComments(query);

  const [searchParams, setSearchParams] = useSearchParams();

  // Build print view choices based on schemaData
  useEffect(() => {
    const newChoices = ["QUERY"];
    if (["approval", "custom"].includes(schemaData.type)) {
      // naive for custom
      newChoices.push("APPROVALS");
    } else if (["response", "custom"].includes(schemaData.type)) {
      newChoices.push("RESPONSES");
    }
    if (schemaData?.settings?.enableTasks) {
      newChoices.push("TASKS");
    }
    if (schemaData?.settings?.enableTasks) {
      newChoices.push("COMMENTS");
    }
    newChoices.push("SETTINGS");
    setPrintViewChoices(newChoices);
  }, [schemaData]);

  return (
    <>
      <QueryHeader>
        <div />
        <PrintOptionContainer>
          <SelectField
            multi
            label="Selected Query Sections"
            options={printViewChoices
              .map((key) => ({ [key]: PRINT_VIEW_CHOICES[key] }))
              .reduce((acc, cur) => ({ ...acc, ...cur }), {})}
            data={printSelection}
            onChange={(e) => {
              const { value } = e.target;
              setPrintSelection(value);
            }}
            fill
            slim
          />
          <Button2
            label="Cancel"
            size="small"
            style={{ margin: "4px 6px" }}
            onClick={() => {
              setSearchParams((ex) => {
                ex.set(ROUTEKEYS.QUERY_PANE_MAIN_VIEW, QUERYVIEWS.QUERY);
                return ex;
              });
            }}
          />
        </PrintOptionContainer>
      </QueryHeader>
      <QRCode
        id="qrcode-prerender-hidden"
        value={`https://queryitapp.com/app/projects/${projectId}/queries?qq=${schemaData.id}-${queryData.id}`}
        size={36}
        style={{ display: "none" }}
      />
      {isMobile ? (
        <DownloadButtonCenterer>
          <PDFDownloadLink
            style={{ textDecoration: "none", width: "fit-content" }}
            document={
              <PrintWrapperComponent
                schemaData={schemaData}
                queryData={queryData}
                approvals={approvals}
                tasks={tasks}
                users={users}
                printSelection={printSelection}
                comments={comments}
              />
            }
            fileName={`${schemaData.name}-${queryData.id}`}
          >
            <Button2
              style={{ textDecoration: "none" }}
              label={"Download PDF"}
            />
          </PDFDownloadLink>
        </DownloadButtonCenterer>
      ) : (
        <QueryPDFViewer>
          <PrintWrapperComponent
            schemaData={schemaData}
            queryData={queryData}
            approvals={approvals}
            tasks={tasks}
            response={response}
            users={users}
            printSelection={printSelection}
            comments={comments}
          />
        </QueryPDFViewer>
      )}
    </>
  );
};

const PrintWrapperComponent = ({
  schemaData,
  queryData,
  approvals,
  tasks,
  response,
  users,
  printSelection,
  comments,
}) => {
  // We either wrap this in QueryPDFViewer on desktop or a PDFDownloadLink on mobile
  const projectLogo = useContext(ProjectLogoContext);

  return (
    <PrintWrapper
      title={`${schemaData.name}${!queryData ? " - Blank Form" : ""}`}
    >
      {printSelection.includes("QUERY") && (
        <SchemaDocument
          schemaData={schemaData}
          queryData={queryData}
          projectLogo={projectLogo}
          qrCode={"qrcode-prerender-hidden"}
          users={users}
        />
      )}
      {printSelection.includes("APPROVALS") && (
        <ApprovalDocument
          schemaData={schemaData}
          approvals={approvals}
          queryData={queryData}
          projectLogo={projectLogo}
        />
      )}
      {printSelection.includes("TASKS") && (
        <TasksDocument
          schemaData={schemaData}
          tasks={tasks}
          queryData={queryData}
          projectLogo={projectLogo}
          users={users}
        />
      )}
      {printSelection.includes("RESPONSES") && (
        <ResponsesDocument
          schemaData={schemaData}
          response={response}
          queryData={queryData}
          projectLogo={projectLogo}
        />
      )}
      {printSelection.includes("COMMENTS") && (
        <CommentsDocument
          schemaData={schemaData}
          queryData={queryData}
          projectLogo={projectLogo}
          comments={comments}
        />
      )}
      {printSelection.includes("SETTINGS") && (
        <SettingsDocument
          schemaData={schemaData}
          queryData={queryData}
          projectLogo={projectLogo}
        />
      )}
    </PrintWrapper>
  );
};

const PrintOptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 40%;
`;

const DownloadButtonCenterer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
