import React, { useEffect, useState, useCallback, useRef } from "react";

import { QueryContent, QuerySection, QuerySectionHeader } from "../../query";

import { SaveQueryBar } from "../../workflow";

import { useSchema, useSchemaStatusSet } from "../../../../../hooks/projects";
import { TableField2 } from "../../fields/queryfields";
import { log_warning } from "../../../../../tools/logger";

export default ({ project, schemaId, setStatusMessage }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const [tableData, setTableData] = useState([
    {
      assignedTo: "",
      title: "",
      description: "",
      dateOffset: 0,
      generateOnStatus: "",
    },
  ]);
  const statusSet = useSchemaStatusSet(schemaData);

  useEffect(() => {
    if (schemaData && schemaData.autoGenerateTask) {
      setTableData(schemaData.autoGenerateTask);
    }
  }, [schemaData]);

  const handleTableChange = useCallback((e) => {
    setTableData(e.target.value);
  }, []);

  const saveChanges = async () => {
    if (
      tableData[0].assignedTo.length !== 0 &&
      tableData[0].title !== "" &&
      tableData[0].description !== "" &&
      tableData[0].generateOnStatus.length !== 0
    ) {
      log_warning("Save Attempted!");
      await schema.update({ autoGenerateTask: tableData[0] });
      setStatusMessage("Changes Saved!");
    }
    setStatusMessage("Fields cannot be empty.");
  };

  const tableSchema = LIFECYCLE_TABLE_SCHEMA(
    Object.keys(statusSet).reduce(
      (acc, key) => ({
        ...acc,
        [key]: statusSet[key].name,
      }),
      {}
    )
  );

  return (
    <>
      <QueryContent>
        <QuerySection style={{ width: "100%" }}>
          {schemaData?.settings?.enableTasks ? (
            <>
              <TableField2
                data={{
                  [tableSchema.id]: Array.isArray(tableData)
                    ? tableData
                    : [
                        {
                          assignedTo: tableData.assignedTo,
                          title: tableData.title,
                          description: tableData.description,
                          dateOffset: tableData.dateOffset,
                          generateOnStatus: tableData.generateOnStatus,
                        },
                      ],
                }}
                field={tableSchema}
                onFieldChange={handleTableChange}
                status={"editable"}
                editable={true}
                editabilityConditions={{ forceEdit: true }}
                staticTable
              />
            </>
          ) : null}
        </QuerySection>
      </QueryContent>
      <SaveQueryBar saveQuery={saveChanges} />
    </>
  );
};

const LIFECYCLE_TABLE_SCHEMA = (statusSet) => ({
  columns: [
    {
      name: "Assigned To",
      id: "assignedTo",
      editableWhile: ["editable"],
      type: "userlist",
      multi: false,
    },
    {
      name: "Title",
      id: "title",
      type: "string",
      editableWhile: [],
    },
    {
      name: "Description",
      id: "description",
      type: "string",
      editableWhile: [],
    },
    {
      name: "Date Offset",
      id: "dateOffset",
      type: "number",
      editableWhile: [],
    },
    {
      name: "Generate on status",
      id: "generateOnStatus",
      type: "select",
      options: statusSet,
      editableWhile: ["editable"],
    },
  ],
  name: "Configure Auto-Generated Task",
  editableWhile: ["editable"],
  id: "generatedTasks",
});
