import React from "react";

import {
  Modal,
  DialogContent,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import ContentResolver from "../../../ui/popups/index";
import {
  ModalContent,
  ModalFooter,
  PopupModal,
} from "../../../ui/containers";

export default ({ data, closeView, open }) => {
  const theme = useTheme();

  return (
    <Modal open={open} onClose={closeView}>
      <DialogContent sx={{ overflowX: "visible" }}>
        <PopupModal>
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: theme.palette.primary.main,
            }}
            onClick={closeView}
          >
            <Close />
          </IconButton>
          <ModalContent>
            <ContentResolver popup={data} closeView={closeView}/>
          </ModalContent>
        </PopupModal>
      </DialogContent>
    </Modal>
  );
};
