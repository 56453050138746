import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";

import CommentingSidebar from "./sidebar";
import { useHotkey } from "../../../../hooks/system";

// This is a wrapper with blur for the container
export default ({ onClose, task, query, taskData }) => {
  const [anim, setAnim] = useState(false);
  useEffect(() => setAnim(true), []);

  const onCloseInternal = () => {
    setAnim(false);
    return false;
  };

  // Add escape key listener for onClose execution (doesn't run parent onClose)
  useHotkey("Escape", () => onCloseInternal());

  return (
    <CommentTransitionaryContainer>
      <CommentBlur
        animationDirection={anim ? "in" : "out"}
        onClick={onCloseInternal}
      />
      {/* Animation classes in Commenting Sidebar class */}
      <CSSTransition
        in={anim}
        timeout={300}
        classNames="sidebar"
        onExited={onClose}
      >
        <CommentingSidebar
          task={task}
          query={query}
          taskData={taskData}
          onClose={onCloseInternal}
        />
      </CSSTransition>
    </CommentTransitionaryContainer>
  );
};

const CommentTransitionaryContainer = styled.div`
  width: 100%;
  height: calc(100% - 37px);
  margin-top: 37px; // Adjustment for the Query Header we leave exposed
  position: absolute;
  top: 0;
  left: 0;

  overflow-x: hidden;
`;

// TODO: We could add animation to the blur too to smooth things out
const CommentBlur = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 15;
  border: none;

  &:focus {
    outline: none;
  }

  background: rgba(5, 5, 5, 0.4);
`;
