import React from "react";

import { Document, BlobProvider } from "@react-pdf/renderer";
import { Button } from "../../../ui/buttons";

export default ({ title, children }) => {
  return (
    <Document
      title={title}
      author={"Query-It App"}
      creator={"Query-It App"}
      producer={"Query-It App"}
    >
      {children}
    </Document>
  );
};

export const PrintPDFButton = ({ document }) => {
  return (
    <BlobProvider document={document}>
      {({ blob, url, loading, error }) => {
        return (
          <Button
            disabled={loading}
            text={loading ? "..." : "Print"}
            onClick={() => {
              window.open(url);
            }}
          />
        );
      }}
    </BlobProvider>
  );
};
