import { BaseCollection, BaseDocument, BaseData } from "../../../base";

import { Feed } from "./feed";

export interface TaskData extends BaseData {
  assignedTo: string;
  dueDate: string;
  status: string;

  createdDate: Date;
  creator: string;
}

export class Tasks extends BaseCollection<TaskData> {
  task(id: string): Task {
    return new Task(id, this);
  }
}

export class Task extends BaseDocument<TaskData> {

  feed: Feed;

  constructor(id: string, tasks: Tasks) {
    super(tasks.doc(id).ref);

    this.feed = this.collection("feed") as Feed;
  }
}
