import React, { useState, useEffect, useRef } from "react";

import h337 from "heatmap.js";

const X_BUFFER = 30;
const Y_BUFFER = 15;

// TODO: "Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true"
const Heatmap = ({ getDataFromDimensions, children }) => {
  const [containerReady, setContainerReady] = useState(false);
  const container = useRef(null);
  const heatmap = useRef(null);

  // Heatmap mounting hook
  useEffect(() => {
    if (containerReady && container.current) {
      // Paint the heatmap
      if (heatmap.current) {
        // console.log('Heatmap deleted, removing canvas from DOM');
        container.current
          .querySelectorAll(".heatmap-canvas")
          .forEach((canvas) => {
            canvas.remove();
          });
      }
      // console.log('Heatmap created');
      heatmap.current = h337.create({
        container: container.current,
        blur: 0.9,
        opacity: 0.4,
        radius: 7,
      });

      const xpx = container.current.clientWidth;
      const ypx = container.current.clientHeight;

      const refstyle = window.getComputedStyle(container.current);
      // console.log('W: %s, H: %s (%d x %d)', refstyle.width, refstyle.height, xpx, ypx);
      if (xpx && ypx) {
        const data = getDataFromDimensions(xpx - X_BUFFER, ypx - Y_BUFFER);
        // data?.push?.({x: 0, y: 0, value: 1});
        // data?.push?.({x: 0, y: ypx, value: 1});
        // data?.push?.({x: xpx, y: 0, value: 1});
        // data?.push?.({x: xpx, y: ypx, value: 1});
        if (data && data.length) {
          // FIND MAX VALUE
          const max = data.reduce((a, b) => (b.value > a ? b.value : a), 0);
          const min = data.reduce((a, b) => (b.value < a ? b.value : a), 0);

          // console.log('Heatmap data:');
          // console.log(data);
          // console.log('Range: %d to %d', min, max);

          heatmap.current.setData({ data, max, min });
          heatmap.current.repaint();

          // console.log(heatmap.current.getData());
          // heatmap.current.getData().data.length
        }
      }
    }
  }, [containerReady, getDataFromDimensions]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      ref={(ref) => {
        // Every time ref updates, if it is non-NULL, interval until it has both height and width properties
        container.current = ref;
        // ? ref.querySelector(".heatmapChildren") : null;
        if (container.current == null) {
          setContainerReady(false);
        } else {
          const refstyle = window.getComputedStyle(container.current);
          if (refstyle.height && refstyle.width) {
            setContainerReady(true);
          }
        }
      }}
    >
      {children}
    </div>
  );
};

export default Heatmap;
