import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Header from "../../print/header";

const FULL_DIMS = [600, 780];

const HEADER_HEIGHT = 40; //75;
const HEADER_PAD = 10;

const BODY_MARGIN = 10;

const PDFStyles = StyleSheet.create({
  header: {
    height: HEADER_HEIGHT,
    width: FULL_DIMS[0] - HEADER_PAD * 2,
    // borderBottom: '1px solid black',
    padding: HEADER_PAD,
    paddingBottom: HEADER_PAD / 2,
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  footer: {
    bottom: 0,
    padding: HEADER_PAD,
    width: "100%",
    position: "absolute",
    backgroundColor: "#fff",
  },
  footerText: {
    fontSize: 8,
  },
  icon: {
    width: "35px",
    height: "35px",
  },
  body: {
    // width: FULL_DIMS[0] - BODY_MARGIN*2,
    margin: BODY_MARGIN,
  },
  chart: {
    width: 250,
  },
});

export const AnalyticsChartPDF = ({
  projectLogo,
  projectData,
  user,
  schemaData,
  chartsImages,
}) => {
  return (
    <Document
      title={`${schemaData?.name ?? ""} Analytics`}
      author={"Query-It App"}
      creator={"Query-It App"}
      producer={"Query-It App"}
    >
      <Page orientation="landscape" size="A3">
        <Header
          projectLogo={projectLogo}
          title={(projectData?.name ?? "") + " - " + (schemaData?.name ?? "")}
          author={user}
        />
        <View
          style={[
            PDFStyles.body,
            { display: "flex", flexDirection: "row", flexWrap: "wrap" },
          ]}
        >
          {chartsImages &&
            Object.keys(chartsImages).map((key) => (
              <View key={`${key}-chart`}>
                <Text>{key}</Text>
                <Image src={chartsImages[key]} style={PDFStyles.chart} />
              </View>
            ))}
        </View>
        <View style={PDFStyles.footer} fixed>
          <Text style={PDFStyles.footerText}>
            This export was generated by Query-It on{" "}
            {`${
              String(new Date().getDate()).padStart(2, "0") +
              "/" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "/" +
              String(new Date().getFullYear())
            }`}
          </Text>
          {/* There's a bottom right place here for another logo */}
        </View>
      </Page>
    </Document>
  );
};

export const saveAnalyticsPDFAsync = async (
  schemaData,
  chartsImages,
  filename,
  projectData = undefined,
  user = undefined,
  projectLogo = undefined
) => {
  const blob = await pdf(
    <AnalyticsChartPDF
      projectLogo={projectLogo}
      projectData={projectData}
      user={user}
      schemaData={schemaData}
      chartsImages={chartsImages}
    />
  ).toBlob();

  saveAs(blob, filename);
};
