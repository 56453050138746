import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import { Button as MuiButton } from "@mui/material";

import {
  ProjectRouteContainer,
  FilteredQueriesState,
  ProjectUsersContext,
  SelectedSchemaContext,
} from "..";
import { Tile } from "../../ui/containers";
import { useRecoilValue } from "recoil";
import { DownloadQueriesWorkbookButton } from "./workbook";

function matchUser(userID, usersArr) {
  return usersArr.filter((user) => {
    if (userID == user.id) {
      return true;
    }
  })[0];
}

export default ({ projectData, queries, filterManager }) => {
  const users = useContext(ProjectUsersContext);
  const filteredQueries = useRecoilValue(FilteredQueriesState);
  const selectedSchemaData = useContext(SelectedSchemaContext);

  const [exp, setExp] = useState({ filters: false, allCols: false });
  const [exportQueries, setExportQueries] = useState([]);

  useEffect(() => {
    if (!queries) {
      return;
    }
    let queriesToExport = queries;
    // Begin by applying filters if necessary
    if (exp.filters) {
      queriesToExport = filteredQueries;
    }
    if (!exp.allCols) {
      //
    }
    // now map all queries to a clean state for export
    queriesToExport = queriesToExport.map((query) => {
      let tmp = { ...query };
      // Remove unwanted system identifiers
      // All of these elements are not currently supported for export
      delete tmp.id;
      delete tmp.schemaId;
      delete tmp.approvalsRequired;
      delete tmp.response;
      delete tmp.updatedQuery;
      delete tmp.forms;
      delete tmp.linkedQueries;
      delete tmp.package;
      delete tmp.form;
      delete tmp[undefined];
      tmp.createTime = tmp.createTime ? new Date(tmp.createTime) : undefined;
      // Add user data where needed
      if (users) {
        // Creator
        tmp.creator = matchUser(tmp.creator, users);
        tmp.creator = tmp.creator
          ? `${tmp.creator.name?.first} ${tmp.creator.name?.last}`
          : undefined;
        // Assigned User
        tmp.assignedUser = matchUser(tmp.assignedUser, users);
        tmp.assignedUser = tmp.assignedUser
          ? `${tmp.assignedUser.name?.first} ${tmp.assignedUser.name?.last}`
          : undefined;
        // Distribution List
        tmp.distribution = tmp.distribution
          ?.map((usr) => {
            let usrF = matchUser(usr, users);
            return usrF ? `${usrF.name?.first} ${usrF.name?.last}` : undefined;
          })
          .join(", ");
      }
      // Now lets pull the data out of it's object
      Object.keys(tmp.data ?? {}).forEach((key) => {
        let piece = tmp.data[key];
        if (Array.isArray(piece)) {
          let strs = false;
          piece.forEach((chunk) => {
            if (typeof chunk === "object" && chunk !== null) {
              Object.keys(chunk).forEach((chkey) => {
                let lump = chunk[chkey];
                if (Array.isArray(lump)) {
                  tmp[chkey] = lump.join(", ");
                } else {
                  tmp[chkey] = lump;
                }
              });
            } else if (Array.isArray(chunk)) {
              tmp[key] = chunk.join(", ");
            } else {
              strs = true;
            }
          });
          if (strs) tmp[key] = piece.join(", ");
        } else {
          tmp[key] = piece;
        }
      });
      // Now remove the data as it is obselete
      delete tmp.data;
      delete tmp[undefined];
      // Send it back clean :)
      return tmp;
    });
    setExportQueries(queriesToExport);
  }, [exp, queries, users, filteredQueries]);

  /* https://www.npmjs.com/package/react-csv */

  if (!queries) {
    return null;
  }

  return (
    <ProjectRouteContainer>
      <Tile>
        <ExportDetails>
          <ExportExplain>
            Export the data from your queries in various forms. Please note that{" "}
            <b>all</b>&nbsp; queries will be exported (filtered if selected),
            which can take a moment for larger sets. Wait while your data is
            prepared and it will be downloaded automatically.
          </ExportExplain>
          <ExportToggles>
            <ExportOption>
              <FieldCheckbox
                value={exp.filters}
                type="checkbox"
                id={`chk_inc_filter`}
                onChange={(ev) => {
                  setExp({ ...exp, filters: !exp.filters });
                }}
                checked={exp.filters}
              ></FieldCheckbox>
              <FieldLabel htmlFor={`chk_inc_filter`}>
                Apply current filter settings to export
              </FieldLabel>
            </ExportOption>
          </ExportToggles>
        </ExportDetails>

        <ExportButtons>
          <CSVButton
            data={exportQueries}
            filename={`${projectData.name
              .replace(" ", "_")
              .toLowerCase()}-form-${String(new Date().getDate()).padStart(2, "0") +
              "_" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "_" +
              String(new Date().getFullYear())
              }.csv`}
          >
            Export as CSV
          </CSVButton>
          <DownloadQueriesWorkbookButton
            queries={exp.filters ? filteredQueries : queries}
            schema={selectedSchemaData}
            filename={`${selectedSchemaData?.name
              ?.replace(" ", "_")
              ?.toLowerCase()}-form-${String(new Date().getDate()).padStart(2, "0") +
              "_" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "_" +
              String(new Date().getFullYear())
              }`}
            projectName={projectData.name}
          />
        </ExportButtons>
      </Tile>
    </ProjectRouteContainer>
  );
};

export const CSVButton = (props) => {
  return (
    <MuiButton component="span" variant="contained" color="primary" style={{ width: "fit-content" }}>
      <CSVLinkEmbedable {...props} style={{ width: "100%", height: "100%" }} />
    </MuiButton>
  );
};

const CSVLinkEmbedable = styled(CSVLink)`
  color: ${(props) => props.theme.textAlt};
  text-decoration: none;
`;

// export const CSVButton = styled(CSVLink)`
//   padding: 8px 16px;
//   height: fit-content;
//   width: fit-content;
//   background: ${props => props.theme.step150};
//   color: ${props => props.theme.textAlt};

//   font-family: ${props => props.theme.font};
//   font-size: 14px;
//   font-weight: 500;
//   display: flex;
//   align-items: center;
//   border-radius: 2px;

//   min-width: 50px;
//   justify-content: center;
//   border-radius: 25px;

//   user-select: none;
//   text-decoration: none;

//   white-space: nowrap;
//   flex-shrink: 0;

//   margin-left: 8px;

//   :first-of-type {
//       margin-left: 0;
//   }
//   transition: all 0.1s;

//   :hover:not([disabled]) {
//       cursor: pointer;
//       transform: scale(1.05);
//   }

//   :active:not([disabled]) {
//       transform: scale(0.95);
//   }

//   &[disabled] {
//     background: ${props => props.theme.step100};
//     color: ${props => props.theme.textDisabled};
//   }
// `;

const ExportButtons = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 10px;
`;

const ExportExplain = styled.div`
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  font-size: 18px;
`;

const ExportDetails = styled.div``;

const ExportOption = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;

  cursor: pointer;
`;

const ExportToggles = styled.div`
  color: ${(props) => props.theme.textAlt};
  font-family: ${(props) => props.theme.font};
  font-size: 16px;

  display: flex;
  flex-direction: column;
`;

const FieldLabel = styled.label`
  user-select: none;
  margin-right: 12px;
  cursor: pointer;
`;

const FieldCheckbox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
`;
