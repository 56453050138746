export const CHART_TYPES = {
  // Basic Charts
  pie: { label: "Pie", category: "Basic Charts" },
  line: { label: "Line", category: "Basic Charts" },
  bar: { label: "Bar", category: "Basic Charts" },
  multibar: { label: "Multi-Bar", category: "Basic Charts" },
  number: { label: "Big Number", category: "Basic Charts" },
  // Tables
  table: { label: "Table", category: "Tables" },
  matrix: { label: "Matrix", category: "Tables" },
  // Complex Charts
  heat: { label: "Heat Map", category: "Complex Charts" },
  location: { label: "Location", category: "Complex Charts" }
};
