import React, { useState, useContext } from "react";
import styled from "styled-components";

import { IconButton, Tooltip, Badge, Paper } from "@mui/material";
import {
  Check,
  Delete,
  Save,
  History,
  ContentCopy,
  Edit,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { useTaskFeed } from "../../../../../hooks/queries";
import { QueryStatusTag } from "../../query";
import { TaskDataForm, TASK_CLOSED_STATUSES } from ".";

import { UserDataContext } from "../../../../../App";
import { GenericField } from "../../../../ui/inputs2";
import { useHotkey } from "../../../../../hooks/system";

const useBadgeStyles = makeStyles({
  badge: {
    fontSize: 8,
    height: 16,
    minWidth: 16,
    width: 16,
    borderRadius: "50%",
    backgroundColor: "white",
    color: "purple",
    border: `1px solid purple`,
  },
});

export default ({
  query,
  taskData,
  setTaskData,
  deleteTask,
  completeTask,
  saveTask,
  duplicateTask,
  taskSchema,
  closureTried,
  setConfirmationModal,
  setCommentsOpen,
}) => {
  const userData = useContext(UserDataContext);
  const feed = useTaskFeed(query, taskData.id);

  const classes = useBadgeStyles();

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100%",
      }}
    >
      <Paper elevation={3} sx={{ height: "100%" }}>
        <TaskHeading>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <QueryStatusTag>{taskData.status.toUpperCase()}</QueryStatusTag>
            {/* <div>{taskData.dynamicId ?? taskData.id}</div> */}
            <div style={{ marginTop: "2px", marginLeft: "4px" }}>
              <TitleEditor
                taskData={taskData}
                setTaskData={setTaskData}
              />
            </div>
          </div>
          <div>
            {/* Any user can mark or manage a task, this is by design for now */}
            <Tooltip title="Duplicate Task">
              <IconButton onClick={() => duplicateTask(taskData)}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
            {!TASK_CLOSED_STATUSES.includes(taskData.status) && (
              <>
                <Tooltip title="Save Task">
                  <IconButton onClick={() => saveTask()}>
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Task">
                  <IconButton
                    onClick={() =>
                      setConfirmationModal({
                        open: true,
                        title: "Confirm Submission",
                        message:
                          "You are about to delete this task. This action cannot be undone. Are you sure you'd like to proceed?",
                        confirm: () => {
                          deleteTask(taskData.id);
                        },
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    userData.id === taskData.assignedTo
                      ? "Mark Task Completed"
                      : "Only the assignee can mark tasks as completed"
                  }
                >
                  <span>
                    {" "}
                    {/* span is used here to prevent the tooltip from being hidden by the button disable */}
                    <IconButton
                      onClick={() =>
                        setConfirmationModal({
                          open: true,
                          title: "Confirm Submission",
                          message:
                            "You are about to mark this task as completed. This action cannot be undone. Are you sure you'd like to proceed?",
                          confirm: () => {
                            completeTask(taskData.id);
                          },
                        })
                      }
                      disabled={userData.id !== taskData.assignedTo}
                    >
                      <Check
                        color={
                          userData.id !== taskData.assignedTo
                            ? "disabled"
                            : "primary"
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
            <Tooltip title="Task Activity Feed">
              <IconButton onClick={() => setCommentsOpen((ex) => !ex)}>
                <Badge
                  color="secondary"
                  badgeContent={feed?.length}
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  classes={{ badge: classes.badge }}
                >
                  <History />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </TaskHeading>
        <TaskDataForm
          taskData={taskData}
          setTaskData={setTaskData}
          schema={taskSchema}
          closureTried={closureTried}
          enableClosure
        />
      </Paper>
    </div>
  );
};

export const TitleEditor = ({ taskData, setTaskData, editing }) => {
  const [isEditable, setIsEditable] = useState(!!editing);
  const changeable = !TASK_CLOSED_STATUSES.includes(taskData.status);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "none",
      }}
    >
      {isEditable ? (
        <GenericField
          size="small"
          onChange={(e) =>
            setTaskData((ex) => ({
              ...ex,
              data: { ...ex?.data, title: e.target.value },
            }))
          }
          label="Title"
          data={taskData.data.title}
          charLimit={50}
          autoFocus={editing ? true : undefined}
        />
      ) : (
        <>{taskData.data.title || "Untitled Task"}</>
      )}
      {changeable && (
        <FloatingEditButtons
          isEditable={isEditable}
          setIsEditable={setIsEditable}
        />
      )}
    </div>
  );
};

const FloatingEditButtons = ({ isEditable, setIsEditable }) => {
  useHotkey("Enter", () => setIsEditable(false));

  return (
    <SectionFloatingButtonContainer>
      {isEditable ? (
        <Tooltip title="Confirm">
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              setIsEditable(false);
            }}
          >
            <Check />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Edit Title">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setIsEditable(true)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      )}
    </SectionFloatingButtonContainer>
  );
};

export const TaskHeading = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
`;

const TaskFeed = styled.div`
  display: flex;
  position: absolute;
  z-index: 14;
  bottom: 0px;
  right: ${(props) => (props.hidden ? "-500" : "0")}px;
  width: 300px;
  height: calc(100% - 35px);
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  background: white;
  flex-direction: column;

  transition: right 200ms ease-in-out;
`;
const TaskFeedCover = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 13;
`;

const SectionFloatingButtonContainer = styled.div`
  margin-left: 10px;
  border-radius: 6px;
`;
