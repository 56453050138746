import { BaseCollection, BaseDocument, BaseData } from "../../base";

interface Options extends BaseData {
  options: string[];
};

export class AdhocOptions extends BaseCollection<Options> {
  options(id: string) {
    return this.doc(id) as AdhocOption;
  }
}

type AdhocOption = BaseDocument<Options>;
