import { BaseCollection, BaseData, CancelListener } from "../base";
import { Project } from ".";

import {
  DocumentData,
  Query,
  QuerySnapshot,
  orderBy,
  startAt,
  endAt,
  where,
  documentId,
} from "firebase/firestore";

interface ActionSharedData extends BaseData {
  name: string;
  creator: string;
}

/** NEW: Get all OPEN actions that belong to the project */
// This is a composite of CollectionGroup queries
// That also means this is READ-ONLY
// NOTE: Right now this operates outside our firebase api, but should be integrated at some point
export class Actions {
  composite: BaseCollection<BaseData>[];
  dataset: any[] = []; // Dataset holds the list of doc entries for individual composite collections (for updates!)
  actionTypes: string[] = [];
  // listeners: CancelListener[] = [];
  path: string;

  constructor(
    project: Project,
    actionTypes: string[] = ["tasks", "approvals", "responses"]
  ) {
    this.path = project.path;
    this.actionTypes = actionTypes;
    this.composite = actionTypes.map((type) =>
      project.db.collectionGroup(type).compoundQuery(
        orderBy(documentId()),
        // startAt(project.path), endAt(project.path + "\uf8ff"),
        where("projectId", "==", project.ref.id),
        where("status", "in", ["open", "assigned"])
      )
    );
  }

  listen(
    observer: (data: any) => void,
    error: (error: any) => void = (e) => {
      throw e;
    }
  ): CancelListener {
    this.dataset = this.composite.map((q) => []);
    let listeners = this.composite.map((coll, ind) =>
      coll.listenForSnapshot(
        (snapshot: QuerySnapshot) => {
          this.dataset[ind] = snapshot.docs.map((doc) => {
            // Get the ids up the doc chain for reference
            let parentIds: { [k: string]: string } = {};
            let indexes = doc.ref.path.split("/").slice(0, -2);
            Array.from(Array(indexes.length / 2).keys()).forEach(
              (i) =>
                (parentIds[(indexes[i * 2] as string) + "Id"] =
                  indexes[i * 2 + 1])
            );
            // Now return the data
            return {
              id: doc.id,
              type: this.actionTypes[ind],
              ...parentIds,
              ...doc.data(),
            };
          });
          this._issueCompositeUpdate(observer);
        },
        (err: any) => error(err)
      )
    );
    return () => {
      listeners.forEach((l) => l());
    };
  }

  private _issueCompositeUpdate(observer: (data: any) => void) {
    // Reduce the data to single array
    let data = this.dataset.reduce((acc, cur) => acc.concat(cur), []);
    // Send to observer!
    observer(data);
  }
}
