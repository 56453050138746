import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import q from "@queryit/api";

import Logo1 from "../graphics/logo-with-text.svg?url";
import Line from "../graphics/line.png";
import Brochure from "./brochure";

import { CURRENT_YEAR } from "../../../common/dates";

import {
  LocationOnRounded,
  EmailSharp,
  Phone,
  Instagram,
  LinkedIn,
  EmailOutlined,
} from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

function Footer({ navHome, navAbout, navWhy, navUseCases, navPricing }, ref) {
  const [email, setEmail] = useState("");
  const [formHasSubmit, setFormHasSubmit] = useState(false);
  const [formError, setFormError] = useState(false);
  const [brochurePromptOpen, setBrochurePromptOpen] = useState(false);

  const submitEmail = (e) => {
    e.preventDefault();

    if (!email) {
      setFormError(true);
      return;
    }

    q.interests.add({ id: email, newsletter: true }).then((_) => {
      setFormHasSubmit(true);
      setEmail("");
    });

    setFormError(false);
  };

  return (
    <FooterContainer>
      <Brochure
        open={brochurePromptOpen}
        onClose={() => setBrochurePromptOpen(false)}
      />
      <MainContent>
        <Left>
          <Logo src={Logo1} style={{ marginTop: "10px" }} />
          <p style={{ marginTop: 0 }}>
            Technology can be used to make life easier and contribute in making
            a better society. Our mission is to provide our clients with
            technology solutions to improve the status quo. This maybe through
            digitizing & automating work processes, developing stand-alone
            automated tools or acting as technology partners to develop custom
            applications. Our focus will remain on serving our client&#39;s
            unique needs.
          </p>
          <SocialMediaContainer>
            <a href="https://www.linkedin.com/company/query-it/">
              <LinkedIn
                style={{
                  fontSize: "50px",
                  color: "#37227A",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.instagram.com/queryit/">
              <Instagram
                style={{
                  fontSize: "50px",
                  color: "#37227A",
                  cursor: "pointer",
                }}
              />
            </a>
          </SocialMediaContainer>
        </Left>

        <Middle>
          <H1>Discover</H1>
          <Link onClick={navHome}>Home</Link>
          <Link onClick={navAbout}>About</Link>
          <Link onClick={navWhy}>Why use Query-It</Link>
          <Link onClick={navUseCases}>Use Cases</Link>
          <Link onClick={navPricing}>Pricing</Link>
          <Link onClick={() => setBrochurePromptOpen(true)}>Brochure</Link>
          <Link onClick={() => (window.location.href = "/privacy")}>
            Privacy Policy
          </Link>
          <Link onClick={() => (window.location.href = "/termsofservice")}>
            Terms of Use
          </Link>
        </Middle>

        <Right>
          <H1>Get Updates</H1>
          <p style={{ marginTop: "5px" }}>
            Keep up with the latest Query-It news
          </p>

          <form onSubmit={submitEmail}>
            {formError && <p>Please enter a valid email address</p>}
            <FormControl sx={{ width: "300px" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      type="submit"
                    >
                      <EmailOutlined />
                    </IconButton>
                  </InputAdornment>
                }
                label="Email"
              />
            </FormControl>
          </form>
          {formHasSubmit && <p>Thanks for your interest!</p>}

          <H1 style={{ marginTop: "25px" }}>Contact Us</H1>
          <Contact ref={ref}>
            <LocationOnRounded style={{ fill: "#37227A" }} />
            <p style={{ marginTop: "5px", marginBottom: "3px" }}>
              Calgary, Alberta, Canada
            </p>
          </Contact>
          <Contact>
            <EmailSharp style={{ fill: "#37227A" }} />
            <a
              href="mailto:info@queryitapp.com"
              style={{
                marginTop: 0,
                marginBottom: "3px",
                textDecoration: "none",
                color: "#6D6D6D",
              }}
            >
              &nbsp;info@queryitapp.com
            </a>
          </Contact>
          <Contact>
            <Phone style={{ fill: "#37227A" }} />
            <a
              href="tel:403-390-9349"
              style={{
                marginTop: 0,
                marginBottom: "3px",
                textDecoration: "none",
                color: "#6D6D6D",
              }}
            >
              403-390-9349
            </a>
          </Contact>
        </Right>
      </MainContent>

      <Bottom>
        <img src={Line} style={{ width: "100%", height: "auto" }} />
        <p style={{ fontSize: "14px" }}>
          &copy; {CURRENT_YEAR} Query-It. All Rights Reserved
        </p>
      </Bottom>
    </FooterContainer>
  );
}

export default forwardRef(Footer);

const FooterContainer = styled.div`
  min-height: fit-content;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  color: #6d6d6d;

  @media (max-width: 600px) {
    min-height: 800px;
  }
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const Left = styled.div`
  height: 70%;
  width: 40%;
  line-height: 150%;
  font-size: 105%;

  @media (max-width: 1100px) {
    width: 90%;
    text-align: center;
  }
`;
const Middle = styled.div`
  height: 70%;
  width: 13%;

  @media (max-width: 1100px) {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`;
const Right = styled.div`
  height: 70%;
  width: 30%;

  @media (max-width: 1100px) {
    width: 90%;
    text-align: center;
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Logo = styled.img`
  height: 110px;
  margin-bottom: 10px;

  @media (max-width: 1300px) {
    // scale 60%
    height: 92.4px;
  }

  @media (max-width: 600px) and (min-width: 371px) {
    height: 100px;
    margin-left: -10px;
    margin-top: 50px;
  }

  @media (max-width: 370px) {
    // scale 80%
    height: 80px;
    margin-top: 50px;
  }
`;
const H1 = styled.h1`
  color: #37227a;
  margin-bottom: 0;
`;
const SocialMediaContainer = styled.div`
  width: 13%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1100px) {
    margin: auto;
    width: 120px;
  }
`;
const EmailForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const EmailInput = styled.input`
  width: 300px;
  background-color: #37227a;
  border-color: #1c9af5;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px 0px 0px 5px;
  border-right: 0px none;
  font-size: 100%;
  padding: 15px;
  color: #67589a;
`;
const EmailInputSymbol = styled.div`
  // A wrapper for the symbol at the end of the Email Input field
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #37227a;
  color: white;
  width: 50px;
  border-radius: 0px 5px 5px 0px;
  border-style: solid;
  border-color: #1c9af5;
  border-width: 2px;
  border-left: none;
`;
const Contact = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1100px) {
    width: 70%;
    justify-content: center;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 1100px) {
    margin-top: 40px;
  }
`;

const Link = styled.p`
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;
