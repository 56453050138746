import React, { useContext } from "react";

import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { format, intervalToDuration, formatDuration } from "date-fns";
import { parse_db_timestamp } from "../../../../tools";

import { PDFConstants } from "./style";
import { ProjectUsersContext } from "../..";

const PDFStyles = StyleSheet.create({
  cell: {
    display: "table-cell",
  },
  labelRow: {
    marginTop: 1,
    marginLeft: 2,
    height: 10,
  },
  label: {
    fontSize: 8,
  },
  dataRow: {
    height: 16,
    flexDirection: "row",
  },
  signatureRow: {
    height: 40,
    minHeight: 40,
    flexDirection: "row",
  },
  floatDataRow: {
    height: "auto",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "relative",
    flex: "2 1 auto",
  },
  bigDataRow: {
    height: 32,
    width: "100%",
    flexDirection: "row",
    overflow: "hidden",
    display: "block",
    flexWrap: "wrap",
    // wordBreak: "break-all",
  },
  data: {
    fontSize: 10,
    marginLeft: 4,
    marginRight: 4,
    width: "calc(100% - 10px)",
    wordBreak: "break-all",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  textSmooth: {
    breakWord: "break-all",
    width: "90%",
  },
  signatureMetadata: {
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "5px",
  },
  // TABLE STYLES
  table: {
    display: "table",
    color: "black",
    border: "1px solid black",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableLabelRow: {
    height: 14,
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableLabel: {
    fontSize: 11,
    marginLeft: 2,
  },
  tableRowInterior: {
    margin: "auto",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableColInterior: {
    borderRight: "1px solid black",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    wordBreak: "break-all",
  },
  tableDivSpace: {
    height: 6,
    width: "100%",
  },
  tableDivSpaceTop: {
    height: 6,
    width: "100%",
    borderBottom: "1px solid black",
  },
  cellPartitionSemiLineOuter: {
    flexDirection: "column",
    height: 22,
  },
  cellPartitionSemiLineTop: {
    height: 7,
    width: "100%",
    justifyContent: "center",
  },
  cellPartitionSemiLineTopText: {
    fontSize: 6,
    textAlign: "center",
    color: "grey",
  },
  cellPartitionSemiLineBottom: {
    borderRight: "1px solid black",
    height: 9,
    fontSize: 7,
    textAlign: "center",
  },
  informationText: {
    fontSize: 10,
  },
  // SELECT STYLES
  optionSetContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  booleanOptionContainer: {
    margin: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    breakWord: "break-all",
  },
  booleanLabel: {
    fontSize: 10,
    breakWord: "break-all",
  },
  booleanOutsideSquare: {
    height: 6,
    width: 6,
    border: "1px solid black",
    marginRight: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  booleanOutsideRound: {
    height: 6,
    width: 6,
    border: "1px solid black",
    borderRadius: 3,
    marginRight: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  booleanFillSquare: {
    height: 3,
    width: 3,
    backgroundColor: "black",
  },
  booleanFillRound: {
    height: 3,
    width: 3,
    backgroundColor: "black",
    borderRadius: 2,
  },

  // Blank
  noStyle: {},
});

/* This is a supporting map from different field types to their pdf printing equivalents */
export const PrintableField = ({ field, fieldData, data, width }) => {
  const maxRowChars =
    PDFConstants.LINE_MAX_CHARACTERS * (width / PDFConstants.BODY_WIDTH);
  switch (field.type) {
    case "string":
    case "number":
    case "textarea":
    case "rowindex":
    case "message":
      return (
        <PrintableTextField
          label={field.name}
          data={fieldData}
          centerAlign={field.type === "number"}
          textarea={field.type === "textarea"}
          characterRowLength={maxRowChars}
        />
      );
    case "userlist":
      return <PrintableUsersField label={field.name} data={fieldData} />;
    case "table":
    case "statictable":
      return (
        <PrintableTableField
          label={field.name}
          columns={field.columns}
          rows={
            fieldData
              ? fieldData
              : field.rows
              ? Array.isArray(field.rows)
                ? field.rows
                : Object.values(field.rows)[0] // This is complicated because conditional static columns exist
              : [{}, {}, {}]
          }
          foot={
            field.columns?.some((col) => col.calculation !== undefined)
              ? [
                  field.columns?.map((col) =>
                    col?.calculation?.some((v) => v == "sum")
                      ? {
                          default:
                            "Sum: " +
                            (
                              fieldData?.reduce(
                                (acc, curr) =>
                                  (!isNaN(curr[col.id]) ? curr[col.id] : 0) +
                                  acc,
                                0
                              ) ?? 0
                            ).toString(),
                        }
                      : ""
                  ),
                ]
              : []
          }
          width={width}
        />
      );
    case "tableadv":
      return (
        <PrintableTableField
          label={field.name}
          columns={field.schema?.body?.columns?.map((col) => ({
            name: col.title ?? "",
            type: col.type,
            id: col.id,
          }))}
          rows={
            fieldData
              ? fieldData.body
              : field.rows
              ? Array.isArray(field.rows)
                ? field.rows
                : Object.values(field.rows)[0] // This is complicated because conditional static columns exist
              : [{}, {}, {}]
          }
          width={width}
          head={field.schema?.header?.map((row, rowIndex) =>
            Object.values(row)
              .map((cell, cellIndex) => [
                ...Array(cell?.rowOffset ?? 0).fill(""),
                {
                  ...cell,
                  default:
                    fieldData?.header?.[rowIndex]?.[cellIndex] ?? cell?.default,
                  colSpan: cell?.style?.colSpan ?? 1,
                },
              ])
              .reduce((acc, curr) => acc.concat(curr), [])
          )}
        />
      );
    // Selectables (with shown options)
    case "select":
    case "multiselect":
    case "adhoc":
    case "checkbox":
    case "boxset":
    case "radio":
    case "tableradio":
      return field.conditionalOn ? (
        data[field.conditionalOn] ? (
          <PrintableMultiSelectableField
            label={field.name}
            options={
              field.options[data[field.conditionalOn]] ?? {
                yes: "Yes",
                no: "No",
              }
            }
            data={fieldData}
            selectOne={
              Object.keys(field.options[data[field.conditionalOn]]).length > 1
            }
          />
        ) : (
          <PrintableTextField
            label={field.name}
            data={fieldData}
            textarea={true}
          />
        )
      ) : (
        <PrintableMultiSelectableField
          label={field.name}
          options={field.options ?? { yes: "Yes", no: "No" }}
          data={fieldData}
          selectOne={field.type === "radio" || field.type === "select"}
        />
      );
    case "time":
      return (
        <PrintableLocalityField
          label={field.name}
          isDate={field.type !== "time"}
          isTime={field.type !== "date"}
          width={width}
          data={fieldData}
        />
      );
    case "date":
      return (
        <PrintableLocalityField
          label={field.name}
          isDate={field.type !== "time"}
          isTime={field.type !== "date"}
          width={width}
          data={fieldData}
        />
      );
    case "datetime":
      return (
        <PrintableLocalityField
          label={field.name}
          isDate={field.type !== "time"}
          isTime={field.type !== "date"}
          width={width}
          data={fieldData}
        />
      );
    case "timetracker":
      return (
        <PrintableTimeEntryField
          label={field.name}
          width={width}
          data={fieldData}
        />
      );
    case "signature":
      return <PrintableSignatureField label={field.name} data={fieldData} />;
    case "files":
      return fieldData ? (
        <PrintableFilesAttached label={field.name} data={fieldData} />
      ) : (
        <PrintableTableField
          label={field.name}
          columns={[
            {
              id: "file-name",
              name: "File Name",
              type: "rowindex",
            },
            {
              id: "page-number",
              name: "Page Number",
              type: "number",
            },
          ]}
          rows={[{}, {}, {}]}
          width={width}
        />
      );
    default:
      return null;
  }
};

const PrintableTextField = ({
  label,
  data,
  centerAlign,
  textarea,
  characterRowLength,
}) => {
  /* This is a standard blank and is applied to: string, number, select */

  const calcExtraLines = () => {
    return (
      data?.split(/\r\n|\r|\n/).reduce((acc, curr) => {
        if (curr.length > characterRowLength) {
          return acc + Math.ceil(curr.length / characterRowLength) - 1;
        } else {
          return acc;
        }
      }, 0) + Math.max(data?.split(/\r\n|\r|\n/).length - 3, 0)
    );
  };

  const reducedFontSize =
    typeof data === "string" &&
    characterRowLength < PDFConstants.LINE_MAX_CHARACTERS / 3 &&
    data.length > characterRowLength
      ? 7
      : 10;

  return (
    <>
      <View style={PDFStyles.labelRow} key={`${label}-label`}>
        {label !== undefined && <Text style={PDFStyles.label}>{label} </Text>}
      </View>
      <View
        style={
          textarea
            ? {
                ...PDFStyles.bigDataRow,
                height: 32 + calcExtraLines() * 12,
              }
            : PDFStyles.dataRow
        }
        key={`${label}-data`}
      >
        {data !== undefined && (
          <Text
            style={{
              ...PDFStyles.data,
              fontSize: reducedFontSize,
              textAlign: centerAlign ? "center" : PDFStyles.data.textAlign,
            }}
          >
            {data}
          </Text>
        )}
      </View>
    </>
  );
};

const PrintableUsersField = ({ label, data }) => {
  const users = useContext(ProjectUsersContext);
  const selectedUsers = (Array.isArray(data) ? data : !data ? [] : [data])
    .map((user) => users?.find((u) => u.id === user))
    .map((user) => `${user?.name?.first} ${user?.name?.last}`)
    .join(", ");

  return <PrintableTextField label={label} data={selectedUsers} />;
};

const PrintableTableField = ({
  label,
  width,
  columns,
  head = [],
  foot = [],
  rows = [{}, {}, {}],
}) => {
  /* This is a standard empty table and is applied to: table, statictable 
    For this, a default number of rows can be entered, or 3 will be left.
    Static tables will obviously have their rows with pre-populated data
  */
  if (columns.length < 1) {
    return <PrintableTextField label={label} width={width} />;
  }
  let rowNum = 0;
  let colNum = 0;

  return (
    <View wrap={false}>
      <View style={PDFStyles.tableDivSpaceTop} />
      <View style={PDFStyles.tableLabelRow}>
        <Text style={PDFStyles.tableLabel}>{label ?? ""}</Text>
      </View>
      {/* Before table body, do the head if it exists [Row(columns), Row(columns), ...] */}
      {head?.length > 0 &&
        head.map((hd, ind) => (
          <View style={PDFStyles.tableRowInterior} key={`${label}-head-${ind}`}>
            {hd.map((cell, cellInd) => {
              return (
                <View
                  key={`col-${cellInd + 1}`}
                  style={[
                    cellInd + 1 < columns.length
                      ? PDFStyles.tableColInterior
                      : PDFStyles.noStyle,
                    { width: (width / columns.length) * (cell.colSpan ?? 1) },
                  ]}
                >
                  <Text style={PDFStyles.tableCell}>{cell?.default ?? ""}</Text>
                </View>
              );
            })}
          </View>
        ))}
      {/* Now we start with the columns row */}
      <View style={PDFStyles.tableRowInterior} key={`${label}-cols`}>
        {columns.map((col) => {
          colNum++;
          return (
            <View
              key={`col-${colNum}`}
              style={[
                colNum < columns.length
                  ? PDFStyles.tableColInterior
                  : PDFStyles.noStyle,
                { width: width / columns.length },
              ]}
            >
              <Text style={PDFStyles.tableCell}>{col.name}</Text>
            </View>
          );
        })}
      </View>
      {/* Then we move to the rows where data can go! */}
      {rows.map((row) => {
        rowNum++;
        let cellNum = 0;
        return (
          <View
            style={PDFStyles.tableRowInterior}
            key={`${label}-row-${rowNum}`}
          >
            {columns.map((col) => {
              cellNum++;
              return (
                <View
                  key={`col-${cellNum}`}
                  style={[
                    cellNum < columns.length
                      ? PDFStyles.tableColInterior
                      : PDFStyles.noStyle,
                    { width: width / columns.length },
                  ]}
                >
                  <PrintableField
                    field={{
                      ...col,
                      name: "",
                      type: col.type === "text" ? "string" : col.type,
                    }}
                    fieldData={col.type === "rowindex" ? rowNum : row[col.id]}
                    data={row}
                    width={width / columns.length}
                  />
                </View>
              );
            })}
          </View>
        );
      })}
      {/* And finally we'll add the footer */}
      {foot?.length > 0 &&
        foot.map((ft, ind) => (
          <View style={PDFStyles.tableRowInterior} key={`${label}-foot-${ind}`}>
            {ft.map((cell, cellInd) => {
              return (
                <View
                  key={`col-${cellInd + 1}`}
                  style={[
                    cellInd + 1 < columns.length
                      ? PDFStyles.tableColInterior
                      : PDFStyles.noStyle,
                    { width: (width / columns.length) * (cell.colSpan ?? 1) },
                  ]}
                >
                  <Text
                    style={{
                      ...PDFStyles.tableCell,
                      textAlign: "right",
                      marginRight: "45px",
                    }}
                  >
                    {cell?.default ?? ""}
                  </Text>
                </View>
              );
            })}
          </View>
        ))}
      <View style={PDFStyles.tableDivSpace} />
    </View>
  );
};

const PrintableLocalityField = ({
  label,
  width,
  isDate = false,
  isTime = false,
  data,
}) => {
  /* If it's just a date or a time, we use a constrained box with specific spaces for each number (and the background script?) */
  if (isDate && isTime) {
    return <PrintableTextField label={label} />;
  }

  const value = parse_db_timestamp(data);

  return (
    <>
      <View style={PDFStyles.labelRow} key={`${label}-label`}>
        {label !== undefined && <Text style={PDFStyles.label}>{label}</Text>}
      </View>
      {isDate && (
        <View style={PDFStyles.floatDataRow}>
          <View style={PDFStyles.dataRow} key={`${label}-data`}>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineOuter,
                { width: width / 2 },
              ]}
            >
              <View style={PDFStyles.cellPartitionSemiLineTop}>
                <Text style={PDFStyles.cellPartitionSemiLineTopText}>Year</Text>
              </View>
              <View style={PDFStyles.cellPartitionSemiLineBottom}>
                <Text>{value ? format(value, "y") : ""}</Text>
              </View>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineOuter,
                { width: width / 4 },
              ]}
            >
              <View style={PDFStyles.cellPartitionSemiLineTop}>
                <Text style={PDFStyles.cellPartitionSemiLineTopText}>
                  Month
                </Text>
              </View>
              <View style={PDFStyles.cellPartitionSemiLineBottom}>
                <Text>{value ? format(value, "MM") : ""}</Text>
              </View>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineOuter,
                { width: width / 4 },
              ]}
            >
              <View style={PDFStyles.cellPartitionSemiLineTop}>
                <Text style={PDFStyles.cellPartitionSemiLineTopText}>Day</Text>
              </View>
              <View
                style={[
                  PDFStyles.cellPartitionSemiLineBottom,
                  { borderRight: "unset" },
                ]}
              >
                <Text>{value ? format(value, "dd") : ""}</Text>
              </View>
            </View>
          </View>
        </View>
      )}
      {isTime && (
        <View style={PDFStyles.floatDataRow}>
          <View style={PDFStyles.dataRow} key={`${label}-data`}>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineOuter,
                { width: width / 2 },
              ]}
            >
              <View style={PDFStyles.cellPartitionSemiLineTop}>
                <Text style={PDFStyles.cellPartitionSemiLineTopText}>Hour</Text>
              </View>
              <View style={PDFStyles.cellPartitionSemiLineBottom}>
                <Text>{value ? format(value, "HH") : ""}</Text>
              </View>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineOuter,
                { width: width / 2 },
              ]}
            >
              <View style={PDFStyles.cellPartitionSemiLineTop}>
                <Text style={PDFStyles.cellPartitionSemiLineTopText}>
                  Minute
                </Text>
              </View>
              <View
                style={[
                  PDFStyles.cellPartitionSemiLineBottom,
                  { borderRight: "unset" },
                ]}
              >
                <Text>{value ? format(value, "mm") : ""}</Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const PrintableTimeEntryField = ({ label, width, data }) => {
  const start = parse_db_timestamp(data?.start);
  const end = parse_db_timestamp(data?.end);

  return (
    <>
      <View style={PDFStyles.floatDataRow}>
        <View style={PDFStyles.dataRow} key={`${label}-data`}>
          <View
            style={[PDFStyles.cellPartitionSemiLineOuter, { width: width / 2 }]}
          >
            <View style={PDFStyles.cellPartitionSemiLineTop}>
              <Text style={PDFStyles.cellPartitionSemiLineTopText}>Date</Text>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineBottom,
                { borderRight: "unset" },
              ]}
            >
              <Text>{start ? format(start, "P") : ""}</Text>
            </View>
          </View>
          <View
            style={[PDFStyles.cellPartitionSemiLineOuter, { width: width / 2 }]}
          >
            <View style={PDFStyles.cellPartitionSemiLineTop}>
              <Text style={PDFStyles.cellPartitionSemiLineTopText}>Start</Text>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineBottom,
                { borderRight: "unset" },
              ]}
            >
              <Text>{start ? format(start, "pp") : ""}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={PDFStyles.floatDataRow}>
        <View style={PDFStyles.dataRow} key={`${label}-data`}>
          <View
            style={[PDFStyles.cellPartitionSemiLineOuter, { width: width / 2 }]}
          >
            <View style={PDFStyles.cellPartitionSemiLineTop}>
              <Text style={PDFStyles.cellPartitionSemiLineTopText}>Total</Text>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineBottom,
                { borderRight: "unset" },
              ]}
            >
              <Text>
                {start && end
                  ? formatDuration(
                      intervalToDuration({ start: start, end: end }),
                      ["hours", "minutes"]
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View
            style={[PDFStyles.cellPartitionSemiLineOuter, { width: width / 2 }]}
          >
            <View style={PDFStyles.cellPartitionSemiLineTop}>
              <Text style={PDFStyles.cellPartitionSemiLineTopText}>End</Text>
            </View>
            <View
              style={[
                PDFStyles.cellPartitionSemiLineBottom,
                { borderRight: "unset" },
              ]}
            >
              <Text>{end ? format(end, "pp") : ""}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const PrintableInformationField = ({ label, copy }) => {
  /* Just a field for an introductory paragraph, preamble or the like */

  return (
    <Text style={PDFStyles.informationText}>This is information (message)</Text>
  );
};

const PrintableMultiSelectableField = ({ label, options, data, selectOne }) => {
  // We don't support these conditional ones in the form yet
  if (options.conditionalOn) {
    return null;
  }
  if (Object.values(options).some((obj) => typeof obj === "object")) {
    return null;
  }

  return (
    <>
      <View style={PDFStyles.labelRow} key={`${label}-label`}>
        {label !== undefined && (
          <Text style={PDFStyles.label}>
            {label}
            {selectOne && !Object.keys(options).some((opt) => opt === data)
              ? " (Select one)"
              : ""}
          </Text>
        )}
      </View>
      <View style={PDFStyles.optionSetContainer}>
        {data === undefined ||
        !Object.keys(options).some((opt) => opt === data) ? (
          Object.keys(options).map((opt) => (
            <PrintableBooleanOption
              key={`${opt}-option`}
              title={options[opt]}
              selected={
                data === opt || (Array.isArray(data) && data.includes(opt))
              }
              round={selectOne}
            />
          ))
        ) : (
          <PrintableBooleanOption
            title={options[data]}
            selected={true}
            round={selectOne}
          />
        )}
      </View>
    </>
  );
};

const PrintableFilesAttached = ({ label, data }) => {
  return (
    <>
      <View style={PDFStyles.labelRow} key={`${label}-label`}>
        {label !== undefined && (
          <Text
            style={PDFStyles.label}
          >{`${label} (selected files are attached)`}</Text>
        )}
      </View>
      <View style={PDFStyles.optionSetContainer}>
        {data &&
          data?.data.map((file) => (
            <PrintableBooleanOption
              key={file.id}
              title={file.name}
              selected={true}
            />
          ))}
      </View>
    </>
  );
};

const PrintableBooleanOption = ({ title, selected, round }) => {
  return (
    <>
      <View style={PDFStyles.booleanOptionContainer}>
        {/* First the option button */}
        <View
          style={
            round
              ? PDFStyles.booleanOutsideRound
              : PDFStyles.booleanOutsideSquare
          }
        >
          {selected && (
            <View
              style={
                round ? PDFStyles.booleanFillRound : PDFStyles.booleanFillSquare
              }
            ></View>
          )}
        </View>
        {/* Then the label for it */}
        <View style={PDFStyles.booleanLabel}>
          <Text styles={PDFStyles.textSmooth}>{title}</Text>
        </View>
      </View>
    </>
  );
};

const PrintableSignatureField = ({ label, data }) => {
  // If data is defined, there is a signature IMAGE to print here
  return (
    <>
      <View style={PDFStyles.labelRow} key={`${label}-label`}>
        <Text style={PDFStyles.label}>{label ?? ""}</Text>
      </View>
      <View style={PDFStyles.signatureRow} key={`${label}-data`}>
        {data?.signature && data?.confirmed && (
          <>
            <Image
              src={data.signature}
              style={{ height: (PDFStyles.signatureRow.height ?? 20) - 4 }}
            />
            {data?.confirmedAt && (
              <View style={PDFStyles.signatureMetadata}>
                <Text
                  style={{
                    ...PDFStyles.cellPartitionSemiLineTopText,
                    fontSize: 5,
                  }}
                >
                  {data?.user?.name ?? ""}
                </Text>
                <Text
                  style={{
                    ...PDFStyles.cellPartitionSemiLineTopText,
                    // marginTop: "6px",
                  }}
                >
                  Signed{" "}
                  {format(
                    parse_db_timestamp(data?.confirmedAt),
                    "MMM do, yyyy 'at' h:mma"
                  )}
                </Text>
              </View>
            )}
          </>
        )}
      </View>
    </>
  );
};
