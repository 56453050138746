import React, { useState } from "react";
import { GenericField } from "../../../ui/inputs2";
import { QuerySection, QuerySectionHeader, QueryButtons } from "../query";
import { Button2 } from "../../../ui/buttons";

export default ({ makeClarification, form, formUser }) => {
  const [newClarification, setNewClarification] = useState({
    subject: "",
    question: "",
    askedTime: new Date(),
  });

  return (
    <QuerySection>
      <QuerySectionHeader>New Clarification</QuerySectionHeader>
      <GenericField
        label="Subject"
        onChange={(e) => {
          const { value } = e.target;
          setNewClarification((clr) => ({ ...clr, subject: value }));
        }}
        data={newClarification.subject}
      />
      <br></br>
      <GenericField
        label="Question"
        onChange={(e) => {
          const { value } = e.target;
          setNewClarification((clr) => ({ ...clr, question: value }));
        }}
        data={newClarification.question}
        multiLine
        fill
      />
      <QueryButtons>
        <Button2
          label={"Submit Question"}
          onClick={() => {
            makeClarification(newClarification).then((_) =>
              setNewClarification({
                subject: "",
                question: "",
                askedTime: new Date(),
              })
            );
            form.messages.add({
              type: "userIssuesClarification",
              message: `${formUser.name} has issued a clarification`,
              sentTime: new Date(),
              viewed: false,
            });
          }}
          disabled={
            newClarification.subject.length < 1 ||
            newClarification.question.length < 1
          }
        />
      </QueryButtons>
    </QuerySection>
  );
};
