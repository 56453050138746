import React, { useContext, useState } from "react";
import { FormCompositeContext, FormContent, FormDataContext } from ".";

import { GenericField } from "../../ui/inputs2";
import { ButtonSection, Button2 } from "../../ui/buttons";
import { Typography } from "@mui/material";

export default () => {
  const [rejectionReason, setRejectionReason] = useState("");

  const form = useContext(FormCompositeContext);
  const formData = useContext(FormDataContext);

  return (
    <FormContent style={{ alignItems: "center" }}>
      {formData.rejectionReason && (
        <>
          <Typography>
            The form submission has already been rejected by this participant.
          </Typography>
          <Typography>
            <b>Reason:</b> {formData.rejectionReason}
          </Typography>
        </>
      )}
      {!formData.rejectionReason && (
        <>
          <Typography>
            Could you take a couple of seconds to tell us why you would like to
            reject the form?
          </Typography>
          <GenericField
            label="Rejection Reason"
            onChange={(e) => {
              const { value } = e.target;
              setRejectionReason(value);
            }}
            data={rejectionReason}
            multiLine
            style={{ width: "60%" }}
          />
          <ButtonSection>
            <Button2
              label="Cancel Rejection"
              onClick={() => {
                form.update({ status: "pending" });
              }}
            />
            <Button2
              style={{ marginLeft: 8 }}
              label="Confirm Rejection"
              onClick={() => {
                form.update({
                  status: "rejected",
                  rejectionReason: rejectionReason,
                });
              }}
            />
          </ButtonSection>
        </>
      )}
    </FormContent>
  );
};
