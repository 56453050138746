import React from "react";
import styled from "styled-components";
import Link from "./link";

import { Button as MuiButton, Typography } from "@mui/material";

const ButtonDiv = styled.div`
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  background: ${(props) =>
    props.toggleOn ? props.theme.button.active : props.theme.button.inactive};
  color: ${(props) =>
    props.theme.button.inactiveText
      ? props.theme.button.inactiveText
      : props.theme.textAlt};

  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 2px;

  min-width: 50px;
  justify-content: center;
  border-radius: 25px;

  user-select: none;
  text-decoration: none;

  white-space: nowrap;
  flex-shrink: 0;

  margin-left: 8px;
  :first-of-type {
    margin-left: 0;
  }

  transition: all 0.1s;
  :hover:not([disabled]) {
    cursor: pointer;
    transform: scale(1.05);
  }

  :active:not([disabled]) {
    transform: scale(0.95);
  }

  &[disabled] {
    background: ${(props) => props.theme.button.disabled};
    color: ${(props) => props.theme.textDisabled};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.orientation == "hor" ? "row" : "column")};
`;

export const IconButtonText = ({ children, ...props }) => {
  return (
    <Typography sx={{ fontSize: "10px", position: "fixed", marginTop: "30px" }}>
      {children}
    </Typography>
  );
};

export const Button = ({ onClick, text, loading, ...props }) => {
  return (
    <ButtonDiv onClick={!props.disabled ? onClick : () => {}} {...props}>
      {loading ? "···" : text}
    </ButtonDiv>
  );
};

export const Button2 = ({ onClick, label, disabled, ...props }) => {
  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      {...props}
    >
      {label}
    </MuiButton>
  );
};

export const ButtonSection = ({ orientation = "hor", children, style }) => {
  return (
    <ButtonContainer orientation={orientation} style={style}>
      {children}
    </ButtonContainer>
  );
};

export const LinkButton = ({ to, ...props }) => {
  return (
    <Link to={to}>
      <Button {...props} />
    </Link>
  );
};
