import React, { useState } from "react";
// UI
import { DataTable } from "../../../ui/table";
import { ProjectRouteContainer } from "../../../project";

// Hooks
import { useFeedbackSet } from "../../../../hooks/feedback";
import ViewModal from "../../project/popups/view"

//onclick for each row, manage so that it opens the full comment.
export default () => {
  const feedbacks = useFeedbackSet();
  const [currentComment, setCurrentComment] = useState(undefined);
  const [showComment, setShowComment] = useState(false);

  const handleRowClick = (e) => {
    const id = e.row._id;
    setCurrentComment(feedbacks.filter((feedback) => feedback.id === id));
    setShowComment(true);
  };

  return (
    <ProjectRouteContainer>
      <ViewModal open={showComment} closeView={() => {setShowComment(false)}} data={{...currentComment, type: "comment"}}/>
      {feedbacks && (
        <DataTable
          data={feedbacks}
          columns={userTableColumns}
          onRowClick={handleRowClick}
        />
      )}
    </ProjectRouteContainer>
  );
};

const userTableColumns = [
  { headerName: "User ID", index: "userID", sortable: true },
  { headerName: "First Name", index: "name.first", sortable: true },
  { headerName: "Last Name", index: "name.last", sortable: true },
  { headerName: "Rating (1-10)", index: "rating", sortable: true, width: 150 },
  { headerName: "Comments", index: "comments", sortable: true },
];
