import React, { useContext, useEffect, useCallback } from "react";

import {
  Snackbar,
  SnackbarContent,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { SnackbarContext } from "../../../hooks/system";

export default () => {
  const theme = useTheme();
  // Import array of snackbar strings
  const { messages, setMessages } = useContext(SnackbarContext);

  // Function to remove messages from the snackbar but not all of them incase there are multiple snackbars
  const removeSnackbar = useCallback((index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  }, []);

  return (
    <>
      {messages.map((message, index) => (
        <Snackbar
          key={index}
          open={true}
          autoHideDuration={5000}
          onClose={() => removeSnackbar(index)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <SnackbarContent
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              position: "relative",
              bottom: `${60 * index}px`,
            }}
            message={message}
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClose={() => removeSnackbar(index)}
                  style={{
                    color: theme.palette.common.white,
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </>
            }
          />
        </Snackbar>
      ))}
    </>
  );
};
