import React, { useEffect, useState, useContext, useMemo } from "react";
import styled from "@emotion/styled";

import {
  QuerySection,
  QueryContent,
  QuerySectionHeader,
  QueryButtons,
} from "../query";
import { QueryFields } from "./../fields";
import { TableInputDiv, TableHeader, TableCell } from "../fields/queryfields";
import { StatusDot } from "../../../ui/decorations";
import { Inputs, SelectField, BooleanField } from "../../../ui/inputs2";
import { Button, Button2 } from "../../../ui/buttons";
import { GenericField } from "../../../ui/inputs2";
import { useFormSchemaset } from "../../../../hooks/forms";
import { formatDate } from "../../../ui/display";

import { UserDataContext } from "../../../../App";
import { useForms } from "../../../../hooks/forms";
import { log_error } from "../../../../tools/logger";

// DEFECT: We should be able to handle multiple formComposition evaluations but we can't...
export default ({
  status,
  isEvaluationTime,
  projectId,
  query,
  setQueryData,
  schemaData,
  evaluations, // Map of all evaluations (formIds are indexes)
  // setEvaluations // Func takes formId and returns func to set value for form eval obj
}) => {
  const userData = useContext(UserDataContext);

  // TODO: First get all the forms (we'll have to sort them by the formComposition later...)
  const forms = useForms(query);

  // Now let's pull the formSchemaset for the composition
  // 1.5 Pull schemaPaths from the formData's composite (it knows its index and we have the schemaData)
  const [formSchemaPaths, setFormSchemaPaths] = useState(undefined);
  useEffect(() => {
    if (!schemaData) {
      setFormSchemaPaths(undefined);
      return;
    }
    // Get the formIds
    const formIds = schemaData.formCompositions?.[0]?.includedForms;
    // Assign Schema Paths state
    setFormSchemaPaths(
      formIds?.map(
        (d) => `projects/${projectId}/schemas/${schemaData.id}/forms/${d}`
      )
    );
  }, [schemaData, projectId]);
  // 2. Then we'll pull the schemas for all the forms in the composite
  const formSchemas = useFormSchemaset(formSchemaPaths);

  const [selectedEvaluation, setSelectedEvaluation] = useState(undefined);

  // Define edit function for evaluations
  let setEvaluations = (formId) => (value) => {
    setQueryData((ex) => ({
      ...ex,
      evaluations: { ...ex.evaluations, [formId]: value },
    }));
  };

  const singleEvalFormChange = (formId, subId, partId) => (ind) => (value) => {
    setEvaluations(formId)({
      ...evaluations?.[formId],
      submissions: {
        ...(evaluations?.[formId]?.submissions ?? {}),
        [subId]: {
          ...(evaluations?.[formId]?.submissions?.[subId] ?? {}),
          parts: {
            ...(evaluations?.[formId]?.submissions?.[subId]?.parts ?? {}),
            [partId]: {
              ...(evaluations?.[formId]?.submissions?.[subId]?.parts?.[
                partId
              ] ?? {}),
              [ind]: value,
            },
          },
          modified: {
            date: new Date(),
            name: `${userData.name.first} ${userData.name.last}`,
          },
        },
      },
    });
  };

  const singleEvalBooleanChange = (formId) => (subId, partId) => (e) => {
    const { value } = e.target;
    setEvaluations(formId)({
      ...evaluations?.[formId],
      submissions: {
        ...(evaluations?.[formId]?.submissions ?? {}),
        [subId]: {
          ...(evaluations?.[formId]?.submissions?.[subId] ?? {}),
          parts: {
            ...(evaluations?.[formId]?.submissions?.[subId]?.parts ?? {}),
            [partId]: value,
          },
        },
      },
    });
  };

  const setEvalSectionNotes = (formId) => (ind) => (e) => {
    const { value } = e.target;
    setEvaluations(formId)({
      ...evaluations?.[formId],
      sectionNotes: {
        ...(evaluations?.[formId]?.sectionNotes ?? {}),
        [ind]: value,
      },
    });
  };

  const onChange = (formId) => (ind) => (e) => {
    const { value } = e.target;
    setEvaluations(formId)({ ...evaluations?.[formId], [ind]: value });
  };

  // Determine which forms to render
  const renderedEvaluations = useMemo(() => {
    if (!schemaData || !formSchemas) return [];
    // Now let's filter to see which ones we're ready to evaluate!
    const evaluatables = [];
    // DEFECT: This is bad
    Object.values(formSchemas ?? {}).forEach((sch) => {
      if (
        sch?.evaluateWhen === status ||
        Object.keys(evaluations ?? {}).includes(sch?.id)
      ) {
        evaluatables.push({ ...sch, formId: sch?.id });
      }
    });
    // Then see if we are gonna also render the final evaluation (with the fancy bits!)
    if (
      evaluatables.length ===
      Object.values(formSchemas ?? {}).filter(
        (sch) => sch.evaluateWhen !== undefined
      ).length
    ) {
      if (schemaData?.formCompositions[0]?.evaluations !== undefined) {
        evaluatables.push({
          ...schemaData?.formCompositions[0],
          aggregateEvaluation: true,
          formId: 0,
        });
      }
    }
    // Return the evaluatables!
    return evaluatables;
  }, [schemaData, formSchemas, status, evaluations]);

  return (
    <QueryContent>
      {!selectedEvaluation &&
        renderedEvaluations.map((evaluationSchema) => (
          <EvaluationsTable
            key={`form-evaluations-table-${evaluationSchema.formId}`}
            setEvaluationForm={setSelectedEvaluation}
            formId={evaluationSchema.formId}
            formResponses={forms}
            projectId={projectId}
            status={status}
            isEvaluationTime={isEvaluationTime}
            title={
              evaluationSchema?.aggregateEvaluation
                ? "Final"
                : evaluationSchema?.name
            }
            evaluation={evaluations?.[evaluationSchema.formId] ?? {}}
            evaluationSchema={evaluationSchema}
            onChange={onChange(evaluationSchema.formId)}
            setEvalSectionNotes={setEvalSectionNotes(evaluationSchema.formId)}
            singleEvalBooleanChange={singleEvalBooleanChange(
              evaluationSchema.formId
            )}
          />
        ))}
      {/* Single selected eval form */}
      {selectedEvaluation && (
        <EvaluationsForm
          data={
            evaluations?.[selectedEvaluation.formId]?.submissions?.[
              selectedEvaluation.subId
            ]?.parts?.[selectedEvaluation.partId] ?? {}
          }
          setEvaluation={singleEvalFormChange(
            selectedEvaluation.formId,
            selectedEvaluation.subId,
            selectedEvaluation.partId
          )}
          setSelectedEvaluation={setSelectedEvaluation}
          fields={
            selectedEvaluation.evaluationFields.find(
              (pt) => pt.id == selectedEvaluation.partId
            ).fields
          }
          status={status}
          isEvaluationTime={isEvaluationTime}
        />
      )}
    </QueryContent>
  );
};

export const EvaluationsTable = ({
  formResponses,
  evaluation,
  onChange,
  title,
  evaluationSchema,
  status,
  isEvaluationTime,
  setEvaluationForm,
  setEvalSectionNotes,
  singleEvalBooleanChange,
}) => {
  const [bidderScores, setBidderScores] = useState({});
  // const formSchema = useFormSchema(projectId, formId);

  // First resolve evaluation fields based on final or nah
  const evaluationFields = useMemo(
    () =>
      (evaluationSchema?.aggregateEvaluation
        ? evaluationSchema?.evaluations?.parts
        : evaluationSchema?.evaluations) ?? [],
    [evaluationSchema]
  );

  useEffect(() => {
    if (evaluation && evaluation.submissions && evaluationFields?.length > 0) {
      let scores = {};
      Object.keys(evaluation.submissions).forEach((ev) => {
        scores[ev] = 0;
        // First we check if they pass all of the pass/fails
        // If undefined, then they obviously fail the pass/fail
        let pass = true;
        evaluationFields
          .filter((pt) => !pt.fields)
          .forEach(
            (pt) =>
              (pass =
                evaluation.submissions[ev] &&
                evaluation.submissions[ev].parts[pt.id] &&
                pass)
          );
        if (!pass) {
          scores[ev] = "FAIL";
          return;
        }
        // Now that they pass all of these, calculate their numerical score
        evaluationFields
          .filter((pt) => pt.fields)
          .forEach((pt) => {
            if (isNaN(scores[ev])) {
              return;
            }
            let fnc = Function("data", pt.scoring);
            try {
              scores[ev] +=
                (pt.weighting ? pt.weighting : 0) *
                fnc(
                  evaluation.submissions[ev] && evaluation.submissions[ev].parts
                );
            } catch {
              scores[ev] = "Not Evaluated Yet";
            }
          });
        if (!isNaN(scores[ev])) {
          scores[ev] = Math.round(scores[ev] * 100) / 100;
        }
      });
      setBidderScores(scores);
    }
  }, [evaluation, evaluationFields]);

  // Don't render section until form schema ready
  if (!evaluationSchema) {
    return null;
  }

  let i = 0; // Iterator
  let formFormatter = Function(
    "form",
    evaluationSchema.evaluations?.recommendFormat
      ? evaluationSchema.evaluations?.recommendFormat
      : "return form.id"
  );

  return (
    <QuerySection>
      <QuerySectionHeader>{title} Evaluation</QuerySectionHeader>
      <TableInputDiv>
        <thead>
          <tr>
            <TableHeader rowSpan={2}>Name</TableHeader>
            {evaluationFields
              .filter((pts) => pts.fields)
              .map((pts) => (
                <TableHeader colSpan={2} key={`${pts.id}`}>
                  {pts.name}
                </TableHeader>
              ))}
            <TableHeader rowSpan={2}>Last Modified</TableHeader>
          </tr>
          <tr>
            {evaluationFields
              .filter((pts) => pts.fields)
              .map((pts) => (
                <React.Fragment key={`pt-${pts.id}`}>
                  <TableHeader key={`${pts.id}-doc`}>Edit</TableHeader>
                  <TableHeader key={`${pts.id}-stat`}>Status</TableHeader>
                </React.Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {formResponses.map((fm) => (
            <tr key={`row-${fm.id}`}>
              <TableCell>{fm.user.name}</TableCell>
              {evaluationFields
                .filter((pts) => pts.fields)
                .map((pts) => (
                  <React.Fragment key={`docs-${pts.id}`}>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setEvaluationForm({
                            evaluationFields: evaluationFields,
                            formId: evaluationSchema?.formId,
                            subId: fm.id,
                            partId: pts.id,
                          });
                        }}
                        text={`${
                          isEvaluationTime !== "evaluate" ? "View" : "Edit"
                        } Document`}
                      />
                    </TableCell>
                    <TableCell>
                      <StatusDot
                        cmplt={
                          evaluation.submissions &&
                          evaluation.submissions[fm.id] &&
                          evaluation.submissions[fm.id].parts[pts.id]
                        }
                      />
                    </TableCell>
                  </React.Fragment>
                ))}
              <TableCell>
                {evaluation.submissions &&
                evaluation.submissions[fm.id] &&
                evaluation.submissions[fm.id].modified
                  ? `${
                      evaluation.submissions[fm.id].modified.name
                    } on ${formatDate(
                      new Date(evaluation.submissions[fm.id].modified.date)
                    )}`
                  : "Never"}
              </TableCell>
            </tr>
          ))}
        </tbody>
      </TableInputDiv>
      {/* This is where the first table ends and the next begins */}
      {evaluationSchema?.aggregateEvaluation && (
        <>
          <QuerySectionHeader>{title} Evaluation Summary</QuerySectionHeader>
          <TableInputDiv>
            <thead>
              <tr>
                <TableHeader rowSpan={2}>Weighting (%)</TableHeader>
                <TableHeader rowSpan={2}>Criteria</TableHeader>
                <TableHeader colSpan={formResponses.length}>
                  Bidders
                </TableHeader>
                <TableHeader rowSpan={2}>Notes / Comments</TableHeader>
              </tr>
              <tr>
                {formResponses.map((fm) => (
                  <TableHeader key={`head-${fm.id}`}>
                    {fm.user.name}
                  </TableHeader>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Start by covering the data type fields */}
              {evaluationFields
                .filter((pts) => pts.fields)
                .map((pts) => {
                  let evalFunc = Function("data", pts.scoring);
                  let makeCalc = (dt) => {
                    let res = "No Data";
                    if (!dt) {
                      return res;
                    }
                    try {
                      res = evalFunc(dt);
                    } catch (e) {
                      log_error(
                        "schema function custom calculation error during evaluation"
                      );
                    }
                    return !isNaN(res) ? Math.round(res * 100) / 100 : res;
                  };
                  return (
                    <tr key={`calc-${pts.id}`}>
                      <TableCell>{pts.weighting * 100}%</TableCell>
                      <TableCell>{pts.name}</TableCell>
                      {formResponses.map((fm) => (
                        <TableCell key={`pf-${fm.id}`}>
                          {makeCalc(evaluation?.submissions?.[fm.id]?.parts)}
                        </TableCell>
                      ))}
                      <TableCell>
                        <GenericField
                          data={
                            evaluation && evaluation.sectionNotes
                              ? evaluation.sectionNotes[pts.id]
                              : ""
                          }
                          onChange={setEvalSectionNotes(pts.id)}
                          disabled={!isEvaluationTime}
                        />
                      </TableCell>
                    </tr>
                  );
                })}
              {/* Now cover booleans (pass fail) */}
              {evaluationFields
                .filter((pts) => !pts.fields)
                .map((pts) => (
                  <tr key={`pf-${pts.id}`}>
                    <TableCell>Pass/Fail</TableCell>
                    <TableCell>{pts.name}</TableCell>
                    {formResponses.map((fm) => (
                      <TableCell key={`pf-${fm.id}`}>
                        <BooleanField
                          color="primary"
                          label="Pass"
                          onChange={singleEvalBooleanChange(fm.id, pts.id)}
                          data={
                            evaluation.submissions &&
                            evaluation.submissions[fm.id] &&
                            evaluation.submissions[fm.id] &&
                            evaluation.submissions[fm.id].parts[pts.id]
                              ? true
                              : false
                          }
                          disabled={!isEvaluationTime}
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      <GenericField
                        data={
                          evaluation && evaluation.sectionNotes
                            ? evaluation.sectionNotes[pts.id]
                            : ""
                        }
                        onChange={setEvalSectionNotes(pts.id)}
                        disabled={!isEvaluationTime}
                      />
                    </TableCell>
                  </tr>
                ))}
              <tr>
                {Array(3 + formResponses.length)
                  .fill()
                  .map((t) => {
                    i++;
                    return <TableCell key={i}></TableCell>;
                  })}
              </tr>
              <tr>
                <td></td>
                <TableCell>Total Scores</TableCell>
                {formResponses.map((fm) => (
                  <TableCell key={`score-${fm.id}`}>
                    {bidderScores[fm.id]
                      ? bidderScores[fm.id]
                      : "Not Evaluated"}
                  </TableCell>
                ))}
                <td></td>
              </tr>
            </tbody>
          </TableInputDiv>
          <Inputs>
            <SelectField
              label={`Recommended ${
                evaluationSchema.evaluations?.submitterLabel
                  ? evaluationSchema.evaluations.submitterLabel
                  : "Form"
              }`}
              data={evaluation.recommended}
              options={Object.fromEntries(
                formResponses.map((fm) => [fm.id, formFormatter(fm)])
              )}
              onChange={onChange("recommended")}
              disabled={!isEvaluationTime}
              fill
            />
          </Inputs>
          <Inputs>
            <GenericField
              label="Recommendation Remarks"
              onChange={onChange("remarks")}
              data={evaluation ? evaluation.remarks : ""}
              disabled={!isEvaluationTime}
              multiLine
              fill
            />
          </Inputs>
        </>
      )}
    </QuerySection>
  );
};

export const EvaluationsForm = ({
  data,
  fields,
  setSelectedEvaluation,
  setEvaluation,
  status,
  isEvaluationTime,
}) => {
  // NOTE: This schema does not live update because of our current state constraints

  return (
    <>
      <QueryContent>
        <QueryButtons>
          <Button2
            label="Back"
            onClick={() => {
              setSelectedEvaluation(undefined);
            }}
          />
        </QueryButtons>
        <QueryFields
          data={data}
          onFieldChange={setEvaluation}
          fieldSet={fields}
          status={isEvaluationTime ? "evaluate" : "readonly"}
        />
      </QueryContent>
    </>
  );
};

const SwitchContainer = styled.div``;

const QueryViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 99%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  margin: 10px;
  height: 200rem; // Force max height
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;
