import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { log_error } from "../../tools/logger";
import { ProjectQueriesContext } from "../project";

export const DataLinkReferenceContext = React.createContext({});
const DataLinkReferenceSetterContext = React.createContext(() => {});
const DataLinkLocalContext = React.createContext({});

export const DataLinkProvider = ({
  references,
  setReferences,
  localContext,
  extend,
  children,
}) => {
  // If we're "extending", then use the extension hook instead of reference args
  const [extendedReferences, extendedSetReferences] = useDataLinkExtension();

  // Also extend the local context
  const oldLocalContext = useContext(DataLinkLocalContext);

  return (
    <DataLinkLocalContext.Provider
      value={{ ...(extend ? oldLocalContext : {}), ...localContext }}
    >
      <DataLinkReferenceContext.Provider
        value={extend ? extendedReferences : references}
      >
        <DataLinkReferenceSetterContext.Provider
          value={extend ? extendedSetReferences : setReferences}
        >
          {children}
        </DataLinkReferenceSetterContext.Provider>
      </DataLinkReferenceContext.Provider>
    </DataLinkLocalContext.Provider>
  );
};

export const DataLinkProvisioner = ({ datalinkSchema, fieldId, children }) => {
  // Get Project Queries
  const queries = useContext(ProjectQueriesContext);

  // Fetch the data from the reference
  const setReferences = useContext(DataLinkReferenceSetterContext);

  // Maintain a ref state for monitoring value changes on setChange (we catch it on the way back down)
  const checkDataState = useRef(true);

  // Create callbacks to override child
  const onChange = useCallback(
    (val) => {
      // First set the reference
      setReferences((ex) => ({
        ...ex,
        [fieldId]: val,
      }));
      // Now run the children's callback
      if (children.props.onChange) {
        children.props.onChange(val);
      }
    },
    [fieldId, setReferences, children]
  );

  const setChange = useCallback(
    (callback) => {
      // First set flag for checking data
      checkDataState.current = true;
      // Now run the children's callback
      if (children.props.setChange) {
        children.props.setChange(callback);
      }
    },
    [children]
  );

  // Setup useEfect to check for data changes
  useEffect(() => {
    // If we're not checking data, then skip
    if (!checkDataState.current) {
      return;
    }
    // Check that child has a data field
    if (children.props.data === undefined) {
      return;
    }
    // Reset flag
    checkDataState.current = false;
    // Then update the reference
    setReferences((ex) => ({
      ...ex,
      [fieldId]: children.props.data,
    }));
  }, [children, fieldId, setReferences]);

  // Render the options for this provisioner based on queries in project
  const provisionableQueries = useMemo(() => {
    // Parse filter function
    const datalinkFilter = new Function(
      "query",
      datalinkSchema.filter ?? "return true"
    );
    // Parse sort function
    const datalinkSort = new Function(
      "a",
      "b",
      datalinkSchema.sort ?? "return 0"
    );
    // Parse format function
    const datalinkFormat = new Function(
      "query",
      datalinkSchema.format ?? "return query.id"
    );
    const queriesDict = {};
    try {
      // First filter the queries to those we are targeting
      const filteredQueries = queries
        .filter((query) => query.schemaId === datalinkSchema.targetSchemaId)
        .filter(datalinkFilter);
      // Sort filtered results
      filteredQueries.sort(datalinkSort);
      // Now parse into a dict with format values
      for (let query of filteredQueries) {
        queriesDict[query.id] = datalinkFormat(query);
      }
    } catch (e) {
      log_error("Error parsing datalink provisioner", e);
    }
    return queriesDict;
  }, [queries, datalinkSchema]);

  // If children count is not one, we have a problem
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "DataLinkReferencer must have exactly one child component, but has " +
        React.Children.count(children)
    );
  }

  // Return the child with new options & callback
  return React.cloneElement(children, {
    options: provisionableQueries,
    data: children.props.options
      ? children.props.data
      : provisionableQueries[children.props.data],
    onChange: onChange,
    setChange: setChange,
  });
};

export const DataLinkReferencer = ({ datalinkSchema, children }) => {
  // Hold all queries in context
  const queries = useContext(ProjectQueriesContext);

  // Fetch the data from the reference
  const references = useContext(DataLinkReferenceContext);

  // Track changes in reference
  const referenceChanged = useRef(false);

  // Reference should be one data linked query from the data provisioner
  const referenceResult = useMemo(() => {
    referenceChanged.current = true;
    if (datalinkSchema && datalinkSchema.targetProvisionerId) {
      const reference = references?.[datalinkSchema.targetProvisionerId];
      if (reference) {
        // Now see about formatting
        if (datalinkSchema.manipulator) {
          // Retrieve the query
          const query = queries.find((q) => q.id === reference);
          // Parse the manipulator function
          const datalinkManipulator = new Function(
            "query",
            datalinkSchema.manipulator
          );
          // Run the manipulator
          try {
            return datalinkManipulator(query);
          } catch (e) {
            log_error("Error parsing datalink manipulator", e);
          }
        }
        return reference;
      }
    }
    return null;
  }, [references, datalinkSchema, queries]);

  // Now we can useEffect the reference to set the change
  useEffect(() => {
    if (!referenceChanged.current) {
      return;
    }
    referenceChanged.current = false;
    // Now run the children setters
    if (children.props.setChange) {
      children.props.setChange((ex) => referenceResult ?? "");
    } else if (children.props.onChange) {
      children.props.onChange(referenceResult ?? "");
    }
  }, [referenceResult, children]);

  // If children count is not one, we have a problem
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "DataLinkReferencer must have exactly one child component, but has " +
        React.Children.count(children)
    );
  }

  // Return the child disabled
  return React.cloneElement(children, { disabled: true, editable: false });
};

export const DataLinkAggregator = ({ datalinkSchema, children }) => {
  // Hold all queries in context
  const queries = useContext(ProjectQueriesContext);

  // Fetch the context for the local datalink
  const localContext = useContext(DataLinkLocalContext);

  // Setup reference for data commit
  const dataChanged = useRef(undefined);
  const resultCommitted = useRef(false);

  // Reference should be one data linked query from the data provisioner
  const referenceResult = useMemo(() => {
    dataChanged.current = true;
    if (datalinkSchema && datalinkSchema.reducer) {
      // Once we're here, let's get the queries & fitler by the target schema
      const targetQueries = queries.filter(
        (q) => q.schemaId === datalinkSchema.targetSchemaId
      );
      // Now we need to parse the filter function
      const datalinkFilter = new Function(
        "query",
        "ctx",
        datalinkSchema.filter ?? "return true"
      );
      // Then parse the reducer function
      const datalinkReducer = new Function(
        "acc",
        "query",
        "ctx",
        datalinkSchema.reducer ?? "return acc"
      );
      // Now we can reduce the queries
      try {
        return targetQueries
          .filter((q) => datalinkFilter(q, localContext))
          .reduce((acc, q) => datalinkReducer(acc, q, localContext), null);
      } catch (e) {
        log_error("Error parsing datalink aggregator", e);
      }
    }
    return null;
  }, [localContext, datalinkSchema, queries]);

  // Now we can useEffect the reference to set the change
  useEffect(() => {
    if (!dataChanged.current || resultCommitted.current) {
      return;
    }
    dataChanged.current = false;
    resultCommitted.current = true;
    // Now run the children setters
    if (children.props.setChange) {
      children.props.setChange((ex) => referenceResult ?? "");
    } else if (children.props.onChange) {
      children.props.onChange(referenceResult ?? "");
    }
  }, [referenceResult, children]);

  // If children count is not one, we have a problem
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "DataLinkReferencer must have exactly one child component, but has " +
        React.Children.count(children)
    );
  }

  return React.cloneElement(children, { disabled: true, editable: false });
};

// Hook
export const useDataLinkExtension = () => {
  // Pull current references
  const { references } = useContext(DataLinkReferenceContext);
  // Now generate independent state for sub-references
  const [rowReferences, setRowReferences] = useState(references);

  // Synchronizer for references
  useEffect(() => {
    // This order is important so that base references don't overwrite sub-refs defined
    setRowReferences((ex) => ({ ...references, ...ex }));
  }, [references]);

  return [rowReferences, setRowReferences];
};
