import React from "react";
import { useState } from "react";
import styled from "styled-components";
import CSSTransition from "react-transition-group/CSSTransition";
import "./navbar.css";

// Images
import Logo1 from "../graphics/logo-with-text.svg?url";
import MenuIcon from "../graphics/menu-icon.png";
import { Close } from "@mui/icons-material";

export default ({ navHome, navAbout, navWhy, navUseCases, navPricing }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <NavbarContainer>
        <NavbarSides />
        <NavbarContent>
          <Logo src={Logo1} />
          <NavbarCenter>
            <NavbarLink onClick={navHome}>Home</NavbarLink>
            <NavbarLink onClick={navAbout}>About</NavbarLink>
            <NavbarLink onClick={navWhy}>Why use Query-It</NavbarLink>
            <NavbarLink onClick={navUseCases}>Use Cases</NavbarLink>
            <NavbarLink onClick={navPricing}>Pricing</NavbarLink>
          </NavbarCenter>
          <A1 href="mailto:info@queryitapp.com?subject=Scheduling A Demo&body=Hi, I'd like to schedule a Demo to explore Query-It's features and learn how it can benefit my business. Thanks!">
            <Button1 type="button">Schedule a Demo</Button1>
          </A1>

          {/* default display is none */}
          {menuOpen ? (
            <CloseWrapper>
              <Close
                style={{ fontSize: "40px" }}
                onClick={() => setMenuOpen(!menuOpen)}
              />
            </CloseWrapper>
          ) : (
            <MIcon src={MenuIcon} onClick={() => setMenuOpen(!menuOpen)} />
          )}
        </NavbarContent>
        <NavbarSides>
          <A2 href="/app/login">
            <Button2>Login</Button2>
          </A2>
        </NavbarSides>
      </NavbarContainer>

      <CSSTransition
        in={menuOpen}
        timeout={500}
        classNames="menu"
        unmountOnExit
      >
        <Menu>
          <MenuOption
            onClick={() => {
              navHome();
              setMenuOpen(false);
            }}
          >
            Home
          </MenuOption>
          <MenuOption
            onClick={() => {
              navAbout();
              setMenuOpen(false);
            }}
          >
            About
          </MenuOption>
          <MenuOption
            onClick={() => {
              navWhy();
              setMenuOpen(false);
            }}
          >
            Why use Query-It
          </MenuOption>
          <MenuOption
            onClick={() => {
              navUseCases();
              setMenuOpen(false);
            }}
          >
            Use Cases
          </MenuOption>
          <MenuOption
            onClick={() => {
              navPricing();
              setMenuOpen(false);
            }}
          >
            Pricing
          </MenuOption>
          <AMenuLogin href="/app/login">
            <MenuOptionLogin>
              <p style={{ marginLeft: "17px" }}>Login</p>
            </MenuOptionLogin>
          </AMenuLogin>
        </Menu>
      </CSSTransition>
    </>
  );
};

const NavbarContainer = styled.div`
  background-color: black;
  height: 106px; // 10%
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 20;

  @media (max-width: 1100px) {
    height: 87px;
  }
`;

const Logo = styled.img`
  height: 75%;

  @media (max-width: 1100px) {
    height: 54px;
    margin-left: 20px;
  }
`;

const NavbarContent = styled.div`
  background-color: white;
  width: 86%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  user-select: none;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const NavbarSides = styled.div`
  background-color: white;
  height: 100%;
  width: 7%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1100px) {
    display: none;
  }
`;
const A1 = styled.a`
  // wrapper for Button1 - Button1's height, width, font-size styles moved here
  height: 45%;
  width: fit-content;
  font-size: 105%;

  @media (max-width: 1430px) and (min-width: 1101px) {
    height: 40%;
    font-size: 95%;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;
const Button1 = styled.button`
  height: 100%;
  width: 100%;
  border: solid #37227a;
  border-radius: 5px;
  background-color: #37227a;
  color: white;
  font-size: 105%;
  user-select: none;
  white-space: nowrap;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      border: solid #37227a;
      color: #37227a;
    }
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const A2 = styled.a`
  // wrapper for Button2 - Button2's height, width, font-size styles moved here
  height: 45%;
  width: 60%
  font-size: 105%;

  @media (max-width: 1430px) and (min-width: 1101px) {
    height: 40%;
    font-size: 90%;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const Button2 = styled.button`
  height: 100%;
  width: 100%;
  border: solid #37227a;
  border-radius: 5px;
  background-color: white;
  color: #37227a;
  margin-left: 5px;
  font-size: 105%;
  user-select: none;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: #37227a;
      border: solid #37227a;
      color: white;
    }
  }
`;

const NavbarCenter = styled.div`
  width: 38%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #3c287c;

  @media (max-width: 1100px) {
    display: none;
  }

  @media (min-width: 1101px) and (max-width: 1400px) {
    width: 45%;
  }
`;
const NavbarLink = styled.div`
  text-decoration: underline 5px rgba(239, 20, 150, 0);
  text-underline-offset: 50%;
  transition: text-decoration-color 400ms;

  &:hover {
    cursor: pointer;
    text-decoration-color: rgba(239, 20, 150, 1);
  }
`;
const MIcon = styled.img`
  display: none;

  @media (max-width: 1100px) {
    display: block;
    height: 35px;
    margin-right: 20px;
    cursor: pointer;
  }
`;
const CloseWrapper = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: block;
    margin-right: 20px;
    cursor: pointer;
    color: #37227a;
  }
`;
const Menu = styled.div`
  display: none;

  @media (max-width: 1100px) {
    padding-top: 87px;
    z-index: 10;
    display: block;
    width: 100%;
    min-height: fit-content;
    background-color: white;
    position: fixed;
    box-shadow: 0 8px 6px -6px black;
  }
`;
const MenuOption = styled.div`
  width: 50%;
  height: 50px;
  margin-left: 20px;
  cursor: pointer;
`;
const AMenuLogin = styled.a`
  // A wrapper for MenuOptionLogin. MenuOptionLogin's designated height, width, etc. were moved here
  width: 100%;
  height: 50px;
`;
const MenuOptionLogin = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
  color: #37227a;
  text-align: left;
  // margin-left: 17px;
  font-size: 17px;
  font-weight: bold;
  margin-top: -50px;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: #37227a;
      color: white;
      cursor: pointer;
    }
  }
`;
