import {
  selector,
  atom,
  useRecoilValue,
  RecoilState,
  useSetRecoilState,
  useResetRecoilState,
  Resetter,
  SetterOrUpdater,
} from "recoil";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../common/query";

import { differenceInMinutes } from "date-fns";
import stringSimilarity from "string-similarity";

import { parse_on_fields, get_query_due_date } from "../tools/forms";
import { parse_db_timestamp, is_overdue } from "../tools";

import { get_start_end_date } from "../tools";

export enum ModifyFlag {
  NONE = "none",
  SCHEME = "scheme"
}

export const atomicUseFilterableElement = (atomicSet: any) => {
  // Setup an atomic pre-calculated selector for the filterable elements
  const filterableSet = selector({
    key: "filterableSet_" + atomicSet.key,
    get: ({ get }) => {
      const elements = get(atomicSet);
      // Now we add a a property to each element
      // This property is an encoded string used for filtering later
      return FilterManager.prepareFilterableElements((elements as any[]) ?? []);
    },
  });

  // Setup an atomic filtered selector for the atomicSet
  const filteredSet = selector({
    key: "filteredSet_" + atomicSet.key,
    get: ({ get }) => {
      const elements = get(filterableSet);
      const filters = get(FilterState);
      return FilterManager.applyFilters(filters, (elements as any[]) ?? []);
    },
  });

  // Setup an atom for the filter data
  const FilterState = atom({
    key: "filterState_" + atomicSet.key,
    default: FilterManager.DEFAULT_STRUCTURE,
  });
  const filterManager = new FilterManager(FilterState);

  // We return a read-only filtered set selector
  return [filteredSet, filterManager];
};

export class FilterManager {
  private filterState: RecoilState<any>;
  private filterScheme: RecoilState<any>;

  // Stored atomic hooks
  private filterStateResetter: Resetter | undefined;
  private filterStateSetter: SetterOrUpdater<any> | undefined;
  private filterSchemeSetter: SetterOrUpdater<any> | undefined;

  constructor(atomicSet: RecoilState<any>) {
    this.filterState = atomicSet;
    // Now we'll spawn the atom for the filter scheme
    this.filterScheme = atom<any>({
      key: "filterScheme_" + this.filterState.key.replace("filterState_", ""),
      default: {},
    });
  }

  public init() {
    // Setup atomic hooks in DOM
    this.filterStateResetter = useResetRecoilState(this.filterState);
    this.filterStateSetter = useSetRecoilState(this.filterState);
    this.filterSchemeSetter = useSetRecoilState(this.filterScheme);
  }

  private checkInit() {
    if (
      !this.filterStateResetter ||
      !this.filterSchemeSetter ||
      !this.filterStateSetter
    ) {
      throw new Error("FilterManager not initialized in DOM");
    }
  }

  /**
   *
   * UseEffect triggered in the dom to manage the filter's scheme for rendering options
   * The format of this should be a formatted filter scheme.
   * If you have a regular fieldSet from a schema, use the static function to convert to this before calling
   *
   * The "schema" datastructure here is a 1d array of objects where each has a set of properties
   */
  public setFilterScheme(
    schema: FilterScheme[],
    prefilters: FilterProperty[] = [],
    defaultFilters: FilterProperty[] = []
  ) {
    this.checkInit();
    // SOFT reset filter state
    this.filterStateSetter!((ex: any) => ({
      ...FilterManager.DEFAULT_STRUCTURE,
      ready: true,
      awaitFlag: ModifyFlag.NONE,
      options: { ...ex.options },
    }));
    // Set the filter scheme
    this.filterSchemeSetter!(
      schema?.map((f: FilterScheme) => ({
        ...f,
        category: f.category ?? "General",
      }))
    ); // We default undefined category labels to "General"
    // Then set any prefilters as well (and restore previously cached options)
    this.filterStateSetter!((ex: any) => ({ ...ex, preFilters: prefilters }));
    // Finish by defaulting any filters
    this.filterStateSetter!((ex: any) => ({
      ...ex,
      dataFields: defaultFilters.reduce(
        (acc: any, curr: FilterProperty) => ({
          ...acc,
          [curr.target]: curr,
        }),
        {}
      ),
    }));
  }

  public modifyAsync(flag: ModifyFlag) {
    this.checkInit();
    this.filterStateSetter!((ex: any) => ({ ...ex, awaitFlag: flag }));
  }

  public clearFilterScheme() {
    this.checkInit();
    this.filterStateSetter!(FilterManager.DEFAULT_STRUCTURE);
    this.filterSchemeSetter!({});
  }

  public setFilterOptions(options: any) {
    this.checkInit();
    this.filterStateSetter!((ex: any) => ({
      ...ex,
      options: { ...ex.options, ...options },
    }));
  }

  public clearFilterOptions() {
    this.checkInit();
    this.filterStateSetter!((ex: any) => ({
      ...ex,
      options: { ...FilterManager.DEFAULT_STRUCTURE.options },
    }));
  }

  /*
   * Filter Control Functions
   * NOTE: Filter control functions MUST be called from within the DOM
   */
  setFilterProperty(scheme: FilterScheme, value: any, options: any = {}) {
    this.checkInit();
    // Define a cleanup for clearing undefined filters
    const cleanUp = (dataFields: any) => {
      return Object.keys(dataFields).reduce(
        (acc: any, curr: string) => ({
          ...acc,
          ...(dataFields[curr] === undefined
            ? {}
            : { [curr]: dataFields[curr] }),
        }),
        {}
      );
    };
    // Based on the scheme field, let's set the value :)
    switch (scheme.type) {
      case "date":
        //   var dateIndex = (options.term === "end") ? 1 : 0;
        //   this.filterStateSetter!((ex: any) => ({...ex, dataFields: cleanUp({...ex.dataFields,
        //     ...(value == '' && (ex.dataFields[scheme.index]?.value ?? [null, null])[dateIndex == 1 ? 0 : 1] == null)
        //       ? {[scheme.index]: undefined}
        //       : {[scheme.index]: {
        //         target: scheme.index,
        //         value: [
        //           dateIndex === 1 ? (ex.dataFields[scheme.index]?.value ?? [null, null])[0] : value,
        //           dateIndex === 0 ? (ex.dataFields[scheme.index]?.value ?? [null, null])[1] : value
        //         ],
        //         type: "date"
        //       }}
        //   })}));
        // We have a component that either outputs either a [startDate, endDate] OR a string. No need to process/distinguish at this step
        this.filterStateSetter!((ex: any) => ({
          ...ex,
          dataFields: cleanUp({
            ...ex.dataFields,
            ...(value == "all"
              ? { [scheme.index]: undefined }
              : {
                  [scheme.index]: {
                    target: scheme.index,
                    value: value,
                    type: "date",
                  },
                }),
          }),
        }));
        break;
      case "select":
        this.filterStateSetter!((ex: any) => ({
          ...ex,
          dataFields: cleanUp({
            ...ex.dataFields,
            ...((ex.dataFields[scheme.index]?.value ?? []).includes(value) &&
            (ex.dataFields[scheme.index]?.value ?? []).length === 1
              ? { [scheme.index]: undefined } // Condition for clearing this filter field
              : {
                  [scheme.index]: {
                    target: scheme.index,
                    value: [
                      // First remove the value if present
                      ...(ex.dataFields[scheme.index]?.value ?? []).filter(
                        (f: string) => f !== value
                      ),
                      // Then if the value WAS present, then don't add it back, otherwise add it :)
                      ...((ex.dataFields[scheme.index]?.value ?? []).includes(
                        value
                      )
                        ? []
                        : [value]),
                    ],
                    type: "select",
                  },
                }),
          }),
        }));
        break;
      case "user": // Select dropdown returns differently than individual bools, so we'll logic it separately
        // Here we expect the value to be the array to set to. We'll use undefined if length < 1
        this.filterStateSetter!((ex: any) => ({
          ...ex,
          dataFields: cleanUp({
            ...ex.dataFields,
            ...(value.length < 1
              ? { [scheme.index]: undefined }
              : {
                  [scheme.index]: {
                    target: scheme.index,
                    value: value,
                    type: "select", // We use select for both of these array types in post-filtering
                  },
                }),
          }),
        }));
        break;
      case "boolean":
        // This is just a toggle, so if we get a change in, set it if true, clear it if false
        this.filterStateSetter!((ex: any) => ({
          ...ex,
          dataFields: cleanUp({
            ...ex.dataFields,
            ...(value
              ? {
                  [scheme.index]: {
                    target: scheme.index,
                    value: scheme.eval,
                    type: "boolean",
                  },
                }
              : { [scheme.index]: undefined }),
          }),
        }));
        break;
      default:
        break;
    }
  }

  clearFilterProperty(scheme: FilterScheme) {
    this.checkInit();
    // Define a cleanup for clearing undefined filters
    const cleanUp = (dataFields: any) => {
      return Object.keys(dataFields).reduce(
        (acc: any, curr: string) => ({
          ...acc,
          ...(dataFields[curr] === undefined
            ? {}
            : { [curr]: dataFields[curr] }),
        }),
        {}
      );
    };
    // Now set the index to undefined
    this.filterStateSetter!((ex: any) => ({
      ...ex,
      dataFields: cleanUp({ ...ex.dataFields, [scheme.index]: undefined }),
    }));
  }

  setFilterString(query: string) {
    this.checkInit();
    this.filterStateSetter!((ex: any) => ({ ...ex, filterString: query }));
  }

  clearDataFilters() {
    this.checkInit();
    this.filterStateSetter!((ex: any) => ({ ...ex, dataFields: {} }));
  }

  // Filter state reader for filling in the filter scheme
  useFilterState() {
    return useRecoilValue(this.filterState);
  }

  // Filter scheme reader
  useFilterScheme() {
    return useRecoilValue(this.filterScheme);
  }

  useFilterSchemeField(field: string) {
    const sch = useRecoilValue(this.filterScheme);
    return sch.find((f: FilterScheme) => f.index === field);
  }

  useFilterActive() {
    const st = useRecoilValue(this.filterState);
    return Object.keys(st.dataFields).length > 0;
  }

  /**
   * String filtering is hard to do well
   * If we don't do any pre-computation, it's costly at the time of filtering as well.
   * To mitigate this, we do this computation on data-set change instead, when the focus of the user is not usually
   * on the data or it's time to load (because this computation is memoized in the background and not tied to render directly)
   *
   * Practically this just means we're doing some extra work ahead of time, and not re-doing it when we don't need to
   *
   * Note: This step is NOT mandatory in the filtermanager filtering process
   */
  static prepareFilterableElements(elements: any[]) {
    return elements.map((elem: any) => {
      // Elem needs to be an object of some kind, and all we're going to do is product an "objectstring"
      // This is a string that composes every element of the object all together

      // We prep a recursive function first
      const objectToString = (obj: any) => {
        // Start with empty string
        let objStr = "";
        // Now iterate over obj values
        Object.values(obj).forEach((v) => {
          // If it's an object, we recurse
          if (typeof v === "object") {
            objStr += objectToString(v ?? {});
          } else {
            // Otherwise, we add the value to the string
            objStr += " " + (Array.isArray(v) ? (v as Array<any>).join("") : v);
          }
        });
        // Finally return
        return objStr;
      };

      // Then apply the function to the element
      return { ...elem, __Stringcomposition__: objectToString(elem) };
    });
  }

  /** Prep a Schema for use in the filter scheme
   *
   * The plan is that whatever comes in here will have a "fields" attribute that is an array of "fields"
   * (which is as defined with the mess of fields, sections, nests, etc.)
   * We'll then first process some common high-level elements (status, creator, etc.)
   * And then ALL of the fields, adding the types of fields that we like to filter by
   */
  static prepSchema(schema: any) {
    const preparedSchema: any[] = [];

    // Before we parse anything, get the schema status set
    const statusSet =
      schema?.type === "custom"
        ? schema.customStatusSet
        : ((TEMPLATE_SCHEMA_STATUSSETS as any)[schema?.type] as {
            [key: string]: string[];
          }) ?? {};
    const statusOptions = Object.values(statusSet)
      .filter((v: any) => v.status !== "new") // Filtered query cannot have "new" as a status name
      .reduce(
        (acc: { [key: string]: string }, curr: any) => ({
          ...acc,
          [curr.status]: curr.name,
        }),
        {}
      );

    // Prep an eval function for overdue
    const dueDateEval = (queryData: any) => {
      // Get due date from data
      const dueDate = parse_db_timestamp(get_query_due_date(queryData, schema));
      // now, use the same condition as queryCounts in schemas.js
      return (
        dueDate && is_overdue(dueDate) && queryData.closeTime === undefined
      );
    };

    // First we'll parse the non-"fields" elements
    // These elements are pre-defined. Could be made a dynamic with a default later?
    const QUERY_STANDARD_DATAFIELDS = [
      {
        index: "status",
        name: "Status",
        type: "select",
        options: statusOptions,
        category: "General",
      },
      { index: "creator", name: "Creator", type: "user", category: "General" },
      {
        index: "createTime",
        name: "Create Time",
        type: "date",
        category: "General",
      },
      {
        index: "closeTime",
        name: "Close Time",
        type: "date",
        category: "General",
      },
      {
        index: "overdue",
        name: "Overdue Only",
        type: "boolean",
        eval: dueDateEval,
        category: "General",
      },
    ];

    preparedSchema.push(...QUERY_STANDARD_DATAFIELDS);

    // Then parse the "fields" elements using the standard tools we've used elsewhere
    parse_on_fields(schema?.fields ?? [], (field: any) => {
      // Choose acceptable field types
      if (
        ["date", "select", "checkbox", "radio", "boxset"].includes(field.type)
      ) {
        // Determine FilterScheme type from field type
        const type = field.type === "date" ? "date" : "select";
        // If we need to parse conditional options, do it now
        let options = null;
        if (type === "select") {
          if (field.conditionalOn !== undefined) {
            // We'll need to run a parse reduce on the conditional option set
            options = Object.keys(field.options).reduce(
              (acc, currKey) => ({ ...acc, ...field.options[currKey ?? ""] }),
              {}
            );
          } else {
            // We can just set the options
            options = field.options ?? {};
          }
        }
        // Then add to prep
        preparedSchema.push({
          name: field.name,
          type: type,
          index: `data.${field.id}`,
          category: schema.name,
          // Optionally include the options for the field
          ...(type === "select" ? { options: options } : {}),
        });
      } else {
        return;
      }
    });

    // We'll also setup a single prefilter to match the schema id to the query's Id for these cases
    const prefilters: { [key: string]: FilterProperty } = {
      schemaId: {
        target: "schemaId",
        type: "select",
        value: [schema?.id],
      },
    };

    return [preparedSchema, prefilters];
  }

  // Filter Manager Applicator Function (to be applied when filterstate is read)
  static applyFilters(filters: any, set: any[]) {
    // If not ready, return nothing
    if (!filters.ready) {
      return undefined;
    }
    // If ready but await is flagged, return nothing if we're not ignoring the flag
    if (filters.awaitFlag !== ModifyFlag.NONE && !filters.options?.ignoreFlags?.includes(filters.awaitFlag)) {
      return undefined;
    }
    // Now run the filtering!
    return (
      set
        // First run a pre-filter application
        .filter((item: any) => {
          // TODO: Maybe move this to "prep filterable elements?" who knows.
          // check disablePrefilters option to see if we want to do this
          if (filters.options.disablePreFiltering) {
            return true;
          }
          // Now start
          let filterSchemePassed = Object.values(filters.preFilters).some(
            (filter: any) => {
              // First get the data element from the item
              const dataElement = filter.target
                .split(".")
                .reduce((acc: any, curr: string) => acc[curr], item);
              switch (filter.type) {
                case "select":
                  return filter.value.includes(dataElement); // Return true if element should pass
                case "date":
                  const start = new Date(filter.value[0]);
                  const end = new Date(filter.value[1]);
                  const data = new Date(dataElement);
                  return (
                    filter.value.includes(null) ||
                    (differenceInMinutes(end, data) > 0 &&
                      differenceInMinutes(data, start) > 0)
                  );
                default:
                  return false;
              }
            }
          );
          // Fail the object if we don't pass the filter scheme and there is more than one filter scheme
          if (
            !filterSchemePassed &&
            Object.keys(filters.preFilters).length > 0
          ) {
            return false;
          }
          return true;
        })
        // Now apply the ad-hoc filters
        .filter((item: any) => {
          // 1. Apply the string search
          if (filters.filterString !== null && filters.filterString !== "") {
            // TODO: This likely needs improvement
            let stringScore = stringSimilarity.compareTwoStrings(
              filters.filterString,
              item.__Stringcomposition__
            );
            stringScore *= item.__Stringcomposition__
              .toLowerCase()
              .includes(filters.filterString)
              ? 4
              : 1;
            stringScore =
              (stringScore * 100) / (item.__Stringcomposition__.length * 0.5); // Normalize
            const comparisonFactor =
              10 /
              (item.__Stringcomposition__.length * filters.filterString.length);
            if (stringScore < comparisonFactor) {
              return false;
            }
          }
          // 2. Apply the data fields scheme
          let filterSchemePassed = Object.values(filters.dataFields).every(
            (filter: any) => {
              // First get the data element from the item
              const dataElement = filter.target
                .split(".")
                .reduce((acc: any, curr: string) => acc[curr], item);
              switch (filter.type) {
                case "select":
                  return filter.value.includes(dataElement); // Return true if element should pass
                case "date":
                  const dateRange = get_start_end_date(filter.value);
                  const start = dateRange[0];
                  const end = dateRange[1];
                  let data = parse_db_timestamp(dataElement);
                  if (typeof data === "string") {
                    return false; // We ignore data that isn't formatted properly for filtering
                  }
                  return (
                    filter.value.includes(null) ||
                    (differenceInMinutes(end, data) > 0 &&
                      differenceInMinutes(data, start) > 0)
                  );
                case "boolean":
                  return filter?.value(item);
                default:
                  return false;
              }
            }
          );
          // Fail the object if we don't pass the filter scheme and there is more than one filter scheme
          if (
            !filterSchemePassed &&
            Object.keys(filters.dataFields).length > 0
          ) {
            return false;
          }
          return true;
        })
    );
  }

  // The default data structure for the atom's data
  static DEFAULT_STRUCTURE = {
    preFilters: {} as { [key: string]: FilterProperty }, // This is immutable when filter is operating
    dataFields: {} as { [key: string]: FilterProperty },
    filterString: null,
    options: {
      disablePreFiltering: false,
    },
    ready: false,
    awaitFlag: ModifyFlag.NONE,

    // Sort not currently supported, maybe another time
    // sort: {
    //   by: 'name',
    //   direction: 'asc'
    // }
  };
}

interface FilterScheme {
  name: string;
  index: string; // Place in the input set data where the property is located
  type: string; // select, date, user,
  category?: string; // Used to sort filter fields cleanly

  // select type
  options?: { [key: string]: string };
  // boolean type
  eval?: (queryData: any) => boolean; // Function to call to filter queries when enabled
}

interface FilterProperty {
  target: string; // "index" from the FilterScheme
  value: string | any[] | Date; // The value to filter by
  type: string; // select, date (extensible for >, <, etc?) --> NOTE: For FilterProperty, user is just a select
}