import React, { useState, useContext, useEffect } from "react";

import { QuerySection, QuerySectionHeader } from "../query";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Collapse,
  Tab,
  Tabs,
  Box,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BadgeContainer } from "../../../ui/decorations";

import {
  useFormComposite,
  useFormData,
  useFormDatasets,
  useFormSchemaset,
  useForms,
} from "../../../../hooks/forms";
import { Button2 } from "../../../ui/buttons";
import { ProjectReferenceContext } from "../..";
import { TabContext, TabPanel, TabPanelContainer } from "../actions";
import { QueryFields } from "../fields";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const ViewFormSetterContext = React.createContext(() => {});

export default ({ formComposition, queryData, schemaData, query }) => {
  const [selectedForm, setSelectedForm] = useState(undefined); // DEFECT: TEMP

  if (selectedForm !== undefined) {
    return (
      <ViewFormSetterContext.Provider value={setSelectedForm}>
        <ShowForm
          selectedForm={selectedForm}
          formSet={
            schemaData?.formCompositions?.[selectedForm.compositionIndex]
              ?.includedForms
          }
          formComposite={query.forms.form(selectedForm?.id)}
        />
      </ViewFormSetterContext.Provider>
    ); // DEFECT: TEMP
  }

  return (
    <ViewFormSetterContext.Provider value={setSelectedForm}>
      <QuerySection>
        <QuerySectionHeader>
          {formComposition.title} Management
        </QuerySectionHeader>
        <ExistingFormSet formSchema={formComposition} query={query} />
        {/* TODO: We'll add a pane before the individual form user table here with some settings for the form (extensions, etc.) */}
      </QuerySection>
    </ViewFormSetterContext.Provider>
  );
};

const ExistingFormSet = ({ formSchema, query }) => {
  const forms = useForms(query);

  return (
    <TableContainer style={{ width: "90%" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell>Name</TableCell>
            <TableCell>Form Status</TableCell>
            <TableCell>Last Viewed</TableCell>
            <TableCell>Actions Required</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forms?.map((form) => (
            <FormRow key={form.id} form={form} queryId={query.ref.id} />
          ))}
          {forms && forms?.length < 1 && (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: "center" }}>
                No forms issued for the query
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FormRow = ({ form, queryId }) => {
  const [open, setOpen] = useState(false);
  const rowStyle = useRowStyles();

  const setForm = useContext(ViewFormSetterContext);

  // TEMP
  const project = useContext(ProjectReferenceContext);

  // REMOVE: TEMP
  const [formComposition, formData] = useFormComposite(
    project.ref.id,
    queryId,
    form.id
  );
  const formDatasets = useFormDatasets(formComposition); // DEFECT: This will stop working as soon as security exists

  const formsViewed =
    formDatasets?.find((fm) => fm?.firstViewer !== undefined)?.firstViewTime ??
    "Never";

  return (
    <>
      <TableRow className={rowStyle.root}>
        {/* <TableCell><IconButton onClick={() => setOpen(ex => !ex)}>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton></TableCell> */}
        <TableCell>{form?.user?.name}</TableCell>
        <TableCell>
          <BadgeContainer style={{ width: "fit-content" }}>
            {form?.status}
          </BadgeContainer>
        </TableCell>
        <TableCell>{formsViewed}</TableCell>
        {/* Actions is where we'll put a badge for submission extension requests, etc. */}
        <TableCell>None</TableCell>
        <TableCell>
          <Button2
            size="small"
            label="View Form"
            onClick={() => setForm(form)}
          />
        </TableCell>
      </TableRow>
      {/* Collapsable row down here */}
      <TableRow>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={5}>
          <Collapse in={open} unmountOnExit>
            <Button2 label="View Form" />
            <Button2
              label="IDK"
              onClick={() =>
                console.log(
                  `http://localhost:1234/form/${project.ref.id}/${queryId}/${form.id}?token=${form.token}&name=developer`
                )
              }
            />
            <Button2 label="Washe" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ShowForm = ({ selectedForm, formSet, formComposite }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  // 2. Pull the datasets for reading (we'll allow each pane to pull its own for writing later)
  const formDatasets = useFormDatasets(formComposite);
  // 2.5. Pull schemaPaths from the formDatasets
  const [formSchemaPaths, setFormSchemaPaths] = useState(undefined);
  useEffect(() => {
    // Assign Schema Paths state
    setFormSchemaPaths(formDatasets?.map((d) => d.schemaPath));
  }, [formDatasets]);
  // 3. Pull the form schemas
  const formSchemas = useFormSchemaset(formSchemaPaths); // This is a dict :)

  const setSelectedForm = useContext(ViewFormSetterContext);

  if (!formSchemas || !formDatasets)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext.Provider value={selectedTab}>
        <Tabs
          value={selectedTab}
          onChange={(e, i) => {
            if (i == 0) {
              setSelectedForm(undefined);
            } else {
              setSelectedTab(i);
            }
          }}
        >
          <Tab label="Close" value={0} color="primary" />
          {formDatasets?.map((formData, i) => (
            <Tab
              label={formSchemas[formData?.id].name}
              value={i + 1}
              key={formData?.id}
            />
          ))}
        </Tabs>
        {formDatasets?.map((formData, i) => (
          <TabPanel value={i + 1} index={i + 1} key={formData?.id}>
            <TabPanelContainer>
              <QueryFields
                data={formData.data}
                fieldSet={formSchemas[formData?.id].fields}
                preview
              />
            </TabPanelContainer>
          </TabPanel>
        ))}
      </TabContext.Provider>
    </Box>
  );
};

const FormPanel = ({ formId }) => {
  return "woof";
};
