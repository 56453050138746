import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import CSSTransition from "react-transition-group/CSSTransition";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CURRENT_YEAR } from "../../common/dates";

export const MobileDropdown = ({ children, title, close }) => {
  const [anim, setAnim] = useState(false);

  useEffect(() => setAnim(true), []);

  return (
    <CSSTransition in={anim} timeout={300} classNames="mdrop" onExited={close}>
      <MobileDropdownContainer>
        <MobileDropdownHeader>
          <MobileDropdownHeaderTitle>{title}</MobileDropdownHeaderTitle>
          <IconButton onClick={() => setAnim(false)}>
            <Close />
          </IconButton>
        </MobileDropdownHeader>
        <ProjectList>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                onClick: () => {
                  if (child.props.onClick) child.props.onClick();
                  setAnim(false);
                },
              });
            }
          })}
        </ProjectList>
        <CopyrightNotice>
          <CopyrightWord>&copy; Copyright {CURRENT_YEAR}, Query-It Inc.</CopyrightWord>
        </CopyrightNotice>
      </MobileDropdownContainer>
    </CSSTransition>
  );
};

export const MobileDropdownRowLink = ({
  icon,
  title,
  to,
  onClick,
  selected,
}) => {
  return (
    <MobileDropdownLink to={to} onClick={onClick}>
      {selected ? <b>{title}</b> : title}
    </MobileDropdownLink>
  );
};

export const MobileDropdownRowButton = ({ icon, title, onClick }) => {
  return <MobileDropdownButton onClick={onClick}>{title}</MobileDropdownButton>;
};

const MobileDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  min-width: 100%;
  height: 100%;
  z-index: 1200;
  top: 0;
  left: 0;

  background: ${(props) => props.theme.palette.background.step50};

  &.mdrop-enter {
    transform: translateX(100%);
  }
  &.mdrop-enter-active {
    transform: translateX(0);
    transition: transform 0.3s;
  }
  &.mdrop-exit {
    transform: translateX(0);
  }
  &.mdrop-exit-active {
    transform: translateX(100%);
    transition: transform 0.3s;
  }
`;

const MobileDropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 26px !important;
  max-height: 40px;
  font-family: ${(props) => props.theme.palette.font};
`;

const MobileDropdownHeaderTitle = styled.div`
  margin: 8px;
`;

const MobileDropdownLink = styled(Link)`
  display: flex;
  border-bottom: 1px solid grey;
  min-width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${(props) => props.theme.palette.font};
  font-size: 22px;
  text-decoration: none;
  padding: 16px 0;
  padding-left: 6px;

  &:last-child {
    border-bottom: none;
  }
`;

const MobileDropdownButton = styled.div`
  display: flex;
  border-bottom: 1px solid grey;
  min-width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${(props) => props.theme.palette.font};
  font-size: 22px;
  text-decoration: none;
  padding: 16px 0;
  padding-left: 6px;

  &:last-child {
    border-bottom: none;
  }
`;

const CopyrightNotice = styled.div`
  height: 14px;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${(props) => props.theme.palette.font};
  display: flex;

  margin-bottom: 2px;

  justify-content: flex-end;
  align-items: bottom;
`;

const CopyrightWord = styled.small`
  margin-right: 5px;
`;

const ProjectList = styled.div`
  maxHeight: calc(100% - 54px);
  overflow-y: auto;
  overflow-x: hidden;
`