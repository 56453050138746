import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { QueryContent, QuerySection, QuerySectionHeader } from "../../query";

import { SaveQueryBar } from "../../workflow";

import { useSchema, useCurrentSchemas } from "../../../../../hooks/projects";
import { log_warning } from "../../../../../tools/logger";
import { UserContext } from "../../../../../App";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import { GenericField, SelectField } from "../../../../ui/inputs2";
import { BooleanField } from "../../../../ui/inputs2";
import AdvancedTable from "../../../../ui/tableadv";
import { parse_on_fields } from "../../../../../tools/forms";
import { Button2 } from "../../../../ui/buttons";

import { deleteField } from "@queryit/api";

export default ({ project, schemaId, setStatusMessage }) => {
  const [currentSchema, currentSchemaData] = useSchema(schemaId, project);
  const [tableData, setTableData] = useState(undefined);
  const [columns, setColumns] = useState(undefined);
  const [settings, setSettings] = useState([]);
  const [requirements, setRequirements] = useState([]);

  const user = useContext(UserContext);
  const schemas = useCurrentSchemas(project, user);

  // Inital load
  useEffect(() => {
    const links = currentSchemaData?.linkedQueries?.from;
    const reqs = currentSchemaData?.linkedQueries?.requirements;

    if (reqs) {
      // lets set the requirements
      const formated = reqs.map((req) => req.schemas);
      setRequirements(formated);
    }

    if (links && schemas) {
      // migration for any link settings with old ds
      const newLinks = Array.isArray(links) ? links : [links];

      const keys = [
        ...new Set(newLinks.flatMap((link) => Object.keys(link.autoPopulate))),
      ];

      setTableData(
        keys.map((key) => {
          return newLinks.reduce(
            (acc, link) => {
              if (link.autoPopulate[key]) {
                acc[link.id] = link.autoPopulate[key];
              }
              return acc;
            },
            { target: key }
          );
        })
      );

      const linkMap = newLinks.reduce((acc, link) => {
        acc[link.id] = {
          id: link.id,
          multi: link?.multi || false,
          priority: link?.priority || 0,
          title: link.title,
        };
        return acc;
      }, {});

      setSettings(
        schemas
          .map((schema) => {
            // If already in the map, use that
            if (linkMap[schema.id]) {
              return linkMap[schema.id];
            }
            // If not & not in the field linking, skip
            return undefined;
          })
          .filter((s) => s !== undefined)
      );
    }

    if (schemas && currentSchemaData) {
      // set the options for each table column
      const optionsArray = schemas
        .filter((s) => s.id !== currentSchemaData.id)
        .map((schema) => {
          let options = {};
          parse_on_fields(schema.fields, (fld) => {
            options[`data.${fld.id}`] = fld.id;
          });

          return {
            id: schema.id,
            title: schema.name,
            type: "select",
            options: options,
            allowNone: true,
          };
        });

      let currentFields = {};
      parse_on_fields(currentSchemaData.fields, (fld) => {
        currentFields[`data.${fld.id}`] = fld.id;
      });
      setColumns([
        {
          id: "target",
          title: "Current Schema Field",
          type: "select",
          options: currentFields,
        },
        ...optionsArray,
      ]);
    }
  }, [currentSchemaData?.linkedQueries, schemas, currentSchemaData]);

  // Update available settings
  useEffect(() => {
    const tableSchemas = [];
    tableData?.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "target" && row[key] !== "") {
          tableSchemas.push(key);
        }
      });
    });

    // Make array unique
    const uniqueSchemas = [...new Set(tableSchemas)];

    // Then set settings with updated schemas
    setSettings((ex) => {
      return uniqueSchemas.map((schema) => {
        const exists = ex.find((s) => s.id === schema);
        if (exists) {
          return exists;
        }
        return {
          id: schema,
          multi: false,
          priority: 0,
          title: schemas.find((s) => s.id === schema)?.name + " Link",
        };
      });
    });
  }, [tableData, schemas]);

  const schemaOptions = useMemo(() => {
    if (!schemas || settings === undefined) {
      return null;
    }
    return settings.reduce((acc, s) => {
      acc[s.id] = schemas.find((schema) => schema.id === s.id)?.name;
      return acc;
    }, {});
  }, [schemas, settings]);

  const handleSave = async () => {
    let error = false;

    // lets reconstruct the table data to be parsed for linking
    const formattedTable = tableData.reduce((acc, curr) => {
      const { target, ...ids } = curr;
      if (!target) error = true;

      Object.entries(ids).forEach(([id, value]) => {
        if (!value) return;

        const existingItem = acc.find((item) => item.id === id);
        if (existingItem) {
          existingItem.autoPopulate[target] = value;
        } else {
          acc.push({
            id,
            autoPopulate: { [target]: value },
          });
        }
      });

      return acc;
    }, []);

    if (formattedTable.length === 0) {
      error = true;
    }

    // now lets tack the settings properties
    const formattedLinks = formattedTable.map((link) => {
      const setting = settings.find((setting) => setting.id === link.id);
      return { ...link, ...setting };
    });

    const formattedRequirements = requirements
      .filter((req) => req.length > 0)
      .map((req) => ({ schemas: req }));

    if (error) {
      log_warning("A link entry has undefined or empty fields.");
      setStatusMessage("A link entry has undefined or empty fields.");
      return null;
    }

    await currentSchema.update({
      linkedQueries: {
        from: formattedLinks,
        requirements: formattedRequirements,
      },
    });

    setStatusMessage("Changes Saved!")
  };

  if (
    !(
      currentSchemaData?.settings?.generateLinkedQuery ||
      currentSchemaData?.linkedQueries
    )
  ) {
    return (
      <QueryContent>
        <QuerySection style={{ width: "100%", padding: "10px" }}>
          This Schema does not have query linking enabled.
          <Box style={{ marginTop: "8px" }}>
            <Button2
              label="Enable Query Linking"
              onClick={async () => {
                await currentSchema.update({
                  settings: {
                    ...currentSchemaData.settings,
                    generateLinkedQuery: true,
                  },
                });
                setStatusMessage("Changes Saved!")
              }}
            />
          </Box>
        </QuerySection>
      </QueryContent>
    );
  }

  return (
    <>
      <QueryContent>
        <QuerySection style={{ width: "100%" }}>
          <Typography p={1}>Configure Schema Field Linking</Typography>
          {columns && (
            <AdvancedTable
              label="Schema Fields"
              schema={{
                body: {
                  columns: columns,
                },
                options: { allowImportExport: false },
              }}
              data={tableData ? { body: tableData } : undefined}
              setChange={(callback) =>
                setTableData((ex) => [...callback({ body: ex })?.body])
              }
            />
          )}
        </QuerySection>
        <QuerySection style={{ width: "100%" }}>
          <Typography p={1}>Source Settings</Typography>
          <Box p={1}>
            {settings &&
              settings.map((s, i) => (
                <SettingsConfig
                  key={s.id}
                  index={i}
                  setting={s}
                  setSettings={setSettings}
                />
              ))}
          </Box>
        </QuerySection>
        <QuerySection style={{ width: "100%" }}>
          <Typography p={1}>Source Requirements</Typography>
          <Box display="flex" flexDirection="column" p={1}>
            {requirements &&
              requirements.map((r, i) => (
                <Box
                  key={i}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <SelectField
                    label="Must Select One of"
                    data={r}
                    options={schemaOptions}
                    multi
                    onChange={(e) => {
                      setRequirements((ex) => {
                        const copy = [...ex];
                        copy[i] = e.target.value;
                        return copy;
                      });
                    }}
                  />
                  <Tooltip
                    title="Delete Requirement"
                    onClick={() => {
                      setRequirements((ex) =>
                        ex.filter((_r, index) => index !== i)
                      );
                    }}
                  >
                    <IconButton>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}
            <Box>
              <Button2
                label={"Add Requirement"}
                onClick={() => {
                  setRequirements((ex) => [...ex, []]);
                }}
              />
            </Box>
          </Box>
        </QuerySection>
        <QuerySection style={{ width: "100%", padding: "10px" }}>
          Danger Zone
          <Box style={{ marginTop: "8px" }}>
            <Button2
              label="Disable Linked Query"
              onClick={() => {
                currentSchema.update({
                  settings: {
                    ...currentSchemaData.settings,
                    generateLinkedQuery: false,
                  },
                  linkedQueries: deleteField(),
                });
              }}
            />
          </Box>
        </QuerySection>
      </QueryContent>
      <SaveQueryBar saveQuery={handleSave} />
    </>
  );
};

const SettingsConfig = ({ index, setting, setSettings }) => {
  return (
    <Box width="90%" p={0.5}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{`${setting.title} (${setting.id})`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" alignItems="center">
            <GenericField
              label={"Title"}
              data={setting.title}
              onChange={(e) => {
                setSettings((ex) => {
                  const copy = [...ex];
                  copy[index] = { ...setting, title: e.target.value };
                  return copy;
                });
              }}
            />
            <GenericField
              label={"Linking Priority"}
              data={setting.priority}
              onChange={(e) => {
                setSettings((ex) => {
                  const copy = [...ex];
                  copy[index] = { ...setting, priority: e.target.value };
                  return copy;
                });
              }}
              number
            />
            <BooleanField
              label="Multiple"
              data={setting.multi}
              onChange={(e) => {
                setSettings((ex) => {
                  const copy = [...ex];
                  copy[index] = { ...setting, multi: e.target.value };
                  return copy;
                });
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
