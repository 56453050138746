import React, { useState } from "react";
import { Typography } from "@mui/material";

import { GenericField } from "../../ui/inputs2";
import { Button2 } from "../../ui/buttons";
import { useHotkey } from "../../../hooks/system";
import q from "@queryit/api";

export default ({ user, nextState }) => {
  const [password, setPassword] = useState({
    password: null,
    confirmPassword: null,
  });
  const [authError, setAuthError] = useState(undefined);

  const makePasswordSet = () => {
    setAuthError(undefined);
    if (password.password === null) {
      setAuthError("Please enter a password");
      return;
    }
    if (password.password !== password.confirmPassword) {
      setAuthError("Passwords do not match");
      return false;
    }
    if (password.password.length < 6) {
      setAuthError("Password must be at least 6 characters");
      return false;
    }
    // Now we can do the password set
    q.auth
      .updatePassword(password.password)
      .then((res) => {
        nextState();
      })
      .catch((err) => {
        if (err.message === "Firebase: Error (auth/requires-recent-login).") {
          setAuthError(
            "Welcome session expired, please refresh the page and try again..."
          );
          setTimeout(() => q.auth.signOut(), 500);
          return;
        }
        setAuthError("Password could not be set");
      });
  };

  // Add hotkey
  useHotkey("Enter", () => makePasswordSet());

  return (
    <>
      <Typography style={{ marginBottom: 3 }}>
        Because this is your first time with us, you&apos;ll need to setup a
        password. You can always change it later.
      </Typography>
      {authError && (
        <Typography style={{ color: "red" }}>{authError}</Typography>
      )}
      <GenericField
        label="Password"
        password
        contrast
        data={password.password}
        onChange={(e) => {
          const { value } = e.target;
          setPassword((ex) => ({ ...ex, password: value }));
        }}
      />
      <GenericField
        label="Confirm Password"
        password
        contrast
        data={password.confirmPassword}
        onChange={(e) => {
          const { value } = e.target;
          setPassword((ex) => ({ ...ex, confirmPassword: value }));
        }}
      />
      <br />
      <Button2 onClick={makePasswordSet} label="Set Password" />
    </>
  );
};
