import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import styled from "styled-components";
import { format, isSameDay } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { withStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableSortLabel,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Tooltip,
} from "@mui/material";

// Icons!
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { generate_tempid, is_overdue, parse_db_timestamp } from "../../tools";
import { ProjectUsersContext, SelectedSchemaContext } from "../project";
import { Badge, UserCircle } from "./decorations";
import { Flag, FlagOutlined } from "@mui/icons-material";
import { log_error } from "../../tools/logger";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../../common/query";

// Support Functions
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const useDataGridStyles = makeStyles((theme) => {
  const tableBorderColor = theme.palette.text.primary + "1F";

  return {
    dataGridTable: {
      border: "none",
      "& *": {
        borderColor: tableBorderColor,
      },
      "& .MuiDataGrid-columnSeparator": {
        color: tableBorderColor,
      },
    },
    dataGridCell: {
      borderColor: tableBorderColor,
    },
    dataGridRow: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  };
});

const sortDateCorrectly = (a, b) => {
  return Date.parse(a) - Date.parse(b);
};

const resolveTableColumnData = (row, col) => {
  let dataResult = "";
  if (col.component) {
    // None of the other logic can apply to component formatting
    return col.component(row);
  } else if (col.format) {
    try {
      dataResult = col.format(row);
    } catch (e) {
      log_error(`Error formatting data for column - ${e}`);
    }
  } else if (col.preset) {
    // These are basically the same as 'col.format' except they are used so commonly that we build them into the app!
    // Before we get into this, get the overdue status as its used in a fair few of these and it can't hurt
    // const overdue = parse_db_timestamp(row.dueDate) < new Date() && !row.closeTime;
    const overdue =
      row.closeTime === undefined &&
      is_overdue(parse_db_timestamp(row.dueDate));

    switch (col.preset) {
      case "status":
        dataResult = overdue ? "overdue" : row.status;
        break;
      case "duedate":
        var formatDate = row.dueDate
          ? format(parse_db_timestamp(row.dueDate), "dd/MMM/yyyy")
          : "-";
        dataResult = overdue ? "Overdue" : formatDate;
        dataResult = isSameDay(parse_db_timestamp(row.dueDate), new Date())
          ? "Due Today"
          : dataResult;
        break;
      default:
        break;
    }
  } else if (col.index || col.field) {
    let dt = row;
    if (col.index) {
      col.index.split(".").forEach((ind) => {
        try {
          dt = Array.isArray(dt) ? dt[0][ind] : dt[ind];
        } catch {
          log_error("Table data indexing failed");
        }
      });
    } else {
      col.field.split(".").forEach((ind) => {
        try {
          dt = Array.isArray(dt) ? dt[0][ind] : dt[ind];
        } catch {
          log_error("Table data indexing failed");
        }
      });
    }
    dataResult = resolveTableDataFormat(dt, col);
    if (Array.isArray(dataResult)) {
      return dataResult;
    } // Protect array against string formatting
  }
  // Check if value is a number & there is no syle preset
  if (typeof dataResult === "number" && !col.stylePreset) {
    return dataResult;
  } else {
    return `${
      col.stylePreset?.prepend ? col.stylePreset?.prepend + " " : ""
    }${dataResult}${
      col.stylePreset?.append ? " " + col.stylePreset?.append : ""
    }`;
  }
};

const resolveTableDataFormat = (data, col) => {
  if (typeof data === "boolean") {
    return data.toString();
  } else if (
    typeof data === "object" &&
    data !== null &&
    data.nanoseconds !== undefined &&
    data.nanoseconds >= 0
  ) {
    const dateObject = parse_db_timestamp(data);
    if (col.specialFormat === "date") {
      return format(dateObject, "dd-MMM-yyyy"); // Format as date
    } else if (col.specialFormat === "time") {
      return format(dateObject, "HH:mm:ss");
    }
    return format(dateObject, "dd-MMM-yyyy");
  } else if (typeof data === "number") {
    const dateObject = new Date(data);
    if (col.specialFormat === "date") {
      return format(dateObject, "dd-MMM-yyyy"); // Format as date
    } else if (col.specialFormat === "time") {
      return format(dateObject, "HH:mm:ss"); // Format as time
    }
  }
  return data;
};

const resolveTableColumnParseInstructions = (col, extraData) => {
  const instructions = {
    // Ordering here is important as overwriting is expected
    ...(col.preset && col.preset === "duedate"
      ? {
          renderCell: (params) => (
            <span
              style={{
                color: params.value === "Overdue" ? "#ff5353" : undefined,
              }}
            >
              {params.value}
            </span>
          ),
        }
      : {}), // This condition is for coloring past due dates red
    ...(col.decorate
      ? { ...resolveTableColumnDecoration(col.decorate, extraData) }
      : {}),
    ...(col.specialFormat && col.specialFormat === "date"
      ? { sortComparator: (a, b) => sortDateCorrectly(a, b) }
      : {}),
  };
  // Now strip undefined values
  const toDelete = [];
  Object.keys(instructions).forEach((v) => {
    if (instructions[v] === undefined) {
      toDelete.push(v);
    }
  });
  toDelete.forEach((v) => {
    delete instructions[v];
  });
  // We center special renders
  if (Object.keys(instructions).length > 0) {
    instructions["align"] = "center";
    instructions["filter"] = false;
  }
  return instructions;
};

const resolveTableColumnDecoration = (key, extraData) => {
  switch (key) {
    case "user":
      return {
        renderCell: (params) => {
          let user = undefined;
          if (Array.isArray(params.value)) {
            user = params.value.map((valueUser) =>
              extraData.users?.find((usr) => usr.id === valueUser)
            );
          } else {
            user = extraData.users?.find((usr) => usr.id === params.value);
          }
          return <UserCircle userData={user} noUserGuarantee />;
        },
      };
    case "badge":
      return {
        renderCell: (params) => {
          // First grab the schema status set
          const schema = extraData?.schema;
          const statusSet =
            schema?.type === "custom"
              ? schema?.customStatusSet
              : TEMPLATE_SCHEMA_STATUSSETS[schema?.type];
          // Then fetch the status from the set!
          const status = statusSet?.[params.value?.toLowerCase()];
          // And finally render the component
          return (
            <Badge
              text={params.value}
              color={params.value === "overdue" ? "#b11e1e" : status?.color}
            />
          );
        },
      };
    case "flag":
      return {
        renderCell: (params) => {
          let color = null;
          let outline = false;
          // Note: While data is not capitalized, by this point we're in data presentation which is!
          switch (params.value) {
            case "Urgent":
              color = "#dc2626";
              break;
            case "High":
              color = "#ffbc41";
              break;
            case "Normal":
              color = "#70b6ee";
              break;
            case "Low":
              color = "#b4c8d8";
              break;
            default:
              outline = true;
              break;
          }
          return (
            <Tooltip title={!outline ? params.value : "None"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!outline && <Flag style={{ color: color }} />}
                {outline && <FlagOutlined style={{ opacity: 0.65 }} />}
              </div>
            </Tooltip>
          );
        },
        sortComparator: (a, b) => {
          const order = ["Urgent", "High", "Normal", "Low"];
          const getIndex = (val) =>
            order.indexOf(val) > -1 ? order.indexOf(val) : 999;
          return getIndex(a) - getIndex(b);
        },
      };
    default:
      return {};
  }
};

const parseTableColumns = (columnArgs, setColumns) => {
  setColumns(
    columnArgs.map((col) => ({
      ...col,
      id: col.name.replaceAll(" ", "_"),
      name: col.name,
      index: col.index ? col.index : undefined,
      format: col.format ? new Function("data", col.format) : undefined,
    }))
  );
};

// Main Exports
export const DataTable = React.memo(
  ({ data, columns, onRowClick, multiselect = false, ...props }) => {
    /*
    This type of table is meant to be fast. It does only what it's designed to do, display data for selection and
    subsequently return that data for external function triggering. It's fast to search, filter and otherwise modify,
    but it does not tone some pleasant or necessary features for more dynamic table use-cases like dual column span.
    For any of those features, use the InteractiveTable

    columns: list of {field: <identifier in a flat object>, index: <identifier with dots if object is deep>, 
                        headerName: <title in table>, format: <function to format data (as a string)>}
  */
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);

    // Get the user context here so we can use that data in tables where necessary
    const users = useContext(ProjectUsersContext);
    // Get the schema context here so we can use that data in tables where necessary
    const schema = useContext(SelectedSchemaContext);

    useEffect(() => {
      // We setup our own columns in here because we have some pedantic properties to add
      // to ensure that all our tables look spiffy off the bat (the "rip" as the kids would say)
      if (columns) {
        let addedCol;
        if (
          columns.id &&
          columns.find(
            (c) => c.headerName?.replaceAll(" ", "_").toLowerCase() == "id"
          ) &&
          columns.id &&
          columns.find(
            (c) => c.headerName?.replaceAll(" ", "_").toLowerCase() == "id"
          ) === undefined
        ) {
          addedCol = {
            headerName: "ID",
            field: "id",
            index: "__id",
            hide: true,
          };
        }
        setTableColumns(
          (addedCol ? [...columns, addedCol] : columns).map((col) => ({
            ...col,
            flex: col.width ? 0 : 1,
            field: `col_${
              col.field
                ? col.field
                : col.headerName?.replaceAll(" ", "_")?.toLowerCase()
            }`,
            // Now let's add some formatters and parsers (native to DataGrid API)
            ...resolveTableColumnParseInstructions(col, { users, schema }),
            // These should be unnecessary but are a result of coming from an old table system
            // We should migrate away from doing this ASAP
            headerName: col.headerName ? col.headerName : col.name,
            // index: (col.selector) ? col.selector : undefined
          }))
        );
      }
    }, [columns, users, schema]);

    useEffect(() => {
      if (data && tableColumns) {
        setTableData(
          data.reduce(
            (rows, dt) => [
              ...rows,
              {
                ...tableColumns.reduce(
                  (row, col) => ({
                    ...row,
                    [col.field]: resolveTableColumnData(dt, col, users),
                  }),
                  {}
                ),
                id: generate_tempid(),
                _id: dt.id,
              }, //dt.id}
            ],
            []
          )
        );
      }
    }, [data, tableColumns, users]);

    useTheme(); // Make sure custom theme is used
    const classes = useDataGridStyles();

    return (
      <DataGrid
        rows={tableData}
        columns={tableColumns}
        checkboxSelection={multiselect}
        hideFooterSelectedRowCount={!multiselect}
        onRowClick={onRowClick}
        columnVisibilityModel={tableColumns.reduce(
          (acc, col) => (col.hide ? { ...acc, [col.field]: false } : acc),
          {}
        )}
        classes={{
          root: classes.dataGridTable,
          row: classes.dataGridRow,
          cell: classes.dataGridCell,
        }}
        density={"compact"}
        {...props}
        // TODO: Add custom "no rows" overlay
      />
    );
  }
);

export const InteractiveTable = ({
  data,
  columns,
  allowOrdering = true,
  emptyMessage,
  defaultOrder,
  CollapseComponent,
  onRowClick,
}) => {
  /*
    This type of table is meant for use cases where a lot more interactivity with the size, shape, and functionality
    of the table are necessary. Many more options are provided and a lot more of a flexible approach
    is taken to the design and implementation
  */
  // Check input arg for ordering to do default order
  let orderForm;
  if (defaultOrder && defaultOrder.col && defaultOrder.dir) {
    orderForm = defaultOrder;
  }
  // Added table function props
  const [order, setOrder] = useState(orderForm);

  const orderHandler = (colId) => (e) => {
    // We won't need the event, but the colId and current state of ordering matter
    setOrder((ord) =>
      ord
        ? ord.col == colId
          ? ord.dir == "asc"
            ? { col: colId, dir: "desc" }
            : undefined
          : { col: colId, dir: "asc" }
        : { col: colId, dir: "asc" }
    );
  };

  // Table Inherent Data
  const [tableColumns, setTableColumns] = useState([]);

  const orderTableData = useCallback(
    (dt) => {
      return !order || !dt
        ? dt
        : dt.sort((a, b) => {
            let item1 =
              a[order.col] && a[order.col].props
                ? a[order.col].props.data
                : a[order.col];
            let item2 =
              b[order.col] && b[order.col].props
                ? b[order.col].props.data
                : b[order.col];

            if (typeof item1 === "string") {
              return item1.localeCompare(item2) * (order.dir == "asc" ? 1 : -1);
            }
            return (
              (item1 < item2 ? 1 : item1 > item2 ? -1 : 0) *
              (order.dir == "asc" ? 1 : -1)
            );
          });
    },
    [order]
  );

  // const parseTableData = useCallback((data) => {
  //   if (tableColumns.length < 1) { return; }
  //   setTableData(orderTableData(data.reduce((rr, row) => {
  //     rr.push({...tableColumns.reduce((dt, col) => (
  //       {...dt, [col.id]: resolveTableColumnData(row, col)}
  //     ), {}), _bin: row, _id: row.__id ? row.__id : row.id});
  //     return rr;
  //   }, [])));
  // }, [tableColumns, orderTableData]);

  useEffect(() => {
    if (columns) {
      parseTableColumns(columns, setTableColumns);
    }
  }, [columns]);

  // useEffect(() => {
  //   if (data) {
  //     parseTableData(data);
  //   }
  // }, [data, parseTableData]);

  // useEffect(() => {
  //   setTableData(ex => orderTableData(ex));
  // }, [order, orderTableData]); // Re-do ordering when any part of the data state changes

  const tableData = useMemo(() => {
    if (tableColumns.length < 1 || !data) {
      return [];
    }
    return orderTableData(
      data.reduce((rr, row) => {
        rr.push({
          ...tableColumns.reduce(
            (dt, col) => ({
              ...dt,
              [col.id]: resolveTableColumnData(row, col),
            }),
            {}
          ),
          _bin: row,
          _id: row.__id ? row.__id : row.id ? row.id : generate_tempid(),
        });
        return rr;
      }, [])
    );
  }, [data, tableColumns, orderTableData]);

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small" aria-label="collapsible table">
        <InteractiveTableHead
          columns={tableColumns}
          collapse={CollapseComponent ? true : false}
          order={order}
          changeOrder={allowOrdering ? orderHandler : undefined}
        />
        <TableBody>
          {tableData.map((row) => {
            return (
              <InteractiveTableRow
                key={`int-table-${row._id}`}
                row={row}
                columns={tableColumns}
                CollapseComponent={CollapseComponent}
                {...(onRowClick ? { onClick: () => onRowClick(row) } : {})}
              />
            );
          })}
          {tableData.length < 1 && (
            <TableEmptyTableRow
              colSpan={tableColumns.length + (CollapseComponent ? 1 : 0)}
              message={emptyMessage}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Support Views
const InteractiveTableHead = ({ columns, collapse, order, changeOrder }) => {
  const classes = useRowStyles();

  return (
    <TableHead>
      <TableRow>
        {collapse && <TableCell> </TableCell>}
        {columns.map((col) => {
          return (
            <TableCell
              key={col.id}
              align={col.align ? col.align : "left"}
              padding={col.disablePadding ? "none" : "normal"}
              sortDirection={order && order.col === col.id ? order.dir : false}
              width={col.width ? col.width : "auto"}
            >
              <TableSortLabel
                active={order && order.col === col.id}
                direction={order && order.col === col.id ? order.dir : "asc"}
                onClick={changeOrder ? changeOrder(col.id) : undefined}
              >
                {col.name}
                {order && order.col === col.id ? (
                  <span className={classes.visuallyHidden}>
                    {order && order.dir === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const InteractiveTableRow = ({ row, columns, CollapseComponent, onClick }) => {
  const [open, setOpen] = useState(false);
  const [dataState, setDataState] = useState(row);
  const classes = useRowStyles();

  useEffect(() => {
    setDataState(row);
  }, [row]);

  return (
    <>
      <TableRow
        className={classes.root}
        hover={onClick ? true : false}
        onClick={onClick}
      >
        {CollapseComponent && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {CollapseComponent ? (
                open ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )
              ) : null}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col) => (
          <TableCell
            align={col.align}
            key={`row-${row._id}-${col.name.replaceAll(" ", "_")}`}
          >
            {dataState[col.id]}
          </TableCell>
        ))}
      </TableRow>
      {/* We render the second row for the collapse if needed */}
      {CollapseComponent && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <CollapseComponent row={dataState} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const TableEmptyTableRow = ({ colSpan, message }) => {
  const classes = useRowStyles();
  return (
    <TableRow className={classes.root}>
      <TableCell
        colSpan={colSpan}
        style={{ textAlign: "center", fontSize: 16 }}
      >
        {message ? message : "No Data Available"}
      </TableCell>
    </TableRow>
  );
};

// Free form table components
const IconStyles = {
  label: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#000 !important",
    },
  },
};

const CellStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Ubuntu",
  },
};

const _FFTable = styled(Table)`
  @media (max-width: 700px) {
    min-width: 900px;
  }
`;
export const FFTable = (props) => (
  <_FFTable
    stickyHeader
    size="small"
    {...props}
    style={{ margin: "5px", maxWidth: "95%", ...props?.style }}
  >
    {props.children}
  </_FFTable>
);
export const FFTableHead = styled(TableHead)``;
export const FFTableBody = styled(TableBody)``;
export const FFTableRow = styled(TableRow)``;
export const FFTableFooterRow = styled.div`
  width: 100%;
  color: black;
`;
export const FFTableHeader = styled.th``;
export const FFTableCell = React.memo(
  (props) => (
    <FFTableCellStyled size="small" colSpan={props.colSpan} width={props.width}>
      {props.center && (
        <FFTableCellCenterContent>{props.children}</FFTableCellCenterContent>
      )}
      {!props.center && props.children}
    </FFTableCellStyled>
  ),
  (p, n) =>
    Object.keys(p).some((k) => p[k] !== n[k] && k !== "children") ||
    JSON.stringify(Object.keys(p)) !== JSON.stringify(Object.keys(n))
);
export const FFTableCellPartitionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
export const FFTableCellPartition = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
`;
export const FFTableCellCenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font};
  color: black;
`;
export const FFTableIconButton = withStyles(IconStyles)(IconButton);
const FFTableCellStyled = styled(TableCell)`
  minWidth: ${(props) => props.width ?? "auto"}, 
  maxWidth: ${(props) => props.width ?? "auto"}
`;

export const CollapseContainer = styled.div``;

export const MobileGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// badge color primary or secondary
export const MobileGridTile = ({ title, dataRows, badge, onClick }) => {
  return (
    <MobileGridTileDiv onClick={onClick}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div style={{ fontSize: 16, fontWeight: 500 }}>{title}</div>
        {dataRows && dataRows.map((row, ind) => <div key={ind}>{row}</div>)}
      </div>
      <div
        style={{
          minWidth: "30%",
          justifyContent: "flex-end",
          flexGrow: 1,
          display: "flex",
          marginRight: 10,
        }}
      >
        {badge && (
          <Chip
            style={{ backgroundColor: badge.color, padding: "5px" }}
            label={badge.text}
          />
        )}
      </div>
    </MobileGridTileDiv>
  );
};

export const MobileGridTileDiv = styled.div`
  display: flex;
  background: ${(props) => props.theme.step100};
  color: ${(props) => props.theme.text};
  border-radius: 4px;
  margin: 6px 4px;
  width: 93%;
  min-height: 100px;
  padding: 8px;

  overflow: hidden;
`;

export const MobileGridNoDataMessage = styled.div`
  color: ${(props) => props.theme.text};
`;
