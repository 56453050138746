import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { TextField, Box } from "@mui/material";
import { Add, Save, Delete } from "@mui/icons-material";
import { generate_tempid } from "../../../../../tools";
import AdvancedTable from "../../../../ui/tableadv";
import { is_random_insert_id, StatusContext } from ".";
import {
  INPUT_TYPES,
  STD_TABLE_INPUT_TYPES,
} from "../../../../../common/inputs";
import {
  BooleanField,
  SelectField,
  FileField,
  GenericField,
  PhotosField,
} from "../../../../ui/inputs2";
import { EditPackageField } from "../../fields/packagedoc";

import {
  TableColumnEditor,
  TableHeaderEditor,
  OptionsTableNested,
  VersionMapTableNested,
  OPTIONS_LIST,
  PREFIX_MAP_LIST,
} from "./table";
import { log_warning } from "../../../../../tools/logger";

export default React.memo(
  ({ field, onFieldChange, lessenOpacity, isDragPreview, custom }) => {
    if (!onFieldChange) {
      //we are generating a preview for field drag and drop, fields will not change
      onFieldChange = () => {};
    }

    const [tableRowDetailEditor, setTableRowDetailEditor] = useState(undefined);
    const [tableHeaderEditor, setTableHeaderEditor] = useState(undefined);
    const [tableColumnEditor, setTableColumnEditor] = useState(undefined);
    const [randomId, setRandomId] = useState("");

    const statusSet = useContext(StatusContext);

    const fieldIsNew = is_random_insert_id(field.id);
    useEffect(() => {
      if (fieldIsNew) {
        setRandomId(field.id.substring("rdxj_".length));
      }
    }, [fieldIsNew]);

    // initialize basic schema for advanced table if it does not exist
    useEffect(() => {
      if (field.type === "tableadv" && field.schema === undefined) {
        onFieldChange((ex) => ({
          ...ex,
          schema: {
            body: {
              columns: [
                {
                  id: generate_tempid().substring(0, 10),
                  title: "NEW COLUMN",
                  type: "text",
                },
              ],
            },
          },
        }));
      }
    }, [field]);

    // MIGRATION: Check if schema is using old format for editable whiles
    if (field.editableWhile && !Array.isArray(field.editableWhile)) {
      // Convert to array
      onFieldChange((ex) => ({
        ...ex,
        editableWhile: Object.keys(field.editableWhile).filter(
          (key) => field.editableWhile[key]
        ),
      }));
      log_warning(
        "MIGRATION: Converted editableWhile to array for field " + field.id
      );
      return null;
    }

    const onChange = (lbl) => (e) => {
      onFieldChange((ex) => ({ ...ex, [lbl]: e.target.value }));
      // set field ID based on name for personal fields
      if (custom && lbl == "name") {
        onFieldChange((ex) => ({
          ...ex,
          id: `${e.target.value
            .toLowerCase()
            .replaceAll(" ", "-")}_${randomId}`,
        }));
      }
    };

    const setAdvancedTableChange = (callback) => {
      onFieldChange((ex) => ({
        ...ex,
        schema: callback({ schema: ex?.schema }),
      }));
    };

    // Static File Type Change Handler
    const changeFiles = (value, propName) => {
      //only allow one doc per field
      if (value.length > 1) {
        value.shift();
      }
      onFieldChange((ex) => ({ ...ex, [propName]: value }));
    };

    // First we'll init the properties with those shared by all fields
    const properties = [
      !custom && (
        <TextField
          key="id"
          label="ID"
          value={fieldIsNew ? "" : field.id}
          onChange={onChange("id")}
          error={fieldIsNew}
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        />
      ),
      <TextField
        key="name"
        label="Name"
        value={field.name}
        onChange={onChange("name")}
        variant="standard"
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />,
      !custom && (
        <SelectField
          key="edits"
          label="Editable"
          options={statusSet.reduce(
            (acc, st) => ({ ...acc, [st.status]: st.name }),
            {}
          )}
          multi
          slim
          data={field.editableWhile}
          onChange={onChange("editableWhile")}
        />
      ),
      !custom && (
        <BooleanField
          key="required-type"
          data={field.required?.validator !== undefined}
          label="Adv. Constraint"
          onChange={(e) =>
            onFieldChange((ex) => ({
              ...ex,
              required: ex?.required?.validator
                ? {
                    status: ex?.required?.status ?? "",
                    message: ex?.required?.message ?? "",
                  }
                : { ...ex?.required, validator: {} },
            }))
          }
        />
      ),
      field.required?.validator !== undefined ? (
        <TextField
          key="required-preset"
          label="Required Preset"
          value={field.required?.validator?.preset}
          onChange={(e) =>
            onFieldChange((ex) => ({
              ...ex,
              required: {
                ...ex?.required,
                validator: {
                  ...ex?.required?.validator,
                  preset: e.target.value,
                },
              },
            }))
          }
        />
      ) : (
        !custom && (
          <SelectField
            key="required-status"
            label="Required by Status"
            options={statusSet.reduce(
              (acc, st) => ({ ...acc, [st.status]: st.name }),
              {}
            )}
            slim
            data={field.required?.status}
            onChange={(e) =>
              onFieldChange((ex) => ({
                ...ex,
                required: { ...ex?.required, status: e.target.value },
              }))
            }
          />
        )
      ),
      (field.type === "multiselect" ||
        field.type === "userlist" ||
        field.type === "select" ||
        field.type === "adhoc") && (
        <BooleanField
          key="multi-toggle"
          label="Multi"
          data={field.multi}
          onChange={(e) => {
            if (field.type === "userlist" && !e.target.value) {
              // remove min/max selections constraint since only one can be chosen
              onFieldChange((ex) => {
                if (ex.required?.validations?.userlist_count_range) {
                  delete ex.required.validations.userlist_count_range;
                }
                return ex;
              });
            }
            onFieldChange((ex) => ({ ...ex, multi: e.target.value }));
          }}
        />
      ),
      field.type === "tableadv" && (
        <BooleanField
          key="tableadv-static"
          label="Static"
          data={field.schema?.options?.static ?? false}
          onChange={(e) => {
            onFieldChange((ex) => ({
              ...ex,
              schema: {
                ...ex?.schema,
                options: { ...ex?.schema?.options, static: e.target.value },
              },
            }));
          }}
        />
      ),
    ];

    // Then define a few possible properties that we'll use in multiple bases
    // that way we don't have to define more than once and can change them easily

    // Excluding table
    let TABLE_COMPATIBLE_INPUT_TYPES = Object.keys(INPUT_TYPES).reduce(
      (acc, key) => ({ ...acc, [key]: INPUT_TYPES[key].label }),
      {}
    );

    [
      "table",
      "statictable",
      "tableadv",
      "package",
      "checkbox",
      "radio",
    ].forEach((key) => delete TABLE_COMPATIBLE_INPUT_TYPES[key]);

    // some nested fields in tables require an additional property to be pushed
    const DETAIL_EDIT_RESOLVER = (id) => {
      const index = field.columns.findIndex((col) => col.id === id);
      if (index === -1) {
        return;
      }
      switch (field.columns[index].type) {
        case "files":
          return (
            <Box key="fileEditResolution">
              {!custom && (
                <BooleanField
                  key="enableRevisions"
                  label="Revision History"
                  data={field.columns[index].enableRevisions}
                  onChange={(e) => {
                    onFieldChange((ex) => ({
                      ...ex,
                      columns: [
                        // All indices BEFORE row
                        ...(ex?.columns ?? [{}]).slice(0, index),
                        // The row itself
                        {
                          ...(ex?.columns ?? [{}])[index],
                          enableRevisions: e.target.value,
                          versionMap:
                            ex?.versionMap && Array.isArray(ex.versionMap)
                              ? ex.versionMap
                              : statusSet.map((st) => ({
                                  status: st.status,
                                  augmentPosition: 1,
                                  autoAugment: 1,
                                })),
                        },
                        // All indices AFTER row
                        ...(ex?.columns ?? [{}]).slice(index + 1),
                      ],
                    }));
                  }}
                />
              )}
              {field.columns[index].enableRevisions && (
                <VersionMapTableNested
                  id={id}
                  key="versionMap"
                  index={index}
                  field={field}
                  onFieldChange={onFieldChange}
                  statusSet={statusSet}
                />
              )}
            </Box>
          );
        case "boxset":
        case "tableradio":
        case "select":
          return (
            <OptionsTableNested
              id={id}
              key="optionsTable"
              index={index}
              field={field}
              onFieldChange={onFieldChange}
            />
          );
      }
    };

    const TABLE_COLUMNS = (
      <Box key="tableColsAdvRes">
        <AdvancedTable
          key="table-columns"
          label="Table Columns"
          schema={{
            body: {
              columns: [
                { id: "id", title: "ID", type: "text" },
                { id: "name", title: "Label", type: "text" },
                {
                  id: "type",
                  title: "Type",
                  type: "select",
                  options: {
                    ...TABLE_COMPATIBLE_INPUT_TYPES,
                    ...STD_TABLE_INPUT_TYPES,
                  },
                },
                {
                  id: "editableWhile",
                  title: "Editable While",
                  type: "mselect",
                  options: statusSet.reduce(
                    (acc, st) => ({ ...acc, [st.status]: st.name }),
                    {}
                  ),
                },
                ...(field?.columns &&
                field.columns.some((col) =>
                  ["boxset", "tableradio", "select", "files"].includes(col.type)
                )
                  ? [
                      {
                        id: "editDetails",
                        title: "Edit Details",
                        type: "button",
                        action: (id) => {
                          if (tableRowDetailEditor === id) {
                            setTableRowDetailEditor(undefined);
                          } else {
                            setTableRowDetailEditor(id);
                          }
                        },
                      },
                    ]
                  : []),
              ],
            },
            options: { allowImportExport: false },
          }}
          data={field?.columns ? { body: field?.columns } : undefined}
          setChange={(callback) =>
            onFieldChange((ex) => ({
              ...ex,
              columns: callback({ body: ex?.columns })?.body,
            }))
          }
        />
        {tableRowDetailEditor !== undefined &&
          DETAIL_EDIT_RESOLVER(tableRowDetailEditor)}
      </Box>
    );

    // Now use a switch to append fields specific to the type of field
    switch (field.type) {
      case "select":
      case "multiselect":
      case "boxset":
      case "radio":
        properties.push(
          <OPTIONS_LIST
            key="optionList"
            field={field}
            onFieldChange={onFieldChange}
          />
        );
        break;
      case "files":
        properties.push(
          !custom && (
            <BooleanField
              key="enableRevisions"
              label="Revision History"
              data={field.enableRevisions}
              onChange={(e) => {
                onFieldChange((ex) => ({
                  ...ex,
                  enableRevisions: e.target.value,
                  versionMap:
                    ex?.versionMap && Array.isArray(ex.versionMap)
                      ? ex.versionMap
                      : statusSet.map((st) => ({
                          status: st.status,
                          augmentPosition: 1,
                          autoAugment: 1,
                        })),
                }));
              }}
            />
          ),
          field.enableRevisions && (
            <PREFIX_MAP_LIST
              key="prefixMapList"
              field={field}
              onFieldChange={onFieldChange}
              statusSet={statusSet}
            />
          )
        );
        break;
      case "table":
      case "statictable":
        // tables may also have columns that need individual settings rendered
        // TODO: Add support for this :)
        var columnSettings = [];

        // there should always be an editableWhile array
        if (field?.columns) {
          field.columns.map((col, i) => {
            if (!Array.isArray(col.editableWhile)) {
              field.columns[i].editableWhile = [];
            }
          });
        }
        // Now push all for the table
        properties.push(TABLE_COLUMNS, ...columnSettings);
        break;
      case "tableadv":
        properties.push(
          <Box key="tableadv-alledits">
            <AdvancedTable
              key="tableadv-edit"
              label="Edit Table"
              schema={field.schema}
              setChange={() => {}} // no-op
              setSchemaChange={setAdvancedTableChange}
              tableHeaderEditor={tableHeaderEditor}
              setTableHeaderEditor={setTableHeaderEditor}
              tableColumnEditor={tableColumnEditor}
              setTableColumnEditor={setTableColumnEditor}
              schemaEditOnly
            />
            {tableColumnEditor !== undefined && (
              <TableColumnEditor
                key="tableadv-column-edit"
                column={tableColumnEditor}
                field={field}
                setChange={setAdvancedTableChange}
              />
            )}
            {tableHeaderEditor !== undefined && (
              <TableHeaderEditor
                key="tableadv-header-edit"
                header={tableHeaderEditor}
                setChange={setAdvancedTableChange}
                setTableHeaderEditor={setTableHeaderEditor}
              />
            )}
          </Box>
        );
        properties.push();
        break;
      case "message":
        properties.push(
          <TextField
            key="default-value"
            label="Message"
            value={field.default}
            multiline={true}
            variant="outlined"
            style={{ minWidth: "50%" }}
            onChange={(e) =>
              onFieldChange((ex) => ({ ...ex, default: e.target.value }))
            }
          />
        );
        break;
      case "package":
        properties.push(
          <EditPackageField
            key="package-field-edit"
            field={field}
            onEditChange={(newSchema) =>
              onFieldChange((ex) => ({ ...ex, schema: newSchema }))
            }
          />
        );
        break;
      case "staticdoc":
        properties.push(
          <FileField
            key="filestatic-edit"
            files={field?.file ?? null}
            onChange={(value) => {
              changeFiles(value, "file");
            }}
            mini
          />
        );
        break;
      case "staticimage":
        properties.push(
          <PhotosField
            key="staticimg-edit"
            files={field?.staticImg ?? null}
            onChange={(value) => {
              changeFiles(value, "staticImg");
            }}
            mini
          />
        );
        break;
      default:
        break;
    }

    return isDragPreview ? (
      lessenOpacity ? (
        <div
          style={{
            margin: "10px",
            opacity: "0.5",
            overflow: "hidden",
            cursor: "default",
          }}
        >
          {field.name}
        </div>
      ) : (
        <div style={{ margin: "10px", overflow: "hidden" }}>{field.name}</div>
      )
    ) : lessenOpacity ? (
      <div style={{ opacity: "0.5" }}>
        {field.name} ({INPUT_TYPES[field.type].label})
        <FormContainer style={{ margin: "10px" }}>{properties}</FormContainer>
      </div>
    ) : (
      <>
        {field.name} ({INPUT_TYPES[field.type]?.label})
        <FormContainer style={{ margin: "10px" }}>{properties}</FormContainer>
      </>
    );
  }
);

const FormContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 12px;
`;
