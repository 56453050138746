export const COPIABLE_FIELDS = [
  "string",
  "number",
  "textarea",
  "select",
  "multiselect",
  "userlist",
  "radio",
  "multi",
  "checkbox",
  "boxset",
  "table",
  "statictable",
  "tableadv",
  "photos",
  "files",
];
