import React from 'react';
import { DialogContent, Modal } from '@mui/material';
import { ModalViewFullScreen } from '../ui/containers';

export default ({open, onClose}) => {
  return (<Modal open={open} onClose={onClose}>
    <DialogContent><ModalViewFullScreen>
      <EULAFormatted />
    </ModalViewFullScreen></DialogContent>
  </Modal>);
}

export const EULAFormatted = () => {
  return (<div>
    <b>Last updated: December 21, 2021</b>
    <br/>
    <br/>
    This End-User SaaS Agreement (the &quot;Agreement&quot;) governs your relationship with Query-It Inc.&apos;s website <a href="https://queryitapp.com/">https://queryitapp.com/</a> and Query-It Inc.&apos;s software (the &quot;Service&quot;) operated by Query-It c. (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;). Please read this Agreement carefully before using our website and Service. By clicking the &quot;I Agree&quot; button, downloading or using the Service, you are agreeing to be bound by the terms and conditions of this Agreement. This Agreement applies to all visitors, users, and others who access or use the Service.
    <br/>
    If you do not agree to the terms and conditions of this Agreement, do not click on the &quot;Accept&quot; button and do not download or use the Service.
    <br/>
    <b>Introduction</b>
    <br/>
    You have been granted access to use the Service in accordance with our software as a service agreement between Query-It Inc. and its client which could be your employer, contractor, service provider, client, customer or other relation (the &quot;SaaS Agreement&quot;). The SaaS Agreement incorporates Query-It&apos;s standard Terms of Use, which are also incorporated into this Agreement and which you are also bound to. As part of the SaaS Agreement and as a result of your relationship with the party who signed the SaaS Agreement, you have been granted permission to use the Service. This Agreement sets forth the provisions under which we are willing to grant you this use which may also include access to our host server, hardware, maintenance and software improvements (the &quot;Service Provider Platform&quot;) for use on certa permitted devices such as your mobile phone, tablet, computer, or other smart device (&quot;Permitted Device&quot;). This permission to use the Service on Permitt Devices is known as a &quot;License&quot; and is more specifically described elow. It outlines what you can and cannot do.
    <br/>
    <b>Licence</b>
    <br/>
    Query-It grants you, a royalty-free, non-exclusive, non-transferable revocable licence to use the Service, and to upload, process, view, display, analyze information that you or other related users under the SaaS Agreement have uploaded to the Service Provider Platform (&quot;User Content&quot;). You agree to use the Service only for the purposes provided to you by your employer, partner, client, customer and only in accordance with this Agreement, the SaaS Agreement, and Query-It&apos;s Terms of Use.
    <br/>
    <b>Title and Ownership</b>
    <br/>
    The Service is licenced, not sold by Query-It and Query-It reserves any rights not expressly granted to you. All right, title, and interest in the Service, including without limitation all patent rights, copyrights and other intellectual property rights thereto, is retained by Query-It. You may run or use the Service subject to the restrictions herein solely for your professional use.
    <br/>
    In the case you make any alterations or improvements to the Service (which you are not permitted to do)(the &quot;Improvements&quot;) you hereby agree to assign to Query-It all right, title and interest in the Improvements which may exist including all intellectual property rights including copyright, patents, trade-secrets and/or any moral rights thereto. In the case that such Improvements cannot be assigned, you grant Query-It a worldwide, exclusive right to use, exploit, copy, modify, sell and or sub-license the Improvements in any manner whatsoever.
    <br/>
    <b>User Content</b>
    <br/>
    You grant Query-It a non-exclusive, transferable, sub-licensable, royalty-free, perpetual (or, in jurisdictions where this is not permitted, for a term equal to the duration of the SaaS Agreement plus twenty (20) years), irrevocable, fully paid, worldwide license to use, reproduce, analyze, sell, translate, modify, create derivative works from, and distribute any of your User Content in connection with the Service through any medium, whether alone or in combination with other content or materials, in any manner and by any means, method or technology, whether now known or hereafter created. Aside from the rights specifically granted herein, you retain ownership of all rights, including intellectual property rights, in the User Content. Where applicable and permitted under applicable law, you also agree to waive and not enforce any &quot;moral rights&quot; or equivalent rights, such as your right to be identified as the author of any User Content, including and your right to object to derogatory treatment of such User Content.
    <br/>
    <b>Restrictions</b>
    <br/>
    You agree not to, and you will not permit others to:
    <ol type="a">
      <li>
      permit any third Person to use the Services;
      </li>
      <li>
      re-licence or sub-licence, lease, loan or otherwise distribute the Service to any third Person;
      </li>
      <li>
      process or permit to be processed the data of any other Person;
      </li>
      <li>
      use the Service in the operation of a commercial business whereby you are directly or indirectly reselling or selling the Service or using it for commercial profit; or
      </li>
      <li>
      reverse engineer, decompile, dis-assemble, make derivative works, products, software or services from or translate, reprint, copy any of the Service.
      </li>
    </ol>
    <b>Modifications to Software</b>
    <br/>
    Query-It reserves the right to modify, suspend or discontinue, temporarily or permanently, the Services or any service to which it connects, with or without notice and without liability to you.
    <br/>
    <b>Term and Termination</b>
    <br/>
    We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the terms of this Agreement, the SaaS Agreement or our Terms of Use. All provisions of the this Agreement shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
    <br/>
    Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service. Upon termination of this Agreement, you shall cease all use of the Service and delete any and all copies from your Authorized Device.
    <br/>
    <b>Governing Law</b>
    <br/>
    The Parties to this Agreement submit to the jurisdiction of the courts of the Province of Alberta for the enforcement of this Agreement or any arbitration award or decision arising from this Agreement. This Agreement will be enforced or construed in accordance with the laws applicable and in force in the Province of Alberta, Canada.
    <br/>
    <b>Severability</b>
    <br/>
    If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provisions to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
    <br/>
    <b>Amendments to this Agreement</b>
    <br/>
    Query-It may amend the terms of this Agreement by posting the amended terms on its website, or by giving notice with updates. Your continued use of the Query-It Service after the effective date of any amendment will deem your agreement. Except as otherwise provided in this section, no amendment to this Agreement will be valid unless it is posted in the Query-It Services itself or on the Query-It website.
    <br/>
    <b>Indemnification</b>
    <br/>
    You agree to indemnify, defend and hold harmless Query-It Inc., its principals, officers, directors, representatives, employees, contractors, licensors, licensees, suppliers and agents, from and against any claims, losses, damages, losses, obligations, costs, actions or demands.
    <br/>
    These include but are not limited to: (a) legal and accounting fees resulting from your use of the Service; (b) your breach of any of this Agreement; (c) anything you post on or upload to the Service; and (d) any activity related to your account. This includes any negligent or illegal conduct by you, any person or entity accessing the Service using your account whether such access is obtained via fraudulent or illegal means.
    <br/>
    <b>Limitation of Liability</b>
    <br/>
    <i>
    Query-It makes no warranty expressed or implied regarding the fitness of the Services for a particular purpose or that the Service will be suitable or appropriate for the Authorized User&apos;s specific requirements.
    </i>
    <br/>
    <i>
    Query-It does not warrant that the use of the Services will be uninterrupted or error-free. You accept that the Service in general is prone to bugs and flaws within an acceptable level as determined in the industry.
    </i>
    <br/>
    <i>
    Query-It, its employees and agents, shall not be liable for any claims, damages, injuries, loss, of any kind, whether direct or indirect, consequential or incidental, arising from the use or the inability to use the Query-It Service or the failure to safeguard the data contained in the Query-It Service. This includes, without limitation, loss of revenue, profit or savings, lost, damaged or stolen data, or other commercial or economic loss.
    </i>
    <br/>
    <i>
    You agree to indemnify and hold Query-It, its employees and agents, harmless from and against any claims, damages, complaints, costs or expenses, loss, actions or causes of action you or any third party incurred or suffered, as a result of the use or inability to use the Query-It Service or your failure to safeguard the data contained in the Query-It Service
    </i>
    <br/>
    <b>Contact Information</b>
    <br/>
    If you have any questions about this Agreement, please contact us: info@queryitapp.com
  </div>);
}
