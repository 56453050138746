import React, { forwardRef } from "react";
import styled from "styled-components";

import Future from "../graphics/future-of-work.png";

const About = ({ showButton }, ref) => {
  return (
    <AboutContainer ref={ref}>
      <Image src={Future} />
      <TextContainer>
        <H1>About</H1>
        <H2>Why we exist / mission / purpose</H2>
        <P1>"PROVIDE SERVICE THROUGH TECHNOLOGY"</P1>
        <P2>
          Technology can be used to make life easier and contribute in making a
          better society. Our mission is to provide our clients with technology
          solutions to improve the status quo. This maybe through digitizing &
          automating work processes, developing stand-alone automated tools or
          acting as technology partners to develop custom applications. Our
          focus will remain on serving our client’s unique needs.
        </P2>
        {/* {showButton && (
          <A href="/more-about">
            <Button type="button">More About</Button>
          </A>
        )} */}
      </TextContainer>
    </AboutContainer>
  );
};

export default forwardRef(About);

const AboutContainer = styled.div`
  min-height: 700px; // 833px
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    min-height: 765px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;
const TextContainer = styled.div`
  margin-left: 3%;
  margin-bottom: 3%;
  width: 40%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;

  @media (max-width: 1000px) and (min-width: 601px) {
    margin-top: 75px;
  }

  @media (max-width: 600px) {
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0;
  }
`;
const Image = styled.img`
  height: 530px;
  width: 781px;

  @media (max-width: 1500px) and (min-width: 1201px) {
    // scale 80%;
    height: 424px;
    width: 625px;
  }

  @media (max-width: 1200px) and (min-width: 1001px) {
    // scale 60%
    height: 318px;
    width: 468.6px;
  }

  @media (max-width: 1000px) and (min-width: 601px) {
    // scale 40%
    height: 212px;
    width: 312.4px;
  }

  @media (max-width: 600px) and (min-width: 381px) {
    // mobile mockup dimensions
    height: 240px;
    width: 353px;
    margin-top: 40px;
    border-radius: 15px;
  }

  @media (max-width: 380px) {
    // scale mobile 80%;
    height: 192px;
    width: 282.4px;
    margin-top: 25px;
    border-radius: 15px;
  }
`;
const A = styled.a`
  height: 57px;
  width: 204px;
  margin-top: 5%;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  height: 100%;
  width: 100%;
  border: solid #37227A;
  border-radius: 5px;
  background-color: #37227A;
  color: white;
  user-select: none;

  -webkit-transition-duration: 0.4s; // Safari
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: #37227A;
    }
`;
const H1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 400%;
  color: #37227a;

  @media (max-width: 600px) {
    font-size: 275%;
    margin-top: 20px;
  }
`;
const H2 = styled.h2`
  margin: 0;
  font-size: 200%;
  color: #37227a;

  @media (max-width: 600px) {
    font-size: 150%;
  }
`;
const P1 = styled.p`
  margin: 0;
  margin-top: 5%;
  font-size: 200%;
  color: #37227a;

  @media (max-width: 600px) {
    font-size: 150%;
  }
`;
const P2 = styled.p`
  margin: 0;
  width: 100%;
  color: black;

  @media (max-width: 600px) {
    line-height: 30px;
    margin-top: 10px;
  }
`;
