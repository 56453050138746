import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import stringSimilarity from "string-similarity";
import { Search, Description, Widgets, Close } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import { UserDataContext } from "../../App";
import { useCurrentSchemas, useProject } from "../../hooks/projects";
import { useQueries } from "../../hooks/queries";

import { parse_on_fields } from "../../tools/forms";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useInputStyles } from "../ui/inputs2";

export default ({ projectData, setSearchActive }) => {
  const user = useContext(UserDataContext);

  // Statefulness of the search
  const [results, setResults] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // Get queries & schemas
  const [project, _] = useProject(projectData?.id ?? "");
  const schemas = useCurrentSchemas(project, user);
  const queries = useQueries(project, projectData?.settings?.isAnonymized);

  // Now get the search params state
  const [searchParams, setSearchParams] = useSearchParams();

  const classes = useInputStyles({
    contrast: true,
    fill: true,
  });

  const navigate = useCallback(
    (id) => {
      setSearchActive(false);
      setSearchParams({ qq: `${projectData.id}-${id}` });
    },
    [projectData, setSearchActive, setSearchParams]
  );

  // Memoize a parse for queries into searchable queries
  const searchableQueries = React.useMemo(() => {
    return queries
      ?.map((query) => {
        const schema = schemas.find((schema) => schema.id === query.schemaId);
        if (!schema) {
          return;
        } // This means the query is from a schema that is deleted
        const textFields = [];
        const optionFields = {};
        parse_on_fields(schema.fields, (fld) => {
          if (["string", "textarea"].includes(fld.type)) {
            textFields.push(fld.id);
          } else if (["select"].includes(fld.type)) {
            if (fld.conditionalOn) {
              return;
            }
            optionFields[fld.id] = fld.options;
          }
        });
        let dataString = query.id;
        textFields.forEach((fld) => {
          dataString += ` ${query?.data?.[fld] ?? ""}`;
        });
        Object.keys(optionFields).forEach((fld) => {
          dataString += ` ${optionFields[fld]?.[query?.data?.[fld]] ?? ""}`;
        });
        return { [query.id]: dataString };
      })
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
  }, [queries, schemas]);

  useEffect(() => {
    // Every time search changes, make it "loading"
    setLoading(true);
  }, [search]);

  // This is the code that does the searching!
  useEffect(() => {
    const resultSet = [];
    if (search.length > 0 && queries && searchableQueries && schemas) {
      const scored = {};
      // Search through the searchableQueries to score the IDs
      Object.keys(searchableQueries).forEach((id) => {
        scored[id] = stringSimilarity.compareTwoStrings(
          search,
          searchableQueries[id]
        );
      });
      // Sort the IDs by score
      const sorted = Object.keys(scored).sort((a, b) => scored[b] - scored[a]);
      // Add the results to the resultSet
      sorted.forEach((id) => {
        const query = queries.find((query) => query.id === id);
        resultSet.push({
          ...query,
          type: "query",
          schemaName: schemas.find((schema) => schema.id === query.schemaId)
            ?.name,
        });
      });
    } else {
      // Here the search is empty, so let's show some recent stuff
      if (queries && queries.length > 0) {
        resultSet.push(
          ...queries
            .sort((a, b) => a.created - b.created)
            .map((q) => ({ ...q, type: "query" }))
        );
      }
      if (schemas && schemas.length > 0) {
        resultSet.push(
          ...schemas
            .sort((a, b) => a.order - b.order)
            .map((s) => ({ ...s, type: "schema" }))
        );
      }
    }
    setLoading(false);
    setResults(resultSet);
  }, [search, queries, schemas, searchableQueries]);

  return (
    <AbsoluteFrame>
      <ClickDismissFrame onClick={() => setSearchActive(false)} />
      <SearchContainer>
        <CloseBar>
          <IconButton
            onClick={() => setSearchActive(false)}
            style={{ padding: 0, marginLeft: "6px" }}
          >
            <Close fontSize="small" />
          </IconButton>
        </CloseBar>
        <SearchBar>
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: "14px" }}
          >
            <TextField
              label={"Search Here"}
              value={search ? search : ""}
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
              }}
              variant={"standard"}
              type={"text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.input,
                inputProps: { min: 0 },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
              autoFocus
            />
          </FormControl>
        </SearchBar>
        <SearchResults>
          <SearchResultsTitle>
            {search.length > 0 ? "Results" : "Recent Queries"}
          </SearchResultsTitle>
          {/* Now display 3 results (inifinite on mobile with full screen browse) */}
          {!loading &&
            results
              .slice(0, isMobile ? 25 : 4)
              .map((result) => (
                <SearchResult
                  key={result.id}
                  result={result}
                  navigate={navigate}
                />
              ))}
          {loading && <SearchLoading />}
          {!loading && results.length < 1 && (
            <SearchResultTile>No results found</SearchResultTile>
          )}
        </SearchResults>
      </SearchContainer>
    </AbsoluteFrame>
  );
};

const SearchResult = ({ result, navigate }) => {
  return (
    <SearchResultTile onClick={() => navigate(result.id)}>
      {/* Icon for the type */}
      {result.type === "query" && (
        <Description style={{ fontSize: "1.7rem" }} />
      )}
      {result.type === "schema" && <Widgets style={{ fontSize: "1.7rem" }} />}
      <SearchResultContent>
        {/* Then Title */}
        <SearchResultTitle>
          {result.type === "query"
            ? result.dynamicId ?? result.id
            : result.name}
        </SearchResultTitle>

        {/* Finish with a bit of a preview */}
        <SearchResultPreview>
          {result.type === "query" && result.schemaName}
        </SearchResultPreview>
      </SearchResultContent>
    </SearchResultTile>
  );
};

const SearchLoading = () => <div>Loading...</div>;

const AbsoluteFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClickDismissFrame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(20, 20, 20, 0.85);
  border: none;

  &:focus {
    outline: none;
  }
`;

const SearchContainer = styled.div`
  width: 500px;
  min-height: ${isMobile ? "100%" : "250px"};
  max-height: ${isMobile ? "100%" : "350px"};
  z-index: 102;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.step100};
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  height: 44px;
  margin: 6px 12px;
`;

const CloseBar = styled.div`
  height: 12px;
  width: 100%;
`;

const SearchResults = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  color: ${(props) => props.theme.palette.text.secondary};

  overflow-y: auto;
  overflow-x: hidden;

  margin-top: 8px;
  margin-bottom: ${isMobile ? "20px" : "15px"};
`;

const SearchResultsTitle = styled.div`
  font-size: 13px;
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SearchResultTile = styled.div`
  width: 98%;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 6px;
  padding-left: 10px;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.palette.background.step50};
  }
`;

const SearchResultContent = styled.div`
  display: flex;
  margin-left: 5px;
  flex-direction: column;
`;

const SearchResultTitle = styled.div``;

const SearchResultPreview = styled.div`
  font-size: 12px;
  margin-left: 4px;
`;
