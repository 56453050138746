import React from "react";
import ReactDOM from "react-dom";
import ReactDOMClient from "react-dom/client";
import App from "./App";

// for the webpack sauce
import "core-js/stable";
import "regenerator-runtime/runtime";

import "./themes/global.css";

const container = document.getElementById("root");

// Create root
const root = ReactDOMClient.createRoot(container);

// Then inject the component for render
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById("root"));
