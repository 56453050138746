import React, { useState } from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { PrintableField } from "../fields";
import { useUserLookup } from "../../../../../hooks/users";

import PrintQueryHeader from "../../../../print/header";
import { PDFConstants, PDFStyles } from "../style";
import { log_warning } from "../../../../../tools/logger";

import { ProjectUsersContext } from "../../..";

export default ({ schemaData, queryData, projectLogo, qrCode, users }) => {
  const [author, authorRef] = useUserLookup(
    queryData ? queryData.creator : undefined
  );
  const [query, setQuery] = useState(queryData ? queryData : { data: {} }); // Guarantee the data prop

  return (
    <Page>
      <ProjectUsersContext.Provider value={users}>
        <PrintQueryHeader
          projectLogo={projectLogo}
          author={author}
          title={schemaData.name}
          id={queryData ? queryData.dynamicId ?? queryData.id : undefined}
          qrCode={qrCode}
          typeTitle="Query for"
        />
        {/* This is where the body of the form starts */}
        <View style={PDFStyles.body}>
          <View style={PDFStyles.query}>
            {schemaData.fields.map((fld) => {
              if (fld.condition) {
                log_warning("Conditional Evaluation? (Empty condition)");
              }
              if (fld.formId) {
                return;
              }
              if (fld.section) {
                return (
                  <View key={fld.name} style={PDFStyles.section}>
                    {/* We need this wrapper so the section title breaks to the next page if the first section overflows */}
                    <View
                      key="section-title-and-first-field-wrapper"
                      wrap={false}
                    >
                      <View style={PDFStyles.queryRow} key={"section-title"}>
                        <View
                          style={[
                            PDFStyles.queryCol,
                            { width: PDFConstants.BODY_WIDTH },
                          ]}
                        >
                          <Text
                            style={[
                              PDFStyles.tableCell,
                              PDFStyles.sectionTitle,
                            ]}
                          >
                            {fld.name}
                          </Text>
                          {fld.condition && (
                            <Text
                              style={[PDFStyles.tableCell, PDFStyles.label]}
                            >
                              {`(Fill this section only if you selected the corresponding option in ${fld.condition
                                .split(" ")[1]
                                .slice(7)})`}
                            </Text>
                          )}
                        </View>
                      </View>
                      {fld.section.length > 0 && (
                        <QueryRowGenerator
                          key={
                            fld.section[0].id
                              ? fld.section[0].id
                              : fld.section[0].nested[0].id
                          }
                          field={fld.section[0]}
                          query={query}
                        />
                      )}
                    </View>
                    {fld.section.slice(1).map((secfld) => (
                      <QueryRowGenerator
                        key={secfld.id ? secfld.id : secfld.nested[0].id}
                        field={secfld}
                        query={query}
                      />
                    ))}
                  </View>
                );
              } else {
                return (
                  <QueryRowGenerator
                    key={fld.id ? fld.id : fld.nested[0].id}
                    field={fld}
                    query={query}
                  />
                );
              }
            })}
          </View>
        </View>
        <View style={PDFStyles.footer} fixed>
          <Text style={PDFStyles.footerText}>
            This form was generated by Query-It on{" "}
            {`${
              String(new Date().getDate()).padStart(2, "0") +
              "/" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "/" +
              String(new Date().getFullYear())
            }`}
          </Text>
          {/* There's a bottom right place here for another logo */}
        </View>
      </ProjectUsersContext.Provider>
    </Page>
  );
};

const QueryRowGenerator = ({ field, query }) => {
  return (
    <View wrap={false}>
      <View style={PDFStyles.queryRow}>
        {field.nested ? (
          field.nested.map((nstfld) => (
            <View
              key={`${nstfld.id}-wrap`}
              style={[
                PDFStyles.queryCol,
                {
                  width: PDFConstants.BODY_WIDTH / field.nested.length,
                },
              ]}
            >
              <PrintableField
                field={nstfld}
                fieldData={query.data[nstfld.id]}
                data={query.data}
                width={PDFConstants.BODY_WIDTH / field.nested.length}
              />
            </View>
          ))
        ) : (
          <View
            key={field.id}
            style={[PDFStyles.queryCol, { width: PDFConstants.BODY_WIDTH }]}
          >
            <PrintableField
              field={field}
              fieldData={query.data[field.id]}
              data={query.data}
              width={PDFConstants.BODY_WIDTH}
            />
          </View>
        )}
      </View>
    </View>
  );
};
