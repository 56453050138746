import { Check } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TEMPLATE_SCHEMA_STATUSSETS } from "../../../common/query";

export const QueryProgressBar = ({ status, schemaData }) => {
  let foundStatus = false;
  let nextPending = false;
  let prevColor, nextPrevColor;

  const [parsedStatusSet, setParsedStatusSet] = useState([]);

  useEffect(() => {
    // Basically, the parsed statusset is a list of alternating objects
    // First is a status, then the associated connector, then another status
    // And it proceeds like that until the list is empty
    // Connectors are a string, while statuses are an object. That sets them apart

    // First check custom
    if (!schemaData.customStatusSet && schemaData.type === "custom") {
      setParsedStatusSet([]);
      return;
    }

    // Now let's get the statusset object array
    let statuses =
      schemaData.type === "custom"
        ? schemaData.customStatusSet
        : TEMPLATE_SCHEMA_STATUSSETS[schemaData.type];

    let currentStatus = statuses["new"]; // 'new' is ALWAYS the entry point, but never included in this view
    let parsed = [];

    // Because we use a while loop, add a failsafe
    let c = 0;

    // Now we begin the parse
    while (currentStatus !== undefined && currentStatus.advance !== undefined) {
      if (c > 24) {
        break;
      } else {
        c++;
      } // failsafe line, basically shouldn't ever be needed

      // Assume currentStatus is already added
      // First, parse through requirements to find the next status "moveTo"
      let nextStatRequirement = currentStatus.advance.requirements.find(
        (r) => r.moveTo !== undefined
      );

      // First we'll add the line to take us to the status
      if (currentStatus.status !== "new") {
        if (["poll", "trigger"].includes(nextStatRequirement.type)) {
          parsed.push("none");
        } else {
          parsed.push(
            nextStatRequirement.type === "approve" ? "approve" : "normal"
          );
        }
      }

      // If there is a status, add it to the parse
      currentStatus = statuses[nextStatRequirement.moveTo];
      parsed.push({
        status: currentStatus.status,
        name: currentStatus.name,
        color: currentStatus.color,
      });
    }

    // Done!
    setParsedStatusSet(parsed);
  }, [schemaData]);

  if (!parsedStatusSet.some((s) => s?.status === status)) {
    return null;
  }

  if (parsedStatusSet.length === 0) {
    return null;
  }

  let nextColor;

  return (
    <ProgressContainer>
      {parsedStatusSet.map((stt, ind) => {
        let pending;
        // Before anything else, determine if this is status or connector
        const tp = typeof stt === "string" ? "connector" : "status";

        if (tp === "status") {
          // State of status manager
          if (nextPending) {
            pending = true;
            nextPending = false;
          }
          if (stt.status === status) {
            foundStatus = true;
            nextPending = true;
          }
          const cmplt = !foundStatus || stt.status === status;

          // Color management
          prevColor = nextPrevColor;
          nextPrevColor = stt.color;
          nextColor = stt.color;

          // Now render
          return (
            <ProgressMarker key={`pg-wrap-${stt.status}`}>
              <ProgressMarkerGraphicContainer>
                <ProgressStemContainer>
                  {parsedStatusSet[ind - 1] &&
                    parsedStatusSet[ind - 1] !== "none" && (
                      <ProgressStem
                        cmplt={cmplt || pending}
                        color={prevColor}
                      />
                    )}
                </ProgressStemContainer>
                <ProgressMarkerCircle
                  cmplt={cmplt || pending}
                  color={pending ? "#FF7171" : stt.color}
                />
                <ProgressStemContainer>
                  {parsedStatusSet[ind + 1] &&
                    parsedStatusSet[ind + 1] !== "none" && (
                      <ProgressStem cmplt={cmplt} color={stt.color} />
                    )}
                </ProgressStemContainer>
              </ProgressMarkerGraphicContainer>
              <ProgressMarkerText>
                {stt.status == status && <b>{stt.name}</b>}
                {stt.status != status && <>{stt.name}</>}
              </ProgressMarkerText>
            </ProgressMarker>
          );
        } else {
          // Here we just render iconography for status transfer from one to another
          // this is either "normal", "approve" or "none"
          if (stt !== "approve") {
            return null;
          }
          return (
            <ProgressActionCircle
              key={`stt-${ind}`}
              cmplt={!foundStatus || nextPending}
              color={pending ? "#FF7171" : nextColor}
            >
              <Check
                style={{
                  fontSize: 10,
                  position: "relative",
                  top: -2,
                  right: -2,
                  color: "black",
                }}
              />
            </ProgressActionCircle>
          );
        }

        // Now fulfill statuses

        //
        //   </ProgressMarker>
        // )
      })}
    </ProgressContainer>
  );
};

const defaultStatusSet = [
  {
    name: "Created",
    status: "created",
  },
  {
    name: "Drafted",
    status: "drafted",
  },
  {
    name: "Submitted",
    status: "submitted",
  },
  {
    name: "Closed",
    status: "closed",
  },
];

const ProgressContainer = styled.div`
  position: absolute;

  height: 70px;
  width: 100%;

  z-index: 20;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ProgressMarker = styled.div`
  width: 100px;
  height: 100%;
  user-select: none;
`;

const ProgressMarkerGraphicContainer = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
`;

const ProgressMarkerCircle = styled.div`
  min-width: 17px;
  min-height: 17px;
  max-width: 17px;
  max-height: 17px;
  border-radius: 8px;

  background-color: ${(props) =>
    props.cmplt
      ? props.color
        ? props.color
        : "#77CB6A"
      : "#C4C4C4"}; // Only on completed status
`;

const ProgressActionCircle = styled.div`
  min-width: 13px;
  min-height: 13px;
  max-width: 13px;
  max-height: 13px;
  border-radius: 8px;

  position: relative;
  top: -19px;

  background-color: ${(props) =>
    props.cmplt
      ? props.color
        ? props.color
        : "#77CB6A"
      : "#C4C4C4"}; // Only on completed status
`;

const ProgressMarkerText = styled.div`
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const ProgressStemContainer = styled.div`
  height: 13px;
  max-width: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 60px;
`;

const ProgressStem = styled.div`
  background-color: ${(props) =>
    props.cmplt ? (props.color ? props.color : "#77CB6A") : "#C4C4C4"};
  height: 1px;
  width: 100%;
`;
