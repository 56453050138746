/**
 *
 * Anything to do with the root level users collection.
 * This is basically used for managing the CURRENT signed in user.
 * To get all the users associated with a project,
 * or otherwise deal with users other than the one signed in, you probably want src/projects/users.
 */

import { BaseCollection, BaseDocument, BaseData } from "../base";
import { auth } from "../firebase";

/** Data for a user. */
interface FormUserData extends BaseData {
  name: string;
  email: string;
}

/** The root level users collection. */
export class FormUsers extends BaseCollection<FormUserData> {

  /** A specific user. */
  user(id: string) {
    return this.doc(id) as FormUser;
  }

  /** Get the current user based on auth state. */
  currentUser(): FormUser | undefined {
    const { currentUser } = auth.getAuth();
    if (!currentUser) {
      return undefined;
    }
    return this.user(currentUser.uid);
  }
}

export class FormUser extends BaseDocument<FormUserData> {
  // Probably combine into an extension for User more broadly
  form_user: boolean = true;
  is_admin: boolean = false;
}