import React from "react";
import { FormContent } from ".";
import { ParagraphField } from "../../project/query/fields/queryfields";

export default () => (
  <FormContent style={{ alignItems: "center", justifyContent: "center" }}>
    <ParagraphField>
      Thank you for completing our form, we will contact you soon to follow up
      should it be necessary.
    </ParagraphField>
    <ParagraphField>
      A link to review your form has been sent to you as confirmation of our
      receipt.
    </ParagraphField>
    <ParagraphField>
      You may unsubmit and resubmit the form up to the form&apos;s due date in
      the &quot;Settings&quot; tab if you wish.
    </ParagraphField>
  </FormContent>
);
