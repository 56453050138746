import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Navigate, useSearchParams } from "react-router-dom";
import { LogoNoNav } from "../ui/logos";
import { GoogleSSO, MicrosoftSSO } from "../ui/auth";
import "../../fonts/fonts.css";

import {
  ThemeProvider as MuiThemeProvider,
  TextField,
  Button,
  Slide,
} from "@mui/material";
import { authTheme } from "../../themes/mui_theme_auth";
import { useHotkey } from "../../hooks/system";
import { clean_phone_number } from "../../tools";

import q from "@queryit/api";

export default ({ projects, user }) => {
  const [userIdentifier, setUserIdentifier] = useState("");
  const [confirmationObject, setConfirmationObject] = useState(undefined); // Necessary for mobile auth

  const [error, setError] = useState({ state: false, message: undefined });

  // Login stage & animation handling
  const [stage, setStage] = useState(1);

  // NOTE: We're not allowing users to edit this anymore
  const [remember, setRemember] = useState(true);

  const { next } = useSearchParams();

  const userIdentifierIsPhone = !userIdentifier.includes("@");

  const tryAdvance = () => {
    setError((ex) => ({ ...ex, state: false }));
    // Check the userIdentifier to see if it's a valid phone or email
    if (userIdentifier.includes("@") && userIdentifier.includes(".")) {
      // This is a "valid" email
      setStage(2);
      return;
    } else {
      // We'll need to check and try to clean a phone number out of this
      // We're stricter on the requirements here because we don't want to text a rando

      let phoneCandidate = clean_phone_number(userIdentifier);

      // Now check length is 10 and all numbers
      if (phoneCandidate.length === 10 && phoneCandidate.match(/^[0-9]+$/)) {
        // This is a valid phone number
        setUserIdentifier("+1" + phoneCandidate);
        setStage(2);
        return;
      } else if (
        phoneCandidate.includes("+") &&
        phoneCandidate.length === 12 &&
        phoneCandidate.match(/^\+[0-9]+$/)
      ) {
        // This is a valid phone number with a country code!
        setUserIdentifier(phoneCandidate);
        setStage(2);
        return;
      }
    }
    // If we're here, there's an error
    setError({ state: true, message: "Invalid phone or email" });
  };

  if (projects && user) {
    if (next && next != "") {
      return <Navigate to={next} />;
    } else {
      return <Navigate to="/app" />;
    }
  }

  return (
    <MuiThemeProvider theme={authTheme}>
      <LoginPage>
        <LoginContainer>
          <TopBrand>
            <Logo />
            <LogoText>Query-it</LogoText>
          </TopBrand>
          {/* Now we'll have slides for each component to smooth it out */}
          <Slide
            direction="right"
            in={stage == 1}
            appear={false}
            mountOnEnter
            unmountOnExit
          >
            <LoginStageContainer>
              <LoginError>{error.state ? error.message : ""}</LoginError>
              <LoginStage1
                remember={remember}
                setError={setError}
                userIdentifier={userIdentifier}
                setUserIdentifier={setUserIdentifier}
                tryAdvance={tryAdvance}
              />
            </LoginStageContainer>
          </Slide>
          <Slide
            direction="left"
            in={stage == 2 && !userIdentifierIsPhone}
            mountOnEnter
            unmountOnExit
          >
            <LoginStageContainer>
              <LoginError>{error.state ? error.message : ""}</LoginError>
              <LoginWithEmailStage2
                remember={remember}
                setError={setError}
                userIdentifier={userIdentifier}
                goBack={() => setStage(1)}
              />
            </LoginStageContainer>
          </Slide>
          {/* Phone number slides */}
          <Slide
            direction="left"
            in={
              stage == 2 &&
              userIdentifierIsPhone &&
              confirmationObject === undefined
            }
            mountOnEnter
            unmountOnExit
          >
            <LoginStageContainer>
              <LoginError>{error.state ? error.message : ""}</LoginError>
              <LoginWithPhoneStage2
                remember={remember}
                setError={setError}
                userIdentifier={userIdentifier}
                setUserIdentifier={setUserIdentifier}
                setConfirmationObject={setConfirmationObject}
                goBack={() => setStage(1)}
              />
            </LoginStageContainer>
          </Slide>
          <Slide
            direction="left"
            in={
              stage == 2 &&
              userIdentifierIsPhone &&
              confirmationObject !== undefined
            }
            mountOnEnter
            unmountOnExit
          >
            <LoginStageContainer>
              <LoginError>{error.state ? error.message : ""}</LoginError>
              <LoginWithPhoneStage3
                remember={remember}
                setError={setError}
                userIdentifier={userIdentifier}
                confirmationObject={confirmationObject}
                goBack={() => setConfirmationObject(undefined)}
              />
            </LoginStageContainer>
          </Slide>
        </LoginContainer>
      </LoginPage>
    </MuiThemeProvider>
  );
};

const LoginStage1 = ({
  remember,
  setError,
  userIdentifier,
  setUserIdentifier,
  tryAdvance,
}) => {
  useHotkey("Enter", tryAdvance);

  useEffect(() => {
    setError({ state: false, message: undefined }); // When we arrive here the error state should clear
  }, [setError]);

  return (
    <>
      <TextField
        variant="outlined"
        label="Email or mobile number"
        size="small"
        style={{ width: "70%" }}
        value={userIdentifier}
        onChange={(e) => setUserIdentifier(e.target.value)}
      />
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={tryAdvance}
      >
        Next
      </Button>
      <OrDivider>
        <Divider style={{ marginRight: "6px" }} />
        or
        <Divider style={{ marginLeft: "6px" }} />
      </OrDivider>
      {/* Single Sign-ons */}
      {/* Note: We know when these succeed because of the internal auth listeners in the app (api) */}
      <LoginStageSSOContainer>
        <MicrosoftSSO setError={setError} rememberMe={remember} />
      </LoginStageSSOContainer>
      <LoginStageSSOContainer>
        <GoogleSSO setError={setError} rememberMe={remember} />
      </LoginStageSSOContainer>
      <BottomPushBuffer style={{ height: "40px" }} />
    </>
  );
};

const LoginWithEmailStage2 = ({
  remember,
  setError,
  userIdentifier,
  goBack,
}) => {
  useHotkey("Enter", handleLoginAttempt);
  useHotkey("Escape", goBack);

  const [password, setPassword] = useState("");

  // Focus password field on mount
  const passwordRef = useRef(null);
  useLayoutEffect(() => {
    setTimeout(() => passwordRef.current.focus(), 300);
  }, []);

  function handleLoginAttempt() {
    q.auth
      .getAuth()
      .setPersistence(remember ? q.PERSISTENCE.local : q.PERSISTENCE.session);
    try {
      q.auth
        .signInWithEmailAndPassword(userIdentifier, password)
        .catch((err) => {
          let message = "";
          switch (err.code) {
            case "auth/user-not-found":
              message = "The email or password is incorrect";
              break;
            case "auth/wrong-password":
              message = "The email or password is incorrect";
              break;
            case "auth/internal-error":
              message = "An internal error has occured";
              break;
            case "auth/invalid-auth-event":
              message = "An internal error has occured";
              break;
            case "auth/no-auth-event":
              message = "An internal error has occured";
              break;
            case "auth/invalid-email":
              // theoretically you should not even be able to get to this stage with an incorrectly formatted email
              message = "Please enter a valid email";
              break;
            case "auth/quota-exceeded":
              message = "An internal error has occured";
              break;
            case "auth/timeout":
              message = "The login process has timed-out";
              break;
            case "auth/user-token-expired":
              message = "Credential not valid, please try again";
              break;
            case "auth/too-many-requests":
              message = "Too many login attempts. Try again later";
              break;
            case "auth/unverified-email":
              message = "Please verify your email";
              break;
            case "auth/user-disabled":
              message = "Your account has been disabled";
              break;
            default:
              message = "Something went wrong";
              break;
          }
          setError({
            state: true,
            message,
          });
        });
    } catch (err) {
      setError({ state: true, message: "No credentials provided" });
    }
  }

  return (
    <>
      <TextField
        variant="outlined"
        label="Email"
        size="small"
        style={{ width: "70%" }}
        disabled
        defaultValue={userIdentifier}
      />
      <TextField
        variant="outlined"
        label="Password"
        size="small"
        type="password"
        style={{ width: "70%", marginTop: "16px" }}
        onChange={(e) => setPassword(e.target.value)}
        inputRef={passwordRef}
      />
      <ForgotPasswordLink
        onClick={() => {
          try {
            q.auth
              .sendPasswordResetEmail(userIdentifier)
              .then((res) =>
                setError({ state: true, message: "Password reset email sent" })
              )
              .catch((err) =>
                setError({ state: true, message: "Email not found" })
              );
          } catch (err) {
            setError({ state: true, message: "No email provided" });
          }
        }}
      >
        Forgot your password?
      </ForgotPasswordLink>
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={handleLoginAttempt}
      >
        Login
      </Button>
      <OrDivider style={{ marginTop: "8px" }}>
        <Divider />
      </OrDivider>
      <Button
        style={{ width: "70%", marginTop: "4px" }}
        variant="contained"
        sx={{
          backgroundColor: "grey.300",
          color: "black",
          "&:hover": { backgroundColor: "grey.400" },
        }}
        onClick={goBack}
      >
        Go Back
      </Button>
      <BottomPushBuffer style={{ height: "40px" }} />
    </>
  );
};

const LoginWithPhoneStage2 = ({
  remember,
  setError,
  userIdentifier,
  setConfirmationObject,
  goBack,
}) => {
  const [sendCodeReady, setSendCodeReady] = useState(false);

  useLayoutEffect(
    () =>
      q.auth.useRecaptchaComponent(
        "visible-recaptcha",
        () => setSendCodeReady(true),
        () => setSendCodeReady(false)
      ),
    []
  );

  const handleLoginAttempt = () => {
    q.auth
      .getAuth()
      .setPersistence(remember ? q.PERSISTENCE.local : q.PERSISTENCE.session);
    try {
      q.auth
        .signInWithPhoneNumber(userIdentifier)
        .then((confirmationResult) => {
          // Now we'll need to pass the code, so set the stage to stage 3 and save the confirmation result object for submission
          setConfirmationObject(confirmationResult);
        })
        .catch((err) =>
          setError({ state: true, message: "Invalid Credentials" })
        );
    } catch (err) {
      setError({ state: true, message: "No credentials provided" });
    }
  };

  return (
    <>
      <LoginError style={{ height: "48px" }}>
        Complete the reCaptcha to have a code sent to your mobile number <br />
        <b>
          {userIdentifier.slice(0, 2)} {userIdentifier.slice(2, 5)}{" "}
          {userIdentifier.slice(5, 8)} {userIdentifier.slice(8, 12)}
        </b>
      </LoginError>
      <Captcha id="visible-recaptcha"></Captcha>
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={() => handleLoginAttempt()}
        disabled={!sendCodeReady}
      >
        Send Code
      </Button>
      <Button
        style={{ width: "70%", marginTop: "4px" }}
        variant="contained"
        color="default"
        onClick={goBack}
      >
        Go Back
      </Button>
    </>
  );
};

const LoginWithPhoneStage3 = ({
  remember,
  setError,
  confirmationObject,
  userIdentifier,
  goBack,
}) => {
  const [code, setCode] = useState("");

  useHotkey("Enter", submitCode);
  useHotkey("Escape", goBack);

  const submitCode = () => {
    confirmationObject
      .confirm(code)
      .then((res) => {
        // Successful login
        // Now we'll check if this mobile user is a first time login (we don't like those here!)
        if (q.auth.checkResultIsFirstTimeLogin(res)) {
          q.auth.signOut().then((_) =>
            setError({
              state: true,
              message:
                "This phone number is not verified for login. Please try email or Single Sign-On",
            })
          );
        }
      })
      .catch((err) => {
        setError({ state: true, message: "Invalid Code" });
        setCode(""); // We reset code on invalid submission
      });
  };

  return (
    <>
      <LoginError style={{ height: "48px" }}>
        A code has been sent to your mobile number <br />
        <b>
          +1 {userIdentifier.slice(0, 3)} {userIdentifier.slice(3, 6)}{" "}
          {userIdentifier.slice(6, 10)}
        </b>
      </LoginError>
      <TextField
        variant="outlined"
        label="Enter the code sent to your phone"
        size="small"
        style={{ width: "70%" }}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button
        style={{ width: "70%", marginTop: "16px" }}
        variant="contained"
        color="secondary"
        onClick={() => submitCode()}
      >
        Confirm
      </Button>
      <Button
        style={{ width: "70%", marginTop: "4px" }}
        variant="contained"
        color="default"
        onClick={goBack}
      >
        Go Back
      </Button>
    </>
  );
};

const LoginPage = styled.div`
  height: 100%;
  width: 100%;
  background: #1c1f3c;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: "Roboto", sans-serif;
`;

const LoginContainer = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 8px;

  background: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LoginStageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // margin-top: 10px;
  flex: 2 2 auto;
  position: relative;
`;

const TopBrand = styled.div`
  height: 64px;
  margin-top: 4px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const Logo = styled(LogoNoNav)`
  position: relative;
  margin-left: 15px;
  height: 48px;
`;

const LogoText = styled.div`
  color: #7a1d84;

  font-size: 36px;
  font-family: sol, Ubuntu;
  font-style: strong;
  font-weight: bold;

  margin-left: 8px;
`;

const OrDivider = styled.div`
  margin: 16px 0 0 0;

  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 20px;
`;

const Divider = styled.div`
  background-color: rgba(0, 0, 0, 0.3);

  height: 1px;
  display: flex;

  flex: 3 1 50%;
`;

const LoginStageSSOContainer = styled.div`
  display: flex;
  width: 70%;
`;

const LoginError = styled.div`
  text-align: left;
  height: 14px;
  width: 70%;
  margin: 0px 0px 12px 0;
  box-sizing: border-box;
  // color: black;
`;

const BottomPushBuffer = styled.div`
  width: 100%;
  height: 1px;
`;

const Captcha = styled.div`
  width: 304px;
  height: 78px;
  margin: auto;
  margin-top: 16px;
`;

const ForgotPasswordLink = styled.a`
  width: 70%;
  margin-top: 6px;
  font-size: 12px;
  color: grey;
  text-decoration: underline;

  cursor: pointer;
`;
