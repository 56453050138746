import { BaseCollection, BaseDocument, BaseData } from "../base";

interface InterestSchema extends BaseData {
  name: string;
  projectId: string;
}

export class Interests extends BaseCollection<InterestSchema> {

  interest(id: string) {
    return this.doc(id) as Interest;
  }
}

type Interest = BaseDocument<InterestSchema>;
