import React from "react";
import styled from "@emotion/styled";
import { TableBodyTCell } from "./body";
import { log_error } from "../../../tools/logger";

export const TableFooter = ({ columns, data }) => {
  return (
    <TableBodyFooter>
      <TableFooterRow>
        {/* First is empty cell for the modifier column */}
        <TableFooterCell />
        {/* Now map the columns */}
        {columns?.map((col, colIndex) => (
          <TableFooterCalculationCell
            key={colIndex}
            scheme={col}
            tableData={data}
          />
        ))}
      </TableFooterRow>
    </TableBodyFooter>
  );
};

const TableFooterCalculationCell = ({ scheme, tableData }) => {
  // First check if the column has a calculation
  if (scheme?.footerCalculation === undefined) {
    return <TableFooterCell />;
  }

  // Now let's resolve the funciton we'll use for the calculation
  let calculationFunction = null;
  let calculationLabel = "";
  let calculationResult = undefined;
  switch (scheme.footerCalculation) {
    case "sum":
      calculationLabel = "Sum";
      calculationFunction = (valueSet) => valueSet.reduce((a, b) => a + b, 0);
      break;
    case "avg":
      calculationLabel = "Average";
      calculationFunction = (valueSet) =>
        valueSet.reduce((a, b) => a + b, 0) / valueSet.length;
      break;
    case "min":
      calculationLabel = "Minimum";
      calculationFunction = (valueSet) => Math.min(...valueSet);
      break;
    case "max":
      calculationLabel = "Maximum";
      calculationFunction = (valueSet) => Math.max(...valueSet);
      break;
    default:
      // This means we're raw dawgin it here, we'll just use a basic label
      calculationLabel = "Custom";
      // Eval the function
      try {
        calculationFunction = new Function("data", scheme.footerCalculation);
      } catch (e) {
        log_error("Error in custom calculation function", e);
      }
      break;
  }

  // Now put the data together in a valueset
  const valueSet = tableData.map((row) => row[scheme.id] ?? 0);
  // Now run the function
  try {
    calculationResult = calculationFunction(valueSet);
  } catch (e) {
    log_error("Error in calculation function evaluation", e);
  }

  return (
    <TableFooterCell>
      <TableFooterCalculationContainer>
        {calculationLabel}: {calculationResult ?? "-"}
      </TableFooterCalculationContainer>
    </TableFooterCell>
  );
};

const TableBodyFooter = styled.tbody``;
const TableFooterRow = styled.tr``;
const TableFooterCell = styled(TableBodyTCell)`
  border: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const TableFooterCalculationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
