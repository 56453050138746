import React from "react";
import { DialogContent, Modal } from "@mui/material";
import { ModalViewFullScreen } from "../ui/containers";

// Modal for viewing
export default ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <DialogContent>
        <ModalViewFullScreen>
          <TOSFormatted />
        </ModalViewFullScreen>
      </DialogContent>
    </Modal>
  );
};

export const TOSFormatted = () => {
  return (
    <div>
      <ol>
        <li>
          <b>
            <u>Interpretation</u>
          </b>
          <ol type="a">
            <li>
              <b>Definitions</b>
              <ol>
                In this terms of use, the following terms shall have the
                respective meanings indicated below:
                <br />
                &quot;<b>Agreements</b>&quot;
                <br />
                means this Query-It Inc. Terms of Use (the “Terms of Use”), the
                Query-It Software as a Service Agreement (the “SaaS Agreement”),
                the Query-It Inc. End User License Agreement (“EULA”), and all
                schedules therein referred to.
                <br />
                &quot;<b>Applicable Laws</b>&quot;
                <br />
                means any and all (i) laws, statutes, rules, regulations, by
                laws, codes, treaties, constitutions and ordinances
                (&quot;Laws&quot;), (ii) order, directive, judgment, decree,
                award or writ of any court (including a court of equity),
                arbitrator or arbitration panel, or any Governmental Authority
                or other body exercising adjudicative, regulatory, judicial or
                quasi-judicial powers, including any stock exchange
                (&quot;Orders&quot;), and (iii) policies, guidelines, standards,
                requirements, notices and protocols of any Governmental
                Authority (&quot;Policies&quot;); which are applicable to or
                govern Customer, Service Provider or the transactions
                contemplated by this Terms of Use.
                <br />
                &quot;<b>Authorized User</b>&quot;
                <br />
                means Customer&apos;s employees, consultants, contractors, and
                agents (i) who are authorized by Customer to access and use the
                Services under the rights granted to Customer pursuant to the
                SaaS Agreement.
                <br />
                &quot;<b>Business Day</b>&quot;
                <br />
                means any calendar day except as specified by Query-It Inc.
                <br />
                &quot;<b>Confidential Information</b>&quot;
                <br />
                means the Agreement, the Software, Customer Data and all ideas,
                designs, business models, databases, drawings, documents,
                diagrams, formulas, test data, marketing, financial or personnel
                data, sales information, customer or supplier information,
                including information provided by such customers or suppliers,
                or any other information already furnished and to be furnished
                or made available by one Party to the other, whether in oral,
                written, graphic or electronic form including any such
                information exchanged during informational sessions designated
                as confidential, including, without limitation, information
                concerning a Party&apos;s actual and potential customers and
                other Intellectual Property Rights of such Party, provided,
                however, that Confidential Information shall not include any
                data or information: (i) that, at the time of disclosure, is in
                or, after disclosure, becomes part of the public domain, through
                no act or failure on the part of the receiving Party, whether
                through breach of this Terms of Use or otherwise; (ii) that,
                prior to disclosure by the disclosing Party, was already in the
                possession of the receiving Party, as evidenced by written
                records kept by the receiving Party in the ordinary course of
                its business, or as evidenced by proof of actual prior use by
                the receiving Party; (iii) independently developed by the
                receiving Party, by Persons having no direct or indirect access
                to the disclosing Party&apos;s Confidential Information provided
                that the receiving Party provides clear and convincing evidence
                of such independent development; (iv) which, subsequent to
                disclosure, is obtained from a third Person: (A) who is lawfully
                in possession of the such information; (B) who is not in
                violation of any contractual, legal, or fiduciary obligation to
                either Party, as applicable, with respect to such information;
                and (C) who does not prohibit either Party from disclosing such
                information to others; or (v) is further disclosed with the
                prior written consent of the disclosing Party, but only to the
                extent of such consent.
                <br />
                &quot;<b>Customer Data</b>&quot;
                <br />
                means collectively any data, files, documentation or other
                information: (i) that Customer or any of its Authorized Users
                may upload to the Software when using the Services; and (ii)
                processed through the use of the Services.
                <br />
                &quot;<b>Effective Start Date</b>&quot;
                <br />
                means the date specified in the SaaS Agreement.
                <br />
                &quot;<b>Fees</b>&quot;
                <br />
                means the Fees as provided in Section 7 herein and in Section
                4(a) of the SaaS Agreement, to be paid by Customer to Service
                Provider for the performance of the Services.
                <br />
                &quot;<b>Governmental Authority</b>&quot;
                <br />
                means any domestic, foreign or supranational government, whether
                federal, provincial, state, territorial or municipal; and any
                governmental agency, ministry, department, tribunal, commission,
                bureau, board or other instrumentality, including inter-national
                institutions, exercising or purporting to exercise legislative,
                judicial, regulatory or administrative functions of, or
                pertaining to, government.
                <br />
                &quot;<b>Intellectual Property</b>&quot;
                <br />
                means any property, tangible or intangible, that may be subject
                to Intellectual Property Rights, including without limitation,
                ideas, formulae, algorithms, concepts, techniques, processes,
                procedures, approaches, methodologies, plans, systems, research,
                information, documentation, data, data compilations,
                specifications, requirements, designs, diagrams, programs,
                inventions, technologies, software (including its source code),
                tools, products knowledge, know-how, including without
                limitation, trade secrets, and other materials or things.
                <br />
                &quot;<b>Intellectual Property Rights</b>&quot;
                <br />
                means: (a) any and all proprietary rights anywhere in the world
                provided under: (i) patent law; (ii) copyright law, including
                moral rights; (iii) trademark law; (iv) design patent or
                industrial design law; (v) semiconductor chip or mask work law;
                (vi) trade secret law; (vii) privacy law; or (viii) any other
                statutory provision or common law principle applicable to this
                Terms of Use which may provide a right in either: (A)
                Intellectual Property; or (B) the expression or use of
                Intellectual Property; and (b) any and all applications,
                registrations, licenses, sub-licenses, franchises, agreements or
                any other evidence of a right in any of the foregoing.
                <br />
                &quot;<b>Objectionable Content</b>&quot;
                <br />
                means content that infringes any Applicable Laws, regulations or
                third-party rights, and content which is obscene, indecent,
                pornographic, seditious, offensive, defamatory, threatening,
                liable to incite racial hatred, menacing, blasphemous,
                misleading, deceptive or in breach of any person&apos;s
                Intellectual Property Rights.
                <br />
                &quot;<b>Party</b>&quot;
                <br />
                means either Service Provider or Customer; and
                &quot;Parties&quot; means both of them.
                <br />
                &quot;<b>Person</b>&quot;
                <br />
                means any individual, estate, sole proprietorship, firm,
                partnership, unincorporated association, unincorporated
                syndicate, unincorporated organization, limited liability
                company, corporation, body corporate, trustee, trust,
                Governmental Authority or other entity or organization and
                includes any successor to any of the foregoing.
                <br />
                &quot;<b>Privacy Policy</b>&quot;
                <br />
                Means the Query-It Privacy Policy as amended from time to time
                and located here:{" "}
                <a href="https://queryitapp.com/privacy">
                  https://queryitapp.com/privacy
                </a>
                .
                <br />
                &quot;<b>Service Provider Platform</b>&quot;
                <br />
                means such devices and peripherals, including all computer
                hardware, software, network elements, and electrical and
                telecommunications infrastructure.
                <br />
                &quot;<b>Services</b>&quot;
                <br />
                means collectively the Software to be provided by Service
                Provider to Customer.
                <br />
                &quot;<b>Software</b>&quot;
                <br />
                means the SaaS Software licensed to the Customer as set out in
                the SaaS Agreement signed by the Customer.
                <br />
                &quot;<b>Virus</b>&quot;
                <br />
                means a piece of code usually (but not necessarily) disguised as
                something else that causes some unexpected and, for the victim,
                usually undesirable, event and which is designed so that it may
                automatically spread to other computer users; the term
                &quot;Virus&quot; will also be deemed to include worms,
                cancelbots, trojan horses, harmful contaminants (whether
                self-replicating or not) and nuisance-causing or otherwise
                harmful applets.
                <br />
              </ol>
            </li>
            <li>
              <b>Headings</b>
              <br />
              The division of this Terms of Use into articles, sections,
              schedules and other subdivisions, and the inclusion of headings,
              are for convenience of reference only and shall not affect the
              construction or interpretation of this Terms of Use. The headings
              in this Terms of Use are not intended to be full or precise
              descriptions of the text to which they refer. Unless something in
              the subject matter or context is inconsistent therewith,
              references herein to Articles and Sections are to Articles and
              Sections of the Agreement.
            </li>
            <li>
              <b>Entire Agreement</b>
              <br />
              This Terms of Use, together with the SaaS Agreement, EULA, and the
              Privacy Policy constitutes the entire agreement between the
              Parties pertaining to the subject matter hereof and supersedes all
              prior agreements, negotiations, discussions and understandings,
              written or oral, between the Parties. Except as expressly provided
              in this Terms of Use, there are no representations, warranties,
              conditions other agreements or acknowledgements, whether direct or
              collateral, express or implied, that form part of or affect this
              Terms of Use. The execution of this Terms of Use has not been
              induced by, nor do either of the Parties rely upon or regard as
              material, any representations, warranties, conditions, other
              agreements or acknowledgements not expressly made in this Terms of
              Use or in the other documents to be delivered pursuant hereto.
            </li>
            <li>
              <b>Governing Law</b>
              <br />
              This Terms of Use shall be governed by, and construed and enforced
              in accordance with, the laws in force in the Province of Alberta,
              Canada (excluding any conflict of laws rule or principle which
              might refer such construction to the laws of another
              jurisdiction). The Parties hereto agree to submit to the exclusive
              jurisdiction of the courts of the Province of Alberta, Canada and
              waive any objection relating to improper venue or forum non
              conveniens to the conduct of any proceeding in any such court.
            </li>
            <li>
              <b>Severability</b>
              <br />
              In the event that any provision (or any portion of a provision) of
              this Terms of Use shall, for any reason, be held by a court of
              competent jurisdiction to be invalid, illegal, or unenforceable
              for any reason, such invalidity, illegality or unenforceability
              shall not affect any other provision hereof and this Terms of Use
              shall be construed as if such invalid, illegal or unenforceable
              provision (or portion of a provision) had never been contained
              herein in regard to that particular jurisdiction.
            </li>
            <li>
              <b>Additional Rules of Interpretation</b>
              <ol type="i">
                <li>
                  In this Terms of Use, unless the context requires otherwise,
                  words in one gender include all genders and words in the
                  singular include the plural and vice versa.
                </li>
                <li>
                  Wherever the words &quot;include&quot;, &quot;includes&quot;
                  or &quot;including&quot; are used in this Terms of Use, they
                  shall be deemed to be followed by the words &quot;without
                  limitation&quot; and the words following &quot;include&quot;,
                  &quot;includes&quot; or &quot;including&quot; shall not be
                  considered to set forth an exhaustive list.
                </li>
                <li>
                  The terms &quot;this Terms of Use&quot;, &quot;hereof&quot;,
                  &quot;hereunder&quot; and similar expressions refer to this
                  Terms of Use and not to any particular article, section or
                  other portion hereof and include any agreement supplemental
                  hereto.
                </li>
                <li>
                  Unless otherwise defined herein, words or abbreviations which
                  have well-known trade meanings are used herein with those
                  meanings.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Services Generally</u>
          </b>
          <br />
          Subject to the terms and conditions contained in this Terms of Use and
          payment of the Fees by Customer to Service Provider, Service Provider
          shall perform the Services as set out herein to Customer in accordance
          with the terms hereof and the policies and procedures in relation to
          the Service, which are established by Service Provider from time to
          time. In the event of a conflict between the preceding documents, the
          following shall be the order of precedence: (i) this Terms of Use; and
          (ii) the policies and procedures set out by Service Provider from time
          to time, which may be posted on the Service Provider&apos;s website.
        </li>
        <li>
          <b>
            <u>Service Provider Platform</u>
          </b>
          <br />
          <ol type="a">
            <li>
              <b>Provision and Access to Service Provider Platform</b>
              <br />
              The Service will require access and use of the Service Provider
              Platform. Service Provider shall operate and maintain the Service
              Provider Platform in accordance with the terms of this Terms of
              Use. Access to the Service Provider Platform may be through a
              secure connection with the public internet or through the use of a
              Network Service Provider. Customer acknowledges and agrees that
              Service Provider is not responsible or liable for any
              communication over the public internet, or for the Network Service
              Provider&apos;s network or its operation or the Network Service
              Provider&apos;s network&apos;s failure to deliver communication to
              and from the Service Provider Platform on a timely basis.
            </li>
            <li>
              <b>Maintenance</b>
              <br />
              From time to time, it will be necessary to perform maintenance on
              the Service Provider Platform. Such maintenance includes routine
              maintenance to ensure the continued provision of the Services
              through the continued operation of the Service Provider Platform
              or upgrading, updating or enhancing the Service Provider Platform.
              Commercially reasonable efforts to perform such maintenance at
              such times to minimize the impact of any downtime of the Service
              Provider Platform to Customer will be implemented. To the extent
              Service Provider is able, Service Provider shall notify Customer
              in advance of any scheduled maintenance by posting a message on
              the website or by sending an e-mail to the designated Customer
              Service Manager of the scheduled maintenance time and the
              anticipated duration of such maintenance.
            </li>
            <li>
              <b>Changes</b>
              <br />
              Service Provider may, at any time, with or without notice to
              Customer: (i) make changes that are necessary to comply with
              applicable safety, security or other statutory requirements or
              orders from applicable Governmental Authorities; (ii) supplement
              or make changes to its rules of operations, access procedures,
              security and privacy procedures and policies; and (iii) change the
              components, type and location of the Service Provider Platform.
            </li>
            <li>
              <b>Customer Data</b>
              <br />
              Customer acknowledges and agrees that Service Provider: (i) will
              not be responsible for the accuracy, completeness or adequacy of
              any Customer Data or the results generated from any Customer Data
              uploaded to the Service Provider Platform and processed by the
              Software; (ii) has no control over any Customer Data or the
              results therefrom; and (iii) does not purport to monitor Customer
              Data.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Use of Services</u>
          </b>
          <ol type="a">
            <li>
              <b>Grant by Service Provider</b>
              <br />
              Subject to the terms and conditions of this Terms of Use
              commencing and for the duration of the term as specified in the
              SaaS Agreement, Service Provider hereby grants to Customer a
              non-exclusive, non-transferable, revocable right, for
              Customer&apos;s internal business purposes to access the Service
              Provider Platform as set out herein for the purpose of:
              <ol type="i">
                <li>using the Software;</li>
                <li>
                  uploading, processing, viewing, displaying, using and
                  downloading Customer Data to and from the Service Provider
                  Platform.
                </li>
              </ol>
              Customer acknowledges that this Terms of Use is a services
              agreement and the Service Provider will not be delivering copies
              of the Software to Customer as part of the Services.
            </li>
            <li>
              <b>Restrictions on Use</b>
              <br />
              Any rights not granted herein are strictly reserved by Service
              Provider. Customer shall not: (i) permit any third Person to use
              Service Provider&apos;s Intellectual Property; (ii) re-license or
              sublicense, lease, loan or otherwise distribute Service
              Provider&apos;s Intellectual Property to any third Person; (iii)
              process or permit to be processed the data of any other Person; or
              (iv) use Service Provider&apos;s Intellectual Property or the
              Service in the operation of a service bureau. Customer shall not,
              and shall not permit others to, reverse engineer, decompile,
              disassemble or translate any software used by Service Provider to
              deliver the Services, including the Software, or otherwise attempt
              to view, display or print such software, including the
              Software&apos;s, source code.
            </li>
            <li>
              <b>Authorized Users</b>
              <br />
              Only Authorized Users may be authorized by Customer to use the
              Services or the Software as strictly provided for in this Terms of
              Use, the EULA and subject to the Privacy Policy. Customer agrees
              and acknowledges that any Authorized Users will be required to opt
              into the EULA and the Privacy Policy prior to having access to our
              Service Provider Platforms. Customer shall ensure that all
              Authorized Users are aware of the provisions of this Terms of Use,
              the EULA and the Privacy Policy including their obligation to
              comply with the provisions contained therein as it relates to
              their use of the Services and the Software. Customer shall be
              responsible and liable for the actions and omissions of each
              Authorized User and their compliance of the provisions herein.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Customer&apos;s Obligations</u>
          </b>
          <ol type="a">
            <li>
              <b>Grant by Customer</b>
              <br />
              Customer hereby grants to Service Provider a royalty-free,
              non-exclusive, non-transferable right and licence to use, copy,
              store, display and analyze the Customer Data: (i) for the purpose
              of enabling Service Provider to perform the Services under this
              Terms of Use; and (ii) to improve upon the software and Services
              provided for under this Terms of Use;
            </li>
            <li>
              <b>Customer Responsibilities</b>
              <br />
              In addition to its other obligations contained in this Terms of
              Use, Customer shall:
              <ol type="i">
                <li>
                  be responsible for procuring, installing, operating,
                  supporting and maintaining Customer&apos;s systems, including
                  computer hardware and software, including browsers, necessary
                  for Customer to access the Services;
                </li>
                <li>
                  be responsible for procuring and maintaining communication
                  services, including high speed internet connections between
                  Customer&apos;s systems and the Service Provider Platform;
                </li>
                <li>
                  assign, record and control the issuance and use of all
                  Authorized Users;
                </li>
                <li>
                  be responsible for the accuracy, completeness and adequacy of
                  all Customer Data; for the management, manipulation and
                  processing of Customer Data; and the maintenance of all
                  Customer Data;
                </li>
                <li>
                  use the Services, Service Provider Platform and Software, by
                  itself and Authorized Users, in accordance with Applicable
                  Laws and the terms of this Terms of Use; and
                </li>
                <li>
                  comply, at all times, with all applicable legal and regulatory
                  requirements and with Service Provider&apos;s conduct and
                  security policies in respect of the use of the Services and
                  the Software.
                </li>
              </ol>
            </li>
            <li>
              <b>Prohibited Activities</b>
              <br />
              Customer shall not:
              <ol type="i">
                <li>use the Services for improper or unlawful purposes;</li>
                <li>
                  include, or knowingly allow others to include, any
                  Objectionable Content or introduce Viruses to the Service
                  Provider Platform and shall institute such security procedures
                  and safeguards as Customer deems necessary to prevent the
                  posting, uploading or inclusion of any Objectionable Content
                  or Viruses to the Service Provider Platform;
                </li>
                <li>
                  intercept or attempt to intercept any messages transmitted to
                  and from the Service Provider Platform that are not intended
                  for Customer or any of its Authorized Users;
                </li>
                <li>
                  take any action that imposes an unreasonable or
                  disproportionately large load on the Service Provider
                  Platform;
                </li>
                <li>
                  use the Services or the Software to develop any derivative
                  works or any functionally compatible or competitive software;
                </li>
                <li>
                  copy or download the Software or any other software used by
                  Service Provider to provide the Services and which is
                  contained within the Service Provider Platform; or
                </li>
                <li>
                  remove any copyright or other proprietary rights notice on the
                  Software or any copies thereof.
                </li>
              </ol>
            </li>
            <li>
              <b>Viruses</b>
              <br />
              If Service Provider, in its absolute discretion, forms the view
              that any Customer Data or any other information or files uploaded
              by Customer or any of its Authorized Users contains or includes a
              Virus or is considered Objectionable Content, Service Provider may
              remove such Customer Data, information or file from the Service
              Provider Platform and take such other action as Service Provider
              deems necessary to protect the integrity and operation of the
              Services, Service Provider Platform and the Software. Any costs
              associated with such removal may be charged by Service Provider to
              Customer. Service Provider shall notify Customer of its actions
              under this para. 5(d) as soon as reasonably possible.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Audit Rights</u>
          </b>
          <ol type="a">
            <li>
              <b>Service Provider&apos;s Audit Right</b>
              <ol type="i">
                <li>
                  Service Provider reserves the right to monitor and audit
                  Customer&apos;s and its Authorized Users&apos; usage of the
                  Services for the purpose of (among others) ensuring compliance
                  with the terms of this Terms of Use, including without
                  limitation para. 5(c). Any such audit may be carried out by
                  Service Provider or a third party authorised by Service
                  Provider, at Service Provider&apos;s expense.
                </li>
                <li>
                  If Service Provider&apos;s monitoring activities or its audit
                  pursuant to para. 6(a)(i) reveals that Customer&apos;s or any
                  Authorized User&apos;s use of the Services is in contravention
                  of this Terms of Use, including any Applicable Laws, then
                  Service Provider may immediately suspend and discontinue the
                  Services to Customer or to that specific End User, at Service
                  Provider&apos;s sole discretion and without notice to
                  Customer. Service Provider shall notify Customer of such
                  suspension as soon as reasonably possible, which notice shall
                  set out the circumstances of the suspension. If Customer
                  rectifies the situation to Service Provider&apos;s
                  satisfaction, then Service Provider will reinstate the
                  Services. If Customer does not rectify the situation within a
                  reasonable period of time, then it shall be deemed a material
                  breach of this Terms of Use and Service Provider shall be free
                  to terminate this Terms of Use under para. 9(c) and pursue any
                  remedies available to it.
                </li>
              </ol>
            </li>
            <li>
              <b>Coordination of Regulatory Audits</b>
              <br />
              Customer hereby agrees that Service Provider may hire an
              independent third-party auditor to conduct an audit in
              satisfaction of para. 6(a) and to provide the results of such
              audit to Customer in lieu of Customer conducting its own audit.
              Alternatively, and if consented to by Service Provider, Customer
              shall coordinate with Service Provider regarding the timing, scope
              and processes of any audit conducted by Customer under para. 6(a)
              to minimize any disruption to the Services and duplication of
              effort with any other similar audit.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Fees and Payment</u>
          </b>
          <ol type="a">
            <li>
              <b>Fees</b>
              <br />
              Fees, payment terms and invoicing are as set out in the SaaS
              Agreement. For terms that have expired beyond what is contracted
              in the SaaS Agreement and have not been renewed, Fees will default
              to 120% of the Fee of a standard monthly subscription rate of a
              one (1) year contract. The Fees do not include applicable taxes.
              Customer agrees to pay the Fees and applicable taxes in accordance
              with the payment terms as set out in the SaaS Agreement.
            </li>
            <li>
              <b>Taxes</b>
              <br />
              Customer shall pay any and all taxes, however designated or
              incurred, which are paid or payable as a result of or otherwise in
              connection with the transactions contemplated in this Terms of Use
              including, without limitation, federal, provincial and local,
              excise, sales, use, goods and services, harmonized, value added
              and any taxes or other amounts in lieu thereof, except for any
              taxes based on Service Provider&apos;s net income.
            </li>
            <li>
              <b>Interest on Late Payments</b>
              <br />
              Where Customer fails to pay any amount in accordance with the
              payment terms set out in The Agreement, Service Provider shall
              have the right, in addition to any other rights or remedies
              available to it, to charge, and Customer shall pay, interest on
              such overdue amounts at the rate of 8% per month calculated daily,
              compounded monthly both before and after any court judgement in
              respect of the same from the date such payment was due.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Term</u>
          </b>
          <br />
          The term of this Terms of Use and the rights and obligations of the
          Parties hereto shall commence as of the Effective Start Date as
          specified in the SaaS Agreement.
        </li>
        <li>
          <b>
            <u>Suspension and Termination</u>
          </b>
          <ol type="a">
            <li>
              <b>Suspension of Services</b>
              <br />
              In the event that Customer does not pay the Fees or any portion
              thereof, when due, Service Provider may immediately suspend
              Customer&apos;s and each of its Authorized Users&apos; right to
              receive the Services and access and use of the Software.
            </li>
            <li>
              <b>Customer&apos;s Right to Terminate</b>
              <br />
              Subject to para. 9(e), Customer may terminate this Terms of Use
              and the rights granted hereunder without prejudice to enforcement
              of any other legal right or remedy, immediately upon giving
              written notice of such termination if Service Provider breaches
              any material provision of this Terms of Use and such breach
              continues for a period of twenty (20) Business Days after delivery
              of a written notice by Customer requiring Service Provider to
              correct such failure;
            </li>
            <li>
              <b>Service Provider&apos;s Right to Terminate</b>
              <br />
              Subject to para 9(e) Service Provider may terminate this Terms of
              Use and the rights granted hereunder without prejudice to
              enforcement of any other legal right or remedy, immediately upon
              giving written notice of such termination if Customer:
              <ol type="i">
                <li>
                  fails to pay in full any sum owing by it under this Terms of
                  Use by the due date thereof and such failure continues for a
                  period of five (5) Business Days after delivery of a written
                  notice by Service Provider requiring Customer to correct such
                  failure;
                </li>
                <li>
                  infringes the Intellectual Property Rights of Service
                  Provider;
                </li>
                <li>
                  breaches any other provision of this Terms of Use and such
                  breach continues for a period of twenty (20) Business Days
                  after delivery of a written notice by Service Provider
                  requiring Customer to correct such failure; or
                </li>
                <li>
                  becomes or is adjudicated insolvent or bankrupt, admits in
                  writing its inability to pay its debts as they mature, or
                  makes an assignment for the benefit of creditors; or Customer
                  applies for or consents to the appointment of any receiver,
                  trustee or similar officer for it or for all or any
                  substantial part of its property; or such receiver, trustee or
                  similar officer is appointed without the consent of Customer;
                  or Customer institutes any bankruptcy, insolvency,
                  reorganization, moratorium, arrangement, readjustment or debt,
                  dissolution, liquidation or similar proceeding relating to it
                  under the laws of any jurisdiction, or any such proceeding is
                  instituted against Customer and is not dismissed within sixty
                  (60) Business Days; or any judgment, writ, warrant or
                  attachment or execution of similar process is issued or levied
                  against a substantial part of Customer&apos;s property and
                  remains unsatisfied for sixty (60) Business Days.
                </li>
              </ol>
            </li>
            <li>
              <b>Waiver</b>
              <br />
              The waiver by either Party of a breach or default of any provision
              of this Terms of Use by the other Party shall not be effective
              unless in writing and shall not be construed as a waiver of any
              succeeding breach of the same or of any other provision. Nor shall
              any delay or omission on the part of either Party to exercise or
              avail itself of any right, power or privilege by such Party
              constitute a waiver.
            </li>
            <li>
              <b>Effect of Termination</b>
              <br />
              Upon the termination of this Terms of Use for any reason:
              <ol type="i">
                <li>
                  Service Provider shall terminate and invalidate any access to
                  the Service associated with Customer and any of its Authorized
                  Users;
                </li>
                <li>
                  Service Provider shall, but not earlier than 10 Business Days
                  after the termination or expiration of this Terms of Use,
                  destroy any copies of the Customer Data contained in the
                  Service Provider Platform;
                </li>
                <li>
                  Customer shall pay to Service Provider the full amount of all
                  Fees payable for the remainder of the Term hereunder as of the
                  date of termination, if any, whether already invoiced or not
                  (including any amounts due as late payment charges), and any
                  other monies owing to Service Provider hereunder; and
                </li>
              </ol>
              Customer acknowledges and agrees if Customer fails to download the
              Customer Data from the Service Provider Platform in a timely
              manner, it may not have access to such information or such
              information may be destroyed by Service Provider in accordance
              with the terms of this para. 9(e). It is Customer&apos;s
              responsibility to download and obtain all Customer Data prior to
              the expiration or termination of this Terms of Use. Service
              Provider shall have no responsibility, or any liability to
              Customer, for maintaining or providing to Customer the Customer
              Data or any portion thereof from and after the 30th Business Day
              after the termination or expiration of this Terms of Use.
            </li>
            <li>
              <b>Survival of Covenants</b>
              <br />
              Notwithstanding the termination or expiration of this Terms of Use
              for any reason, the covenants set out in this para. 9(f) and
              paras. 7(c), 9(e), 10, 11, 14, 15, 16, 17, 18(a) and those
              provisions set out in para. 1, as necessary to interpret the
              foregoing provisions, of this Terms of Use shall survive any such
              termination or expiration.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Ownership</u>
          </b>
          <ol type="a">
            <li>
              <b>Service Provider&apos;s Ownership</b>
              <br />
              Customer acknowledges and agrees that, as between Customer and
              Service Provider, Service Provider owns all worldwide right, title
              and interest, including all Intellectual Property Rights, in and
              to: (i) the Service Provider Platform; (ii) Software, and (iii)
              any modifications, enhancements, upgrades, updates or
              customization to the Software. Customer does not acquire any
              rights, title or ownership interests of any kind whatsoever,
              express or implied, in any of the foregoing other than the
              licenses granted herein.
            </li>
            <li>
              <b>Customer&apos;s Ownership</b>
              <br />
              Service Provider acknowledges and agrees that all worldwide right,
              title and interest, including all Intellectual Property Rights in
              and to the Customer Data, shall be the exclusive property of
              Customer. Service Provider does not acquire any rights, title or
              ownership interest of any kind whatsoever, express or implied, in
              any of the Customer Data, other than the license granted herein
              (save and except for the uses outlined in para 5(a) of this Terms
              of Use.
            </li>
            <li>
              <b>No Reverse Engineering</b>
              <br />
              Without limiting the generality of the foregoing, the Customer
              shall not reverse engineer, decompile, disassemble or otherwise
              attempt to derive the design, device or create any derivative
              software from, any of the Software, the Service provider Platform,
              or any other items described in 10(a).
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Confidentiality</u>
          </b>
          <ol type="a">
            <li>
              <b>Obligation</b>
              <br />
              Each Party acknowledges that all Confidential Information consists
              of confidential and proprietary information of the disclosing
              Party. Each Party shall, and shall cause its employees, agents and
              contractors to hold Confidential Information of the other Party in
              confidence, and shall use the same degree of care by instruction,
              agreement or otherwise, to maintain the confidentiality of the
              other Party&apos;s Confidential Information that it uses to
              maintain the confidentiality of its own Confidential Information,
              but with at least a reasonable degree of care commensurate with
              the nature and importance of such Confidential Information. Each
              Party agrees not to make use of Confidential Information other
              than for the exercise of rights or the performance of obligations
              under this Terms of Use, and not to release, disclose, communicate
              it or make it available to any third person other than employees,
              agents and contractors of the Party who reasonably need to know it
              in connection with the exercise of rights or the performance of
              obligations under this Terms of Use.
            </li>
            <li>
              <b>Subpoena</b>
              <br />
              In the event that any Party receives a request to disclose all or
              any part of the Confidential Information under the terms of a
              valid and effective subpoena or order issued by a court of
              competent jurisdiction or by a Governmental Authority, such Party
              agrees to: (i) immediately notify the other Party of the
              existence, terms and circumstances surrounding such a request;
              (ii) consult with the other Party on the advisability of taking
              legally available steps to resist or narrow such request; and
              (iii) if disclosure of such Confidential Information is required,
              exercise its best efforts to obtain an order or other reliable
              assurance that confidential treatment will be accorded to such
              portion of the disclosed Confidential Information which the other
              Party so designates.
            </li>
            <li>
              <b>Injunctive Relief</b>
              <br />
              Each Party acknowledges and agrees that any unauthorized use or
              disclosure by it of any of the other Party&apos;s Confidential
              Information, in whole or part, will cause irreparable damage to
              the disclosing Party, that monetary damages would be an inadequate
              remedy and that the amount of such damages would be extremely
              difficult to measure. The receiving Party agrees that the
              disclosing Party shall be entitled to seek temporary and permanent
              injunctive relief to restrain the receiving Party from any
              unauthorized disclosure or use. Nothing in this Terms of Use shall
              be construed as preventing the disclosing Party from pursuing any
              and all remedies available to it for a breach or threatened breach
              of a covenant made in this para. 11, including the recovery of
              monetary damages from the receiving Party.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Representations, Warranties and Disclaimers</u>
          </b>
          <ol type="a">
            <li>
              <b>Mutual Representations of the Parties</b>
              <br />
              Each Party represents to the other that:
              <ol type="i">
                <li>
                  it is a company duly organized, validly existing and in good
                  standing under the laws of its incorporation and it has full
                  power and authority to enter into this Terms of Use and to
                  perform each and every covenant and agreement herein
                  contained;
                </li>
                <li>
                  this Terms of Use, through the Customer&apos;s signing of the
                  SaaS Agreement, has been duly authorized, executed and
                  delivered by it and constitutes a valid, binding and legally
                  enforceable agreement of it;
                </li>
                <li>
                  the execution and delivery of this Terms of Use, and the
                  performance of the covenants and agreements herein contained,
                  are not, in any manner or to any extent, limited or restricted
                  by, and are not in conflict with, any commercial arrangements,
                  obligations, contract, agreement or instrument to which it is
                  either bound or subject; and
                </li>
                <li>
                  the execution and delivery of this Terms of Use and the
                  performance of its covenants and agreements herein contained
                  shall comply in all respects with all laws and regulations to
                  which it or its business is subject.
                </li>
              </ol>
            </li>
            <li>
              <b>Additional Representations of Service Provider</b>
              <br />
              Service Provider represents to Customer that Service Provider
              possesses the knowledge, skill and experience necessary for the
              provision and completion of the Services in accordance with the
              terms of this Terms of Use.
            </li>
            <li>
              <b>Warranties</b>
              <br />
              Service Provider warrants that:
              <ol type="i">
                <li>
                  it shall perform the Services in a first class, professional
                  and timely manner in accordance with the highest professional
                  and industry standards; and
                </li>
                <li>
                  for the duration of the term, the Software will substantially
                  operate in accordance with, and have the functions set out in,
                  the SaaS Agreement.
                </li>
              </ol>
            </li>
            <li>
              <b>Exclusion of Other Warranties</b>
              <br />
              Except as otherwise expressly stated in this Terms of Use, there
              are no express or implied warranties or conditions in relation to
              the Service, the Service Provider Platform, or Software that are
              the subject matter of this Terms of Use, including implied
              warranties or conditions of merchantable quality, fitness for a
              particular purpose, or non-infringement, or that the services,
              Service Provider Platform, or Software will meet Customer&apos;s
              needs or will be available for use at any particular time or will
              be error free. Under no circumstances will Service Provider be
              liable for the results of Customer use or misuse of the Services,
              including any use contrary to Applicable Law.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Indemnities</u>
          </b>
          <ol type="a">
            <li>
              <b>Intellectual Property Indemnity</b>
              <br />
              Service Provider shall defend at its own expense any claim,
              proceeding or suit (a &quot;Claim&quot;) brought against Customer
              or any of its Authorized Users to the extent such Claim alleges
              that any of the Service, or Software furnished hereunder infringes
              any Canadian copyright, patent or registered trademark of a third
              person and will indemnify and pay all damages which by final
              judgment or settlement may be assessed against Customer on account
              of such infringement, provided that:
              <ol type="i">
                <li>
                  Service Provider is given prompt written notice of the Claim
                  or of any allegations or circumstances known to Customer which
                  could result in a Claim;
                </li>
                <li>
                  Service Provider is given all reasonable information and
                  assistance from Customer, at Service Provider&apos;s expense,
                  which Service Provider may require to defend the Claim;
                </li>
                <li>
                  Service Provider is given sole control of the defence of the
                  Claim, and all negotiations for the settlement or compromise
                  thereof; and
                </li>
                <li>
                  the alleged infringement does not result from any
                  non-permitted uses, alterations, modifications or enhancements
                  carried out by Customer or on its behalf by a third person.
                </li>
              </ol>
              If such Claim has occurred, or in Service Provider&apos;s opinion
              is likely to occur, Service Provider may, at its option and
              expense, either procure for Customer the right to continue using
              the Service, Software or modify the same so that it becomes
              non-infringing without loss of functionality, or if none of the
              foregoing alternatives is reasonably available and at Service
              Provider&apos;s discretion, discontinue the Service and use of the
              Software and refund to Customer any pre-paid and unused portion of
              the Fees paid by Customer in respect of use of the Services. The
              foregoing states the entire obligations of Service Provider with
              respect to any infringement of Intellectual Property Rights of any
              third Person.
            </li>
            <li>
              <b>Customer&apos;s Indemnity</b>
              <br />
              Customer shall defend at its own expense any Claim brought against
              Service Provider, its affiliates, directors, officers, employees
              and agents, to the extent such Claim: (i) alleges, directly or
              indirectly, that any Customer Data infringes any copyright, patent
              or registered trademark of a third person; alleges, directly or
              indirectly, that the Customer Data contains any Objectionable
              Content; or (iii) is in relation to Customer&apos;s use of the
              Service, including contrary to Applicable Law, except however to
              the extent as Service Provider has indemnified Customer pursuant
              to para. 13(a); provided that Customer is given:
              <ol type="i">
                <li>
                  prompt written notice of the Claim or of any allegations or
                  circumstances known to Service Provider which could result in
                  a Claim;
                </li>
                <li>
                  all reasonable information and assistance from Service
                  Provider, at Customer&apos;s expense, which Customer may
                  require to defend the Claim; and
                </li>
                <li>
                  sole control of the defence of the Claim, and all negotiations
                  for its settlement or compromise thereof.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Limitation of Liability</u>
          </b>
          <ol type="a">
            <li>
              <b>Consequential Damages</b>
              <br />
              Subject to para. 14(c), in no event shall either Party be liable
              to the other for any consequential, incidental, exemplary or
              punitive damages even if advised in advance of the possibility of
              such damages. Further Service Provider shall not be liable to
              Customer for any lost revenue, lost profit or lost savings.
            </li>
            <li>
              <b>Limitation of Direct Damages</b>
              <br />
              Subject to para. 14(c), in respect of any claim, demand or action
              by either Party against the other or any of their respective
              employees, directors, officers, or agents whether based in
              contract, tort (including negligence), or otherwise, including a
              breach by a Party of any of its obligations under this Terms of
              Use (whether or not a fundamental breach), the other Party&apos;s
              sole and exclusive remedy shall be to receive from the breaching
              Party payment for actual and direct damages to a maximum aggregate
              amount equal to: (i) in the case of a breach by Service Provider
              the amount paid by Customer to Service Provider in the 12 months
              preceding the date of the event, if the total duration of the
              contract is less than 12 months, then the amount paid by Customer
              to Service Provider in the months preceding the date of the event
              giving rise to the loss; and (ii) in the case of a breach by
              Customer CAD $500,000.
            </li>
            <li>
              <b>Exceptions to Limitations</b>
              <br />
              Notwithstanding paras. 14(a) and (b), neither Party excludes nor
              limits any liability for:
              <ol type="i">
                <li>
                  personal injury or death to the extent that such injury or
                  death results from the negligence or wilful misconduct of a
                  Party or its employees;
                </li>
                <li>
                  fraud, fraudulent misrepresentation or fraudulent concealment;
                </li>
                <li>
                  the Party&apos;s obligations set out in paras. 4(a), 4(b),
                  4(c), 5(c), 10(a), 10(c), 11 or 14; or
                </li>
                <li>Customer&apos;s payment obligations contained herein.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Force Majeure</u>
          </b>
          <br />
          Except for any obligation to make payments, any delay or failure of
          either Party to perform its obligations under this Terms of Use or
          under any Schedule attached hereto shall be excused if, and to the
          extent, that the delay or failure is caused by an event or occurrence
          beyond the reasonable control of the Party and without its fault or
          negligence, such as, by way of example and not by way of limitation,
          acts of God, action by any Governmental Authority (whether valid or
          invalid), fires, flood, wind storms, explosions, riots, natural
          disasters, wars, terrorist acts, sabotage, labour problems (including
          lock-outs, strikes and slowdowns, except for any labour problems of
          the Party claiming a force majeure event), or court order or
          injunction; provided that written notice of delay (including
          anticipated duration of the delay) shall be given by the affected
          Party to the other Party within 10 Business Days of the affected Party
          first becoming aware of such event. If requested by the unaffected
          Party, the affected Party shall, within 10 Business Days of the
          request, provide adequate assurances that the delay shall not exceed
          60 Business Days. In the event that the force majeure event lasts for
          60 Business Days or longer, either Party shall have the option to
          terminate this Terms of Use upon written notice to the other without
          liability.
        </li>
        <li>
          <b>
            <u>Dispute Resolution</u>
          </b>
          <ol type="a">
            <li>
              <b>Discussions</b>
              <br />
              Each Party agrees to utilize all reasonable efforts to resolve any
              dispute, whether arising during the term of this Terms of Use or
              at any time after the expiration of termination of this Terms of
              Use, which touches upon the validity, construction, meaning,
              performance or affect this Terms of Use or the rights and
              liabilities of the Parties or any matter arising out of or
              connected with this Terms of Use, promptly and in an amicable and
              good faith manner by negotiations between the Parties.
            </li>
            <li>
              <b>Mediation</b>
              <br />
              Either Party may submit a dispute to mediation by providing
              written notice to the other Party. In the mediation process, the
              Parties will try to resolve their differences voluntarily with the
              aid of a single, impartial mediator, who shall attempt to
              facilitate negotiations. The mediator shall be selected by
              agreement of the Parties. If the Parties cannot otherwise agree on
              a mediator within 10 Business Days, a single mediator shall be
              designated by the ADR Institute of Canada, Inc. or any successor
              organization (&quot;ADR&quot;) at the request of a Party. Any
              mediator so designated must not have a conflict of interest with
              respect to any Party. The mediation shall be conducted as
              specified by the mediator and agreed upon by the Parties. The
              Parties agree to discuss their differences in good faith and to
              attempt, with the assistance of the mediator, to reach an amicable
              resolution of the dispute. The mediation shall be treated as a
              settlement discussion and therefore shall be confidential. The
              mediator may not testify for either Party in any later proceeding
              relating to the dispute. No recording or transcript shall be made
              of the mediation proceedings. Each Party shall bear its own costs
              and legal fees in the mediation. The Parties shall share the fees
              and expenses of the mediator equally.
            </li>
            <li>
              <b>Arbitration</b>
              <br />
              Subject to para. 16(d), any dispute that has proceeded through
              mediation established in para. 16(b) without resolution may be
              submitted to arbitration. Any arbitration conducted pursuant to
              this Terms of Use shall take place in the City of Calgary. The
              costs of the arbitration shall be borne equally by the Parties or
              as may be specified in the arbitrator&apos;s decision. The
              provisions of Alberta&apos;s Arbitration Act, RSA 2000, c A-43, as
              amended, except as otherwise provided in this Terms of Use, shall
              govern the arbitration process. The Parties agree to exclude the
              appeal provisions of the Arbitration Act, as may be amended from
              time to time. The determination arising out of the arbitration
              process shall be final and binding upon the Parties to the
              arbitration.
            </li>
            <li>
              <b>Exceptions to Arbitration</b>
              <br />
              The following matters shall be excluded from arbitration under
              this Terms of Use:
              <ol type="i">
                <li>any disputes involving third Persons;</li>
                <li>breach of confidentiality by either Party; and</li>
                <li>
                  intellectual property claims, whether initiated by third
                  Persons or by one of the Parties to this Terms of Use.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <u>Miscellaneous</u>
          </b>
          <ol type="a">
            <li>
              <b>Relationship</b>
              <br />
              The Parties are independent contractors and no other relationship
              is intended. Nothing herein shall be deemed to constitute either
              Party as an agent, representative or employee of the other Party,
              or both Parties as joint ventures or partners for any purpose.
              Neither Party shall act in a manner that expresses or implies a
              relationship other than that of independent contractor. Each Party
              shall act solely as an independent contractor and shall not be
              responsible for the acts or omissions of the other Party. Neither
              Party will have the authority or right to represent nor obligate
              the other Party in any way except as expressly authorized by this
              Terms of Use.
            </li>
            <li>
              <b>Enurement</b>
              <br />
              This Terms of Use shall enure to the benefit of and be binding
              upon each of the Parties hereto and their permitted successors and
              assigns.
            </li>
            <li>
              <b>No Assignment</b>
              <br />
              Neither this Terms of Use nor any rights or obligations hereunder
              shall be assignable by Customer without the prior written consent
              of the Service Provider.
            </li>
            <li>
              <b>Language</b>
              <br />
              It is the Parties desire and agreement that this Terms of Use and
              associated documentation be drafted in English. Les Parties
              conviennent que la présente convention et tous les documents
              s&apos;y rattachant, soient rédigés en anglais.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
