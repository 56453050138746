import React from "react";

import { Modal, DialogContent, Button, Snackbar, SnackbarContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModalPrompt, ModalContent, ModalFooter } from "./containers";

import { useTheme } from "@mui/material/styles";

export const ConfirmationModal = ({
  open,
  onConfirm,
  onCancel,
  body = "Are you sure?",
}) => (
  <Modal open={open} onClose={onCancel}>
    <DialogContent>
      <ModalPrompt>
        <ModalContent>
          <h2>Confirm Action</h2>
          <p>{body}</p>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm}>OK</Button>
        </ModalFooter>
      </ModalPrompt>
    </DialogContent>
  </Modal>
);

// An acknowledgement modal to let user know they saved, set data, etc
export const AcknowledgeModal = ({
  isOpen,
  onClose,
  acknowledgeMessage,
  autoHideDuration=5000,
  verticalPos="bottom", //top, bottom
  horizontalPos="left", // left, center, right
}) => {
  const theme = useTheme();
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: verticalPos, horizontal: horizontalPos }}
    >
      <SnackbarContent 
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        }}
        message={acknowledgeMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
              style={{
                color: theme.palette.common.white,
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  )
}
