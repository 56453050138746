import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";

import q from "@queryit/api";

const hotkeys = {}; // Data structure of [key]: Array<Callbacks>
let keyHandlerMounted = false;

export const useHotkey = (keyname, callback) => {
  const keyHandler = useCallback(
    (event) => {
      if (event.key === keyname) {
        // Then run the last callback in the array
        if (hotkeys[keyname][hotkeys[keyname].length - 1](event) === false) {
          // If the callback returns false, then stop propagation
          event.stopPropagation();
        }
      }
    },
    [keyname]
  );

  const register = useCallback((event) => callback(event), [callback]);

  useEffect(() => {
    // Register handler
    if (!keyHandlerMounted) {
      window.addEventListener("keydown", keyHandler, {
        capture: true,
      });
      keyHandlerMounted = true;
    }
    // Also append to the hotkeys dict & register the callback
    if (!hotkeys[keyname]) {
      hotkeys[keyname] = [];
    }
    hotkeys[keyname].push(register);
    // Then return a cleanup function
    return () => {
      // Remove from hotkeys dict
      hotkeys[keyname] = hotkeys[keyname].filter((cb) => cb !== register);
      // And if dict is blank, remove keyHandler
      if (hotkeys[keyname].length === 0) {
        delete hotkeys[keyname];
      }
      // If hotkeys is blank, unmount handler
      if (Object.keys(hotkeys).length === 0) {
        keyHandlerMounted = false;
        window.removeEventListener("keydown", keyHandler, { capture: true });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]); // Exclude keyname intentionally, no one cares
};

export const useDisableHotkey = (key) => {};

export const useUpdateCheck = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    q.systeminfo.listen((info) => {
      if (info.currentVersion !== process.env.APP_VERSION) {
        setUpdateAvailable(true);
      }
    });
  }, []);

  return updateAvailable;
};

// Unifed Snackbar

export const SnackbarContext = createContext({
  messages: [],
  setMessages: () => {},
});

export const SnackbarProvider = ({ children }) => {
  const [messages, setMessages] = useState(["hello"]);

  return (
    <SnackbarContext.Provider value={{ messages, setMessages }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { setMessages } = useContext(SnackbarContext);

  const callSnackbar = useCallback(
    (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    },
    [setMessages]
  );

  return { callSnackbar };
};
