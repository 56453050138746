import React from "react";
import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";
import { Tooltip, Paper, IconButton } from "@mui/material";
import {
  Check,
  Assignment,
  QuestionAnswer,
  Schedule,
} from "@mui/icons-material";

import { format } from "date-fns";
import { is_overdue, parse_db_timestamp } from "../../tools";

export const ActionTileScroller = ({ actions, onTileClick }) => {
  const isOverdue = (action) => {
    let dueDate;
    if (action.type === "tasks") {
      dueDate = parse_db_timestamp(action.dueDate);
    } else {
      // Due date is Query due date
      dueDate = parse_db_timestamp(action.query?.dueDate);
    }
    return is_overdue(dueDate);
  };

  return (
    <ActionTileScrollWay>
      {actions.map((act) => {
        if (isMobile && act.type === "tasks") {
          return;
        }
        return (
          <ActionTile
            key={act.id}
            title={act.query.dynamicId ?? act.query.id}
            status={act.status}
            type={act.type}
            due={
              act.type === "tasks"
                ? parse_db_timestamp(act.dueDate)
                : parse_db_timestamp(act.query?.dueDate)
            }
            description={
              act.type === "tasks" ? act?.data?.title ?? "Untitled Task" : ""
            }
            onClick={() => onTileClick(act.projectsId, act.queriesId, act.type)}
            overdue={isOverdue(act)}
          />
        );
      })}
    </ActionTileScrollWay>
  );
};

export const ActionTile = ({
  title,
  status,
  overdue,
  description,
  type,
  onClick,
  due,
}) => {
  return (
    <ActionTileContainer>
      <Paper elevation={3}>
        <ActionTileBox onClick={onClick}>
          <div>
            <ActionTileTitle>{title}</ActionTileTitle>
            <ActionDescription>
              Due Date: {due ? format(due, "dd-MMM-yyyy") : "N/A"}
              <div style={{ marginTop: "10px" }}>{description}</div>
            </ActionDescription>
          </div>
          <ActionTileIcons>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {type === "approvals" && (
                <Tooltip title="Approval">
                  <Check />
                </Tooltip>
              )}
              {type === "tasks" && (
                <Tooltip title="Task">
                  <Assignment />
                </Tooltip>
              )}
              {type === "responses" && (
                <Tooltip title="Response">
                  <QuestionAnswer />
                </Tooltip>
              )}
              {overdue && (
                <Tooltip title="OVERDUE">
                  <IconButton>
                    <Schedule
                      sx={{
                        color: "#d90909",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </ActionTileIcons>
        </ActionTileBox>
      </Paper>
    </ActionTileContainer>
  );
};

const ActionTileBox = styled.div`
  display: flex;
  flex: 0;
  width: 200px;
  height: 100px;
  padding: 6px;
  border-radius: 3px;

  cursor: pointer;
`;

const ActionTileContainer = styled.div`
  background: ${(props) => props.theme.palette.background.step50};
  color: ${(props) => props.theme.palette.text.primary};
  margin: 8px;
`;

const ActionTileIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const ActionTileTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 170px;
  max-height: 1.1rem;
  margin-bottom: 8px;
`;

const ActionTileScrollWay = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ActionDescription = styled.div`
  font-size: 14px;
`;
