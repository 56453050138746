import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Defaults from "./defaults";
import Actions from "./actions";
import Lifecycle from "./lifecycle";
import Linking from "./linking";
import { QueryContent } from "../../query";
import { Tabs, Tab } from "@mui/material";

const ACTIONVIEWS = {
  FIELDS: "approval",
  ACTIONS: "task",
  LIFE_CYCLE: "response",
  LINKING: "linking",
};

const TabContext = React.createContext(undefined);

export default ({ project, schemaId, setStatusMessage }) => {
  const [actionView, setActionView] = useState(ACTIONVIEWS.FIELDS);

  return (
    <QueryContent
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        margin: 0,
      }}
    >
      <TabContext.Provider value={actionView}>
        <Tabs value={actionView} onChange={(e, v) => setActionView(v)}>
          <Tab label="Fields" value={ACTIONVIEWS.FIELDS} />
          <Tab label="Actions" value={ACTIONVIEWS.ACTIONS} />
          <Tab label="Life Cycle" value={ACTIONVIEWS.LIFE_CYCLE} />
          <Tab label="Query Linking" value={ACTIONVIEWS.LINKING} />
        </Tabs>
        <TabPanel value={ACTIONVIEWS.FIELDS}>
          <Defaults project={project} schemaId={schemaId} setStatusMessage={setStatusMessage} />
        </TabPanel>
        <TabPanel value={ACTIONVIEWS.ACTIONS}>
          <Actions project={project} schemaId={schemaId} setStatusMessage={setStatusMessage} />
        </TabPanel>
        <TabPanel value={ACTIONVIEWS.LIFE_CYCLE}>
          <Lifecycle project={project} schemaId={schemaId} setStatusMessage={setStatusMessage} />
        </TabPanel>
        <TabPanel value={ACTIONVIEWS.LINKING}>
          <Linking project={project} schemaId={schemaId} setStatusMessage={setStatusMessage} />
        </TabPanel>
      </TabContext.Provider>
    </QueryContent>
  );
};

const TabPanel = ({ value, ...props }) => {
  const current = useContext(TabContext);

  return <TabPanelDiv hidden={value !== current}>{props.children}</TabPanelDiv>;
};

const TabPanelDiv = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex: 4 0 calc(100% - 48px);
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`;
