import React, { useState, useEffect } from "react";

import { ProjectRouteContainer } from "../../../project";
import { SelectField } from "../../../ui/inputs2";
import { Button2 } from "../../../ui/buttons";
import { Tile } from "../../../ui/containers";

import q from "@queryit/api"; // DANGER

export default () => {
  // This page is scary powerful
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState({
    sourceid: undefined,
    targetid: undefined,
    source: undefined,
    target: undefined,
  });

  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState({
    sourceid: undefined,
    source: undefined,
  });

  useEffect(() => {
    q.projects.listen((projects) => {
      setProjects(
        projects.reduce((dir, proj) => ({ ...dir, [proj.id]: proj.name }), {})
      );
    });
  }, []);

  const determineProject = (pointing, projectId) => {
    let p = q.projects.project(projectId);
    setSelectedProjects((dt) => ({
      ...dt,
      [`${pointing}id`]: projectId,
      [pointing]: p,
    }));
    if (pointing === "source") {
      p.schemas.listen((schemas) => {
        setSchemas(
          schemas.reduce((dir, schm) => ({ ...dir, [schm.id]: schm.name }), {})
        );
      });
    }
  };

  const determineSchema = (schemaId) => {
    selectedProjects.source.schemas
      .schema(schemaId)
      .listen((schema) =>
        setSelectedSchema({ sourceid: schemaId, source: schema })
      );
  };

  const makeCopy = () => {
    selectedProjects.target.schemas
      .add({
        ...selectedSchema.source,
        name: selectedSchema.source.name + " - Copy",
      })
      .then((res) => {
        selectedProjects.target.listen((data) => {
          if (data.columns && data.columns[selectedSchema.source.id]) {
            selectedProjects.target
              .update({
                columns: {
                  ...data.columns,
                  [res.id]: data.columns[selectedSchema.source.id],
                },
              })
              .then((_) => {
                setSelectedSchema({ sourceid: undefined, source: undefined });
                setSelectedProjects({
                  sourceid: undefined,
                  targetid: undefined,
                  source: undefined,
                  target: undefined,
                });
              });
          }
        });
      });
  };

  return (
    <ProjectRouteContainer>
      <Tile>
        <SelectField
          data={selectedProjects.sourceid}
          onChange={(e) => {
            const { value } = e.target;
            determineProject("source", value);
          }}
          options={projects}
          label="Source Project"
          contrast
        />
        <SelectField
          data={selectedSchema.sourceid}
          onChange={(e) => {
            const { value } = e.target;
            determineSchema(value);
          }}
          options={schemas}
          label="Source Schema"
          contrast
        />
        <SelectField
          data={selectedProjects.targetid}
          onChange={(e) => {
            const { value } = e.target;
            determineProject("target", value);
          }}
          options={projects}
          label="Target Project"
          contrast
        />
        {/* <SelectField data={selectedSchema} onChange={(dt) => determineSchema(dt)}
        field={{name: 'Source Schema', id: 'sourceid', options: schemas}}/>
      
      <SelectField data={selectedProjects} onChange={(dt) => determineProject('target', dt)}
        field={{name: 'Target Project', id: 'targetid', options: projects}}/> */}
        <Button2
          style={{ width: "fit-content", whiteSpace: "nowrap" }}
          label="Copy Schema"
          onClick={makeCopy}
        />
      </Tile>
    </ProjectRouteContainer>
  );
};
