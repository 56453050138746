import React, { useEffect, useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { EmojiEvents } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

import { useCurrentUsers } from "../../../hooks/projects";
import { InteractiveTable } from "../../ui/table";
import { PhotoPreview } from "../../account";
import { ProjectRouteContainer } from "..";

import "./Podium.css";

// Since the podium has a pretty fancy animation, lets just use pure CSS
const useStyles = makeStyles((theme) => ({
  podium: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "350px",
  },
  podiumItem: {
    width: "150px",
    textAlign: "center",
    alignItems: "center",
  },
  badge: {
    animation: "fall 2s ease-in-out",
  },
  tableContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    animation: "rise 3s ease-in-out",
  },
  podium1: {
    height: "270px",
    backgroundColor: theme.palette.primary.main + "",
    animation: "rise 2s ease-in-out",
    borderRadius: "8px",
  },
  podium2: {
    height: "220px",
    backgroundColor: theme.palette.primary.main + "CC",
    animation: "rise 2s ease-in-out",
    borderRadius: "8px",
  },
  podium3: {
    height: "180px",
    backgroundColor: theme.palette.primary.main + "80",
    animation: "rise 2s ease-in-out",
    borderRadius: "8px",
  },
}));

export default ({ userData, project, projectData, photoLink }) => {
  const classes = useStyles();
  const projUsers = useCurrentUsers(project);

  const sortedUsers = useMemo(() => {
    if (projUsers) {
      return projUsers
        .filter((user) => user.points)
        .sort((a, b) => b.points - a.points)
        .map((user, index) => ({ ...user, rank: index + 1 }));
    }
    return null;
  }, [projUsers]);

  const currentUserPoints = useMemo(() => {
    if (sortedUsers) {
      return sortedUsers.some((user) => user.id === userData.id);
    }
    return null;
  }, [sortedUsers, userData.id]);

  // Guard for no users with points
  if (sortedUsers && sortedUsers.length === 0) {
    return (
      <Typography sx={{ margin: "10px", color: "white" }}>
        No Users have earned badges yet! <br />
        Check out your <i> Earnable Badges</i> on your profile to get started!
      </Typography>
    );
  }

  return (
    <ProjectRouteContainer style={{ gap: 50 }}>
      <Typography
        variant="h4"
        sx={{
          color: "white",
          textAlign: "center",
        }}
      >
        <EmojiEvents /> Project Leaderboard <EmojiEvents />
      </Typography>
      <Box className={classes.podium}>
        {sortedUsers.slice(0, 3).map((user, index) => {
          let podiumClass = "";
          let flair = "";
          if (index === 0) {
            podiumClass = classes.podium1;
            flair = "gold";
          } else if (index === 1) {
            podiumClass = classes.podium2;
            flair = "silver";
          } else if (index === 2) {
            podiumClass = classes.podium3;
            flair = "#CD7F32";
          }
          return (
            <Box
              key={user.id}
              sx={{ order: 3 - (index + 1) === 0 ? 3 : 3 - (index + 1) }} //math
              className={classes.podiumItem}
            >
              <UserProfileFlaired
                userData={user}
                flair={flair}
                photoLink={photoLink}
              />
              <Box className={podiumClass}>
                <Typography
                  variant="h1"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  {index + 1}
                </Typography>
                <Typography variant="body2" sx={{ color: "white" }}>
                  {user.name.first} {user.name.last}
                </Typography>
                <Typography variant="body2" sx={{ color: "white" }}>
                  {user.points} Points
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ width: "60%" }} className={classes.tableContainer}>
        <InteractiveTable
          data={sortedUsers}
          columns={leaderboardColumns(userData.id)}
        />
        {!currentUserPoints && (
          <Typography sx={{ margin: "10px", color: "white" }}>
            You currently have no points in this project. Earn points by
            completing actions such as creating queries, approvals, comments, or
            tasks!
          </Typography>
        )}
      </Box>
    </ProjectRouteContainer>
  );
};

const UserProfileFlaired = ({ userData, flair, photoLink }) => {
  const canvasRef = useRef(null);

  const nameHash = (userData.name.first + userData.name.last)
    .split("")
    .reduce((acc, c) => acc + c.charCodeAt(0), 0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const particles = [];

    const addParticle = (x, y) => {
      particles.push({
        x,
        y,
        size: Math.random() * 15 + 1,
        speedX: Math.random() * 3 - 1.5,
        speedY: Math.random() * 3 - 1.5,
        angle: Math.floor(Math.random() * 360),
        color: flair,
      });
    };

    const updateParticles = () => {
      particles.forEach((p, index) => {
        if (p.size <= 0) {
          particles.splice(index, 1);
        } else {
          p.x += p.speedX;
          p.y += p.speedY;
          p.angle += 5;
          p.size -= 0.1;
        }
      });
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((p) => {
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate((p.angle * Math.PI) / 180);
        ctx.fillStyle = p.color;
        ctx.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
        ctx.restore();
      });
    };

    const animateParticles = () => {
      requestAnimationFrame(animateParticles);
      addParticle(canvas.width / 2, canvas.height / 2);
      updateParticles();
      drawParticles();
    };

    canvas.width = 200;
    canvas.height = 200;
    animateParticles();

    return () => cancelAnimationFrame(animateParticles);
  }, [flair]);

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100px",
          height: "100px",
        }}
      />
      <UserCircle
        color={FUN_COLOR_SET[nameHash % FUN_COLOR_SET.length]}
        flair={flair}
      >
        {userData.profilePhoto && photoLink ? (
          <PhotoPreview src={photoLink} />
        ) : (
          ` ${userData.name.first[0]}${userData.name.last[0]} `
        )}
      </UserCircle>
    </Box>
  );
};

const leaderboardColumns = (currentUser) => [
  {
    name: "Rank",
    component: (row) => (
      <Typography
        sx={{
          fontWeight: currentUser && currentUser === row.id ? "bold" : "normal",
        }}
      >
        {row.rank}
      </Typography>
    ),
  },
  {
    name: "Name",
    component: (row) => (
      <Typography
        sx={{
          fontWeight: currentUser && currentUser === row.id ? "bold" : "normal",
        }}
      >
        {`${row.name.first} ${row.name.last}`}
      </Typography>
    ),
  },
  {
    name: "Score",
    component: (row) => (
      <Typography
        sx={{
          fontWeight: currentUser && currentUser === row.id ? "bold" : "normal",
        }}
      >
        {row.points}
      </Typography>
    ),
  },
];

const FUN_COLOR_SET = [
  "#D32F2F",
  "#FF4081",
  "#303F9F",
  "#448AFF",
  "#388E3C",
  "#8BC34A",
  "#E64A19",
  "#FF9800",
  "#0288D1",
  "#00BCD4",
  "#AFB42B",
  "#00796B",
];

const UserCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border: ${(props) => `5px solid ${props.flair}`};
  z-index: 2;

  background: ${(props) => props.color};
  font-family: ${(props) => props.theme.font};
`;
