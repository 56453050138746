import React, { forwardRef } from "react";
import styled from "styled-components";

import Applications from "../graphics/applications.png";

function Market({}, ref) {
  return (
    <MarketContainer ref={ref}>
      <TextContainer>
        <TextBox>
          <H1Desktop>Market Applications / Use Cases</H1Desktop>
          <H1Mobile>Market Applications</H1Mobile>
          <P>
            Identify the opportunity in your business to add value. If you are
            using processes requiring manual efforts to generate and manage
            data, then Query-it can help you be more efficient.
          </P>
        </TextBox>
      </TextContainer>
      <ImageContainer>
        <Img src={Applications} />
      </ImageContainer>
    </MarketContainer>
  );
}

export default forwardRef(Market);

const MarketContainer = styled.div`
  min-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    background: #e8295d;
  }
`;
const TextContainer = styled.div`
  width: 40%;
  background-color: #e8295d;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 950px) and (min-width: 601px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-height: 350px;
  }
`;
const TextBox = styled.div`
  width: 70%;
  text-align: left
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 90%;
  }
`;
const ImageContainer = styled.div`
  width: 60%;
  background-color: white;

  @media (max-width: 950px) and (min-width: 601px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-height: 30px;
    height: fit-content;
    background-color: #e8295d;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }
`;
const Img = styled.img`
  width: 90%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 600px) {
    margin-bottom: 5vw;
  }
`;
const H1Desktop = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;

  @media (max-width: 950px) and (min-width: 601px) {
    font-size: 250%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
const H1Mobile = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 300%;

  @media (max-width: 950px) and (min-width: 601px) {
    font-size: 250%;
  }

  @media (min-width: 601px) {
    display: none;
  }
`;
const P = styled.p``;
