import React from "react";
import styled from "styled-components";

import Customizable from "../graphics/customizable.png";
import Workflow from "../graphics/workflow.png";
import Notifications from "../graphics/notifications.png";
import Data from "../graphics/data.png";
import Analytics from "../graphics/analytics.png";

import { forwardRef } from "react";

function Features({}, ref) {
  return (
    <FeaturesContainer ref={ref}>
      <H1>Features & Benefits</H1>
      <FeatureBar>
        <Feature>
          <FeatureImageBackground>
            <img src={Customizable} />
          </FeatureImageBackground>
          <p style={{ marginBottom: 0, color: "#37227A", fontWeight: "bold" }}>
            Fully Customizable
          </p>
          <p style={{ marginTop: 0 }}>
            No limit on number of form fields, type of fields (drop down, free
            text, checklist, etc.). Capture all required attributes.
          </p>
        </Feature>
        <Feature>
          <FeatureImageBackground>
            <img src={Workflow} />
          </FeatureImageBackground>
          <p style={{ marginBottom: 0, color: "#37227A", fontWeight: "bold" }}>
            Automatic Workflows
          </p>
          <p style={{ marginTop: 0 }}>
            Forms can be routed to stakeholders for actions based on set rules
            or triggers.
          </p>
        </Feature>
        <Feature>
          <FeatureImageBackground>
            <img src={Data} />
          </FeatureImageBackground>
          <p style={{ marginBottom: 0, color: "#37227A", fontWeight: "bold" }}>
            Auto Notifications & Reminders
          </p>
          <p style={{ marginTop: 0 }}>
            Tool will automatically send both text and email reminders -
            automatic based on client defined custom target dates.
          </p>
        </Feature>
        <Feature>
          <FeatureImageBackground>
            <img src={Analytics} />
          </FeatureImageBackground>
          <p style={{ marginBottom: 0, color: "#37227A", fontWeight: "bold" }}>
            Data Centric
          </p>
          <p style={{ marginTop: 0 }}>
            Query-it is configured for your work processes and data model.
            Process steps are executed digitally through the tool and all data
            is captured in real time, making Query-it your central and
            integrated data hub.
          </p>
        </Feature>
        <Feature>
          <FeatureImageBackground>
            <img src={Notifications} />
          </FeatureImageBackground>
          <p style={{ marginBottom: 0, color: "#37227A", fontWeight: "bold" }}>
            Real Time Analytics
          </p>
          <p style={{ marginTop: 0 }}>
            Real time metrics: graphs to gauge performance indicators and
            analyze trends to implement continuous improvement.
          </p>
        </Feature>
      </FeatureBar>
    </FeaturesContainer>
  );
}

export default forwardRef(Features);

const FeaturesContainer = styled.div`
  min-height: 500px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 800px) {
    text-align: center;
  }
`;
const FeatureBar = styled.div`
  width: 85%;
  min-height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const Feature = styled.div`
  width: 15%;
  height: 100%;

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const FeatureImageBackground = styled.div`
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #e8295d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const H1 = styled.h1`
  marging: 0;
  font-weight: bold;
  font-size: 300%;
  color: 37227A;

  @media (max-width: 600px) {
    width: 85%;
  }
`;
