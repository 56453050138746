import { useState, useEffect } from "react";
import q from "@queryit/api";

export const useDevlog = () => {
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    let mounted = true;
    const unsub = q.devlogger.listen((errors) => {
      if (mounted) setErrors(errors);
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, []);

  return errors;
};
