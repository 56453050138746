import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Route, Navigate, Routes, useParams } from "react-router-dom";

import NavBar from "../../navigator";
import SchemaTab from "../../project/filter/schemas";

import ProjectSettings from "./settings";
import SchemaSettings from "./schemas";
// import { SchemaEditor2 as FieldEditor } from "./fields";
// import PackageEditor from "./package";
import UserSettings from "./users";
// import AnalyticsEditor from "./analytics";
import InternalAnalytics from "./analytics";
// import WorkflowEditor from "./workflow";
import TableEditor from "./columns";
import ImportData from "./import";
import Badges from "./badges";
import Popups from "./popups";
// import ReportsEditor from "./reports";
// import NotificationSettings from "./notifications";

import {
  useProject,
  useCurrentSchemas,
  useCurrentReports,
  useSchema,
} from "../../../hooks/projects";
import { SchemaSelectorContext, SelectedSchemaContext } from "../../project";
import {
  EmojiEvents,
  FileUpload,
  People,
  Settings,
  SettingsEthernet,
  ViewColumn,
  Announcement,
  PieChart,
  Description,
} from "@mui/icons-material";

// Now introduce schema & schemadata contexts
export const SchemaContext = React.createContext(undefined);

export default ({ user, userData }) => {
  const { projectId } = useParams();
  const [project, projectData] = useProject(projectId);
  const schemas = useCurrentSchemas(project, user, false, true);

  // Also get the selected schema context (we'll need to provide it here)
  const [selectSchema, setSelectedSchema] = useState(schemas?.[0]?.id);
  useEffect(() => {
    if (schemas) setSelectedSchema(schemas[0]?.id);
  }, [schemas]);

  // Then get the actual selected schema
  const [selectedSchema, selectedSchemaData] = useSchema(selectSchema, project);

  if (!projectData || !schemas) {
    return null;
  }

  if (Object.keys(projectData).length === 0) {
    // Then re-route to the admin dashboard
    return <Navigate to={`/app/admin`} />;
  }

  return (
    <ProjectAdminPage>
      <NavBar options={navOptions} root={`/app/admin/projects/${projectId}/`} />
      <ProjectAdminContain>
        <SchemaContext.Provider value={selectedSchema}>
          <SelectedSchemaContext.Provider value={selectedSchemaData}>
            <SchemaSelectorContext.Provider value={setSelectedSchema}>
              {schemas.length > 1 && <SchemaTab schemas={schemas} />}
              <Routes>
                <Route
                  path={`psettings`}
                  element={
                    <ProjectSettings
                      project={project}
                      projectData={projectData}
                    />
                  }
                />
                <Route
                  path={`ssettings`}
                  element={
                    <SchemaSettings
                      project={project}
                      projectData={projectData}
                    />
                  }
                />
                {/* <Route path={`fields`} element={<FieldEditor />} /> */}
                {/* <Route path={`package`} element={<PackageEditor />} /> */}
                <Route path={`table`} element={<TableEditor />} />
                <Route
                  path={`analytics`}
                  element={<InternalAnalytics project={project} />}
                />
                {/* <Route path={`workflow`} element={<WorkflowEditor />} /> */}
                {/* <Route
                  path={`reports`}
                  element={
                    <ReportsEditor
                      project={project}
                      projectData={projectData}
                      schemas={schemas}
                    />
                  }
                /> */}
                {/* <Route
                  path={`notifications`}
                  element={
                    <NotificationSettings
                      project={project}
                      projectData={projectData}
                      reports={reports}
                    />
                  }
                /> */}
                <Route
                  path={`users`}
                  element={
                    <UserSettings project={project} projectData={projectData} />
                  }
                />
                <Route
                  path={`import`}
                  element={<ImportData project={project} userData={userData} />}
                />
                <Route
                  path={`badges`}
                  element={
                    <Badges project={project} projectData={projectData} />
                  }
                />
                <Route
                  path={`popups`}
                  element={
                    <Popups
                      project={project}
                      projectData={projectData}
                      user={user}
                      userData={userData}
                    />
                  }
                />
                <Route path={"*"} element={<Navigate to={`psettings`} />} />
              </Routes>
            </SchemaSelectorContext.Provider>
          </SelectedSchemaContext.Provider>
        </SchemaContext.Provider>
      </ProjectAdminContain>
    </ProjectAdminPage>
  );
};

const navOptions = [
  { dest: "psettings", name: "Project", icon: <Settings /> },
  { dest: "ssettings", name: "Schema", icon: <SettingsEthernet /> },
  // { dest: "fields", name: "Fields", icon: "dynamic_feed" },
  // { dest: "package", name: "Package", icon: "receipt" },
  // { dest: "table", name: "Table Columns", icon: <ViewColumn /> },
  { dest: "analytics", name: "Analytics", icon: <PieChart /> },
  // { dest: "workflow", name: "Workflow", icon: "workspaces" },
  // { dest: "reports", name: "Reports", icon: <Description /> },
  { dest: "users", name: "Users", icon: <People /> },
  // { dest: "notifications", name: "Notifications", icon: "edit_notifications" },
  { dest: "import", name: "Import Data", icon: <FileUpload /> },
  { dest: "popups", name: "Popups", icon: <Announcement /> },
  { dest: "badges", name: "Badges", icon: <EmojiEvents /> },
];

const ProjectAdminPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const ProjectAdminContain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
`;
