import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from "react";
import styled from "styled-components";
import { get_field_by_id, parse_on_fields } from "../../../../../tools/forms";

import { QueryContent, QuerySectionHeader } from "../../query";
import { SelectField } from "../../../../ui/inputs2";
import {
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add, ExpandMore, Delete } from "@mui/icons-material";

import { SaveQueryBar } from "../../workflow";

import { useSchema } from "../../../../../hooks/projects";
import { FieldGenerator } from "../../fields/generator";

import NewApprovals from "../../actions/approvals/new";

/*
Some Vocabulary:
  - A "Conditional" is an object that holds a pair consisting of the values for the fields with conditions, 
    and the corresponding approvers
  - A "ConditionalOn" is a collection of fields each conditional has to have a value for
General Behaviour:
  - every conditional must have the same set of fields, but can have different values
    - example: there can't be a conditional only with fields X, Y, Z while another conditional has only X, Y
  - when the set of fields all conditionals need to adhere to is changed, the values for that field shall be wiped
*/

export default ({ project, schemaId, setStatusMessage }) => {
  const [schema, schemaData] = useSchema(schemaId, project);
  const [fieldList, setFieldList] = useState(undefined);
  const [dataDB, setDataDB] = useState(undefined);
  const [conditionalOns, setConditionalOns] = useState([]);
  const [currentConditionals, setCurrentConditionals] = useState([]);

  // useEffect to set current defaultApprovers, condtionals selected, and allowed fields for conditionals
  useEffect(() => {
    if (schemaData) {
      // first, lets gather all the fields that can have a conditional set on them
      let fields = {};
      parse_on_fields(schemaData.fields, (fld) => {
        if (
          ![
            "table",
            "statictable",
            "files",
            "message",
            "link",
            "staticimage",
            "staticdoc",
            "signature",
            "tableadv",
            "photos",
            "textarea",
          ].includes(fld.type)
        ) {
          fields["data." + fld.id] = `${fld.name} (${fld.type})`;
        }
      });

      // now, gather all the conditionOns if they exist
      let existingConditionalOns = schemaData?.defaultApprovers?.conditionalOn;
      if (existingConditionalOns?.length > 0) {
        // set conditionalOns data as existing, otherwise dont set and leave as empty array
        setConditionalOns(existingConditionalOns);
      }

      // Finally, do a set for other values
      setDataDB(schemaData.defaultApprovers);
      setFieldList(fields);
    }
  }, [schemaData]);

  // useEffect to set currentConditionals
  useEffect(() => {
    if (dataDB) {
      let conditionals = [];
      // grab every key which is really the field values (ampTrain), and approvals
      Object.entries(dataDB).forEach(([key, value]) => {
        if (key !== "conditionalOn") {
          //new object for every conditional
          conditionals.push({
            id: Math.random().toString(16).slice(2),
            fieldValues: key.split("&&"),
            approvals: value,
          });
        }
      });
      setCurrentConditionals(conditionals);
    }
  }, [dataDB]);

  // ampTrain Builder
  const buildAmp = (fieldValue) => {
    let ampTrain = "";
    fieldValue.forEach((val, index) => {
      ampTrain += val;
      if (index < fieldValue.length - 1) {
        ampTrain += "&&";
      }
    });
    return ampTrain;
  };

  const saveChanges = async () => {
    try {
      const formattedConditionalData = conditionalOns.map((val) => {
        return val;
      });
      let newApprovers = {};
      currentConditionals.forEach((cond) => {
        newApprovers[buildAmp(cond.fieldValues)] = cond.approvals;
      });
      newApprovers["conditionalOn"] = formattedConditionalData;
      await schema.update({ defaultApprovers: newApprovers });
      setStatusMessage("Changes Saved!");
    } catch (error) {
      console.error(error);
      setStatusMessage("Error saving conditionals.");
    }
  };

  if (schemaData === undefined) {
    return null;
  }

  return (
    <>
      <QueryContent>
        <FieldsConfigurationEditor
          conditionalOn={conditionalOns}
          fieldOptions={fieldList}
          setConditionalOns={setConditionalOns}
        />
        <ConditionalsDisplay
          conditionalOn={conditionalOns}
          schemaData={schemaData}
          setCurrentConditionals={setCurrentConditionals}
          currentConditionals={currentConditionals}
        />
      </QueryContent>
      <SaveQueryBar saveQuery={saveChanges} />
    </>
  );
};

const FieldsConfigurationEditor = ({
  conditionalOn,
  fieldOptions,
  setConditionalOns,
}) => {
  return (
    <>
      <Typography style={{ padding: "10px" }}>
        Select Fields for Conditionals
      </Typography>
      <SelectField
        multi
        label="Condition On"
        options={fieldOptions ?? {}}
        data={conditionalOn}
        onChange={(e) => {
          setConditionalOns(e.target.value);
        }}
      />
    </>
  );
};

// There is a set of fields that will all map to a select field or multi-select field
const MSFIELDS = ["boxset"]; // all map to select
const SFIELDS = ["radio"]; // all map to multiselect

const ConditionalsDisplay = ({
  conditionalOn,
  schemaData,
  currentConditionals,
  setCurrentConditionals,
}) => {
  // gather the field data for each field selected to have a conditional
  const conditionalFields = useMemo(() => {
    let fields = [];
    if (conditionalOn.length > 0) {
      (Array.isArray(conditionalOn) ? conditionalOn : [conditionalOn]).map(
        (k, i) => {
          const field = get_field_by_id(
            schemaData.fields,
            conditionalOn[i]?.split(".")[1]
          );
          if (field != null) {
            fields.push(field);
          }
        }
      );
    }
    return fields;
  }, [conditionalOn, schemaData.fields]);

  // handle new conditional
  const newConditional = () => {
    setCurrentConditionals((prev) => [
      ...prev,
      {
        id: Math.random().toString(16).slice(2),
        fieldValues: [],
        approvals: undefined,
      },
    ]);
  };

  // handle a deletion of an approval conditional
  const onApproversDefaultDelete = (deleteIndex) => {
    setCurrentConditionals((current) => [
      ...(current ?? []).slice(0, deleteIndex),
      ...(current ?? []).slice(deleteIndex + 1),
    ]);
  };

  // finally, lets build the view
  let conditionals = [];
  (currentConditionals ?? []).forEach((cond, index) => {
    conditionals.push(
      <ConditionalDefaultFieldEdit
        key={cond.id} // Added key
        conditional={cond}
        index={index}
        conditionalOn={conditionalOn}
        conditionalFields={conditionalFields}
        schemaData={schemaData}
        onApproversDefaultDelete={onApproversDefaultDelete}
        setCurrentConditionals={setCurrentConditionals}
      />
    );
  });

  return (
    <>
      <Typography style={{ padding: "10px" }}>
        Approval User Default Condtionals
      </Typography>
      {/* Existing Conditioal Containers */}
      {conditionals}
      {/* New Conditional Container*/}
      <ConditionContainer
        style={{
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "90%",
          cursor: "pointer",
          alignSelf: "stretch",
        }}
        onClick={newConditional}
      >
        <div style={{ fontSize: "18px", userSelect: "none" }}>
          New Conditional
        </div>
        <QuerySectionHeader
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            height: "10px",
          }}
        >
          <Tooltip title="Add Condition">
            <Add style={{ color: "#901D81" }} />
          </Tooltip>
        </QuerySectionHeader>
      </ConditionContainer>
    </>
  );
};

const ConditionalDefaultFieldEdit = ({
  conditional,
  conditionalFields,
  onApproversDefaultDelete,
  setCurrentConditionals,
  conditionalOn,
  index,
}) => {
  const [showEditButtons, setshowEditButtons] = useState(false);
  const [fields, setFields] = useState(() => {
    let currentFields = [];
    if (conditional?.fieldValues) {
      // conditional has already parsed the ampTrain, format each value
      conditional?.fieldValues.forEach((val) => {
        if (val === "*") {
          //wildcard for that field
          currentFields.push({
            condition: "",
            checked: true,
          });
        } else {
          //no wildcard, then there is a value
          currentFields.push({
            condition: val,
            checked: false,
          });
        }
      });
    }
    return currentFields;
  });
  const [approversStages, setApproversStages] = useState(
    conditional?.approvals
      ? [conditional.approvals]
      : [
          {
            approvers: [],
            stageLabel: "Approval",
            targetStatus: "open",
          },
        ]
  );
  // we need a reference to previous conditionalOn to process the data wiping when a field is unselected
  const prevConditionalOnRef = useRef(conditionalOn);

  // useEffect for removing a field in conditionalOn (wipes all values for that field)
  useEffect(() => {
    if (conditionalOn.length < prevConditionalOnRef.current.length) {
      // find lost index
      const lostIndex = prevConditionalOnRef.current.findIndex((val) => {
        return !conditionalOn.includes(val);
      });
      // now lets remove all the values for conditionals at that lostIndex
      if (lostIndex !== -1) {
        setFields((prev) => {
          const newField = prev;
          return newField.filter((_, index) => index !== lostIndex);
        });
      }
    }
    prevConditionalOnRef.current = conditionalOn;
  }, [conditionalOn]);

  // useEffect to manage the current conditionals data and changes
  useEffect(() => {
    if (fields.length > 0) {
      let newFieldValues = [];
      fields.forEach((val, i) => {
        if (fields[i]?.checked) {
          newFieldValues.push("*");
        } else {
          newFieldValues.push(fields[i]?.condition);
        }
      });
      setCurrentConditionals((prev) => {
        return prev.map((cond, i) => {
          if (i !== index) return cond;
          return {
            id: cond.id,
            approvals: approversStages,
            fieldValues: newFieldValues,
          };
        });
      });
    }
  }, [fields, approversStages, setCurrentConditionals, index]);

  const handleConditionalDelete = () => {
    onApproversDefaultDelete(index);
  };

  return (
    <ConditionContainer
      style={{
        alignItems: "left",
        justifyContent: "left",
        maxWidth: "90%",
        alignSelf: "stretch",
      }}
      onMouseEnter={() => setshowEditButtons(true)}
      onMouseLeave={() => setshowEditButtons(false)}
    >
      <AddContainer style={{ minHeight: "40px" }}>
        <Typography style={{ paddingBottom: "10px", fontWeight: "bold" }}>
          {fields
            .map(
              (val, index) => ` ${val?.checked ? "*" : val?.condition}
            ${index < fields.length - 1 ? ", " : ""}`
            )
            .join("")}
        </Typography>
        {showEditButtons && (
          <FloatingButtonContainer>
            {/* Rendered view */}
            <Tooltip title="Delete Conditional">
              <IconButton size="small" onClick={handleConditionalDelete}>
                <Delete style={{ color: "#901D81" }} />
              </IconButton>
            </Tooltip>
          </FloatingButtonContainer>
        )}
      </AddContainer>
      {/* TODO: abstract accordion to ui folder? */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Conditional Values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {conditionalFields.length > 0 && (
            <ConfigurationLine
              style={{
                backgroundColor: "#F2F2F2",
                borderRadius: 6,
                paddingTop: "10px",
              }}
            >
              <ConfigurationLineFieldsRow style={{ flex: "2 1 auto" }}>
                {/* This one is for the field we're conditional on */}
                {conditionalFields.map((k, i) => (
                  <ConfigurationLineFieldsCol
                    key={k.id}
                    style={{ flex: "2 1 auto" }}
                  >
                    <FieldGenerator
                      field={{
                        ...conditionalFields[i],
                        type: MSFIELDS.includes(conditionalFields[i].type)
                          ? "multiselect"
                          : SFIELDS.includes(conditionalFields[i].type)
                          ? "select"
                          : conditionalFields[i].type,
                        name: `${conditionalFields[i].name} Value`,
                      }}
                      data={{
                        [conditionalFields[i].id]: fields[i]?.checked
                          ? ""
                          : fields[i]?.condition,
                      }}
                      onChangeGenerator={() => (v) => {
                        let fieldsCopy = [...fields];
                        fieldsCopy[i] = {
                          condition: v,
                          checked: fieldsCopy[i]?.checked
                            ? fieldsCopy[i].checked
                            : false,
                        };
                        setFields(fieldsCopy);
                      }}
                      withLabel
                      editabilityConditions={{ forceEdit: !fields[i]?.checked }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            let fieldsCopy = [...fields];
                            fieldsCopy[i] = {
                              condition: fieldsCopy[i]?.condition,
                              checked: event.target.checked,
                            };
                            setFields(fieldsCopy);
                          }}
                          checked={fields[i]?.checked === true ? true : false}
                        />
                      }
                      label="Any Value"
                      style={{ padding: "5px" }}
                    />
                  </ConfigurationLineFieldsCol>
                ))}
              </ConfigurationLineFieldsRow>
            </ConfigurationLine>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Approvals Required</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {conditionalFields.length > 0 && (
            <ConfigurationLine>
              <NewApprovals
                approvalsRequired={approversStages}
                setApprovalsRequired={setApproversStages}
                approvalStagesAddable={true}
              />
            </ConfigurationLine>
          )}
        </AccordionDetails>
      </Accordion>
    </ConditionContainer>
  );
};

const ConfigurationLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConfigurationLineFieldsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ConfigurationLineFieldsCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px dashed grey;
  margin-right: 5px;
  min-height: 60px;
  flex: 1 1 90%;
  padding: 10px;
  margin: 10px;
`;

const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
  alignitems: "center";
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px dashed grey;
  padding: 4px 6px;
  margin-right: 5px;
  min-height: 60px;
  flex: 1 1 100%;
`;

const FloatingButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 6px;
  background: #f2f2f2;
`;
