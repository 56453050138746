import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Charts from "../analytics/charts";

import { FilteredQueriesState, ProjectRouteContainer } from "..";
import { ControlPanel } from "../controls";
import { CurrentProjectContext } from "..";

import PrintPreview from "../analytics/preview";
import { ModifyFlag } from "../../../hooks/filters";

export const ReportView = ({
  reports,
  setFiltersPaneVisible,
  queryFilterManager,
  projectActions,
}) => {
  const projectData = useContext(CurrentProjectContext);
  const queries = useRecoilValue(FilteredQueriesState);

  // We don't want to use ALL actions though, just the ones for this schema should show here :)
  const [actions, setActions] = useState([]);
  const [previewPrint, setPreviewPrint] = useState(false);

  const { reportId } = useParams();
  const chartsImages = useRef({});

  const chartBlobCollector = useCallback(
    (name, url) =>
      (chartsImages.current = { ...chartsImages.current, [name]: url }),
    [chartsImages]
  );

  const report = reports.find((rp) => rp.id === reportId);

  // Disable prefilters on filtermanager here
  useEffect(() => {
    queryFilterManager.setFilterOptions({
      disablePreFiltering: true,
      ignoreFlags: [ModifyFlag.SCHEME],
    });
    return () => queryFilterManager.clearFilterOptions();
  }, [queryFilterManager]);

  // Small filter to take in project actions and just count the ones from this schema
  useEffect(() => {
    if (projectActions && queries) {
      const schemaActions = projectActions.filter((action) =>
        queries.map((q) => q.id).includes(action.queriesId)
      );
      setActions(schemaActions);
    }
  }, [projectActions, queries]);

  return (
    <ProjectRouteContainer>
      {previewPrint && (
        <PrintPreview
          onClose={() => setPreviewPrint(false)}
          data={queries}
          actions={actions}
          chartSchemaSet={report?.schema ?? []}
          selectedSchema={report}
          projectName={projectData?.name}
        />
      )}
      <ControlPanel
        setFiltersPaneVisible={setFiltersPaneVisible}
        settings={{
          broad: "extended",
          disableNew: true,
        }}
        printAction={() => setPreviewPrint(true)}
        // printAction={() => saveAnalyticsPDFAsync({name: report?.name}, chartsImages.current, `${projectData?.name}-${report?.name}-analytics.pdf`)}
      />
      {queries && (
        <SchemaCharts>
          <Charts
            data={queries}
            actions={actions}
            chartSchemaSet={report?.schema ?? []}
            chartBlobCollector={chartBlobCollector}
          />
        </SchemaCharts>
      )}
    </ProjectRouteContainer>
  );
};

export default ReportView;

const SchemaCharts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
`;
