import React, { useState, useContext, useEffect } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { useCompanies, useCompany } from "../../../../hooks/companies";
import { useUsers } from "../../../../hooks/users";
import { useAllProjects } from "../../../../hooks/projects";

import { Modal, DialogContent } from "@mui/material";

import { ProjectRouteContainer } from "../../../project";
import { Button, Button2, ButtonSection } from "../../../ui/buttons";
import { ModalNotify, Tile } from "../../../ui/containers";
import { GenericField, SelectField } from "../../../ui/inputs2";
import { InteractiveTable } from "../../../ui/table";
import { ThemeProvider } from "@mui/material/styles";
import { materialThemeContrast } from "../../../../themes/mui_theme";

export default (props) => {
  // get company data and create new company
  const [addingCompany, setAddingCompany] = useState(false);
  const [newCompany, setNewCompany] = useState(emptyCompany);

  const [companies, addCompany] = useCompanies();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [company, companyData] = useCompany(selectedCompany);
  const [tempCompany, setTempCompany] = useState(companyData);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    companies?.forEach((company) => {
      setCompanyOptions((ex) => ({ ...ex, [company.id]: company.name }));
    });
  }, [companies]);

  useEffect(() => {
    if (companyData) {
      setTempCompany(companyData);
    }
  }, [companyData]);

  const onChange = (ind) => (e) => {
    const { value } = e.target;
    setNewCompany((ex) => ({ ...ex, [ind]: value }));
  };

  const onPropChange = (ind) => (e) => {
    const { value } = e.target;
    setTempCompany((ex) => ({ ...ex, [ind]: value }));
  };

  const createCompany = () => {
    addCompany(newCompany.name);
    setAddingCompany(false);
  };

  // users
  const [allUsers, addUser] = useUsers();
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    allUsers?.forEach((user) => {
      setUserOptions((ex) => ({
        ...ex,
        [user.id]: user.name?.first + user.name?.last,
      }));
    });
  }, [allUsers]);

  const newUser = () => {
    if (selectedUser === "") {
      return;
    }
    company.update({ users: [...companyData.users, selectedUser] });
    setSelectedUser(undefined);
  };

  const removeUser = (id) => {
    company.update({
      ...companyData,
      users: [...companyData.users.filter((el) => el !== id)],
    });
  };

  // projects
  const [projects, newProject] = useAllProjects();
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [projectOptions, setProjectOptions] = useState([]);

  useEffect(() => {
    projects?.forEach((project) => {
      setProjectOptions((ex) => ({ ...ex, [project.id]: project.name }));
    });
  }, [projects]);

  const addProject = () => {
    if (selectedProject === "") {
      return;
    }
    company.update({ projects: [...companyData.projects, selectedProject] });
    setSelectedProject("");
  };

  const removeProject = (id) => {
    company.update({
      ...companyData,
      projects: [...companyData.projects.filter((el) => el !== id)],
    });
  };

  const saveCompany = () => {
    company.set(tempCompany);
  };

  if (!allUsers || !companies) {
    return null;
  }

  return (
    <ProjectRouteContainer>
      <Modal
        open={addingCompany}
        onClose={() => {
          setAddingCompany(false);
        }}
      >
        <DialogContent>
          <ModalNotify>
            <ThemeProvider theme={materialThemeContrast(theme)}>
              <GenericField
                label={"Company Name"}
                data={newCompany.name}
                onChange={onChange("name")}
              />
              <Button text={"Create"} onClick={createCompany} />
            </ThemeProvider>
          </ModalNotify>
        </DialogContent>
      </Modal>
      <Button2
        label="Add Company"
        onClick={() => {
          setAddingCompany(true);
        }}
        style={{ width: "fit-content" }}
      />
      <SelectField
        label={"Select Company"}
        data={selectedCompany}
        options={companyOptions}
        onChange={(e) => setSelectedCompany(e.target.value)}
        contrast
      />
      {selectedCompany ? (
        <>
          x<Message></Message>
          <ButtonSection>
            <Button2 label={"Save Changes"} onClick={saveCompany} />
          </ButtonSection>
          <GenericField
            label="Name"
            contrast
            data={tempCompany?.name}
            onChange={onPropChange("name")}
          />
          <ButtonSection>
            <Button2 label={"Add Selected User"} onClick={() => newUser()} />
          </ButtonSection>
          <SelectField
            label={"New User"}
            data={selectedUser}
            options={userOptions}
            onChange={(e) => setSelectedUser(e.target.value)}
            contrast
          />
          <InteractiveTable
            data={companyData?.users ?? []}
            columns={usersColumns(removeUser)}
          />
          <ButtonSection>
            <Button2
              label={"Add Selected Project"}
              onClick={() => addProject()}
            />
          </ButtonSection>
          <SelectField
            label={"New Project"}
            data={selectedProject}
            options={projectOptions}
            onChange={(e) => setSelectedProject(e.target.value)}
            contrast
          />
          <InteractiveTable
            data={companyData?.projects ?? []}
            columns={projectsColumns(removeProject)}
          />
        </>
      ) : null}
    </ProjectRouteContainer>
  );
};

const emptyCompany = {
  name: "",
};

const usersColumns = (removeUser) => [
  { name: "Id", index: "id", format: "return `${data}`" },
  {
    name: "...",
    component: (row) => (
      <Button2 label={"Remove User"} onClick={() => removeUser(row)} />
    ),
  },
];

const projectsColumns = (removeProject) => [
  { name: "Id", index: "id", format: "return `${data}`" },
  {
    name: "...",
    component: (row) => (
      <Button2 label={"Remove Project"} onClick={() => removeProject(row)} />
    ),
  },
];

const Message = styled.div`
  margin: 12px;
  font-size: 18px;
  color: ${(props) => props.theme.text};
`;
