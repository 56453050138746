import { Box, Button, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "@emotion/styled";
import { LogoNoNav } from "./logos";

export const UpdatePrompt = () => {
  const [dismissed, setDismissed] = useState(0);

  // TODO: Use mui 5.x box shadow here :)
  // DEFECT: location.reload(true) is deprecated by most browsers now :(
  return (
    <UpdatePromptShell dismissed={dismissed}>
      <UpdateGraphic>
        <LogoNoNav style={{ height: "70px" }} />
      </UpdateGraphic>
      <UpdateContent>
        <UpdateExplain>
          A new version of Query-It is now available. Click to go!
        </UpdateExplain>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => location.reload(true)}
        >
          Update Now
        </Button>
      </UpdateContent>
      <ButtonContainer>
        <IconButton onClick={() => setDismissed(1)}>
          <Clear style={{ fontSize: "20px", color: "white" }} />
        </IconButton>
      </ButtonContainer>
    </UpdatePromptShell>
  );
};

const UpdateGraphic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30%;
  height: 100%;
`;

const UpdateContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%; // remainder

  justify-content: space-evenly;
  align-items: center;
`;

const UpdateExplain = styled.div`
  font-family: ${(props) => props.theme.palette.font};
  color: ${(props) => props.theme.palette.text.primary};

  font-size: 0.8em;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const UpdatePromptShell = styled(Box)`
  position: absolute;
  z-index: 10000;
  bottom: 25px;
  right: 25px;
  padding: 5px;
  width: 300px;
  height: 100px;

  background: ${(props) => props.theme.palette.background.step100};
  border-radius: 8px;

  // We don't currently support update prompting on mobile
  display: ${(props) => (isMobile || props.dismissed ? "none" : "flex")};

  // Add box shadow manually for now
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
