import React, { useState } from "react";

import { ProjectRouteContainer } from "../../../project";
import { Tile } from "../../../ui/containers";

import q from "@queryit/api";
import FileUploader from "react-firebase-file-uploader";
import { Typography, Box } from "@mui/material";

const storagePath = "/patchNotes/";

export default () => {
  // Its rather crude design wise, but the only expected user of this would be the devs

  const [fileURL, setFileURL] = useState(undefined);
  const handlePatchUpload = async (url) => {
    await q.patchnotes.set({ pdf: url });
    setFileURL(url);
  };

  return (
    <ProjectRouteContainer>
      <Box sx={{ margin: "10px", height: "100%" }}>
        <Tile>
          <Typography
            sx={{ margin: "10px", fontWeight: "bold", fontSize: "24px" }}
          >
            Upload Patch Notes:
          </Typography>
          <FileUploader
            onUploadSuccess={async (fileName) => {
              const fileRef = q.storage?.ref(storagePath + fileName);
              if (fileRef) {
                const url = await q.storage.generateDownloadURL(fileRef);
                handlePatchUpload(url);
              }
            }}
            onUploadError={() => {
              console.log("Upload failed.");
            }}
            metadata={{}}
            storageRef={q.storage?.compatibleRef(storagePath)}
            disabled={false}
            accept=".pdf"
            style={{ margin: "10px" }}
          />
        </Tile>
        <object
          data={fileURL}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ margin: "10px" }}
        ></object>
      </Box>
    </ProjectRouteContainer>
  );
};
