import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { ConfirmationModal } from "../../ui/modals";
import { Button2 as CustomButton } from "../../ui/buttons";
import { GenericField, LocalityField } from "../../ui/inputs2";
import { useProjectsUsers } from "../../../hooks/projects";
import { isMobile } from "react-device-detect";

import {
  FFTable,
  FFTableBody,
  FFTableCell,
  FFTableHead,
  FFTableRow,
} from "../../ui/table";
import {
  TableHeader,
  UsersSelectInput,
} from "../../project/query/fields/queryfields";
import { ProjectUsersContext } from "../../project";
import { QueryBlur } from "../../project/query/query";
import { useProjectSchemas } from "../../../hooks/projects";
import { parse_db_timestamp } from "../../../tools";

// Modal for viewing
export default ({ onClose, userProjects, userData, user, admin }) => {
  const [confirmEndVacation, setConfirmEndVacation] = useState(false);
  // Need a separate internal vacation period state so that we can change the vacation period without the changes showing on the Vacation Card under the popup. The display underneath should only update if the user hits submit
  const [vacationPeriodInternal, setVacationPeriodInternal] = useState({
    startDate: userData?.vacation?.start,
    endDate: userData?.vacation?.end,
  });
  const [assignees, setAssignees] = useState(undefined);
  const getProjectSchemas = useProjectSchemas();

  useEffect(() => {
    // Once we know userProjects, exists, let's populate assignees table
    if (userProjects) {
      // Now iterate over them and collect the projectSchemas
      let assigneeSet = [];
      Promise.all(
        userProjects.map(async (proj) => {
          (await getProjectSchemas(proj.id, userData.id)).forEach((schema) => {
            assigneeSet.push({
              assignedTo: "",
              projectName: proj.name,
              schemaName: schema.name,
              projectId: proj.id,
              schemaId: schema.id,
            });
          });
          return;
        })
      ).then((_) => {
        // Once we've collected all of that data, let's set Assignees
        setAssignees(assigneeSet);
      });
    }
  }, [userProjects]);

  useEffect(() => {
    if (userData?.vacation && assignees) {
      // Then let's apply existing assigned users for vacation
      const { alternateAssignments } = userData.vacation;
      Object.keys(alternateAssignments).map((key) => {
        const projectId = key.split("-")[0];
        const schemaId = key.split("-")[1];
        const assignee = alternateAssignments[key];
        setAssignees((ex) =>
          ex.map((a) =>
            a.projectId === projectId && a.schemaId === schemaId
              ? { ...a, assignedTo: assignee }
              : a
          )
        );
      });
    }
  }, [userData, assignees]);

  const onSave = async () => {
    // Update firebase!
    user.update({
      vacation: {
        start: vacationPeriodInternal.startDate,
        end: vacationPeriodInternal.endDate,
        alternateAssignments: assignees
          .filter((a) => a.assignedTo !== "")
          .map((a) => ({ [`${a.projectId}-${a.schemaId}`]: a.assignedTo }))
          .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
      },
    });
    // Then close as well!
    onClose(true);
  };

  const onDateChangeInternal = (value, index) => {
    // make end date inclusive by setting time to 23:59:59
    if (index === "endDate") {
      value.setHours(23, 59, 59, 999);
    }
    setVacationPeriodInternal((ex) => ({ ...ex, [index]: value }));
  };

  return (
    <EditContainer>
      <QueryBlur onClick={() => onClose(false)} />
      <TopBar>
        <Title>Set Vacation Dates</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "6px",
          }}
        >
          <DateSelectContainer>
            <LocalityField
              data={vacationPeriodInternal?.startDate ?? ""}
              onChange={(value) => onDateChangeInternal(value, "startDate")}
              useDate={true}
              disablePast
              label="Start Date"
            />
            <LocalityField
              style={{ marginRight: "20px" }}
              data={vacationPeriodInternal?.endDate ?? ""}
              onChange={(value) => onDateChangeInternal(value, "endDate")}
              useDate={true}
              disablePast
              label="End Date"
            />
          </DateSelectContainer>
          {!admin && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginRight: "4px", marginLeft: "-2px" }}
            />
          )}
          {!admin && (
            <Tooltip title="Cancel">
              <IconButton onClick={() => onClose(false)}>
                <Close />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TopBar>
      <VacationForm
        userProjects={userProjects}
        assignees={assignees}
        setAssignees={setAssignees}
        userId={userData.id}
      />
      <BottomBar>
        {admin && (
          <CustomButton
            style={{ marginRight: "16px" }}
            label="Cancel"
            onClick={() => onClose(false)}
          />
        )}
        <CustomButton
          style={{ marginRight: "16px" }}
          label="Save"
          onClick={onSave}
          disabled={
            !(
              vacationPeriodInternal?.startDate &&
              vacationPeriodInternal?.endDate &&
              vacationPeriodInternal?.startDate != "Invalid Date" &&
              vacationPeriodInternal?.endDate != "Invalid Date" &&
              vacationPeriodInternal?.endDate >
                vacationPeriodInternal?.startDate &&
              parse_db_timestamp(vacationPeriodInternal?.endDate) >
                Date.now() &&
              assignees != undefined
            )
          }
        />
      </BottomBar>
      <ConfirmationModal
        open={confirmEndVacation}
        onConfirm={() => {
          setConfirmEndVacation(false); // temporary
          // firebase update
        }}
        onCancel={() => setConfirmEndVacation(false)}
        body="Are you sure you want to end your vacation period?"
      />
    </EditContainer>
  );
};

const VacationForm = ({ userProjects, assignees, setAssignees, userId }) => {
  const projectsUsers = useProjectsUsers(userProjects?.map((proj) => proj.id));
  // not to be confused with userProjects, which is being passed in!
  // - userProjects contains the projects that the current logged in user is a part of
  // - projectsUsers stores the users associated with each project in userProjects
  //-  we use projectsUsers to determine the value for our ProjectUsersContext.Provider in AssigneesTable

  if (!projectsUsers || !assignees) {
    return (
      <MainContent>
        <Centerer>
          <CircularProgress />
        </Centerer>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Title style={{ marginTop: "15px", marginBottom: "10px" }}>
        Project Responsibility Assignments
      </Title>
      {Object.keys(projectsUsers).map((projectId) => (
        <ProjectUsersContext.Provider
          value={projectsUsers[projectId]?.filter((u) => u.id !== userId)}
          key={projectId}
        >
          <ProjectVacationForm
            projectId={projectId}
            setAssignees={setAssignees}
            assignees={assignees}
          />
        </ProjectUsersContext.Provider>
      ))}
    </MainContent>
  );
};

const ProjectVacationForm = ({ projectId, setAssignees, assignees }) => {
  return (
    <Box>
      <Title style={{ marginTop: "15px", marginBottom: "10px" }}>
        {assignees?.find((a) => a.projectId === projectId)?.projectName}
      </Title>
      <FFTable>
        <FFTableHead>
          <tr>
            <TableHeader>
              <TableHeaderDiv>Schema</TableHeaderDiv>
            </TableHeader>
            <TableHeader>
              <TableHeaderDiv>Alternate Assigner</TableHeaderDiv>
            </TableHeader>
          </tr>
        </FFTableHead>
        <FFTableBody>
          {assignees
            ?.filter((a) => a.projectId === projectId)
            ?.map((tableRow, ind) => (
              <FFTableRow key={ind}>
                <FFTableCell>
                  <GenericField
                    disabled={true}
                    data={tableRow.schemaName}
                    fill
                  />
                </FFTableCell>
                <FFTableCell>
                  <UsersSelectInput
                    editable={true}
                    label={"Assignees"}
                    withLabel={true}
                    data={tableRow.assignedTo}
                    onChange={(e) => {
                      const { value } = e.target;
                      setAssignees((ex) => [
                        ...ex.map((row) =>
                          row.schemaId == tableRow.schemaId
                            ? { ...row, assignedTo: value }
                            : row
                        ),
                      ]);
                    }}
                  />
                </FFTableCell>
              </FFTableRow>
            ))}
        </FFTableBody>
      </FFTable>
    </Box>
  );
};

const EditContainer = styled.div`
  position: absolute;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  top: 10%;
  left: 10%;
`;

const MainContent = styled.div`
  height: 0%;
  width: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 20;
  flex: 1 1 auto;
`;

const TopBar = styled.div`
  width: 80%;
  height: 7%;
  min-height: 72px;
  flex-shrink: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border-bottom: 1px solid #c2c2c2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const BottomBar = styled.div`
  width: 80%;
  min-height: ${isMobile ? "72px" : "60px"};
  z-index: 20;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f2f2f2;
  border-top: 1px solid #c2c2c2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const Title = styled.div`
  margin-left: 12px;
  font-size: 17px;
  font-weight: bold;
  display: flex;
`;

const TableHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const DateSelectContainer = styled.div`
  height: 68px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-right: 12px;
`;
const Centerer = styled.div`
  height: 450px;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
