import React, { useRef, useContext, useState } from "react";
import styled from "@emotion/styled";

import { Menu, MenuItem, MenuList, Box } from "@mui/material";

import {
  KeyboardArrowDown,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

import { _TableInput } from "./base";

// Context for MSCell
const MultiSelectCellChangeContext = React.createContext(undefined);

export default ({ data, setChange, options, disabled }) => {
  // Hook to impose focus
  const elem = useRef(null);
  // const boxRef = useRef(null);
  const setFocus = () => {
    elem.current && elem.current.focus();
  };

  // Render option list
  const [viewOptions, setViewOptions] = useState(false);

  // Data State Management
  const msChange = (value) => {
    setFocus();
    setChange((ex) => [
      ...(ex?.filter((x) => x !== value) ?? []),
      ...(ex?.includes(value) ? [] : [value]),
    ]);
  };

  // 18px height to match html select for now
  return (
    <MultiSelectCellChangeContext.Provider value={msChange}>
      <FauxTableInput
        onClick={() => {
          if (disabled) return; // Disabled so don't do anything!
          setViewOptions(true);
          setFocus();
        }}
        style={{
          height: "18px",
          marginRight: "20px",
          userSelect: "none",
          cursor: disabled ? "inherit" : "pointer",
        }}
      >
        {/* Hidden input to demonstrate focus */}
        <input
          style={{
            width: 0,
            height: 0,
            border: "unset",
            position: "absolute",
          }}
          ref={elem}
          disabled={disabled}
        />
        {/* Label for caret */}
        <label
          style={{
            position: "absolute",
            right: "0px",
            display: "flex",
            alignItems: "center",
            cursor: "inherit",
          }}
        >
          <KeyboardArrowDown
            sx={{
              fontSize: "19px",
              color: disabled ? "text.disabled" : "text.primary",
              cursor: "inherit",
            }}
          />
        </label>
        <SelectedOptionsList disabled={disabled}>
          {data?.map
            ? data
                ?.map((v) => options[v])
                ?.slice(0, 3)
                ?.join(", ")
                ?.concat(data?.length > 3 ? ", ..." : "") ?? " "
            : null}
        </SelectedOptionsList>
      </FauxTableInput>
      {/* Actual options to display on focus */}
      <Menu
        open={viewOptions}
        anchorEl={elem.current}
        onClose={() => setViewOptions(false)}
        autoFocus={false}
      >
        <MenuList dense sx={{ p: 0 }}>
          {Object.keys(options ?? {}).map((key) => (
            <MSCellOption key={key} value={key} selected={data?.includes(key)}>
              {options[key]}
            </MSCellOption>
          ))}
        </MenuList>
      </Menu>
    </MultiSelectCellChangeContext.Provider>
  );
};

const MSCellOption = ({ value, children, selected }) => {
  const onChange = useContext(MultiSelectCellChangeContext);

  return (
    <MenuItem
      selected={selected}
      dense
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange(value);
      }}
      sx={{ paddingLeft: 1, paddingRight: 1 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {selected ? (
          <CheckBox fontSize="small" />
        ) : (
          <CheckBoxOutlineBlank fontSize="small" />
        )}
        {children}
      </Box>
    </MenuItem>
  );
};

const SelectedOptionsList = styled.div`
  ${(props) =>
    props.disabled
      ? "color: " + props?.theme?.palette?.text?.disabled + ";"
      : ""}
`;

const FauxTableInput = styled.div`
  ${_TableInput}

  // display: inline-block;
  position: relative;
`;
