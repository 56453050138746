import React, { useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";

import { ProjectReferenceContext, CurrentProjectContext } from "../project";
import q, { APIStorage } from "@queryit/api";

import { Tooltip, Popper, Box } from "@mui/material";
import { DetermineBadgeDisplay } from "../../components/statics2/badges";

import { useProject, useCurrentBadges } from "../../hooks/projects";

export const Badge = ({ color, text, ...props }) => {
  return <BadgeContainer color={color}>{text?.toUpperCase()}</BadgeContainer>;
};

// Size options are sm, md, lg

export const UserCircle = ({
  userData,
  noUserGuarantee,
  size = "sm",
  showUserNames,
  p,
}) => {
  // Standardize display format
  const userDataIsList = Array.isArray(userData);
  const displayUserSet = userDataIsList
    ? userData.map((usr) => (!usr ? BLANK_USER_PRESET : usr))
    : [!userData && noUserGuarantee ? BLANK_USER_PRESET : userData];
  const [anchorEl, setAnchorEl] = useState(null);

  // Fetch project badges from context, but seems quite overkill for checking for emptiness of badges
  const projectData = useContext(CurrentProjectContext);
  const project = useProject(projectData?.id);
  const projectBadges = useCurrentBadges(project[0]);

  const handlePopperOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  // Now we'll need to adjust the display user set for rendering (we only render up to 3 coins, 3rd is ... coin if more than 3)
  const displayUserSetToRender = displayUserSet.slice(
    0,
    displayUserSet.length > 3 ? 2 : 3
  );
  if (displayUserSet.length > 3) {
    displayUserSetToRender.push(N_USER_EXTENSION_PRESET);
  }

  const generatedUserCoins = displayUserSetToRender.map((user, ind) => (
    <SingleUserCircle
      key={
        user && user.name ? user.name.last + user.name.first ?? user.name : ind
      }
      userData={user}
      offset={0 + ind * 10}
      opacity={100 - ind * 20}
      size={size}
    />
  ));

  // Now generate the tooltip
  const userSetTooltip =
    displayUserSet.length < 1
      ? "No users"
      : displayUserSet
          .map((usr, ind) => {
            if (ind < 2 || displayUserSet.length < 4) {
              if (usr.thisUserUndefined) {
                return "User Not Found";
              }
              return usr.name && usr.name.first && usr.name.last
                ? `${usr.name.first} ${usr.name.last}`
                : "? ?";
            } else if (ind === 2) {
              // Only hits here if the list is longer than 3 and we need to show "and N more"
              return `and ${displayUserSet.length - 2} more`;
            }
          })
          .join(", ");

  return (
    <Box sx={userCircleLayoutProps({ p })}>
      {!userData ? (
        <Tooltip title={userSetTooltip}>
          <UserCircleSetContainer
            size={size}
            style={{
              maxWidth: 24 + (generatedUserCoins.length - 1) * 14,
              position: !!anchorEl && "absolute",
            }}
          >
            {displayUserSet.length < 1 && <NoUserCircle />}
            {generatedUserCoins.length > 0 && generatedUserCoins}
          </UserCircleSetContainer>
        </Tooltip>
      ) : (
        <>
          <Popper
            id="badge-display-popper"
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement="left"
            sx={userCircleLayoutProps({})}
          >
            <BadgeDisplay
              projectUserData={userData}
              projectBadges={projectBadges}
            />
          </Popper>
          <UserCircleSetContainer
            size={size}
            onMouseEnter={handlePopperOpen}
            onMouseLeave={handlePopperClose}
            style={{
              maxWidth: 24 + (generatedUserCoins.length - 1) * 14,
            }}
          >
            {displayUserSet.length < 1 && <NoUserCircle />}
            {generatedUserCoins.length > 0 && generatedUserCoins}
          </UserCircleSetContainer>
          <Box>
            {showUserNames &&
              displayUserSetToRender.map((user, ind) => (
                <Box key={ind} sx={{ fontSize: "11px", textAlign: "center" }}>
                  {user.name.first} {user.name.last}
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const SingleUserCircle = ({
  userData: projectUserData,
  offset,
  opacity,
  size = "sm",
}) => {
  const [photoLink, setPhotoLink] = useState(undefined); // State of retrieved file

  // Fetch project from context
  const project = useContext(ProjectReferenceContext);

  useEffect(() => {
    const getUserData = async () => {
      if (projectUserData && projectUserData.id) {
        if (projectUserData.profilePhoto) {
          APIStorage.getDownloadURL(
            q.storage.ref("/profiles/" + projectUserData.profilePhoto)
          ).then((url) => {
            setPhotoLink(url);
          });
        } else {
          setPhotoLink(undefined);
        }
      } else {
        setPhotoLink(undefined);
      }
    };

    getUserData();
  }, [project, projectUserData]);

  // Check the userData is sound
  if (
    projectUserData &&
    (!projectUserData?.name || !projectUserData?.name?.first)
  ) {
    return null;
  }

  // This is how we select a background color
  const nameHash = (projectUserData.name.first + projectUserData.name.last)
    .split("")
    .reduce((acc, c) => acc + c.charCodeAt(0), 0);

  return (
    <UserCircleContainer
      color={
        projectUserData.name.first === "?" && projectUserData.name.last === " "
          ? "#606363"
          : FUN_COLOR_SET[nameHash % FUN_COLOR_SET.length]
      }
      offset={offset ?? 0}
      opacity={opacity}
      size={size}
    >
      {photoLink ? (
        <UserCircleImg src={photoLink} />
      ) : (
        <UserCircleInitials>
          {projectUserData.thisUserIsExtension
            ? projectUserData.name.first
            : projectUserData.name.first[0]}
          {projectUserData.name.last[0]}
        </UserCircleInitials>
      )}
    </UserCircleContainer>
  );
};

const BadgeDisplay = ({ projectUserData, projectBadges }) => {
  const [displayBadge, setDisplayBadge] = useState(null);

  useEffect(() => {
    if (projectBadges && projectBadges.length > 0) {
      setDisplayBadge(projectUserData?.displayBadge);
    }
  }, [projectBadges, projectUserData?.displayBadge]);

  if (Array.isArray(projectUserData)) {
    projectUserData = projectUserData[0];
  }

  if (
    projectUserData &&
    (!projectUserData?.name || !projectUserData?.name?.first)
  ) {
    return null;
  }

  let adjust = -11;

  if (
    projectUserData?.name?.first.length + projectUserData?.name?.last.length >
    10
  ) {
    adjust -= 10;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BadgeDisplayContainer
        style={{
          left: adjust,
          minWidth: "80px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            color: "white",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            whiteSpace: "nowrap",
            padding: "5px",
            borderRadius: "5px",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {projectUserData?.name?.first} {projectUserData?.name?.last}
        </div>
        <DetermineBadgeDisplay displayBadge={displayBadge} />
      </BadgeDisplayContainer>
      <Triangle
        hide={displayBadge === null}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </div>
  );
};

const NoUserCircle = () => (
  <NoUserCircleContainer>
    <UserCircleInitials>+</UserCircleInitials>
  </NoUserCircleContainer>
);

const FUN_COLOR_SET = [
  "#D32F2F",
  "#FF4081",
  "#303F9F",
  "#448AFF",
  "#388E3C",
  "#8BC34A",
  "#E64A19",
  "#FF9800",
  "#0288D1",
  "#00BCD4",
  "#AFB42B",
  "#00796B",
  // "#7C4DFF",
];

const BLANK_USER_PRESET = {
  thisUserUndefined: true,
  name: {
    first: "?",
    last: " ",
  },
};

const N_USER_EXTENSION_PRESET = {
  thisUserUndefined: true,
  thisUserIsExtension: true,
  name: {
    first: "...",
    last: " ",
  },
};

const SIZES = {
  sm: 24,
  md: 32,
  lg: 48,
};

const BadgeDisplayContainer = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.palette.background.step150};
  padding: 3px;
  z-index: 200;
  transform: translateX(-45%);
  border-radius: 4px;
  left: -45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const UserCircleSetContainer = styled.div`
  height: ${(props) => SIZES[props.size] ?? SIZES["sm"]}px;
  min-width: ${(props) => SIZES[props.size] ?? SIZES["sm"]}px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const UserCircleContainer = styled.div`
  width: ${(props) => SIZES[props.size] ?? SIZES["sm"]}px;
  height: ${(props) => SIZES[props.size] ?? SIZES["sm"]}px;
  flex: 0 0 ${(props) => SIZES[props.size] ?? SIZES["sm"]}px;

  border-radius: 50%;
  background: ${(props) => props.color};

  // This is how we offset the user circles for overlapping
  right: ${(props) => props.offset}px;
  opacity: ${(props) => props.opacity}%;
  z-index: ${(props) => 40 - props.offset};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NoUserCircleContainer = styled(UserCircleContainer)`
  border: 1px dashed ${(props) => props.theme.text};
`;

const UserCircleImg = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 24px;

  // we can use objectfit, but adjusting bg seems to result in a softer compress
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-color: ${(props) => props.color};

  user-select: none;
  font-family: ${(props) => props.theme.font};

  &:hover {
    cursor: pointer;
  }
`;

const UserCircleInitials = styled.div`
  font-size: 13px;
  user-select: none;
`;

const userCircleLayoutProps = ({ p }) => ({
  display: "inline-flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: p ? p : "10px",
  position: "absolute",
  zIndex: 2000,
});

export const StatusDot = ({ cmplt }) => {
  return (
    <Tooltip title={cmplt ? "Complete" : "Incomplete"}>
      <StatusDotShape cmplt={cmplt} />
    </Tooltip>
  );
};

const StatusDotShape = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;

  background-color: ${(props) => (props.cmplt ? "green" : "red")};
`;

export const BadgeContainer = styled.div`
  background: ${(props) => (props.color ? props.color : "#901D81")};
  border-radius: 6px;
  color: white;

  font-size: 12px;
  line-height: 10px;

  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  // width: fit-content;
  // height: fit-content;
  padding: 1px 5px;
`;

const Triangle = styled.div`
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  position: relative;
  opacity: ${(props) => (props.hide ? "0%" : "100%")};
  background-color: ${(props) => props.theme.palette.background.step150};
`;
