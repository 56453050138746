import React, { useContext, useState } from "react";
import { FormCompositeContext, FormContent, FormDataContext } from ".";
import {
  QueryButtons,
  QuerySection,
  QuerySectionHeader,
} from "../../project/query/query";
import { GenericField } from "../../ui/inputs2";
import { Button2 } from "../../ui/buttons";
import { ModalPrompt, ModalContent, ModalFooter } from "../../ui/containers";
import { Button, Modal, DialogContent } from "@mui/material";

const confirmationModalDefaults = {
  open: false,
  title: undefined,
  message: undefined,
  confirm: () => {},
};

export default () => {
  const form = useContext(FormCompositeContext);
  const formData = useContext(FormDataContext);

  const [notificationEmail, setNotificationEmail] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    ...confirmationModalDefaults,
  }); // add message to activate

  return (
    <FormContent>
      <Modal
        open={confirmationModal.open}
        onClose={() => {
          setConfirmationModal({ ...confirmationModalDefaults });
        }}
      >
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>{confirmationModal.title}</h2>
              <p>{confirmationModal.message}</p>
            </ModalContent>
            <ModalFooter>
              <Button
                onClick={() => {
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  confirmationModal.confirm();
                  setConfirmationModal({ ...confirmationModalDefaults });
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      <QuerySection>
        <QuerySectionHeader>Settings</QuerySectionHeader>
        <GenericField
          label="Notification Email"
          onChange={(e) => {
            const { value } = e.target;
            setNotificationEmail(value);
          }}
          data={
            formData.notificationEmail && formData.notificationEmail !== ""
              ? formData.notificationEmail
              : notificationEmail
          }
          disabled={
            formData.notificationEmail && formData.notificationEmail !== ""
              ? true
              : false
          }
        />
        <QueryButtons>
          {formData.notificationEmail && formData.notificationEmail !== "" ? (
            <Button2
              label={"Disable Notification Emails"}
              onClick={() =>
                setConfirmationModal({
                  open: true,
                  title: "Confirm Disable Notification Emails",
                  message:
                    "You are about to disable notification emails. Are you sure you'd like to proceed?",
                  confirm: () => {
                    form.update({ notificationEmail: "" });
                  },
                })
              }
            />
          ) : (
            <Button2
              label={"Confirm Email"}
              onClick={() => {
                form.update({ notificationEmail: notificationEmail });
              }}
            />
          )}
        </QueryButtons>
      </QuerySection>
      <QuerySection>
        <QuerySectionHeader>Actions</QuerySectionHeader>
        <div>
          {formData.status === "acknowledged" && (
            <Button2
              label="Withdraw Acknowledgement"
              onClick={() =>
                setConfirmationModal({
                  open: true,
                  title: "Confirm Acknowledgement Withdrawal",
                  message: `You are about to withdraw your acknowledgement of this form. 
                If the form has passed its acknowledgement date you will not be able to change your decision later.
                Are you sure you'd like to proceed?`,
                  confirm: () => {
                    form.update({ status: "pending" });
                  },
                })
              }
            />
          )}
          {/* TODO: Add check that submission date has not passed! */}
          {formData.status === "submitted" && (
            <Button2
              label="Withdraw Submission"
              onClick={() =>
                setConfirmationModal({
                  open: true,
                  title: "Confirm Submission Withdrawal",
                  message: `You are about to withdraw your submission of this form. 
                Are you sure you'd like to proceed?`,
                  confirm: () => {
                    form.update({ status: "acknowledged" });
                  },
                })
              }
            />
          )}
        </div>
      </QuerySection>
    </FormContent>
  );
};
