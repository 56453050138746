import { BaseCollection, BaseDocument, BaseData } from "../base";
 
export interface NotificationData extends BaseData {
  projectId: string;
  
}
 
export class Notifications extends BaseCollection<NotificationData> {

  notification(id: string): BaseDocument<NotificationData> {
    return this.doc(id) as BaseDocument<NotificationData>;
  }
}
