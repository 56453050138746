import { IconButton, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

export const StatusFilterDropdown = ({ filterManager }) => {
  // Fetch state & scheme from filter manager for statuses
  const filterState = filterManager?.useFilterState();
  const filterScheme = filterManager?.useFilterScheme();

  // On each render, extract the status information from filtering
  const [selectedStatuses, setSelectedStatuses] = useState([]); // Array of status strings active
  const [statusSet, setStatusSet] = useState({}); // The options for the dropdown TODO: Sort by category in filters hook, then apply that here :)

  // Also hold a show/hide state for the dropdown area
  const [showStatusSelector, setShowStatusSelector] = useState(undefined);

  // When state changes, check which statuses are active
  useEffect(() => {
    if (!filterState) {
      return;
    }
    const statuses = filterState.dataFields?.["status"]?.value;
    setSelectedStatuses(statuses ?? []);
  }, [filterState]);

  // when the scheme is updated, update the status set
  useEffect(() => {
    if (!filterScheme || !Array.isArray(filterScheme)) {
      return;
    }
    const statuses = filterScheme.find((s) => s.index === "status")?.options;
    setStatusSet(statuses ?? {});
  }, [filterScheme]);

  // Hide the component if there is no manager provided
  if (!filterManager || !statusSet || statusSet.length < 1) {
    return null;
  }

  // Onclick for selecting a status
  const toggleStatusSelected = (status) => {
    // Note this "find" op would be dangerous, but the component can only render if it's not :)
    filterManager.setFilterProperty(
      filterScheme.find((s) => s.index === "status"),
      status
    );
  };

  // Onclick for clearing the status selection
  const clearStatusSelected = () => {
    filterManager.clearFilterProperty(
      filterScheme.find((s) => s.index === "status")
    );
  };

  return (
    <>
      {/* The menu for selecting from */}
      <Menu
        anchorEl={showStatusSelector}
        open={showStatusSelector !== undefined}
        onClose={() => setShowStatusSelector(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList dense style={{ padding: 0, minWidth: "140px" }}>
          {/* Here we render a dense list of statuses as menuItems */}
          {Object.keys(statusSet).map((status) => {
            const isActive = selectedStatuses.includes(status);
            return (
              <MenuItem
                key={status}
                onClick={() => toggleStatusSelected(status)}
                style={{ paddingLeft: "4px", justifyContent: "space-between" }}
                selected={isActive}
              >
                <div style={{ display: "flex" }}>
                  <StatusSelectorColor color="#901D81" />
                  {statusSet[status]}
                </div>
                {isActive && (
                  <Close
                    style={{ opacity: 0.6, fontSize: 18, marginBottom: "2px" }}
                  />
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      {/* Then the visible element */}
      <StatusSelectorClickable
        onClick={(e) => setShowStatusSelector(e.currentTarget)}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            maxWidth: "80%",
          }}
        >
          {/* First we'll show the little square for status color (this is a scam right now because we don't have that data lol) */}
          <StatusSelectorColor
            color={selectedStatuses.length > 0 ? "#901D81" : undefined}
          />
          {/* Then we'll show the label */}
          <StatusSelectorText placeholds={selectedStatuses.length < 1}>
            {selectedStatuses.length > 0
              ? selectedStatuses.join(", ")
              : "Filter by Status..."}
          </StatusSelectorText>
        </div>
        {selectedStatuses.length > 0 && (
          <Tooltip title="Clear">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                clearStatusSelected();
              }}
            >
              <Close style={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        )}
      </StatusSelectorClickable>
    </>
  );
};

const StatusSelectorClickable = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 160px;
  max-width: 160px;
  height: 25px;
  margin-left: 10px;

  overflow: hidden;

  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.palette.background.step100};
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.palette.background.step150};
    cursor: pointer;
  }
`;

const StatusSelectorText = styled.div`
  margin-left: 4px;
  display: block;
  max-width: 125px;
  overflow: hidden;

  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${(props) => props.theme.text};
  opacity: ${(props) => (props.placeholds ? "0.7" : "0.9")};
`;

const StatusSelectorColor = styled.div`
  margin: 4px;
  margin-left: 8px;
  width: 10px;
  height: 10px;
  display: flex;
  flex-shrink: 0;

  border-radius: 20%;

  background-color: ${(props) => props.color ?? "grey"};
`;
