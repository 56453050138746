import React, { useMemo, useContext } from "react";
import styled from "styled-components";
import { Button } from "../../ui/buttons";

import { AppBar, Tabs, Tab, Chip } from "@mui/material";
import { SchemaSelectorContext, SelectedSchemaContext } from "..";
import { QueryStatus } from "../../../hooks/status";

const chipDims = {
  height: 16,
  fontSize: "0.7rem",
  marginLeft: 3,
  marginBottom: 3,
  padding: "0 4px",
  paddingTop: 2,
};

export default ({ schemas, queries }) => {
  const selectedSchema = useContext(SelectedSchemaContext);
  const setSelectedSchema = useContext(SchemaSelectorContext);

  if (!schemas || schemas.length < 1) {
    return null;
  }

  const selectedTab = schemas.some((sch) => sch.id == selectedSchema?.id)
    ? selectedSchema?.id
    : schemas[0].id;

  // Calculate query counts
  const queryCounts = useMemo(() => {
    if (!queries) {
      return {};
    }
    let schemaQueries = {};
    queries.forEach((q) => {
      // We use a list for each schemaId, [<open>, <overdue>] where we'll track counts
      if (!schemaQueries[q.schemaId]) {
        schemaQueries[q.schemaId] = [0, 0];
      } // Init the schema's ID for tracking
      // Then fetch the schema & statusset
      const schemaData =
        schemas.find((sch) => sch.id == q.schemaId) ?? undefined;
      if (!schemaData) {
        return;
      }
      // Now let's do the tracking
      let queryState = new QueryStatus(q, schemaData);
      if (queryState.isOverdue) {
        schemaQueries[q.schemaId][1]++;
      } else if (queryState.is_open()) {
        schemaQueries[q.schemaId][0]++;
      }
    });
    return schemaQueries;
  }, [queries, schemas]);

  return (
    <AppBar position="static">
      <Tabs
        value={selectedTab}
        variant="scrollable"
        onChange={(e, value) => {
          setSelectedSchema(value);
        }}
      >
        {schemas.map((schm) => (
          <Tab
            fullWidth={true}
            label={
              <SchemaTabLabel>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {schm.name}
                </div>
                {queryCounts && queryCounts[schm.id] && (
                  <>
                    {queryCounts[schm.id][0] > 0 && (
                      <Chip
                        label={`${queryCounts[schm.id][0]}`}
                        style={{ backgroundColor: "orange", ...chipDims }}
                        size="small"
                      />
                    )}
                    {queryCounts[schm.id][1] > 0 && (
                      <Chip
                        label={`${queryCounts[schm.id][1]}`}
                        style={{ backgroundColor: "red", ...chipDims }}
                        size="small"
                      />
                    )}
                  </>
                )}
              </SchemaTabLabel>
            }
            value={schm.id}
            key={`schema-tab-${schm.id}`}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

const SchemaHead = styled.div`
  height: 40px;
  width: 100%;
  padding: 6px 24px;
  background: ${(props) => props.theme.step150};

  display: flex;
  flex: 0 0 40px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  overflow: auto;

  // z-index: 10;

  flex-direction: row;
  box-shadow: 0px 7px 9px -10px #000000;
`;

const SchemaTag = styled(Button)`
  font-size: 12px;
  padding: 6px 10px;
  ${(props) => props.active && `background: ${props.theme.titleSub};`}
`;

const SchemaTabLabel = styled.div`
  display: flex;

  align-items: center;
  max-width: 100%;
`;
