import React, { useContext, useState } from "react";

import { FormCompositeContext, FormContent, FormDataContext } from ".";
import { Modal, Button as ModalButton, DialogContent } from "@mui/material";
import { ModalPrompt, ModalContent, ModalFooter } from "../../ui/containers";

import { ParagraphField } from "../../project/query/fields/queryfields";
import { Button, ButtonSection } from "../../ui/buttons";
import { format_data_text } from "../../../tools";
import { QueryFields } from "../../project/query/fields";
import { parse_on_fields } from "../../../tools/forms";

export default () => {
  const formData = useContext(FormDataContext);
  const form = useContext(FormCompositeContext);

  const formDates = formData?.deliveryDates ?? {};

  const [confirmAccept, setConfirmAccept] = useState(false);

  const setFormAccepted = () => form.update({ status: "acknowledged" });
  const setFormRejected = () => form.update({ status: "rejected" });

  const acknowledgementClosed =
    formDates &&
    formDates?.acknowledgementDate &&
    formDates?.acknowledgementDate < new Date();

  const data = {};
  parse_on_fields(
    formData?.previewData ?? {},
    (fld) => (data[fld.id] = fld.default)
  );

  return (
    <FormContent>
      <Modal open={confirmAccept} onClose={() => setConfirmAccept(false)}>
        <DialogContent>
          <ModalPrompt>
            <ModalContent>
              <h2>Confirmation</h2>
              {formData?.customPrompts?.confirmAcknowledgement ? (
                <p>
                  {format_data_text(
                    formData.customPrompts.confirmAcknowledgement,
                    { formData: formData }
                  )}
                </p>
              ) : (
                <p>
                  Are you sure you would like to agree to complete this form?
                </p>
              )}
            </ModalContent>
            <ModalFooter>
              <ModalButton onClick={() => setConfirmAccept(false)}>
                Cancel
              </ModalButton>
              <ModalButton
                onClick={() => {
                  setFormAccepted();
                  setConfirmAccept(false);
                }}
              >
                OK
              </ModalButton>
            </ModalFooter>
          </ModalPrompt>
        </DialogContent>
      </Modal>
      {formData?.customPrompts?.introductionText ? (
        <ParagraphField>
          {format_data_text(formData.customPrompts.introductionText, {
            formData: formData,
          })}
        </ParagraphField>
      ) : (
        <ParagraphField>
          Before starting with the form, please confirm your identity. This form
          is meant for <b>{formData.user.name}</b> at{" "}
          <b>{formData.user.email}</b>. All of your progress will be tracked
          under this name, and the person who sent you this form will be able to
          see your responses at their leisure. Sharing this link with anyone
          will allow them to access your submission and/or make changes under
          your name.
        </ParagraphField>
      )}

      {formData.previewData && (
        <>
          <ParagraphField>
            Below are some details about the query that the author felt you
            should know.
          </ParagraphField>
          <QueryFields fieldSet={formData.previewData} data={data} preview />
        </>
      )}
      <ParagraphField>
        {formData?.customPrompts?.additionalAcknowledgementMessage}
      </ParagraphField>
      {formData.finalSubmissionDate && (
        <ParagraphField>
          Final Date to submit this form is {formData.finalSubmissionDate}
        </ParagraphField>
      )}
      {!acknowledgementClosed &&
      formData.customPrompts &&
      formData.customPrompts.welcomeAcknowledge ? (
        <ParagraphField>
          {format_data_text(formData.customPrompts.welcomeAcknowledge, {
            formSchema: formData,
            formData: formData,
          })}
        </ParagraphField>
      ) : (
        <ParagraphField>
          If you agree to provide response to this {formData.title}, please
          press Accept, if you wish to decline this {formData.title}, please
          press Reject
        </ParagraphField>
      )}
      {!acknowledgementClosed && (
        <ButtonSection>
          <Button text="Reject" onClick={setFormRejected} />
          <Button
            text="Accept"
            onClick={() => {
              if (formData.confirmPromptText) {
                setConfirmAccept(true);
              } else {
                setFormAccepted();
              }
            }}
          />
        </ButtonSection>
      )}
      {acknowledgementClosed && (
        <ParagraphField>
          This {formData.title} has been closed. If you would like to request an
          exception, please contact the issuer.
        </ParagraphField>
      )}
    </FormContent>
  );
};
