import { TEMPLATE_SCHEMA_STATUSSETS } from "../common/query";
import { parse_db_timestamp, is_overdue } from "../tools";
import { get_query_due_date } from "../tools/forms";

export class QueryStatus {
  // Basic states of the Schema
  public schemaType: string;
  public statusSet: { [statusKey: string]: any };

  // Saves state of the schemaData
  public schemaData: any;

  // Definitions of the Query
  public isOverdue: boolean;
  public isClosed: boolean;
  public currentStatusId: string;
  public currentStatus: any; // Status object
  public approvalStage: number;
  public statusOrder: { [statusKey: string]: number };
  public relevantApprovals: { firstStage: number; lastStage: number };

  constructor(queryData: any, schemaData: any, dueDate: any) {
    this.schemaType = schemaData.type;
    this.statusSet = QueryStatus.get_statusset(schemaData);
    // Then the due date
    this.isOverdue =
      is_overdue(
        parse_db_timestamp(
          dueDate ? dueDate : get_query_due_date(queryData, schemaData)
        )
      ) && queryData?.closeTime === undefined;
    // Then the close time
    this.isClosed = queryData?.closeTime !== undefined;
    // Now let's dive deeper into the status of this query
    this.currentStatusId = queryData.status;
    this.currentStatus = this.statusSet?.[this.currentStatusId];
    this.approvalStage = queryData?.approvalStage ?? 1;
    const approvalsIteration = (queryData?.approvalsIteration ?? 0) + 1;
    this.relevantApprovals = {
      firstStage: queryData?.approvalsRequired?.length * approvalsIteration,
      lastStage:
        queryData?.approvalsRequired?.length * (approvalsIteration + 1) - 1,
    };
    // Finally, make some quickaccess calculations
    this.statusOrder = this.calculate_status_order();
  }

  public is_created() {
    return this.currentStatus?.statusType === "created";
  }

  public is_open() {
    return this.currentStatus?.statusType === "open" && !this.isClosed;
  }

  public is_approval() {
    return this.get_approvals_statuses().some(s => s.status === this.currentStatus?.status);
  }

  public is_closed() {
    return this.isClosed;
  }

  public is_equivalent(destinationStatusString: string): boolean {
    return this.currentStatus?.status === destinationStatusString;
    // DEFECT: status_difference returns 0 if no path found so statuses may not actually be equivalent
    // return (
    //   this.status_difference(destinationStatusString) === 0 &&
    //   this.statusSet?.[destinationStatusString] !== undefined
    // );
  }

  public has_approvals() {
    return (
      this.schemaType === "approval" || (this.approval_stage_count() ?? 0) > 0
    );
  }

  public has_responses() {
    return this.schemaType === "response";
  }

  public status_has_passed(statusString: string) {
    return this.status_difference(statusString) < 0;
  }

  public previous_status() {
    if (this.currentStatus?.status) {
      const currentStatusPosition =
        this.statusOrder[this.currentStatus?.status];
      if (currentStatusPosition === 0) {
        return undefined;
      }
      return Object.keys(this.statusOrder).find(
        (key) => this.statusOrder[key] === currentStatusPosition - 1
      );
    }
    return undefined;
  }

  public is_approvals_editable() {
    // Approvals are editable until we hit our first approval stage
    // 1. Find the first approval stage by parsing from the created status
    const createdStatus = this.get_created_status();

    let approvalStage = createdStatus;
    let crossedCurrentStatus = false;
    let generatingApprovalsBreak = false;

    const visitedStatuses = [];

    while (true) {
      // Check if we're done because we can't go anywhere (no approvals here!)
      if (approvalStage?.advance?.requirements === undefined) {
        break;
      }
      // Check if we're done because of generatingApprovalsBreak on last status
      if (generatingApprovalsBreak) {
        break;
      }
      // Now check if we're done because we found an approval status
      if (
        approvalStage?.advance?.requirements?.some(
          (rq: any) => rq.type === "approve"
        )
      ) {
        break;
      }
      // Also track if we'll be done because this status will generate approvals!
      if (
        approvalStage?.advance?.actions?.some(
          (ac: any) => ac === "generateApprovals"
        )
      ) {
        generatingApprovalsBreak = true;
      }
      // Check if the status we're at is the current status
      // (this is after so that the approval stage isn't editable)
      if (approvalStage?.status === this.currentStatusId) {
        crossedCurrentStatus = true;
      }
      approvalStage = QueryStatus.find_next_status(
        approvalStage,
        this.statusSet,
        visitedStatuses
      );
      // And track that we've visited this status
      visitedStatuses.push(approvalStage?.status);
    }

    // If we crossed the current status, we're editable! Otherwise the current status is after first approval
    return crossedCurrentStatus;
  }

  // On custom schemas, get the approval stage count of the custom schema status set
  public approval_stage_count() {
    if (this.schemaType !== "custom") {
      return undefined;
    }
    return Object.values(this.statusSet).filter((ss: any) =>
      ss?.advance?.requirements?.some((rq: any) => rq.type === "approve")
    ).length;
  }

  public get_approvals_default_structure() {
    return Object.values(this.statusSet)
      .filter((ss: any) =>
        ss?.advance?.requirements?.some((rq: any) => rq.type === "approve")
      )
      .reduce((acc: Array<any>, status: any) => {
        return [
          ...acc,
          {
            stageLabel: status?.name,
            approvers: [],
            targetStatus: status?.status,
          },
        ];
      }, [])
      .sort(
        (a: any, b: any) =>
          this.statusOrder[a.targetStatus] - this.statusOrder[b.targetStatus]
      );
  }

  public get_approvals_statuses() {
    return Object.values(this.statusSet)
      .filter((ss: any) =>
        ss?.advance?.requirements?.some((rq: any) => rq.type === "approve")
      )
      .reduce((acc: Array<any>, status: any) => {
        return [...acc, { name: status?.name, status: status?.status }];
      }, []);
  }

  // On evaluation schemas, check if the evaluations are available
  public evaluations_available() {
    return Object.values(this.statusSet)
      .filter((st: any) => st?.statusType === "evaluate")
      .some((st: any) => this.status_difference(st) <= 0);
  }

  public get_requirements_map(): { [statusKey: string]: any[] | undefined } {
    return Object.keys(this.statusSet).reduce((acc, key) => {
      return {
        ...acc,
        [key]: this.statusSet[key]?.advance?.requirements,
      };
    }, {});
  }

  private static parse_custom_schema(customStatusSet: {
    [statusKey: string]: any;
  }) {
    return Object.keys(customStatusSet).reduce((acc, key) => {
      const newAdvance: any = {};
      if (customStatusSet[key].advance) {
        newAdvance.advance = { ...customStatusSet[key].advance };
        newAdvance.advance.requirements = [
          ...(newAdvance.advance.requirements?.map((req: any) => {
            // TODO: Maybe also check privilege?
            if (req.check) {
              let newCheck = new Function("data", req.check);
              return { ...req, check: newCheck };
            } else {
              return req;
            }
          }) ?? []),
        ];
      }
      return { ...acc, [key]: { ...customStatusSet[key], ...newAdvance } };
    }, {});
  }

  private get_created_status() {
    return Object.values(this.statusSet ?? {}).find(
      (st: any) => st?.statusType === "created"
    );
  }

  private status_difference(destinationStatusString: string): number {
    // Fetch destination
    const destinationStatus = this.statusSet[destinationStatusString];
    // Check that it's good!
    if (!destinationStatus) {
      // No idea where we are
      return 0;
    }
    // We'll try both forward and backward (this func can return negatives too)
    let statusJumps;
    // Now let's try the forward direction (from current to destination)
    statusJumps = QueryStatus.find_status_through_crawl(
      this.currentStatus,
      destinationStatus,
      this.statusSet
    );
    // If we found a path, return it
    if (statusJumps !== -1) {
      return statusJumps;
    }
    // Now let's try the backward direction (from destination to current)
    statusJumps = QueryStatus.find_status_through_crawl(
      destinationStatus,
      this.currentStatus,
      this.statusSet
    );
    // If we found a (backward) path, return it negative
    if (statusJumps !== -1) {
      return -statusJumps;
    }
    // No path found
    return 0;
  }

  private calculate_status_order() {
    // First, map "new" status (0), then go to created status and begin indexing with crawl
    const statusOrder: { [statusKey: string]: number } = {
      new: 0,
    };
    // Now let's crawl through the status set and assign indexes
    let currentStatus = this.get_created_status();
    let currentIndex = 1;
    const visitedStatuses = [];
    while (currentStatus) {
      statusOrder[currentStatus.status] = currentIndex;
      currentIndex++;
      currentStatus = QueryStatus.find_next_status(
        currentStatus,
        this.statusSet,
        visitedStatuses
      );
      // And track that we've visited this status
      visitedStatuses.push(currentStatus?.status);
    }
    return statusOrder;
  }

  private static find_next_status(
    currentStatus: any,
    statusSet: any,
    statusBlacklist: string[] = []
  ): any | undefined {
    // If we can't advance, we're stuck
    if (!currentStatus?.advance) {
      return undefined;
    }
    // If we can advance, find options for moving
    const moveOptions =
      currentStatus.advance.requirements
        ?.filter((req: any) => req?.moveTo !== undefined)
        .filter((req: any) => !statusBlacklist.includes(req?.moveTo)) ?? [];

    // If we came up empty, return undefined
    if (moveOptions.length === 0) {
      return undefined;
    }

    // Now with our move options, filter out any "poll" type options
    const nonPollMoveOptions = moveOptions.filter(
      (req: any) => req?.type !== "poll"
    );

    // Then with those out of the way, let's prioritize the "approve" type options
    const approveMoveOptions = nonPollMoveOptions.filter(
      (req: any) => req?.type === "approve"
    );

    if (approveMoveOptions.length > 0) {
      // If we have any approve options, let's send that back!
      return statusSet?.[approveMoveOptions[0].moveTo];
    } else {
      // No approve move options, so just pick the first possible move option (still non poll)
      return statusSet?.[nonPollMoveOptions[0].moveTo];
    }
  }

  private static get_statusset(schemaData: any): { [statusKey: string]: any } {
    // Now retrieve the status set
    if (schemaData.type === "custom") {
      return QueryStatus.parse_custom_schema(schemaData?.customStatusSet);
    } else {
      // Retrieve from our standard templates
      return TEMPLATE_SCHEMA_STATUSSETS[schemaData.type];
    }
  }

  /**
   *
   * Crawl across the statusSet trying to connect the startingStatus to the stoppingStatus
   *
   * Returns the number of status jumps required to get from startingStatus to stoppingStatus
   *          or -1 if no path exists
   *
   * @param startingStatus The status to begin crawl from
   * @param stoppingStatus The status to crawl to
   * @param statusSet The full statusSet dict with status definitions
   */
  private static find_status_through_crawl(
    startingStatus: any,
    stoppingStatus: any,
    statusSet: any
  ): number {
    let statusJumps = 0;
    let currentStatus = startingStatus;
    const visitedStatuses = [];
    // Now let's start at the current status and try to advance to the destination status
    while (true) {
      // If we found the destination, return our jump count
      if (currentStatus.status === stoppingStatus.status) {
        return statusJumps;
      }

      // Now advance one status
      currentStatus = QueryStatus.find_next_status(
        currentStatus,
        statusSet,
        visitedStatuses
      );

      // Then track that we've visited this status
      visitedStatuses.push(currentStatus?.status);

      // If we can't advance, we're stuck
      if (!currentStatus) {
        break;
      }
      // Now increment and continue!
      statusJumps++;
    }

    return -1;
  }

  /**
   * Parse the schemadata for the status set and return it in an appropriate format for
   * a dropdown to show Names of statuses and select status Ids
   *
   * @param schemaData
   */
  public static get_droppable_statusset(schemaData: any) {
    // Return format is { [statusId]: statusName, ...}
    const statusSet = QueryStatus.get_statusset(schemaData);
    if (statusSet === undefined) {
      return {};
    }
    // Then map it out to select option format
    return Object.keys(statusSet)
      .map((statusId: string) => {
        return { [statusId]: statusSet[statusId].name };
      })
      .reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
  }
}
