/**
 * Queries also have actions, ephemeral one-off things to do that don't deserve a full query.
 *
 */

import { BaseCollection, BaseDocument, BaseData } from "../../../base";
import { Messages } from "./messages";
import { Requests } from "./requests";
import { FormDataSets } from "./data";

export interface FormData extends BaseData {
  // User that this is assigned to.
  assignedUser: string;
  dueDate: string;
  status: string;
  projectId: string;
  createdDate: Date;
  token: string;

  description: string;
  data: { [key: string]: any };
}

export class Forms extends BaseCollection<FormData> {
  form(id: string): Form {
    return new Form(id, this);
  }
}

export class Form extends BaseDocument<FormData> {
  messages: Messages;
  requests: Requests;
  data: FormDataSets;

  constructor(id: string, forms: Forms) {
    super(forms.doc(id).ref);
    
    // TODO: add limiter and graduation to this collection fetch
    this.messages = new Messages(this.collection("messages"));
    this.requests = new Requests(this.collection("formaccessrequests"));
    this.data = new FormDataSets(this.collection("forms"));
  }
}
