import React, { useState, useEffect, useContext, useCallback } from "react";
import styled, { css } from "styled-components";

import themes from "../../../themes";

import { isMobile } from "react-device-detect";
import { UserDataContext } from "../../../App";

import { ChartResolver } from "../../ui/charts";
import { ChartEditor } from "./admin/editor";

import { CircularProgress } from "@mui/material";

/** Shows a set of intelligently arranged charts (meant to use full display) */
export default ({
  data,
  aggregateData,
  actions,
  chartSchemaSet,
  chartBlobCollector,
  panel,
  editMode,
  setChartSchemaSetPreview,
}) => {
  // (Data should be query-like)
  const userData = useContext(UserDataContext);
  const [theme, setTheme] = useState();

  useEffect(() => {
    if (userData?.theme) {
      setTheme(themes[userData.theme]);
    }
    // This timeout allows us to set the theme state's initial value as undefined without causing a permanent blank page for users without a theme
    setTimeout(() => {
      if (!userData?.theme) {
        setTheme(themes.purple);
      }
    }, 300);
  }, [userData]);

  if (theme === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      {!editMode ? (
        <Charts>
          {chartSchemaSet?.map((chartSchema) => (
            <ChartResolver
              key={chartSchema.id}
              chartSchema={chartSchema}
              data={data}
              aggregateData={aggregateData}
              actions={actions}
              theme={!panel ? theme : undefined}
              sendBlob={chartBlobCollector}
            />
          ))}
        </Charts>
      ) : (
        <ChartEditor
          chartSchemaSet={chartSchemaSet}
          data={data}
          actions={actions}
          panel={panel}
          theme={!panel && theme ? theme : undefined}
          chartBlobCollector={chartBlobCollector}
          setChartSchemaSetPreview={setChartSchemaSetPreview}
        />
      )}
    </>
  );
};

const Charts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${isMobile &&
  css`
    width: 100%;
  `}
`;
