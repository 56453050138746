export const _TableInput = `
  padding: 0;
  margin: 0;
  border: none;

  height: 100%;
  width: 100%;
  font-size: 14px;

  text-overflow: ellipsis;

  // Also hide the arrows when its "number"
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
`;
