import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "@emotion/styled";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Box,
} from "@mui/material";
import { ProjectUsersContext, CurrentProjectContext } from "../../..";
import { UserDataContext } from "../../../../../App";
import { Button2 } from "../../../../ui/buttons";
import { SignatureField } from "../../../../ui/inputs2";
import { QueryFields } from "../../fields";
import { UsersSelectInput } from "../../fields/queryfields";

export default ({
  approvals,
  approvalSchema,
  queryStatus,
  signToApprove,
  requireApprovalSignature,
  query,
}) => {
  const stage = queryStatus?.approvalStage;
  const currentlyApproving =
    queryStatus?.currentStatus?.advance?.requirements?.some(
      (req) => req?.type === "approve"
    );

  return (
    <>
      {currentlyApproving && (
        <>
          <ApprovalTitle>Current Approvals</ApprovalTitle>
          {approvals
            ?.filter((a) => a?.stage === stage)
            ?.map((approval) => (
              <ApprovalCard
                approval={approval}
                approvalSchema={approvalSchema}
                signToApprove={signToApprove}
                requireApprovalSignature={requireApprovalSignature}
                editable={true}
                query={query}
                key={approval?.id}
              />
            ))}
        </>
      )}
      <ApprovalTitle>Upcoming Approvals</ApprovalTitle>
      {approvals
        ?.filter(
          (a) =>
            a?.stage > stage ||
            (a?.stage === stage &&
              !currentlyApproving &&
              ["assigned", "pending"].includes(a?.status))
        )
        ?.map((approval) => (
          <ApprovalCard
            approval={approval}
            approvalSchema={approvalSchema}
            upcoming
            key={approval?.id}
          />
        ))}
      <ApprovalTitle>Past Approvals</ApprovalTitle>
      {approvals
        ?.filter(
          (a) =>
            a?.stage < stage ||
            (a?.stage === stage &&
              !currentlyApproving &&
              !["assigned", "pending"].includes(a?.status))
        )
        ?.map((approval) => (
          <ApprovalCard
            approval={approval}
            approvalSchema={approvalSchema}
            signToApprove={signToApprove}
            key={approval?.id}
          />
        ))}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "60px",
        }}
      />
    </>
  );
};

const ApprovalCard = ({
  approval,
  approvalSchema,
  signToApprove,
  requireApprovalSignature,
  editable,
  query,
  upcoming,
}) => {
  const users = useContext(ProjectUsersContext);
  const projectData = useContext(CurrentProjectContext);
  const user = useContext(UserDataContext);

  const [data, setData] = useState(approval.data || {});
  const [intent, setIntent] = useState(undefined);
  const [selectedEscalateUser, setSelectedEscalateUser] = useState(undefined);

  const assignedUsers = (
    Array.isArray(approval?.assignedTo)
      ? approval?.assignedTo
      : [approval?.assignedTo]
  ).map((id) => users?.find((u) => u?.id === id));

  // If the approval is submitted by the signature field,
  // need to check it on change
  useEffect(() => {
    if (!signToApprove) {
      return;
    }
    if (intent?.signature) {
      if (intent.signature.confirmed && approval.status === "assigned") {
        query.approvals.approval(approval.id).update({
          status: intent.status,
          data: data,
          signature: intent.signature,
        });
        setIntent(undefined);
      }
    }
  }, [intent, signToApprove, query, approval, data]);

  const onFieldChange = (fieldId) => (val) => {
    setData((ex) => ({ ...ex, [fieldId]: val }));
  };

  const escalateApproval = () => {
    const approvalIndex = approval.assignedTo.findIndex(
      (usrId) => usrId === user?.id
    );
    if (selectedEscalateUser && approvalIndex !== -1) {
      // Update the approval document
      let newAssignments = [...approval.assignedTo];
      newAssignments[approvalIndex] = selectedEscalateUser;
      query.approvals.approval(approval.id).update({
        assignedTo: newAssignments,
      });
    }
    setSelectedEscalateUser(undefined);
  };

  const primaryUser = assignedUsers?.[0];
  const thisUserIsPrimary = primaryUser?.id === user?.id;
  const thisUserIsAssignedNotPrimary =
    assignedUsers?.find((u) => u?.id === user?.id) && !thisUserIsPrimary;

  const userCanEdit =
    (thisUserIsAssignedNotPrimary || thisUserIsPrimary) &&
    editable &&
    approval?.status === "assigned";

  // A user cannot escalate to themselves
  const selectableEscalationUsers = useMemo(
    () =>
      users?.filter(
        (usr) =>
          projectData.escalationUsers?.includes(usr.id) && usr.id !== user?.id
      ) ?? [],
    [projectData, users, user]
  );

  return (
    <Accordion
      disabled={
        upcoming !== undefined ||
        (userCanEdit === false && approval?.status === "assigned")
      }
    >
      <AccordionSummary
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography
          sx={{
            width: "33%",
            flexGrow: 2,
            display: "flex",
            alignItems: "center",
            color: thisUserIsPrimary ? "primary.main" : "inherit",
          }}
          component="div"
        >
          {thisUserIsPrimary
            ? "You"
            : `${primaryUser?.name?.first} ${primaryUser?.name?.last}`}
          &nbsp;
          <Typography sx={{ color: "primary.main" }}>
            {thisUserIsAssignedNotPrimary ? "or You" : ""}
          </Typography>
        </Typography>
        <Chip
          sx={{ display: "flex" }}
          variant="outlined"
          label={approval?.status}
          color={approval?.status === "pending" ? "primary" : "success"}
        />
      </AccordionSummary>
      <AccordionDetails>
        {userCanEdit && intent === undefined && (
          <>
            <div>
              Upon selecting &quot;Approve&quot; or &quot;Reject&quot;, you will
              be prompted for more detail. Once submitted, you will no longer be
              able to edit these details.
              <br />
              Note that if this is the final approval, the Query could also be
              closed upon your decision&apos;s submission, making it unavailable
              for further editing.
              <br />
              {selectableEscalationUsers.length > 0
                ? `You may also escalate this approval to another user, upon which 
                you will immediately lose your ability to approve or reject this query.`
                : ""}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button2
                  label="Reject"
                  onClick={() => {
                    setIntent({ status: "rejected", reason: "", files: [] });
                  }}
                />
                <Button2
                  label="Approve"
                  onClick={() => {
                    setIntent({ status: "approved", reason: "", files: [] });
                  }}
                />
              </Box>
              {selectableEscalationUsers.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <UsersSelectInput
                    data={selectedEscalateUser || ""}
                    onChange={(e) => setSelectedEscalateUser(e.target.value)}
                    users={selectableEscalationUsers}
                    label="Escalate Approval"
                    style={{ padding: 0, marginTop: 0 }}
                    editable={true}
                  />
                  <Button2
                    label="Escalate"
                    onClick={escalateApproval}
                    disabled={!selectedEscalateUser}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {/* Show schema if already filled out or intending to approve */}
        {(approval.status === "approved" ||
          (userCanEdit && intent && intent.status == "approved")) && (
          <QueryFields
            data={data}
            onFieldChange={onFieldChange}
            fieldSet={approvalSchema ?? APPROVAL_SCHEMA_DEFAULT}
            status={"readonly"}
            editabilityConditions={{
              forceEdit: userCanEdit,
            }}
            noHeaders
          />
        )}
        {(approval.status === "rejected" ||
          (userCanEdit && intent && intent.status === "rejected")) && (
          <QueryFields
            data={data}
            onFieldChange={onFieldChange}
            fieldSet={REJECT_SCHEMA}
            status={"readonly"}
            editabilityConditions={{
              forceEdit: userCanEdit,
            }}
            noHeaders
          />
        )}

        {(intent !== undefined || !userCanEdit) &&
          (requireApprovalSignature || signToApprove) && (
            <>
              {signToApprove && (
                <div>
                  By confirming your signature, you submit your approval. Do not
                  sign before confirming your approval.
                </div>
              )}
              <SignatureField
                label={"Approval Signature"}
                onChange={(e) => {
                  const { value } = e.target;
                  setIntent((ex) => ({ ...ex, signature: value }));
                }}
                data={intent ? intent.signature : approval.signature}
                drawable
                signingUser={{
                  id: user.id,
                  name: `${user.name.first} ${user.name.last}`,
                }}
                disabled={!userCanEdit}
              />
            </>
          )}

        {intent != undefined && (
          <Box>
            <Button2
              label="Cancel"
              onClick={() => {
                setIntent(undefined);
              }}
            />
            {!signToApprove && (
              <Button2
                sx={{ marginLeft: "10px" }}
                label="Submit"
                onClick={() => {
                  query.approvals.approval(approval.id).update({
                    status: intent.status,
                    data: data,
                    approvedBy: user.id,
                  });
                  setIntent(undefined);
                }}
              />
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ApprovalTitle = styled(Typography)`
  user-select: none;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const APPROVAL_SCHEMA_DEFAULT = [
  {
    name: "Justification",
    id: "justification",
    type: "textarea",
    editableWhile: ["editable"],
  },
  {
    name: "Attachments",
    id: "attach",
    type: "files",
    editableWhile: ["editable"],
  },
];

const REJECT_SCHEMA = [
  {
    name: "Rejection Reason",
    id: "reason",
    type: "string",
    editableWhile: ["editable"],
  },
];
