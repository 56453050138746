import { BaseCollection, BaseDocument, BaseData } from "../../base";

interface RevisionData extends BaseData {
  schema: string;
  editor: string;
  publisher: string;
}

export class Revisions extends BaseCollection<RevisionData> {
  revision(id: string) {
    return this.doc(id) as Revision;
  }
}

type Revision = BaseDocument<RevisionData>;