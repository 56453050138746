export function formatDate(dt) {
  let date = dt.getDate();
  let month = dt.getMonth() + 1;
  let year = dt.getFullYear();
  return `${date}/${month}/${year}`;
}

export function shortDate(dt) {
  let hour = dt.getHours();
  let min = dt.getMinutes();
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][dt.getMonth()];
  let date = dt.getDate();
  let suff =
    date % 10 == 1 && date != 11
      ? "st"
      : date % 10 == 2 && date != 12
      ? "nd"
      : date % 10 == 3 && date != 13
      ? "rd"
      : "th";
  return `${("0" + hour).slice(-2)}:${("0" + min).slice(
    -2
  )} on ${month} ${date}${suff}`;
}
