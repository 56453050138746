import React from "react";
import styled from "styled-components";
import { Routes, Route, Navigate } from "react-router-dom";

import NavBar from "../../navigator";
import AllUsers from "./users";
import Duplicator from "./duplication";
import AllProjects from "./projects";
import AllCompanies from "./companies";
import Devlog from "./devlog";
import PatchNotes from "./patchnotes";
import UserFeedback from "./feedback";

import {
  BatchPrediction,
  ContentCopy,
  CorporateFare,
  People,
  BugReport,
  NoteAdd,
  Feedback
} from "@mui/icons-material";

export default ({ user }) => {
  return (
    <ProjectAdminPage>
      <NavBar options={navOptions} root="/app/admin/manage/" />
      <ProjectAdminContain>
        <Routes>
          <Route path={`users`} element={<AllUsers user={user} />} />
          <Route path={`duplicate`} element={<Duplicator />} />
          <Route path={`projects`} element={<AllProjects user={user} />} />
          <Route path={`companies`} element={<AllCompanies user={user} />} />
          <Route path={`patchnotes`} element={<PatchNotes />} />
          <Route path={`feedback`} element={<UserFeedback />} />
          <Route path={`devlog`} element={<Devlog />} />
          <Route path={"*"} element={<Navigate to={`users`} />} />
        </Routes>
      </ProjectAdminContain>
    </ProjectAdminPage>
  );
};

const navOptions = [
  { dest: "users", name: "Users", icon: <People /> },
  { dest: "duplicate", name: "Duplicate", icon: <ContentCopy /> },
  { dest: "projects", name: "Projects", icon: <BatchPrediction /> },
  { dest: "companies", name: "Companies", icon: <CorporateFare /> },
  { dest: "patchnotes", name: "Patch Notes", icon: <NoteAdd /> },
  { dest: "feedback", name: "User Feedback", icon: <Feedback /> },
  { dest: "devlog", name: "Devlog", icon: <BugReport /> },
];

const ProjectAdminPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const ProjectAdminContain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
`;
